import CameraSelectionStep from 'camera-module/new-activation/ui/steps/CameraSelectionStep'
import NewActivationCamera from 'camera-module/camera/ui/add-camera-form/camera-select/NewActivationCamera'
import VerifySignal from 'camera-module/new-activation/ui/steps/VerifySignal'
import UpsellingPlansPanel from 'camera-module/new-activation/ui/steps/UpsellingPlansPanel'

const activationSteps = (t, history, activatedModel, setActivatedModel, step, setStep, setShowSteps) => [
  {
    id: 'camera_selection',
    maxWidth: 'lg',
    content: () => <CameraSelectionStep activatedModel={activatedModel} setActivatedModel={setActivatedModel} setShowSteps={setShowSteps} />,
    handlesBackAction: true,
    disabled: !!activatedModel,
    backwardAction: () => history.push({
      pathname: '/add-camera',
      state: {
        data: true,
      },
    }),
    forwardButtonVariety: 'primary',
    forwardButtonText: t('button.continue'),
  },
  {
    id: 'camera_activation',
    maxWidth: 'md',
    content: handleNext => <NewActivationCamera activatedModel={activatedModel} handleNext={handleNext} />,
    removeStepButton: true,
    handlesBackAction: true,
  },
  {
    id: 'camera_startup',
    maxWidth: 'sm',
    content: () => <VerifySignal />,
    disabled: true,
    handlesBackAction: false,
    forwardButtonVariety: 'primary',
    forwardButtonText: t('button.continue'),
  },
  {
    id: 'camera_plan_purchase',
    maxWidth: step !== 2 ? 'lg' : 'max-content',
    content: () => <UpsellingPlansPanel history={history} step={step} setStep={setStep} />,
    disabled: true,
    handlesBackAction: false,
    removeStepButton: step !== 0,
    forwardAction: () => history.push('/confirm-activation'),
    forwardButtonVariety: 'secondary',
    forwardButtonText: t('app:active_device.plans_cancel_button'),
  },
]

const cameraActivationHelper = { activationSteps: activationSteps }
export default cameraActivationHelper
