const buildScoutingPackage = subscriptions => {
  const subscription = findActiveSubscription(subscriptions)
  subscription.plan.species = buildSpeciesByPackage(subscription.plan.name)
  return subscription
}

const findActiveSubscription = subscriptions => {
  const firstPayed = subscriptions.find(subscription => (subscription.isActive && !subscription.plan.isFree))
  const firstFree = subscriptions.find(subscription => subscription.isActive)

  return firstPayed || firstFree
}

const buildSpeciesByPackage = (name) => {
  return {
    CasualHunter: ['buck'],
    ActiveHunter: ['buck', 'deer', 'turkey'],
    AvidHunter: ['buck', 'deer', 'turkey', 'moose', 'coyote', 'wildboar'],
  }[name]
}

export default buildScoutingPackage
