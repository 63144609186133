import IconButton from '@material-ui/core/IconButton'
import { FullHdActive, FullHdAvailable } from 'assets/icons/SpypointIcons'
import { useTranslation } from 'react-i18next'
import useConfirmationDialogActions from 'shared-module/components/confirmation/useConfirmationDialogActions'
import useUser from 'user-module/user/core/useUser'
import DialogDownloadHdVideo from '../core/DialogDownloadHdVideo'
import SpypointDialogBuyHDVideoRedirection from '../core/SpypointDialogBuyHDVideoRedirection'
import SpypointVideoHdIcon from './SpypointVideoHdIcon'

const SpypointVideoHdRequestableButton = ({ inPreview }) => {
  const { t } = useTranslation()
  const downloadHdVideoDialogActions = useConfirmationDialogActions()
  const hdVideoRedirectionDialogActions = useConfirmationDialogActions()
  const user = useUser()
  const shared = JSON.parse(localStorage.getItem('isShared'))
  const handleClick = () => {
    user.addOns.hdVideos > 0
      ? downloadHdVideoDialogActions.open()
      : hdVideoRedirectionDialogActions.open()
  }

  return (
    <>
      <IconButton disabled={shared} color="secondary" onClick={() => handleClick()}>
        { inPreview
          ? (
            <SpypointVideoHdIcon icon={FullHdActive} style={{ border: '1px solid white' }}>
              { t('app:video_viewer.button.download').toUpperCase() }
            </SpypointVideoHdIcon>
            )
          : <SpypointVideoHdIcon icon={FullHdAvailable} inPreview /> }
      </IconButton>
      <DialogDownloadHdVideo
        open={downloadHdVideoDialogActions.isOpen}
        onClose={downloadHdVideoDialogActions.close}
      />
      <SpypointDialogBuyHDVideoRedirection
        open={hdVideoRedirectionDialogActions.isOpen}
        onClose={hdVideoRedirectionDialogActions.close}
      />
    </>
  )
}

export default SpypointVideoHdRequestableButton
