import SvgIcon from '@material-ui/core/SvgIcon'

const CameraSharing = (props) => (
  <SvgIcon {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" data-testid="camerasharing">
    <g clip-path="url(#clip0_19876_1109)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.0945 7.07872C6.50396 7.07872 6.02536 7.55452 6.02536 8.14127C6.02536 8.63783 6.36836 9.05487 6.83154 9.17129L7.60114 7.20553C7.45041 7.12464 7.27768 7.07872 7.0945 7.07872ZM1.74829 2.96191V17.0382H11.6386V2.96191H1.74829ZM3.88985 15.9682C3.8882 15.9729 3.88353 15.9759 3.87858 15.9759H2.82375C2.82045 15.9759 2.8177 15.9734 2.8177 15.9699V4.03047C2.8177 4.02719 2.82045 4.02446 2.82375 4.02446H8.54705C8.55558 4.02446 8.56135 4.03293 8.55833 4.04085L8.13392 5.12499C7.80798 5.01403 7.45839 4.95391 7.09477 4.95391C5.32315 4.95391 3.8871 6.38075 3.8871 8.14099C3.8871 9.44731 4.67816 10.5702 5.81 11.0622L3.88985 15.9682ZM7.0945 10.2658C5.91342 10.2658 4.95596 9.31449 4.95596 8.14099C4.95596 6.96749 5.91342 6.01645 7.0945 6.01645C8.27557 6.01645 9.23276 6.96777 9.23276 8.14099C9.23276 9.31422 8.2753 10.2658 7.0945 10.2658ZM18.2515 4.59373L16.5514 9.46864L15.4542 7.63734L12.7081 9.09041V6.39087L14.2242 5.58904L13.1237 3.75747L18.2515 4.59373ZM14.2241 14.411L13.1236 16.2426L18.2514 15.4063L16.5513 10.5312L15.4541 12.3627L12.708 10.9097V13.6092L14.2241 14.411Z" fill="#FBF9F9" />
    </g>
    <defs>
      <clipPath id="clip0_19876_1109">
        <rect width="16.5032" height="14.0762" fill="white" transform="translate(1.74829 2.96191)" />
      </clipPath>
    </defs>
  </SvgIcon>
)

export default CameraSharing
