import { ReactComponent as AmericanExpressCardImage } from 'spypoint/public/images/card/american-express-card.svg'
import { ReactComponent as VisaCardImage } from 'spypoint/public/images/card/visa-card.svg'
import { ReactComponent as MastercardCardImage } from 'spypoint/public/images/card/mastercard-card.svg'
import { ReactComponent as JCBCardImage } from 'spypoint/public/images/card/jcb-card.svg'
import { ReactComponent as DiscoverCardImage } from 'spypoint/public/images/card/discover.card.svg'
import { ReactComponent as DinersClubCardImage } from 'spypoint/public/images/card/diners-club-card.svg'
import { ReactComponent as UnionPayCardImage } from 'spypoint/public/images/card/union-pay-card.svg'
import { ReactComponent as GenericCardImage } from 'spypoint/public/images/card/generic.svg'

const CardImage = ({ brand }) => {
  return (() => {
    switch (brand) {
      case 'amex':
        return <AmericanExpressCardImage />
      case 'visa':
        return <VisaCardImage />
      case 'mastercard':
        return <MastercardCardImage />
      case 'jcb':
        return <JCBCardImage />
      case 'discover':
        return <DiscoverCardImage />
      case 'diners_club':
        return <DinersClubCardImage />
      case 'diners':
        return <DinersClubCardImage />
      case 'unionpay':
        return <UnionPayCardImage />
      default:
        return <GenericCardImage />
    }
  })()
}

export default CardImage
