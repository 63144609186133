import SvgIcon from '@material-ui/core/SvgIcon'

const TipsIcon = (props) => (
  <SvgIcon {...props} width="16" height="22" viewBox="0 0 16 22" fill="none">
    <path d="M10.1644 18.5002H5.83566C5.80699 18.5 5.72321 18.4999 5.61839 18.4999C5.31173 18.4997 5.10452 18.8136 5.22902 19.0939C5.2806 19.21 5.32377 19.3044 5.33929 19.3313L5.72022 20.1047C5.90959 20.4858 6.20063 20.8072 6.5612 21.0333C6.92176 21.2595 7.40027 21.386 7.76339 21.386H8.22513C8.58112 21.386 9.07997 21.2689 9.44723 21.0424C9.81448 20.8159 10.1109 20.4911 10.3029 20.1047L10.6839 19.3313C10.7 19.3034 10.7437 19.2029 10.795 19.0809C10.9118 18.8032 10.7071 18.4974 10.4058 18.4976C10.2905 18.4976 10.1962 18.4983 10.1644 18.5002Z" fill="#F89021" />
    <path d="M7.99412 0.614014C6.39891 0.616554 4.84605 1.12757 3.56108 2.07285C2.27611 3.01812 1.32595 4.34842 0.848601 5.87054C0.371252 7.39265 0.391575 9.02731 0.906618 10.5371C1.42166 12.0469 2.4046 13.3531 3.71268 14.2662C3.78876 14.3191 3.95502 14.4366 3.95502 14.4366C3.95502 14.4366 3.9565 14.6466 3.95502 14.7393V16.3431C3.95502 16.5791 4.14625 16.7704 4.38226 16.7704C4.6401 16.7704 4.94883 16.7704 5.10905 16.7704H10.8792C11.0394 16.7704 11.3481 16.7704 11.606 16.7704C11.842 16.7704 12.0332 16.5791 12.0332 16.3431V14.4366C12.0332 14.4366 12.2081 14.317 12.2871 14.2662C13.596 13.3526 14.5793 12.0453 15.0941 10.5344C15.6089 9.02345 15.6283 7.38773 15.1494 5.86504C14.6706 4.34236 13.7186 3.01211 12.4317 2.06774C11.1449 1.12336 9.59031 0.614103 7.99412 0.614014Z" fill="#F89021" />

  </SvgIcon>
)

export default TipsIcon
