import SvgIcon from '@material-ui/core/SvgIcon'

const UserIcon = (props) => (
  <SvgIcon {...props} data-testid="UserIcon">
    <path
      d="M15.375 13.887c-1.082 0-1.602.605-3.375.605s-2.29-.605-3.375-.605a5.066 5.066 0 00-5.063 5.062v.965c0 1 .81 1.809 1.81 1.809h13.257c1 0 1.808-.809 1.808-1.809v-.965a5.064 5.064 0 00-5.062-5.062zM12 13.285a5.426 5.426 0 005.426-5.422 5.426 5.426 0 00-10.852 0A5.426 5.426 0 0012 13.285zm0 0"
      fillRule="evenodd"
    />
  </SvgIcon>
)

export default UserIcon
