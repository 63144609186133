import makeStyles from '@material-ui/core/styles/makeStyles'
import MemberFavoriteButton from 'photo-module/photo/ui/photo-viewer/actions-bar/MemberFavoriteButton'
import DeleteButton from 'photo-module/photo/ui/photo-viewer/actions-bar/DeleteButton'
import BackButton from 'photo-module/photo/ui/photo-viewer/actions-bar/BackButton'
import SpypointVideoHdButton from '../../../video-module/core/SpypointVideoHdButton'
import PhotoHdButton from './photo-viewer/full-hd-status/PhotoHdButton'
import useSelectedPhoto from 'photo-module/photo/core/useSelectedPhoto'
import videoState from 'video-module/video-clip/core/videoState'

export const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
  },
  viewerActionsBar: {
    position: 'absolute',
    top: '0',
    background: `linear-gradient(360deg, rgba(0, 0, 0, 0) 24.74%, ${theme.palette.background.viewer} 196.91%)`,
    width: '100%',
    height: '78px',
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 999,
  },
  backActionContainer: {
    marginLeft: '20px',
  },
  photoStatusActionsContainer: {
    [theme.breakpoints.up('sm')]: {
      marginRight: '20px',
    },
    '& svg': {
      '& path': {
        fill: theme.palette.secondary.contrastText,
      },
      height: '25px',
      width: 'auto',
    },
  },
}))

const SpypointViewerActionsBar = () => {
  const classes = useStyles()
  const photo = useSelectedPhoto()
  return (
    <div className={classes.viewerActionsBar}>
      <div className={classes.backActionContainer}>
        <BackButton />
      </div>
      <div className={classes.photoStatusActionsContainer}>
        { videoState.hasPreview(photo) ? <SpypointVideoHdButton photo={photo} /> : <PhotoHdButton /> }
        <MemberFavoriteButton />
        <DeleteButton />
      </div>
    </div>
  )
}

export default SpypointViewerActionsBar
