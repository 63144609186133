import { useSelector } from 'react-redux'

const usePaymentMethods = () => {
  const paymentMethods = useSelector(state => state.paymentMethods)

  if (!paymentMethods.isFetched) return null

  return paymentMethods.list
}

export default usePaymentMethods
