import userApi from 'user-module/user/api/userApi'

const type = 'SET_USER_HD_PACKAGE'

const set = hd => ({ type, hd })
const get = () => dispatch => userApi.getHdPackage().then(hd => dispatch(set(hd)))

const reducer = (state = null, action) => action.type === type ? action.hd : state

const userHdPackageState = { get: get, reducer: reducer }
export default userHdPackageState
