import { combineReducers } from 'redux'
import photos from 'photo-module/photos/core/photos'
import fetchingPhotos from 'photo-module/photos/core/fetchingPhotos'
import lastDate from 'photo-module/photos/core/lastDate'
import allPhotosLoaded from 'photo-module/photos/core/allPhotosLoaded'
import userHdPackageState from 'user-module/hd-package/core/userHdPackageState'
import error from 'photo-module/photos/core/error'

const reducer = combineReducers({
  photos: photos.reducer,
  lastDate: lastDate.reducer,
  fetching: fetchingPhotos.reducer,
  allPhotosLoaded: allPhotosLoaded.reducer,
  fullHdPackage: userHdPackageState.reducer,
  error: error.reducer,
})

export default { reducer: reducer }
