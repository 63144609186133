import PaymentHistoryList from 'spypoint/src/user-module/account/payment/history/PaymentHistoryList'
import PaymentHistoryLoad from 'spypoint/src/user-module/account/payment/history/PaymentHistoryLoad'
import PaymentHistoryEmpty from 'spypoint/src/user-module/account/payment/history/PaymentHistoryEmpty'

const getLastLoadedInvoiceId = (invoices) => {
  return invoices.slice(-1).pop()?.id
}

const PaymentHistoryContent = ({ paymentHistory }) => {
  return (
    <>
      { paymentHistory.invoices.length === 0 && <PaymentHistoryEmpty /> }
      { paymentHistory.invoices.length !== 0 && (
        <PaymentHistoryList
          invoices={paymentHistory.invoices}
        />
      ) }
      { paymentHistory.hasMore && (
        <PaymentHistoryLoad
          lastLoadedInvoiceId={getLastLoadedInvoiceId(paymentHistory.invoices)}
        />
      ) }
    </>
  )
}

export default PaymentHistoryContent
