import useRegions from 'shared-module/countries/useRegions'
import ProfileSelect from 'spypoint/src/user-module/account/profile/preferences/ProfileSelect'

const ProfileTerritory = (props) => {
  const regions = useRegions(props.formik.values.country)

  if (!regions) return null

  const selectItems = regions
    .map(region => ({
      key: region.isoCode,
      name: region.name,
      value: region.isoCode,
    }))

  return (
    <ProfileSelect items={selectItems} {...props} />
  )
}

export default ProfileTerritory
