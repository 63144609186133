import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import OldCartPromoCode from './OldCartPromoCode'
import { FormDataContext } from 'shared-module/new-components/form/FormDataContext'

const useStyles = makeStyles((theme) => ({
  paperWrapper: {
    padding: 30,
    marginTop: 20,
    marginBottom: 20,
    width: '100%',
    background: theme.palette.primary.contrastText,
  },
}))

const CartSection = ({ children, isNewUi }) => {
  const classes = useStyles()

  return (
    <Grid
      item
      container
      display="flex"
      justifyContent="center"
      xs={12}
      sm={8}
      md={6}
      lg={isNewUi ? 6 : 4}
      xl={isNewUi ? 6 : 3}
    >
      <div className={classes.paperWrapper}>
        { children }
      </div>
      <FormDataContext>
        <OldCartPromoCode />
      </FormDataContext>
    </Grid>
  )
}

export default CartSection
