import clubMembershipId from 'spypoint/src/user-module/club-membership-plan/api/clubMembershipId'

const buildUserClubMembership = (scoutingPackage) => ({
  isMember: scoutingPackage.plan.name === clubMembershipId,
  favoriteCount: scoutingPackage.favoriteCount,
  favoriteLimit: scoutingPackage.plan.favoriteLimit,
  filters: scoutingPackage.plan.filters.species,
  isAutoRenew: scoutingPackage.isAutoRenew,
  billingCycleEndDate: new Date(scoutingPackage.endDateBillingCycle),
  stripeSubscriptionId: scoutingPackage.id,
})

export default buildUserClubMembership
