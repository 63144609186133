import { useTranslation } from 'react-i18next'
import CircleAlert from 'assets/icons/CircleAlert'
import { useHistory } from 'react-router-dom'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  menuLink: {
    fontFamily: theme.typography.menuLink?.fontFamily,
    fontSize: theme.typography.menuLink?.fontSize,
    lineHeight: theme.typography.menuLink?.lineHeight,
  },
  item: {
    justifyContent: 'center',
    paddingLeft: theme.spacing(4),
    '&:hover': {
      background: theme.palette.background.paper,
    },
  },
  circleAlert: {
    display: 'flex',
    alignItems: 'center',
  },
}))
const CameraStatusMenuItem = ({ Icon, text, path, onClick, alert, children }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles()

  const navigateToPath = () => {
    onClick()
    history.push(path)
  }

  const itemText = () => {
    if (!alert) {
      return t(text)
    } else {
      return (
        <div className={classes.circleAlert}> { t(text) }
          <CircleAlert style={{ height: '1rem', marginTop: 1, marginLeft: 8 }} />
        </div>
      )
    }
  }
  return (
    <ListItem button className={classes.item} onClick={navigateToPath}>
      <ListItemIcon> <Icon fontSize="small" /> </ListItemIcon>
      <ListItemText primary={itemText()} primaryTypographyProps={{ className: classes.menuLink }} />
      <ListItemIcon>{ children }</ListItemIcon>
    </ListItem>
  )
}

export default CameraStatusMenuItem
