import { useTranslation } from 'react-i18next'
import { Box, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CButton from 'storybook-component-module/src/components/buttons/CButton'

const useStyles = makeStyles((theme) => ({
  discardButton: {
    alignItems: 'flex-end',
    position: 'relative',
    left: 6,
    marginBottom: '1rem',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      left: 0,
    },
  },
}))

const CheckoutDiscardChangesButton = ({ updatePaymentInfoState }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid container justifyContent="flex-end">
      <Grid component={Box} width="100%" xs={12} sm={6}>
        <CButton
          variety="secondary"
          color="primary"
          className={classes.discardButton}
          onClick={() => updatePaymentInfoState(false, false)}
        >{ t('button.discard') }
        </CButton>
      </Grid>
    </Grid>
  )
}

export default CheckoutDiscardChangesButton
