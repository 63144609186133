import { useDispatch, useSelector } from 'react-redux'

import { getNotifications } from './notifications.selectors'
import notificationActions from './notifications.actions'
import TEMPLATE_TYPES from './notificationTemplateNameTypes'

export const useNotifications = () => useSelector(getNotifications)

export const useCameraNotifications = cameraId => {
  const notifications = useNotifications()

  return notifications.filter(notification => notification?.targetId === cameraId)
}

export const useOfflineNotification = cameraId => {
  const cameraNotifications = useCameraNotifications(cameraId)
  return cameraNotifications.find(notification => notification?.templateName === TEMPLATE_TYPES.POST_HSPA_3G_SUNSET)
}

export const useSunsetNotification = cameraId => {
  const cameraNotifications = useCameraNotifications(cameraId)
  return cameraNotifications.find(notification => notification?.templateName === TEMPLATE_TYPES.HSPA_3G_SUNSET)
}

export const useNotificationsActions = () => {
  const dispatch = useDispatch()

  return {
    getAllNotifications: () => dispatch(notificationActions.getAllNotifications()),
    markAsRead: notification => dispatch(notificationActions.setNotificationAsRead(notification)),
    readMultipleNotifications: (templateNames) => dispatch(notificationActions.setMultipleNotificationsAsRead(templateNames)),
  }
}
