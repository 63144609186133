import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  discountLine: {
    color: theme.palette.primary.main,
  },
}))

const DiscountLine = ({ label, children }) => {
  const classes = useStyles()
  return (
    <>
      <Grid xs={9} className={classes.discountLine} item>
        { label }
      </Grid>

      <Grid xs={3} className={classes.discountLine} item>
        <Typography variant="body2" align="right">{ children }</Typography>
      </Grid>
    </>

  )
}

export default DiscountLine
