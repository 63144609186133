import apm from 'shared-module/monitoring/apm'

const cameraMigrationError = (camera, user) => {
  window.open(`https://gtx-chat.secure.force.com/upgrade?e=${user.email}&d=${camera.ucid}`, '_blank')
  apm.log('migration')
}

const cameraActivationMigrationError = (camera, user) => {
  window.open(`https://gtx-chat.secure.force.com/newactivation?e=${user.email}&d=${camera.ucid || camera.status.sim}`, '_blank')
  apm.log('migration')
}

const cameraActions = {
  cameraMigrationError: cameraMigrationError,
  cameraActivationMigrationError: cameraActivationMigrationError,
}

export default cameraActions
