import PaymentHistoryItem from 'spypoint/src/user-module/account/payment/history/PaymentHistoryItem'

const PaymentHistoryList = ({ invoices }) => {
  return (
    <>
      { invoices.map((payment, index) => <PaymentHistoryItem key={payment.id} payment={payment} index={index} />) }
    </>
  )
}

export default PaymentHistoryList
