import { useEffect, useState } from 'react'

import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import { useMediaQuery, useTheme } from '@material-ui/core'

import { getSubscriptions } from 'checkout-module/checkout/api/storagePlanApi'
import CDivider from 'storybook-component-module/src/components/dividers/CDivider'
import PlansPanel from 'spypoint/src/user-module/account/plan-and-subscription/PlansPanel'
import SubscriptionPanel from 'spypoint/src/user-module/account/plan-and-subscription/SubscriptionPanel'
import { useUserClubMembership } from 'spypoint/src/user-module/club-membership/core/useUserClubMembership'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    padding: '1.875rem',
    marginTop: '1.5rem',
    marginBottom: '1.5rem',
    backgroundColor: theme.palette.primary.contrastText,
    [theme.breakpoints.down('md')]: {
      padding: "1.5rem'",
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      flexWrap: 'nowrap',
    },
  },
  divider: {
    backgroundColor: theme.palette.background.tile,
    marginBottom: '1.125rem',
  },
}))

const PlansAndSubscriptionPanel = () => {
  const theme = useTheme()
  const classes = useStyles()
  const userClubMembership = useUserClubMembership()

  const [subscription, setSubscription] = useState([])
  const isSmallResolution = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    getSubscriptions().then((subscription) => setSubscription(subscription))
  }, [])

  return (
    <Container data-testid="accountPlansAndSubscriptions" className={classes.root}>
      <Grid container className={classes.container}>
        <PlansPanel userClubMembership={userClubMembership} subscription={subscription} />

        { !isSmallResolution && <CDivider orientation="vertical" className={classes.divider} /> }

        <SubscriptionPanel userClubMembership={userClubMembership} subscription={subscription} />
      </Grid>
    </Container>
  )
}

export default PlansAndSubscriptionPanel
