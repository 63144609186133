import InformationBar from 'spypoint/src/photos-module/photos/ui/photos-gallery/information-bar/InformationBar'
import AppBar from '@material-ui/core/AppBar'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  appbar: {
    boxShadow: 'none',
    borderBottom: `4px solid ${theme.palette.background.default}`,
    background: theme.palette.background.paper,
    [theme.breakpoints.down('sm')]: {
      marginTop: '56px',
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: '64px',
    },
  },
}))
const PhotoGalleryTopBar = () => {
  const classes = useStyles()
  return (
    <AppBar className={classes.appbar}>
      <InformationBar />
    </AppBar>
  )
}

export default PhotoGalleryTopBar
