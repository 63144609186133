import Grid from '@material-ui/core/Grid'
import { Trans, useTranslation } from 'react-i18next'
import PrimaryButton from 'shared-module/new-components/buttons/PrimaryButton'
import { makeStyles } from '@material-ui/core/styles'
import useOrderNav from 'checkout-module/checkout/core/useOrderNav'
const useStyles = makeStyles(theme => ({
  banner: {
    marginTop: 5,
    outline: `1px solid ${theme.palette.primary.main}`,
    padding: '24px 8px',
  },
  text: {
    color: theme.palette.primary.main,
  },
}))
const ContinueShoppingBanner = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { handleBuyMore } = useOrderNav('device_selection', '/')
  return (
    <Grid container justifyContent="space-around" className={classes.banner}>
      <Grid item xs={6}>
        <Trans i18nKey="app:checkout_process.cart.continue_shopping.description">
          continue.shopping<strong className={classes.text}>discount</strong>
        </Trans>
      </Grid>
      <Grid item xs={5}>
        <PrimaryButton
          onClick={handleBuyMore}
          variant="outlined"
          fullWidth
          data-testid="continue-shopping-button"
        >
          { t('app:checkout_process.cart.continue_shopping.button') }
        </PrimaryButton>
      </Grid>
    </Grid>
  )
}
export default ContinueShoppingBanner
