import { useDispatch } from 'react-redux'
import userPaymentMethodsState from 'spypoint/src/user-module/payment-methods/core/userPaymentMethods.state'

const useUserPaymentMethodsActions = () => {
  const dispatch = useDispatch()

  return {
    get: () => dispatch(userPaymentMethodsState.get()),
    updateDefault: (token) => dispatch(userPaymentMethodsState.updateDefault(token)),
    clear: () => dispatch(userPaymentMethodsState.clear()),
  }
}

export default useUserPaymentMethodsActions
