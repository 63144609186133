import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import RedeemPromoCodesSection from 'user-module/promo-code/ui/redeem-promo-codes/components/RedeemPromoCodesSection'
import Title from 'shared-module/new-components/layout/Title'
import RedeemPromoCodesButton from 'user-module/promo-code/ui/redeem-promo-codes/components/form/RedeemPromoCodesButton'
import PromoCodeField from 'user-module/promo-code/ui/redeem-promo-codes/components/form/PromoCodeField'
import { useForm } from 'shared-module/new-components/form/new/FormContext'

const RedeemPromoCodesForm = () => {
  const { t } = useTranslation()
  const form = useForm()

  return !form.status.isSubmitted
    ? (
      <RedeemPromoCodesSection>
        <Title h="h2">{ t('redeem_promo_code.form.title') }</Title>
        <Grid container alignItems="flex-start" spacing={3}>
          <Grid item>
            <PromoCodeField />
          </Grid>
          <Grid item>
            <RedeemPromoCodesButton />
          </Grid>
        </Grid>
      </RedeemPromoCodesSection>
      )
    : null
}

export default RedeemPromoCodesForm
