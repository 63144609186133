import { combineReducers } from 'redux'
import userPaymentHistoryState from 'spypoint/src/user-module/payment-history/core/userPaymentHistory.state'

import userReducer from 'user-module/user/core/userReducer'
import cameraReducer from 'camera-module/camera/core/cameraReducer'
import cameraSharingReducer from 'camera-module/camera/core/CameraSharingReducer'
import cameraModelsReducer from 'camera-module/model/core/cameraModelsReducer'
import freeTrialActivationModalReducer from 'camera-module/free-trial-activation-modal/core/freeTrialActivationModalReducer'
import messageReducer from 'shared-module/message/messageReducer'
import dialogReducer from 'shared-module/dialog/dialogReducer'
import photoState from 'photo-module/photo/core/photoState'
import filtersState from 'photo-module/filters/core/filtersState'
import galleryPhotos from 'photo-module/photos/core/galleryPhotos'
import appResetState from 'shared-module/app-reset/appResetState'
import camerasState from 'camera-module/cameras/core/camerasState'
import loadingState from 'shared-module/loading/loadingState'
import supportedFiltersState from 'photo-module/filters/core/supportedFiltersState'
import userClubMembershipState from 'spypoint/src/user-module/club-membership/core/userClubMembershipState'
import userPaymentInformationState from 'vosker/src/user-module/payment-information/core/userPaymentInformationState'
import clubMembershipPlanState from 'spypoint/src/user-module/club-membership-plan/core/clubMembershipPlanState'
import { liveStreamingReducer } from 'video-module/live-streaming'
import cameraPlansReducer from 'vosker/src/camera-module/transmission-plan/core/cameraPlans.reducer'
import cartReducer from 'spypoint/src/checkout-module/order/core/cart.reducer'
import userPaymentMethodsState from 'spypoint/src/user-module/payment-methods/core/userPaymentMethods.state'
import NotificationsReducer from 'notifications-module/core/notifications.reducer'
import NotificationsReducerSpypoint from 'spypoint/src/notifications-module/core/notifications.reducer'
import detectionZoneReducer from 'detection-zone-module/core/detectionZone.reducer'
import filterCollectionReducer from 'photo-module/filters-bar/core/filters.reducer'
import sortedCameraModelsReducer from 'camera-module/model/core/sorted-camera-models/sortedCameraModelsReducer'
import activatedCameraReducer from 'camera-module/new-activation/core/activatedCamera.reducer'
import cameraPurposeReducer from 'camera-module/camera/core/cameraPurposeReducer'
import consentReducer from 'consent-module/core/consentReducer'

const appReducer = combineReducers({
  // user
  user: userReducer,
  clubMembership: userClubMembershipState.reducer,
  paymentInformation: userPaymentInformationState.reducer,

  // camera
  activatedCamera: activatedCameraReducer,
  cameras: camerasState.reducer,
  cameraSharing: cameraSharingReducer,
  cameraPlans: cameraPlansReducer,
  selectedCamera: cameraReducer,
  models: cameraModelsReducer,
  sortedModels: sortedCameraModelsReducer,
  freeTrialActivationModal: freeTrialActivationModalReducer,
  detectionZone: detectionZoneReducer,
  cameraPurpose: cameraPurposeReducer,
  consent: consentReducer,

  // photos
  galleryPhoto: galleryPhotos.reducer,
  photo: photoState.reducer,
  filters: filtersState.reducer,
  filterCollection: filterCollectionReducer,
  supportedFilters: supportedFiltersState.reducer,

  // video
  liveStreaming: liveStreamingReducer,

  // plans
  clubMembershipPlan: clubMembershipPlanState.reducer,

  // shared
  message: messageReducer,
  error: dialogReducer,
  loading: loadingState.reducer,

  // cart
  cart: cartReducer,

  // payment methods
  paymentMethods: userPaymentMethodsState.reducer,

  // payment history
  paymentHistory: userPaymentHistoryState.reducer,

  // notifications
  notifications: NotificationsReducer,

  // notifications Spypoint
  notificationsSpypoint: NotificationsReducerSpypoint,
})

const reducer = appResetState.reducer(appReducer)

const appState = { reducer: reducer }
export default appState
