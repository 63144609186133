import clsx from 'clsx'
import React, { Fragment, useState } from 'react'

import Grid from '@material-ui/core/Grid'
import Chip from '@material-ui/core/Chip'
import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/core'
import Container from '@material-ui/core/Container'

import BackArrowButton from 'camera-module/new-activation/ui/components/BackArrowButton'
import ForwardActionButton from 'camera-module/new-activation/ui/components/ForwardActionButton'

export interface ICHorizontalSteps {
  steps: Array<any>
  setRefOfActiveStep: (any) => {}
  showSteps?: boolean
  planIndex: number
}

const useStyles = makeStyles(theme => ({
  box: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  gridContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    height: 'auto',
    padding: '1rem',
    margin: '2rem 1rem',
    background: (props:ICHorizontalSteps) => (props.planIndex === 1 || props.planIndex === 2) ? theme.palette.background.default : theme.palette.background.paper + '4C',
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
    },
  },
  chip: {
    // @ts-ignore
    color: theme.palette.basic?.black,
  },
  activeChip: {
    background: theme.palette.secondary.dark,
  },
  empty: {
    height: '2rem',
  },
}))

const CHorizontalSteps = (props: ICHorizontalSteps) => {
  const classes = useStyles(props)

  const [activeStep, setActiveStep] = useState(0)

  const handleNext = () => {
    setActiveStep(activeStep + 1)
    props.setRefOfActiveStep(activeStep + 1)
  }
  const handleBack = () => {
    setActiveStep(activeStep - 1)
    props.setRefOfActiveStep(activeStep - 1)
  }

  return (
    <>
      <BackArrowButton steps={props.steps} activeStep={activeStep} handleBack={handleBack} />

      { props.showSteps
        ? (
          <Container maxWidth={props.steps[activeStep].maxWidth} className={classes.box}>
            <Grid container item className={classes.gridContainer}>
              { props.steps.map((step, index) => (
                <Fragment key={step.id}>
                  <Grid item id={step.id}>
                    <Chip
                      label={index + 1}
                      className={activeStep === index
                        ? clsx(classes.activeChip, classes.chip)
                        : classes.chip}
                    />
                  </Grid>

                  { index + 1 < props.steps.length && (
                    <Grid item>
                      <Divider orientation="horizontal" style={{ width: '2rem' }} />
                    </Grid>
                  ) }
                </Fragment>
              )) }
            </Grid>
          </Container>
          )
        : <div className={classes.empty} /> }

      { /* Render child component */ }
      <Container className={classes.container} maxWidth={props.steps[activeStep].maxWidth}>
        { props.steps.map((step, index) => activeStep === index && step.content(handleNext)) }
      </Container>

      { !props.steps[activeStep].removeStepButton &&
        <ForwardActionButton steps={props.steps} activeStep={activeStep} handleNext={handleNext} /> }
    </>
  )
}

export default CHorizontalSteps
