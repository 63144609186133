import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import MuiBox from '@material-ui/core/Box'
import { Divider, ListItemText, Menu, MenuItem, Typography, useTheme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import alphabeticalyCellularFirst from 'camera-module/camera/ui/add-camera-form/camera-select/cameraModelSort'
import cameraModelsState from 'camera-module/model/core/cameraModelsState'
import CameraIcon from 'assets/icons/navigation/CameraIcon'
import useMediaQuery from '@mui/material/useMediaQuery'
import TriangleIcon from 'assets/icons/photo/TriangleIcon'

interface ICameraModelSelectProps {
  fieldName: string;
  onChange?: (model: string) => void;
  className?: string;
  disabled?: boolean;
  margin?: 'dense' | 'normal' | 'none';
  models?: Record<string, any>;
  activatedModel: any
  setActivatedModel: (any) => {}
}

const useStyles = makeStyles((theme) => ({
  iconWrapper: {
    position: 'relative',
    height: '40px',
    width: '28px',
    marginLeft: '3px',
  },
  icon: {
    position: 'absolute',
    bottom: '2px',
    width: '94%',
    left: '-4px',
  },
  itemText: {
    position: 'relative',
    width: '295px',
    height: '30px',
    top: '3px',
    left: '30px',
    fontFamily: 'Oswald',
    fontWeight: 500,
    fontSize: '16px',
  },
  color: {
    // @ts-ignore
    backgroundColor: theme.palette.background?.contrast,
  },
  box: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 'auto',
    height: '67px',
    margin: '35px 5px 50px 5px',
    borderRadius: 5,
    padding: '0px 10px 0px 10px',
    justifyContent: 'space-between',
    // @ts-ignore
    background: theme.palette.background?.viewer + '80',
    [theme.breakpoints.down('xs')]: {
      right: 28,
      width: '95vw',
      position: 'relative',
      margin: '20px 5px 30px',
    },
  },
  divider: {
    marginLeft: 12,
    marginRight: 12,
    marginBottom: '0px !important',
    marginTop: '0px !important',
    color: 'white',
    opacity: 0.25,
    borderBottom: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 16,
      marginRight: 16,
    },
  },
  expansion: {
    marginTop: 16,
    marginLeft: 60,
    [theme.breakpoints.down('xs')]: {
      marginTop: 13,
      marginLeft: 8,
      fontSize: theme.typography.pxToRem(20),
    },
  },
  boxItem: {
    color: theme.palette.secondary.dark,
    marginLeft: '7px',
    marginTop: '-1px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
  },
  rectangle: {
    content: "''",
    position: 'absolute',
    left: '-13px',
    top: '15%',
    transform: 'translateY(-26%)',
    width: '51px',
    height: '60px',
    backgroundColor: theme.palette.background.default,
  },
  rectangleCamera: {
    content: "''",
    position: 'absolute',
    left: '3px',
    top: '30%',
    transform: 'translateY(-50%)',
    width: '51px',
    height: '70px',
    backgroundColor: theme.palette.background.default,
    zIndex: 0,
    transition: 'zIndex 0.3s',
  },
  menuItem: {
    position: 'relative',
    '&:hover $rectangleCamera': {
      zIndex: -1,
    },
  },
  selectedModel: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  typography: {
    marginLeft: '26px',
    fontWeight: 500,
    fontSize: '16px',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      marginRight: 10,
      textAlign: 'center',
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 500,
      whiteSpace: 'normal',
    },
  },
}))

// Bypasses Typescript error: cannot be used as a JSX component
const Box: any = MuiBox

const CameraModelSelect = (props: ICameraModelSelectProps) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const classes = useStyles()
  const [selectedModel, setSelectedModel] = useState('')
  const [selectedModelIcon, setSelectedModelIcon] = useState('')
  const [open, setOpen] = useState(false)
  const [menuAnchor, setMenuAnchor] = useState(null)
  const [anchorWidth, setAnchorWidth] = useState(0)
  const breakpoint = useMediaQuery(theme.breakpoints.down('xs'))

  useEffect(() => {
    const findModel = props.models && props.models.find(model => model === props.activatedModel)

    if (findModel === undefined) {
      setSelectedModel('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.activatedModel])

  const getItemStyle = model => selectedModel && selectedModel === model
    ? clsx(classes.box, classes.selectedModel)
    : classes.box

  const getItemStyleMenu = model => selectedModel === model.name
    ? clsx(classes.selectedModel)
    : ''

  const renderModelsRows = () => {
    if (props.models && cameraModelsState.hasModels(props.models)) {
      return Object.values(props.models).sort(alphabeticalyCellularFirst).map((model, index, models) => (
        <React.Fragment key={model.name}>
          <MenuItem
            disableRipple
            value={model.name}
            className={`${classes.color} ${classes.menuItem} ${getItemStyleMenu(model)}`}
            onClick={(event) => handleMenuItemClick(event, model)}
          ><span className={classes.rectangleCamera} />
            <div className={classes.iconWrapper}>
              <img alt={model.name} src={model.icon} className={classes.icon} />
            </div>
            <ListItemText className={classes.itemText} primary={model.name} />
          </MenuItem>
          { index !== models.length - 1 && <Divider /> }
        </React.Fragment>
      ))
    } else {
      return null
    }
  }

  const openMenu = (e) => {
    setOpen(!open)
    setMenuAnchor(e.currentTarget)
    setAnchorWidth(e.currentTarget.offsetWidth)
  }

  const closeMenu = () => setMenuAnchor(null)

  const handleMenuItemClick = (event, model) => {
    props.setActivatedModel(props.activatedModel === undefined || props.activatedModel.name !== model.name ? model : undefined)
    setSelectedModel((prevModel) => (prevModel === model.name ? '' : model.name))
    setSelectedModelIcon(model.icon)
    closeMenu()
  }

  return (
    <div>
      <Box className={clsx(getItemStyle(selectedModel))} onClick={openMenu}>
        <Box display="flex" className={classes.boxItem} flexDirection="row" alignItems="center">
          <span className={classes.rectangle} />
          { selectedModel
            ? (
              <div className={classes.iconWrapper}>
                <img alt={selectedModelIcon} src={selectedModelIcon} className={classes.icon} />
              </div>
              )
            : (
              <CameraIcon style={{
                marginBottom: 15,
                marginRight: 4,
                zIndex: 1,
                position: 'relative',
                top: '7px',
              }}
              />
              ) }
          <Typography noWrap className={classes.typography}>
            { selectedModel
              ? selectedModel.toUpperCase()
              : String(t('app:active_device.steps.step_1.browse_all')).toUpperCase() }
          </Typography>
        </Box>

        { open
          ? <TriangleIcon className={classes.expansion} />
          : <TriangleIcon className={classes.expansion} /> }
      </Box>

      <Menu
        elevation={1}
        open={!!menuAnchor}
        onClose={closeMenu}
        style={{ top: '-6px' }}
        anchorEl={menuAnchor}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        MenuListProps={{
          disablePadding: true,
          autoFocus: false,
          autoFocusItem: false,
          style: { minWidth: anchorWidth },
        }}
      >
        { renderModelsRows() }
      </Menu>
      { breakpoint && <Divider className={classes.divider} style={{ marginRight: 0 }} /> }
    </div>
  )
}

export default CameraModelSelect
