import { useTranslation } from 'react-i18next'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import SpyDollars from 'user-module/promo-code/ui/redeem-promo-codes/components/SpyDollars'

const SpydollarsBalance = () => {
  const { t } = useTranslation()

  return (
    <Box display="flex" justifyContent="center" bgcolor="background.paper" p={1}>
      <Typography component="span" variant="body2" color="textSecondary">{ t('user.spydollars.balance') }:&nbsp;</Typography>
      <Typography component="span" variant="body2"><SpyDollars /></Typography>
    </Box>
  )
}

export default SpydollarsBalance
