const clientSideID = {
  dev: '662ff9e5838c710fd87375d2',
  staging: '662ff9dc03de200f61694cee',
  prod: '662ff9b5838c710fd87375c5',
}

const env = {
  'dev.spypoint.com': 'development',
  'staging.spypoint.com': 'staging',
  'spypoint.com': 'production',
}

const envMapping = {
  'dev.spypoint.com': clientSideID.dev,
  'staging.spypoint.com': clientSideID.staging,
  'spypoint.com': clientSideID.prod,
}

export const getClientSideId = () =>
  envMapping[Object.keys(envMapping).find(key => window.location.hostname.includes(key))] || clientSideID.dev

export const getEnv = () => env[Object.keys(env).find(key => window.location.hostname.includes(key))] || 'development'
