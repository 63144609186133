import restapi from 'shared-module/api/restapi'
import handleError from 'shared-module/api/apiError'

const getAllNotifications = () =>
  restapi.get('/api/v3/notification/all').then(response => (response.data || [])).catch(handleError)

const setAsRead = (notification) =>
  restapi.patch(`/api/v3/notification/${notification.id}/read`, notification).catch(handleError)

const readMultipleNotifications = () =>
  restapi.post('/api/v3/notification/hspa/bulk/read').catch(handleError)

const notificationApi = {
  getAllNotifications,
  setAsRead,
  readMultipleNotifications,
}

export default notificationApi
