import SpypointCameraStatusTile from '../SpypointCameraStatusTile'

const SpypointTemperatureTile = ({ value, disable, user }) => {
  const temperature = (measurementUnits, value, unit) => {
    if (measurementUnits === 'metric' && unit === 'C') return `${value}°${unit}`
    else if (measurementUnits === 'metric' && unit === 'F') return `${Math.round(((value - 32) * 5) / 9)}°C`
    else if (measurementUnits === 'imperial' && unit === 'F') return `${value}°${unit}`
    else if (measurementUnits === 'imperial' && unit === 'C') return `${Math.round((value * 9) / 5 + 32)}°F`
  }
  return (
    <SpypointCameraStatusTile
      label="camera.temperature"
      icon="/images/status/temp.svg"
      value={temperature(user?.measurementUnits, value.value, value.unit)}
      disable={disable}
    />
  )
}

export default SpypointTemperatureTile
