import consentApi from '../api/consentApi'

const setTerritoryRequired = user => ({ type: 'SET_TERRITORY_REQUIRED', user })
const getTerritoryRequired = (user) => dispatch =>
  consentApi.getTerritoryRequired(user)
    .then(response => dispatch(setTerritoryRequired(response)))
const consentActions = {
  setTerritoryRequired,
  getTerritoryRequired,
}

export default consentActions
