import Grid from '@material-ui/core/Grid'
import { useCart } from 'spypoint/src/checkout-module/order/core/cart.hooks'
import webViewDevice from 'checkout-module/checkout/ui/shared/web-view-bridge/webViewDevice'
import ContinueShoppingBanner from '../cart-items/ContinueShoppingBanner'
import InsidersClubBanner from '../cart-items/InsidersClubBanner'

const CartBanner = ({ isInsidersClubMember }) => {
  const { isInsidersClubInCart } = useCart()
  const { isNativeWebview } = webViewDevice()
  const showInsidersClubBanner = !isNativeWebview && !isInsidersClubInCart && !isInsidersClubMember
  const showContinueShoppingBanner = isInsidersClubMember || (!isNativeWebview && isInsidersClubInCart)

  return (
    <Grid item xs={12} sm={8} md={6} lg={4} xl={3}>
      { showInsidersClubBanner && <InsidersClubBanner /> }
      { showContinueShoppingBanner && <ContinueShoppingBanner /> }
    </Grid>
  )
}

export default CartBanner
