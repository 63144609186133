import HdPackageIcon from 'assets/icons/navigation/HdPackageIcon'
import MenuItem from 'shared-module/navigation/menu/MenuItem'
import { useCart } from 'spypoint/src/checkout-module/order/core/cart.hooks'
import { VIDEOHD } from 'spypoint/src/checkout-module/order/core/productTypes'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import switches from '../../../config/switches'
import appConfig from '../../../config/appConfig'

const HdVideoPackageMenuItem = ({ onClick }) => {
  const camera = useSelectedCamera()
  const { addToCart, navigateToCart, updateQuantity, hdVideosItemInCart } = useCart()

  const flexFeatureEnabled = appConfig.flex.switch === switches.on

  const showCart = () => {
    if (hdVideosItemInCart) {
      updateQuantity({
        itemId: hdVideosItemInCart.id,
        quantity: hdVideosItemInCart.quantity + 1,
      })
    } else {
      addToCart({ productId: VIDEOHD })
    }
    onClick()
    navigateToCart()
  }

  const targetedModelsByName = { FLEX: true, 'FLEX-S': true, 'FLEX G-36': true, 'FLEX E-36': true }

  return (camera?.status?.capability?.video || targetedModelsByName[camera?.status?.model]) && flexFeatureEnabled
    ? (
      <MenuItem Icon={HdPackageIcon} text="menu.hd_video_package" onClick={showCart} path="/cart" />
      )
    : null
}

export default HdVideoPackageMenuItem
