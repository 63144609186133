import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Box from '@material-ui/core/Box'
import { getPlans } from 'checkout-module/checkout/api/billingApi'
import ToggleSwitch from './ToggleSwitch'
import { useUserClubMembership } from 'spypoint/src/user-module/club-membership/core/useUserClubMembership'
// Helper imports
import { canTheSubscriptionBeUpgraded } from 'checkout-module/checkout/ui/shared/canTheSubscriptionBeUpgraded'
import NewPlanBlock from 'checkout-module/checkout/ui/transmission-plans-page/new-activation/NewPlanBlock'
import { getSubscriptions } from 'checkout-module/checkout/api/storagePlanApi'
import appConfig from 'shared-module/config/appConfig'
import switches from 'shared-module/config/switches'
import eCommerceApi from 'ecommerce-module/api/eCommerceApi'
import { useECommerceProductsActions } from 'ecommerce-module/core/eCommerce.hook'
import { useDispatch } from 'react-redux'
import { useLDFlags } from 'spypoint/src/launchDarkly-module/launchDarklyFlagsContext'
import { siteId } from 'ecommerce-module/config/default'

const useStyles = makeStyles((theme) => ({
  box: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    padding: '2rem 0',
    [theme.breakpoints.up('md')]: {
      padding: '2rem 10rem 0',
    },
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 0,
    },
  },
  blocks: {
    display: 'flex',
    flexWrap: 'wrap',
    flexGrow: '2',
    maxWidth: '1000px',
    paddingTop: '36px',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
      width: '95%',
    },
  },
  title: {
    color: theme.palette.text.primary,
  },
  subheading: {
    color: theme.palette.text.primary,
    paddingTop: 10,
  },
  toggle: {
    width: '100%',
    height: '100%',
    paddingTop: '2rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      padding: '2rem 1rem 1rem',
    },
  },
  footNotesContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: 30,
    paddingTop: 10,
    gap: '0.2rem',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'center',
      padding: '10px 10px 30px',
      width: '90%',
    },
  },
  footNotes1: {
    fontFamily: theme.typography.caption1?.fontFamily,
    fontSize: theme.typography.caption1?.fontSize,
    color: theme.palette.text.primary,
  },
  footNotes2: {
    fontFamily: theme.typography.caption1?.fontFamily,
    fontSize: theme.typography.caption1?.fontSize,
    color: theme.palette.text?.tertiary,
  },
  spacing: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
      marginBottom: '-25px',
      transform: 'translateY(5px)',
    },
  },
}))

const CameraPlan = ({ history, setStep }) => {
  const [plans, setPlans] = useState(null)
  const classes = useStyles()
  const { t } = useTranslation()
  const monthlyPeriodType = 'month_by_month'
  const annualPeriodType = 'annual'

  const [subscription, setSubscription] = useState([])
  const [selectedOption, setSelectedOption] = useState('annual')
  const userClubMembership = useUserClubMembership()
  const { getProducts } = useECommerceProductsActions()
  const { flags } = useLDFlags()
  const eCommerceEnabled = flags && flags['e-commerce']

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [subscriptionData, plansData] = await Promise.all([
          getSubscriptions(),
          !eCommerceEnabled && getPlans(),
        ])
        setSubscription(subscriptionData)
        if (!eCommerceEnabled && !plans) {
          setPlans(plansData)
        }
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plans])

  useEffect(() => {
    const fetchProducts = async () => {
      if (eCommerceEnabled) {
        const plans = await getProducts()
        setPlans(plans.products)
      }
    }
    fetchProducts()
  }, [eCommerceEnabled])

  const freePhotos = userClubMembership.isMember ? 250 : 100

  const setNewPlans = () =>
    subscription?.plan?.id === 'Free'
      ? [subscription?.plan, ...plans.plans?.filter(plan => plan.period === selectedOption)]
      : plans?.plans?.filter(plan => plan?.period === selectedOption)

  const setLegacyPlans = () =>
    subscription.plan?.isActive
      ? plans?.filter((plan) => plan.isActive)
      : plans?.filter((plan) => (plan.id === 'Basic' || plan.id === 'Standard' || plan.id === 'Premium'))

  const activePlans = eCommerceEnabled ? setNewPlans() : setLegacyPlans()

  return (
    <Box className={classes.box}>
      <Typography variant="h1" align="center" className={classes.title}>
        { t('app:active_device.steps.step_4.header') }
      </Typography>
      <Typography variant="h1" align="center" className={classes.subheading}>
        { t('app:active_device.steps.step_4.subheading') }
      </Typography>
      <Typography variant="h1" className={classes.spacing}>
        <ToggleSwitch
          className={classes.toggle}
          firstOption={t('app:phto_transmition_plan.billing.monthly')}
          secondOption={t('app:photo_transmition_plan.billing.annually')}
          initialSelectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
      </Typography>

      <div className={classes.blocks}>
        { activePlans?.map((plan) => {
          plan.canUpgradeMonthByMonth = canTheSubscriptionBeUpgraded(subscription, plan, monthlyPeriodType)
          plan.canUpgradeAnnual = canTheSubscriptionBeUpgraded(subscription, plan, annualPeriodType)
          return (
            <NewPlanBlock
              key={plan.id}
              plan={plan}
              subscription={subscription}
              periodType={selectedOption}
              pathname={history?.location?.pathname}
              setStep={setStep}
            />
          )
        }) }
      </div>
      <Typography variant="caption" className={classes.footNotesContainer}>
        <div className={classes.footNotes1}>{ t('app:active_device.steps.step_4.note') }</div>
        <div style={{ alignItems: 'center' }} className={classes.footNotes2}>
          { t('app:active_device.steps.step_4.footnote', { freePhotos }) }
        </div>
      </Typography>
    </Box>
  )
}

export default CameraPlan
