import { useField, useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import RadioGroup from '@material-ui/core/RadioGroup'
import MuiRadio from '@material-ui/core/Radio'
import InputLabel from '@material-ui/core/InputLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'

import { getFieldError } from '../util'

const useStyles = makeStyles({
  formControl: {
    paddingTop: '2rem',
    marginLeft: 10,
  },
  radioOption: {
    marginTop: -12,
  },
})

const Radio = ({
  name,
  label,
  required = false,
  color = 'primary',
  labelPlacement = 'end',
  options,
  horizontal = false,
  children,
  ...radioGroupProps
}) => {
  const { isSubmitting } = useFormikContext()
  const [field, meta, helpers] = useField(name)
  const { t } = useTranslation()
  const classes = useStyles()

  const error = getFieldError(meta, t)

  const onMuiRadioChange = (switchChangeEvent) => {
    helpers.setTouched(true, false)
    field.onChange(switchChangeEvent)
  }

  const renderOptions = () => {
    if (children) {
      return children
    }
    if (!Array.isArray(options)) {
      return null
    }
    return options.map(({ label, value }) => (
      <FormControlLabel
        key={value.toString()}
        value={value.toString()}
        control={<MuiRadio color={color} />}
        label={label}
        labelPlacement={labelPlacement}
        className={classes.radioOption}
      />
    ))
  }

  return (
    <FormControl
      fullWidth
      required={required}
      error={!!error}
      disabled={field.disabled || isSubmitting}
      className={classes.formControl}
    >
      { label && <InputLabel shrink>{ label }</InputLabel> }

      <RadioGroup
        name={name}
        row={horizontal}
        {...radioGroupProps}
        {...field}
        value={field.value.toString()}
        onChange={onMuiRadioChange}
      >
        { renderOptions() }
      </RadioGroup>

      { error && <FormHelperText>{ error }</FormHelperText> }
    </FormControl>
  )
}

export default Radio
