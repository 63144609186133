import ProfileRadio from 'spypoint/src/user-module/account/profile/preferences/ProfileRadio'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'

const ProfileLanguage = ({ form, formik, name, legendKey, disabled, items }) => {
  const { i18n } = useTranslation()
  const localStorageLanguage = localStorage.getItem('i18nextLng')

  useEffect(() => {
    if (localStorageLanguage && localStorageLanguage !== formik.values.language) {
      formik.setFieldValue(name, localStorageLanguage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    i18n.changeLanguage(formik.values.language)
  }, [formik.values.language, i18n])

  return (
    <ProfileRadio
      form={form}
      formik={formik}
      name={name}
      legendKey={legendKey}
      disabled={disabled}
      items={items}
    />
  )
}

export default ProfileLanguage
