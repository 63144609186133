import clsx from 'clsx'
import makeStyles from '@material-ui/core/styles/makeStyles'
import CButton from 'storybook-component-module/src/components/buttons/CButton'

const useStyles = makeStyles(theme => ({
  button: ({ isSpypoint }) => ({
    width: '100%',
    height: '3rem',
    borderRadius: 6,
    color: isSpypoint ? theme.palette.primary.main : theme.palette.text.primary,
    borderColor: isSpypoint ? theme.palette.primary.main : theme.palette.secondary.main + ' !important',
    '&:hover': {
      transition: 'opacity .3s',
    },
  }),
}))

const VoskerOutlinedButton = ({ children, className, isSpypoint, ...props }) => {
  const classes = useStyles({ isSpypoint })

  return (
    <CButton {...props} variety="secondary" color="secondary" disableElevation className={clsx(classes.button, className)}>
      { children }
    </CButton>
  )
}

export default VoskerOutlinedButton
