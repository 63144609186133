import { useTranslation } from 'react-i18next'
import { useCart } from 'spypoint/src/checkout-module/order/core/cart.hooks'
import ItemQuantitySelector from 'spypoint/src/checkout-module/order/ui/cart-lines/ItemQuantitySelector'
import CartPriceLine from '../cart-lines/CartPriceLine'
import CartTitleLine from '../cart-lines/CartTitleLine'
import CartQuantityLine from '../cart-lines/CartQuantityLine'
import ItemDiscountLine from '../cart-lines/ItemDiscountLine'
import { VIDEOHD } from '../../core/productTypes'

const HdPackageInCart = ({ product, currency, checkout, disabled, discount }) => {
  const { updateQuantity, deleteFromCart } = useCart()
  const { t } = useTranslation()

  const getPackageTitle = () => {
    if (product.productId === VIDEOHD) return t('app:checkout_process.hd_videos.label')
    else return t('app:checkout_process.hd_photos.label')
  }

  const setQuantity = async (quantity) => {
    if (!disabled) {
      if (quantity > 0) {
        await updateQuantity({ itemId: product.id, quantity: quantity })
      } else {
        await deleteFromCart({ itemId: product.id })
      }
    }
  }
  return (
    <>
      <CartTitleLine> { getPackageTitle() } </CartTitleLine>
      { checkout
        ? (
            product.quantity > 1 && <CartQuantityLine product={product} currency={currency} />
          )
        : (
          <ItemQuantitySelector product={product} setQuantity={setQuantity} currencyCode={currency} checkout={checkout} />
          ) }
      { discount && <ItemDiscountLine product={product} currency={currency} /> }

      <CartPriceLine value={product.subTotal} currencyCode={currency} />
    </>
  )
}

export default HdPackageInCart
