import React, { useState, useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import FirstNameField from 'storybook-component-module/src/wrappers/fields/FirstNameField'
import clsx from 'clsx'
import LastNameField from 'storybook-component-module/src/wrappers/fields/LastNameField'
import { VoskerInputStyles } from 'vosker/src/components/buttons/VoskerInputField'
import TermsCheckbox from 'storybook-component-module/src/wrappers/fields/TermsCheckbox'
import { useTranslation } from 'react-i18next'
import { FormDataContext } from 'shared-module/new-components/form/FormDataContext'
import TerritoryButton from 'user-module/user/ui/register/components/TerritoryButton'
import EmailListCheckbox from 'storybook-component-module/src/wrappers/fields/EmailListCheckbox'
import VoskerSecondaryButton from 'vosker/src/components/buttons/VoskerSecondaryButton'
import CButton from 'storybook-component-module/src/components/buttons/CButton'
import Grid from '@material-ui/core/Grid'
import useUserActions from 'user-module/user/core/useUserActions'
import consentActions from 'consent-module/core/consentActions'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material'
import useUser from 'user-module/user/core/useUser'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import NewsLetterConfirmation from 'spypoint/public/images/NewsLetterComfirmation.png'
import { Box } from '@material-ui/core'
import userActions from 'user-module/user/core/userActions'
import { useDispatch } from 'react-redux'
import useRegions from 'shared-module/countries/useRegions'
import MenuItem from '@material-ui/core/MenuItem'
import ConsentFormButton from 'user-module/user/ui/register/components/ConsentFormButton'
import NewsLetterButtonConfirmation from 'user-module/user/ui/register/components/NewsLetterButtonConfirmation'

const useStyles = makeStyles(theme => ({
  dialog: {
    width: '432px',
    padding: '32px',
    backgroundColor: '#1F1E1E',
    margin: 'auto',
    borderRadius: ({ isNewUi }) => isNewUi ? '8px' : 0,
  },
  imageHelper: {
    textAlign: 'center',
    marginTop: '6px',
  },
  label: {
    marginTop: 10,
  },
  link: {
    color: `${theme.palette.status?.info}`,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  input: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.palette.background?.paper,
      paddingRight: 0,
    },
    '& .MuiOutlinedInput-root.Mui-error': {
      marginBottom: '0.25rem',
    },
  },
  textContent: {
    textAlign: 'left',
    fontSize: 24,
    fontWeight: 500,
    fontFamily: 'oswald-normal',
    marginBottom: ({ isNewUi }) => isNewUi ? '16px' : '24px',
    color: theme.palette.text.primary,
  },
  text: {
    textAlign: 'left',
    fontSize: 16,
    fontWeight: 400,
    fontFamily: 'ibm-plex-sans-regular',
    marginBottom: '55px',
    color: theme.palette.text.primary,
  },
  subTitle: {
    textAlign: 'left',
    fontSize: 16,
    fontWeight: 400,
    fontFamily: 'ibm-plex-sans-regular',
    marginBottom: '35px',
    color: theme.palette.text.primary,
  },
  content: {
    overflowY: 'hidden !important',
  },
}))

const MarketingNewsletterJourneyAConfirmation = ({ onClose, isNewUi = false, setIsOpenTerritoryConfirmationDialog }) => {
  const theme = useTheme()
  const classes = useStyles({ isNewUi })
  const { t } = useTranslation()
  const importedClasses = VoskerInputStyles()
  const [checked, setChecked] = useState(false)
  const [checkedEmail, setCheckedEmail] = useState(false)
  const [value, setValue] = useState('')
  const dispatch = useDispatch()
  const breakpoint = useMediaQuery(theme.breakpoints.only('xs'))
  const LogoutButton = isNewUi ? VoskerSecondaryButton : CButton
  const regions = useRegions('CA')

  return (
    <Dialog
      open
      onClose={onClose}
      disableBackdropClick
      PaperProps={{
        className: classes.dialog,
      }}
    >
      <Box component="div" className={classes.imageHelper}>
        <img src={NewsLetterConfirmation} className={classes.image} />
      </Box>
      <div style={{ alignSelf: 'left', textAlign: 'left' }}>
        <div className={classes.textContent}>
          { t('newsLetterConfirmation.title') }
        </div>
        <div className={classes.text}>
          { t('newsLetterConfirmation.subtitle') }
        </div>
      </div>
      { breakpoint
        ? (
          <Grid container justifyContent="flex-end">
            <Grid xs={12} item style={{ marginBottom: isNewUi ? '8px' : '24px' }} />
            <Grid xs={12} item>
              <NewsLetterButtonConfirmation setIsOpenTerritoryConfirmationDialog={setIsOpenTerritoryConfirmationDialog} isNewUi={isNewUi} checked={checked} checkedEmail={checkedEmail} onClose={onClose} />
            </Grid>
          </Grid>
          )
        : (
          <Grid container justifyContent="flex-end">
            <Grid item style={{ marginRight: isNewUi ? '8px' : '24px' }} />
            <Grid item>
              <NewsLetterButtonConfirmation setIsOpenTerritoryConfirmationDialog={setIsOpenTerritoryConfirmationDialog} isNewUi={isNewUi} checked={checked} onClose={onClose} />
            </Grid>
          </Grid>
          ) }
    </Dialog>
  )
}

export default MarketingNewsletterJourneyAConfirmation
