import { useEffect, useRef, useState } from 'react'
import { useTheme } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Menu from '@material-ui/core/Menu'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import Collapse from '@material-ui/core/Collapse'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import ExpandedIcon from 'assets/icons/photo/ExpandedIcon'
import ExpansionIcon from 'assets/icons/photo/ExpansionIcon'
import { useTranslation } from 'react-i18next'
import useFilterCollection from 'photo-module/filters-bar/core/filters.hooks'
import SpypointFilterLabel from './SpypointFilterLabel'
import { getSelectedFilterCount, isFilterSelected } from 'photo-module/filters-bar/core/filters.selectors'

const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: -4,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      marginLeft: -20,
      width: '-webkit-fill-available',
    },
  },
  box: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 'max-content',
    margin: '4px 10px 4px 4px',
    borderRadius: 5,
    padding: '0px 10px 0px 10px',
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down('xs')]: {
      marginRight: -8,
      width: '-webkit-fill-available',
      justifyContent: 'space-between',
    },
    justifyContent: 'space-between',

  },
  number: {
    width: 12,
    marginLeft: 8,
    marginRight: 8,
    color: theme.palette.primary.main,
  },
  divider: {
    marginLeft: 12,
    marginRight: 12,
    marginBottom: '0px !important',
    marginTop: '0px !important',
    color: theme.palette.background.paper,
    opacity: 0.25,
    borderBottom: `1px solid ${theme.palette.background.filter}`,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 16,
      marginRight: 16,
    },
  },
  expansion: {
    marginTop: 16,
    marginLeft: 8,
  },
  collapse: {
    marginLeft: 16,
    marginRight: -16,
  },
  count: {
    color: theme.palette.background.paper,
    display: 'inline-block',
    paddingLeft: 3,
  },
  boxItem: {},
}))

const SpypointFilterCategory = ({
  Icon,
  label,
  values,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const { t } = useTranslation()
  const breakpoint = useMediaQuery(theme.breakpoints.down('xs'))
  const { selectedFilters } = useFilterCollection()
  const menuListRef = useRef()
  const [open, setOpen] = useState(false)
  const [menuAnchor, setMenuAnchor] = useState(null)
  const [anchorWidth, setAnchorWidth] = useState(0)
  const [filterCount, setFilterCount] = useState(0)
  // eslint-disable-next-line
  useEffect(() => menuAnchor && setAnchorWidth(menuAnchor.offsetWidth))

  useEffect(() => {
    const stringValues = values.map(function (val) {
      if (typeof val !== 'string') {
        return val.nameId
      } else {
        return val
      }
    })
    setFilterCount(getSelectedFilterCount(stringValues, selectedFilters))
  }, [values, selectedFilters])

  const openMenu = (e) => {
    setOpen(!open)
    setMenuAnchor(e.currentTarget)
    setAnchorWidth(e.currentTarget.offsetWidth)
  }

  const closeMenu = () => setMenuAnchor(null)

  const renderChildren = values && values.map((value, index) => {
    const isLatestItem = values.length === index + 1
    const isChecked = isFilterSelected(value, selectedFilters)
    if (typeof value !== 'string') value = value.nameId
    return (
      <div ref={menuListRef} key={value}>
        <SpypointFilterLabel label={value} checked={isChecked} />
        { !isLatestItem && <Divider className={classes.divider} /> }
      </div>
    )
  })

  return (
    <Box data-testid={label} className={classes.root}>
      <Box className={classes.box} style={{ minWidth: 172 }} onClick={openMenu}>
        <Box display="flex" className={classes.boxItem} flexDirection="row" alignItems="center">
          <Icon style={{
            marginBottom: 2,
            marginRight: 4,
          }}
          />
          <Typography variant="body2" mb={0} noWrap>{ t('app:filters.tags.' + label).toUpperCase() }
          </Typography>
          { filterCount !== 0 && <Typography className={classes.number}>({ filterCount })</Typography> }

        </Box>

        { open
          ? <ExpandedIcon className={classes.expansion} />
          : <ExpansionIcon className={classes.expansion} /> }
      </Box>

      { !breakpoint
        ? (
          <Menu
            elevation={1}
            open={!!menuAnchor}
            onClose={closeMenu}
            style={{ top: -6 }}
            anchorEl={menuAnchor}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            MenuListProps={{
              disablePadding: true,
              autoFocus: false,
              autoFocusItem: false,
              style: { minWidth: anchorWidth },
            }}
          >
            { renderChildren }
          </Menu>
          )
        : (
          <Collapse in={open} className={classes.collapse}>
            <List disablePadding>
              { renderChildren }
            </List>
          </Collapse>
          ) }
      { breakpoint && <Divider className={classes.divider} style={{ marginRight: 0 }} /> }
    </Box>
  )
}

export default SpypointFilterCategory
