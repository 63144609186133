import React, { ReactElement, ReactNode } from 'react'

import MuiBox from '@material-ui/core/Box'
import { TextField } from '@material-ui/core'

import { WarningIcon } from 'assets/icons/SpypointIcons'
import { FormikValues } from 'formik'

export interface ICInput {
  label: string
  name?: string
  type?: string
  select?: boolean
  value?: string
  defaultValue?: string
  register?: any
  disabled?: boolean
  error?: { message: string }
  touched?: boolean
  className?: string
  showHints?: boolean
  hints?: ReactElement
  margin?: 'dense' | 'normal' | 'none'
  onChange?: () => any
  onBlur?: () => any
  inputProps?: Object
  selectProps?: Object
  children?: ReactNode
  formik?: FormikValues
}

// Bypasses Typescript error: cannot be used as a JSX component
const Box: any = MuiBox

const CInputText = (props: ICInput) => {
  const errorMessage = props.error && props.touched && <><WarningIcon style={{ marginRight: '5px' }} />{ props.error }</>

  return (
    <Box display="flex" flexDirection="column" mb={0.5}>
      <TextField
        id={props.name}
        name={props.name}
        margin={props.margin || 'dense'}
        variant="outlined"
        defaultValue={props.defaultValue}
        label={props.label}
        type={props.type}
        select={props.select ?? false}
        disabled={props.disabled}
        inputRef={props.register}
        error={props.error && props.touched}
        helperText={errorMessage}
        onChange={props.onChange}
        onBlur={props.onBlur}
        InputProps={props.inputProps}
        FormHelperTextProps={{ style: { marginLeft: '5px' } }}
        children={props.children}
        SelectProps={props.selectProps}
        value={props.value}
      />
      { props.showHints && props.hints }
    </Box>
  )
}

export default CInputText
