export const getCartItemsSelector = (state) => state.cart.cartItems
export const getCartItemCountSelector = (state) => state.cart.cartItems?.products?.length || 0
export const getCartTotalSelector = (state) => state.cart.cartTotal
export const getCartDiscountSelector = (state) => state.cart.cartDiscount
export const getCartTotalBeforeTax = (state) => state.cart.cartBaseBeforeTaxes
export const getCreditCardNotRequired = (state) => state.cart?.creditCardNotRequired
export const getCartTotalTaxesSelector = (state) => state.cart.totalTaxes
export const getCartQuantitySelector = (state) => state.cart.cartItems?.products?.reduce(
  (sum, item) => (sum + (item.quantity || 1)), 0)
export const getErrorSelector = (state) => state.cart.error
export const getMessageSelector = (state) => state.cart.message
