import * as Yup from 'yup'
import { getInitialCell } from 'spypoint/src/user-module/account/profile/info/ProfileCell'

const rules = {}

const validationSchema = () => {
  rules.firstName = Yup.string().required()
  rules.lastName = Yup.string().required()
  rules.address = Yup.string().required()
  rules.apartment = Yup.string()
  rules.postalCode = Yup.string().required('input.postal_code_required')
  rules.city = Yup.string().required()
  rules.country = Yup.string().required()
  rules.territory = Yup.string().required('input.territory_required')

  return Yup.object().shape(rules)
}

const initialValues = user => ({
  firstName: user.firstName || '',
  lastName: user.lastName || '',
  city: user.city || '',
  address: user.address || '',
  apartment: user.apartment || '',
  country: user.country || '',
  territory: user.territory || '',
  postalCode: user.postalCode || '',
  cell: getInitialCell(user),
})

const billingAddressHelpers = { validationSchema, initialValues }
export default billingAddressHelpers
