import notificationApi from 'spypoint/src/notifications-module/api/notifications.api'

export const MARK_AS_READ_SPYPOINT = 'MARK_AS_READ_SPYPOINT'
export const MARK_AS_MULTIPLE_READ_SPYPOINT = 'MARK_AS_MULTIPLE_READ_SPYPOINT'
export const SET_NOTIFICATIONS_SPYPOINT = 'SET_NOTIFICATIONS_SPYPOINT'

const markAsRead = id => ({ type: MARK_AS_READ_SPYPOINT, id })
const markAsMultipleRead = templateNames => ({ type: MARK_AS_MULTIPLE_READ_SPYPOINT, templateNames })
const setNotifications = notifications => ({ type: SET_NOTIFICATIONS_SPYPOINT, notifications })

const getAllNotifications = () => dispatch => notificationApi.getAllNotifications()
  .then(notifications => (notifications.length > 0) && dispatch(setNotifications(notifications)))

const setNotificationAsRead = (notification) => dispatch => {
  dispatch(markAsRead(notification.id))

  return notificationApi.setAsRead(notification)
}

const setMultipleNotificationsAsRead = (templateNames) => dispatch => {
  dispatch(markAsMultipleRead(templateNames))

  return notificationApi.readMultipleNotifications()
}

const notificationActions = {
  getAllNotifications,
  setNotificationAsRead,
  setMultipleNotificationsAsRead,
}

export default notificationActions
