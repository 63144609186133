import { useTranslation } from 'react-i18next'

import { useFormik } from 'formik'
import * as Yup from 'yup'

import { Box } from '@material-ui/core'

import useUser from 'user-module/user/core/useUser'
import errorState from 'user-module/user/ui/profile/errorState'
import useUserActions from 'user-module/user/core/useUserActions'
import useMessageActions from 'shared-module/message/useMessageActions'
import ProfilePassword from 'user-module/user/ui/password/ProfilePassword'
import ProfileSave from 'spypoint/src/user-module/account/profile/ProfileSave'
import { FormDataContext } from 'shared-module/new-components/form/FormDataContext'
import { validateCell } from 'spypoint/src/user-module/account/profile/info/ProfileCell'
import ProfileInfo, { userInfoInitialValues, userInfoValidationSchema } from 'spypoint/src/user-module/account/profile/info/ProfileInfo'
import ProfilePreferences, { userPreferencesInitialValues, userPreferencesValidationSchema } from 'spypoint/src/user-module/account/profile/preferences/ProfilePreferences'

const userInitialValues = (user) => ({
  ...userInfoInitialValues(user),
  ...userPreferencesInitialValues(user),
})

const userValidationSchema = Yup.object({
  ...userInfoValidationSchema,
  ...userPreferencesValidationSchema,
})

const buildUpdateUser = (values, userId) => ({
  ...values,
  id: userId,
  notificationEmail: values.notificationEmail === 'yes',
  timeFormat: values.timeFormat === '24' ? 24 : 12,
})

const ProfilePanel = () => {
  const user = useUser()
  const { updateExtended } = useUserActions()
  const { showMessage, showError } = useMessageActions()
  const { t, i18n } = useTranslation()
  const readOnly = user.isReadOnlyProfile
  const validationSchema = Yup.object().shape({
    current: Yup.string().required(t('register.form.hints.required')),
    new: Yup.string()
      .min(10, t('form.password-hint.simple-validation'))
      .required(t('input.password_required')),
  })

  const submitUserForm = (values) => {
    const updateUser = buildUpdateUser(values, user.id)
    updateExtended(updateUser)
      .then(() => {
        i18n.changeLanguage(updateUser.language)
        showMessage('profile.update')
      })
      .catch(error => {
        if (!errorState.isHandled(error)) {
          showError('profile.error.text')
        }
      })
  }

  const formikUser = useFormik({
    initialValues: userInitialValues(user),
    validationSchema: userValidationSchema,
    validate: validateCell,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: submitUserForm,
  })
  return (
    <Box data-testid="accountProfile">
      <form id="user-form" onSubmit={formikUser.handleSubmit} />
      <ProfileInfo
        form="user-form"
        formik={formikUser}
        readOnly={readOnly}
      />

      <FormDataContext>
        <ProfilePassword readOnly={readOnly} variation="secondary" validationSchema={validationSchema} />
      </FormDataContext>

      <ProfilePreferences
        form="user-form"
        formik={formikUser}
        readOnly={readOnly}
      />
      <ProfileSave form="user-form" />
    </Box>
  )
}

export default ProfilePanel
