import { FETCH_SORTED_CAMERA_MODELS, SET_SORTED_CAMERA_MODELS } from 'camera-module/model/core/sorted-camera-models/sortedCameraModelsActionTypes'
import sortedCameraModelState from 'camera-module/model/core/sorted-camera-models/sortedCameraModelState'

const sortedCameraModelsReducer = (state = sortedCameraModelState.init(), action) => {
  switch (action.type) {
    case FETCH_SORTED_CAMERA_MODELS:
      return sortedCameraModelState.fetching()
    case SET_SORTED_CAMERA_MODELS:
      return sortedCameraModelState.set(action.models)
    default:
      return state
  }
}

export default sortedCameraModelsReducer
