import handleError from 'shared-module/api/apiError'
import restapi from 'shared-module/api/restapi'

const getCartItems = () => {
  return restapi.get('/api/v3/user/cart')
    .then(result => result.data.cart)
    .catch(handleError)
}

const getCheckoutItems = () => {
  return restapi.get('/api/v3/user/cart/checkout')
    .then(result => result.data.cart)
    .catch(handleError)
}

const placeOrder = (customerToken) => {
  return restapi
    .post('/api/v3/user/cart/checkout', customerToken && { customerToken })
    .then(result => result.data)
}

const addCartItem = ({ productId, cameraId, paymentFrequency, plan }) => {
  const newCart = {
    productId,
    cameraId,
    paymentFrequency,
    plan,
  }

  return restapi.post('/api/v3/user/cart', newCart)
    .then(result => result.data.cart)
    .catch(handleError)
}

const removeCartItem = (itemId) => {
  return restapi.delete(`/api/v3/user/cart/item/${itemId}`)
    .then(result => result.data.cart)
    .catch(handleError)
}

const updateQuantity = ({ itemId, quantity }) => {
  return restapi.put(`/api/v3/user/cart/item/${itemId}/quantity`, { quantity })
    .then(result => result.data.cart)
    .catch(handleError)
}

const updatePaymentFrequency = ({ itemId, paymentFrequency }) => {
  return restapi.put(`/api/v3/user/cart/item/${itemId}/frequency`,
    { paymentFrequency }).then(result => result.data.cart).catch(handleError)
}

const applyPromoCode = (promocode) => {
  return restapi.post('/api/v3/user/cart/promocode', { promocode })
    .then(result => result.data.cart)
}
const cartApi = {
  getCartItems,
  addCartItem,
  removeCartItem,
  updateQuantity,
  updatePaymentFrequency,
  getCheckoutItems,
  placeOrder,
  applyPromoCode,
}
export default cartApi
