export const canTheSubscriptionBeUpgraded = (currentSubscription, plan, frequency, eCommerceEnabled) => {
  const currentPlan = currentSubscription.plan

  if (currentSubscription.isFree && !currentPlan.isFree) {
    // Premium FREE plan
    return false
  }
  if (currentPlan?.isFree && currentSubscription?.isFree) {
    // FREE plan
    return true
  }
  // A user can only upgrade to a plan with the same frequency as the current one.
  if (currentSubscription.paymentFrequency === frequency) {
    return eCommerceEnabled
      ? frequency === 'month_by_month' ? currentPlan.pricePerMonthIfPaidPerMonth < plan.price : currentPlan.pricePerMonthIfPaidAnnually < plan.price
      : currentPlan.pricePerMonthIfPaidPerMonth < plan.pricePerMonthIfPaidPerMonth || currentPlan.price < plan.price
  } else {
    return false
  }
}
