const init = () => ({ fetching: false })
const set = sortedModels => ({ fetching: false, sortedModels: sortedModels })
const fetching = () => ({ fetching: true })

const isReady = state => isFetching(state) || hasModels(state.sortedModels)
const isFetching = state => state.fetching === true
const hasModels = sortedModels => sortedModels !== undefined

const sortedCameraModelState = { init, fetching, set, isReady, hasModels }
export default sortedCameraModelState
