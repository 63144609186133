import { useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import { useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import SpypointFilterList from './SpypointFilterList'
import SelectedFiltersList from './SelectedFiltersList'
import useFilterCollection from 'photo-module/filters-bar/core/filters.hooks'
import FilterDrawer from 'photo-module/filters-bar/ui/FilterDrawer'
import FiltersTopStatusBar from 'photo-module/filters-bar/ui/FiltersTopStatusBar'
import { useLoadClubMembershipPlan } from '../../../user-module/club-membership-plan/core/useClubMembershipPlan'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  button: {
    margin: '4px 10px 4px 4px',
  },
  clearIcon: {
    margin: '8px 0 -8px 0',
    '&:hover': {
      color: theme.palette.text.secondary,
    },
  },
}))

const SpypointNewFiltersBar = () => {
  const theme = useTheme()
  const classes = useStyles()
  const breakpoint = useMediaQuery(theme.breakpoints.down('xs'))
  const {
    filterCollection,
    selectedFilters,
    clearSelectedFilters,
    fetchFilters,
  } = useFilterCollection()

  const [filterKeys, setFilterKeys] = useState([])
  const [filterValues, setFilterValues] = useState([])
  const plan = useLoadClubMembershipPlan()
  useEffect(() => {
    fetchFilters()
    clearSelectedFilters()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (filterCollection && filterCollection.species && plan?.filters && plan?.filters.length !== 0) {
      filterCollection.species = filterCollection.species.filter(species => {
        return plan.filters.some(supported => {
          return supported === species.nameId
        })
      })
      setFilterKeys(Object.keys(filterCollection).reverse())
      setFilterValues(Object.values(filterCollection).reverse())
    }
  }, [filterCollection, selectedFilters, plan])
  return (
    <Box className={classes.container}>
      <FiltersTopStatusBar />

      { !breakpoint
        ? <SpypointFilterList filterKeys={filterKeys} filterValues={filterValues} />
        : <FilterDrawer filterKeys={filterKeys} filterValues={filterValues} FilterList={SpypointFilterList} /> }
      <SelectedFiltersList selectedFilters={selectedFilters} />
    </Box>
  )
}

export default SpypointNewFiltersBar
