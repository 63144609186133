import { useEffect, useState } from 'react'
import { Box, Container, Grid, makeStyles, TextField } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

import shopperApi from 'ecommerce-module/api/shopperApi'
import CButton from 'storybook-component-module/src/components/buttons/CButton'
import CTypography from 'storybook-component-module/src/components/typographies/CTypography'
import useUser from 'user-module/user/core/useUser'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CircularProgress from '@material-ui/core/CircularProgress'
import PlanBlockTitle from 'checkout-module/transmission-plans/ui/transmission-plans-page/PlanBlockTitle'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import FormatedPrice from 'checkout-module/checkout/ui/transmission-plans-page/FormatedPrice'
import { useBasket } from './BasketContext'
import BasketPage from './basket-page/BasketPage'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '150px',
    overflow: 'hidden',
    background: theme.palette.background.contrast,
    marginRight: 'auto',
    marginLeft: 'auto',
    padding: 24,
  },
  wait: {
    marginBottom: 4,
    color: theme.palette.text.secondary,
  },
  success: {
    marginBottom: 8,
    color: theme.palette.text.primary,
    paddingRight: 4,
  },
  spinner: {
    marginTop: 6,
    marginLeft: 8,
    width: '20px !important',
    height: '20px !important',
  },
  check: {
    fill: '#00a1e0 !important',
    color: theme.palette.basic.white,
    marginTop: 4,
  },
  block: {
    height: '297px',
    width: '235px',
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '7px auto',
    },
    margin: '7px',
    fontSize: '10pt',
    borderRadius: '0',
  },
  triangle: {
    width: 0,
    height: 0,
    borderLeft: '116px solid transparent',
    borderRight: '117px solid transparent',
    borderTop: `22px solid ${theme.palette.secondary.main}`,
    [theme.breakpoints.down('xs')]: {
      margin: '0 auto',
      borderLeft: '135px solid transparent',
      borderRight: '135px solid transparent',
      borderTop: `17px solid ${theme.palette.secondary.main}`,
    },
  },
  brightTriangle: {
    borderTopColor: theme.palette.primary.main,
  },
  photoCount: {
    textAlign: 'center',
    fontSize: '16px',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'right',
      paddingRight: '12px',
      marginTop: '-31px',
      marginBottom: '8px',
      fontSize: '19px',
    },
  },
  mainPrice: {
    textAlign: 'center',
    display: 'inline-block',
    fontSize: '40px',
    margin: '10px 0',
    [theme.breakpoints.down('xs')]: {
      fontSize: '50px',
    },
  },
  buy: {
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
}))

const ProductsListing = () => {
  const classes = useStyles()
  const user = useUser()
  const history = useHistory()

  const [setCustomer] = useState(null)
  const [product, setProduct] = useState(null)
  const [products, setProducts] = useState(null)
  const [isConnected, setIsConnected] = useState(false)
  const [isCartVisible, setCartVisible] = useState(false)
  const { addProductToBasket, navigateToCart } = useBasket()

  useEffect(() => connectToSalesForce(), [])

  const connectToSalesForce = () => {
    setTimeout(() => {
      setIsConnected(true)
    }, 1000)
  }

  const newCartItem = product => [{
    productId: product?.id,
    quantity: product?.stepQuantity,
    price: product?.price,
    productName: product?.name,
    itemText: product?.primaryCategoryId,
  }]

  const addPhotoTransmissionPlanToBasket = async (product) => {
    try {
      await addProductToBasket(newCartItem(product))
      navigateToCart(history)
      setCartVisible(true)
    } catch (error) {
      console.error('Failed to add product to basket:', error)
    }
  }
  const fetchProduct = e => {
    setProducts(null)
    e.preventDefault()
    shopperApi
      .getProductById(e.target[0].value)
      .then(product => setProduct(product))
      .catch(e => console.log('e', e))
  }

  const fetchAllProducts = () => {
    setProduct(null)
    shopperApi
      .getProductsByIds('D06121,D06131,D06141')
      .then(products => setProducts(products))
      .catch(e => console.log('e', e))
  }

  const fetchCustomer = () => shopperApi
    .getCustomer()
    .then(product => setCustomer(product))
    .catch(e => console.log('e', e))

  const submit = e => {
    e.preventDefault()
    const login = e.target[0].value
    const password = e.target[1].value

    shopperApi
      .registerCustomer(login, password, user)
      .then(customer => setCustomer(customer))
      .catch(e => console.log('e', e))
  }

  const cssClasses = plan => plan?.photoCountPerMonth
    ? classes.triangle
    : classes.triangle + ' ' + classes.brightTriangle

  const renderPlanTile = product => (
    <Box display="flex" justifyContent="center">
      <Paper className={classes.block}>
        <div className={cssClasses(product)} />
        <PlanBlockTitle title={product.name} />

        <Typography variant="h5" className={classes.photoCount}>
          { product?.photoCountPerMonth > 0 ? product?.photoCountPerMonth + ' photos' : 'Unlimited photos' }
        </Typography>

        <Box component="div" align="center" mt={4}>
          <Typography className={classes.mainPrice} variant="h2">
            <FormatedPrice price={product.period === 'annual' ? (product.price / 12) : product.price} />
          </Typography>
          <Typography component="h6" variant="h6" fontWeight={700} style={{ display: 'inline-block' }}>/MO</Typography>
        </Box>

        <Box display="flex" justifyContent="center" mt={4}>
          <CButton
            color="primary"
            variety="primary"
            className={classes.buy}
            onClick={() => addPhotoTransmissionPlanToBasket(product)}
          >ADD TO CART
          </CButton>
        </Box>
      </Paper>
    </Box>
  )

  return (
    <Container maxWidth="md" className={classes.root}>
      { !isConnected
        ? (
          <Box display="flex" flexDirection="row">
            <CTypography variant="h1" className={classes.wait}>Is connection to SalesForce</CTypography>
            <CircularProgress className={classes.spinner} />
          </Box>
          )
        : (
          <>
            <Box display="flex" flexDirection="row">
              <CTypography variant="h1" className={classes.success}>Connexion with SalesForce Successful</CTypography>
              <CheckCircleIcon className={classes.check} />
            </Box>

            <Box display="flex" alignItems="center" mb={4} style={{ gap: 16 }}>
              <img
                alt=""
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/f9/Salesforce.com_logo.svg/1280px-Salesforce.com_logo.svg.png"
                width="15%"
                height="15%"
              />
              <CTypography variant="body1" className={classes.success}>Shopper API is ready to be accessed through public client starting with 5f3558db</CTypography>
            </Box>
          </>
          ) }

      { isConnected && (
        <div style={{ marginTop: 6 }}>
          { isCartVisible
            ? (
              <BasketPage />
              )
            : (
              <>
                <CTypography variant="h2" marginBottom={0.5}>Fetch test products by id & category</CTypography>

                <form onSubmit={fetchProduct}>
                  <TextField label="Plan ID" variant="outlined" margin="normal" size="small" style={{ marginRight: 8 }} />
                  <CButton type="submit" color="primary" variety="primary" style={{ marginTop: 16 }}>FETCH DATA PLAN</CButton>
                  <br />
                </form>

                <CButton color="primary" variety="secondary" onClick={fetchAllProducts}>FETCH All DATA PLANS</CButton>

                { product && renderPlanTile(product) }
                { products && (
                  <Grid container justifyContent="center" style={{ marginTop: 8 }}>
                    { products?.map(plan => <Grid item>{ renderPlanTile(plan) }</Grid>) }
                  </Grid>
                ) }

                <Box mt={4} mb={2}>
                  <CTypography variant="h2">Register a new customer</CTypography>
                  <form onSubmit={submit}>
                    <br />
                    <TextField label="Login" variant="outlined" margin="dense" size="small" style={{ marginTop: -4 }} />
                    <br />
                    <TextField type="password" label="Password" variant="outlined" margin="dense" size="small" style={{ marginRight: 8 }} />
                    <br /><br />

                    <Grid container>
                      <CButton type="submit" color="primary" variety="primary">SUBMIT</CButton>
                      <Box mr={2} />
                      <CButton onClick={fetchCustomer} color="primary" variety="secondary">FETCH CUSTOMER INFO</CButton>
                    </Grid>
                  </form>
                </Box>
              </>
              ) }
        </div>
      ) }

    </Container>
  )
}

export default ProductsListing
