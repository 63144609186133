import { useDispatch } from 'react-redux'
import userHdPackageState from 'user-module/hd-package/core/userHdPackageState'

const useUserHdPackageActions = () => {
  const dispatch = useDispatch()

  return {
    get: () => dispatch(userHdPackageState.get()),
  }
}

export default useUserHdPackageActions
