import { useTheme } from '@material-ui/core'
import SvgIcon from '@material-ui/core/SvgIcon'

const FreeHDPhotosIcon = (props) => {
  const theme = useTheme()

  return (
    <SvgIcon data-testid="FreeHDPhotosIcon" viewBox="0 0 101 101" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M63.4908 53.3469V78.84H68.4695C71.2801 78.84 72.9664 77.4519 72.9664 73.5961V58.5909C72.9664 54.735 71.2801 53.3469 68.4695 53.3469H63.4908Z"
        fill={theme.palette.primary.main}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M81.8606 10.4214H21.5992C16.781 10.4214 12.875 14.2884 12.875 19.0585V85.4717C12.875 90.2418 16.781 94.1089 21.5992 94.1089H81.8606C86.6788 94.1089 90.5848 90.2418 90.5848 85.4717V19.0585C90.5848 14.2884 86.6788 10.4214 81.8606 10.4214ZM21.4679 40.0688H26.4894V32.5536H31.9944V27.5153H26.4894V22.8903H32.9987V17.9784H21.4679V40.0688ZM75.4803 35.1569V17.9784H70.4588V40.0688H81.2457V35.1569H75.4803ZM30.086 86.552H21.4819V45.6354H30.086V62.2379H39.8633V45.6354H48.6238V86.552H39.8633V69.9496H30.086V86.552ZM54.6591 45.6354H68.6315C77.4647 45.6354 81.8009 50.3396 81.8009 58.9767V73.2107C81.8009 81.8479 77.4647 86.552 68.6315 86.552H54.6591V45.6354ZM60.1007 17.9784H55.0792V40.0688H65.8661V35.1569H60.1007V17.9784ZM45.7353 17.9784H50.7568V33.9199C50.7568 37.9585 48.0787 40.6509 43.8755 40.6509C39.6724 40.6509 36.9942 37.9585 36.9942 33.9199V17.9784H42.0157V33.9199C42.0157 35.0114 42.6852 35.7391 43.8755 35.7391C45.0658 35.7391 45.7353 35.0114 45.7353 33.9199V17.9784Z"
        fill={theme.palette.primary.main}
      />

    </SvgIcon>
  )
}

export default FreeHDPhotosIcon
