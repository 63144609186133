import { useDispatch } from 'react-redux'
import userPaymentHistoryState from './userPaymentHistory.state'

const usePaymentHistoryActions = () => {
  const dispatch = useDispatch()

  return {
    getPaymentHistory: (lastLoadedInvoiceId) => dispatch(userPaymentHistoryState.get(lastLoadedInvoiceId)),
    clearPaymentHistory: () => dispatch(userPaymentHistoryState.clear()),
  }
}

export default usePaymentHistoryActions
