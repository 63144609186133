import { Box, CircularProgress } from '@material-ui/core'
import { useEffect } from 'react'
import usePaymentHistory from 'spypoint/src/user-module/payment-history/core/usePaymentHistory'
import usePaymentHistoryActions from 'spypoint/src/user-module/payment-history/core/usePaymentHistoryActions'
import PaymentHistoryTitle from 'spypoint/src/user-module/account/payment/history/PaymentHistoryTitle'
import PaymentHistoryContent from 'spypoint/src/user-module/account/payment/history/PaymentHistoryContent'

const PaymentHistory = () => {
  const paymentHistory = usePaymentHistory()
  const { getPaymentHistory, clearPaymentHistory } = usePaymentHistoryActions()

  useEffect(() => {
    getPaymentHistory()
    return clearPaymentHistory
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!paymentHistory) return <CircularProgress />

  return (
    <Box
      height="100%"
      borderLeft={{
        xs: 'none',
        sm: 2,
      }}
      borderColor={{
        xs: 'none',
        sm: 'divider',
      }}
    >
      <PaymentHistoryTitle />
      <PaymentHistoryContent paymentHistory={paymentHistory} />
    </Box>
  )
}

export default PaymentHistory
