import { Grid, Typography } from '@material-ui/core'

const CartTitleLine = ({ children }) => {
  return (
    <Grid item xs={12}>
      <Typography style={{ fontWeight: 'bold' }} variant="body2">{ children }</Typography>
    </Grid>
  )
}

export default CartTitleLine
