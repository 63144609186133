import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const BatteryType = ({ props }) => {
  const theme = useTheme()
  return (
    <SvgIcon width="32" height="32" viewBox="0 0 32 32" {...props} data-testid="BatteryType">
      <path
        d="M2 7.33594V28.5547H29.9453V7.33594H2ZM17.4492 22.0469L14.8242 19.0938L8.23438 25.8477L14.4414 13.8711L17.0664 16.8242L23.6562 10.0703L17.4492 22.0469ZM11.5703 4.41016C11.5703 4.30078 11.5247 4.20964 11.4336 4.13672C11.3607 4.04557 11.2695 4 11.1602 4H5.33594C5.22656 4 5.1263 4.04557 5.03516 4.13672C4.96224 4.20964 4.92578 4.30078 4.92578 4.41016V6.40625H11.5703V4.41016ZM26.9922 4.41016C26.9922 4.30078 26.9466 4.20964 26.8555 4.13672C26.7826 4.04557 26.6823 4 26.5547 4H20.7578C20.6302 4 20.5208 4.04557 20.4297 4.13672C20.3568 4.20964 20.3203 4.30078 20.3203 4.41016V6.40625H26.9922V4.41016Z"
        fill={theme.palette.background.logo}
      />
    </SvgIcon>
  )
}

export default BatteryType
