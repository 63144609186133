import Paper from '@material-ui/core/Paper'
import { Box, Grid } from '@material-ui/core'
import PaymentMethod from 'spypoint/src/user-module/account/payment/method/PaymentMethod'
import PaymentHistory from 'spypoint/src/user-module/account/payment/history/PaymentHistory'

const PaymentPanel = () => {
  return (
    <Box
      component={Paper}
      elevation={3}
      bgcolor="primary.contrastText"
      marginTop={3}
      minHeight="65vh"
      padding={{
        xs: 2,
        md: 4,
      }}
      marginBottom={3}
      square
      data-testid="accountPayment"
    >
      <Box component={Grid} minHeight={{ sm: '60vh' }} container>
        <Grid xs={12} sm={6} item>
          <PaymentMethod />
        </Grid>
        <Grid xs={12} sm={6} item>
          <PaymentHistory />
        </Grid>
      </Box>
    </Box>
  )
}

export default PaymentPanel
