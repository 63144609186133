import { useTheme } from '@material-ui/core'
import SvgIcon from '@material-ui/core/SvgIcon'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  sizing: {
    width: '40px !important',
    height: '40px !important',
  },
}))

const ValideSolid = (props) => {
  const theme = useTheme()
  const classes = useStyles()
  return (
    <SvgIcon
      className={classes.sizing}
      data-testid="ValideSolid"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Icons / Label Icons / Validate-solid">
        <path
          id="Subtract"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M20 35.625C28.9746 35.625 36.25 28.3496 36.25 19.375C36.25 10.4004 28.9746 3.125 20 3.125C11.0254 3.125 3.75 10.4004 3.75 19.375C3.75 28.3496 11.0254 35.625 20 35.625ZM28.4684 10.1017C27.489 9.39438 26.1216 9.61492 25.4142 10.5943L16.6499 22.7296L12.9862 19.5893C12.0689 18.803 10.6879 18.9092 9.90167 19.8265C9.11543 20.7438 9.22166 22.1248 10.1389 22.911L15.6077 27.5985C16.0722 27.9966 16.6821 28.1821 17.2896 28.1099C17.8971 28.0376 18.4465 27.7143 18.8047 27.2184L28.961 13.1559C29.6683 12.1765 29.4478 10.8091 28.4684 10.1017Z"
          fill={theme.palette.primary.main}
        />
      </g>
    </SvgIcon>
  )
}

export default ValideSolid
