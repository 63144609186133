import { Box, CircularProgress, Typography } from '@material-ui/core'
import StripeProvider from 'spypoint/src/checkout-module/order/ui/checkout-page/payment/StripeProvider'
import PaymentCardUpdate from 'spypoint/src/user-module/account/payment/method/PaymentMethodUpdate'
import PaymentMethodDisplay from 'spypoint/src/user-module/account/payment/method/PaymentMethodDisplay'
import PaymentMethodAdd from 'spypoint/src/user-module/account/payment/method/PaymentMethodAdd'
import { useEffect, useState } from 'react'
import useDefaultPaymentMethod from 'spypoint/src/user-module/payment-methods/core/useDefaultPaymentMethod'
import usePaymentMethodsActions from 'spypoint/src/user-module/payment-methods/core/usePaymentMethodsActions'

const PaymentMethod = () => {
  const defaultPaymentMethod = useDefaultPaymentMethod()
  const { getPaymentMethods, clearPaymentMethods } = usePaymentMethodsActions()
  const [updateDefaultCard, setUpdateDefaultCard] = useState(false)

  const openUpdateDefaultCard = () => setUpdateDefaultCard(true)
  const closeUpdateDefaultCard = () => setUpdateDefaultCard(false)

  useEffect(() => {
    getPaymentMethods()
    return clearPaymentMethods
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!defaultPaymentMethod) return <CircularProgress />

  return (
    <Box color="text.tertiary">
      <Typography variant="h3">Payment Method</Typography>
      <Box marginY={4}>
        { !updateDefaultCard && !defaultPaymentMethod.hasDefault && (
          <PaymentMethodAdd
            openForm={openUpdateDefaultCard}
          />
        ) }
        { !updateDefaultCard && defaultPaymentMethod.hasDefault && (
          <PaymentMethodDisplay
            card={defaultPaymentMethod.card}
            openForm={openUpdateDefaultCard}
          />
        ) }
        { updateDefaultCard && (
          <StripeProvider>
            <PaymentCardUpdate closeUpdateCardDefault={closeUpdateDefaultCard} />
          </StripeProvider>
        ) }
      </Box>
    </Box>
  )
}

export default PaymentMethod
