import { useTranslation } from 'react-i18next'

import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import { Grid, useMediaQuery, useTheme } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'

import CTitle from 'storybook-component-module/src/components/typographies/CTitle'
import InsidersClubMainIcon from 'spypoint/src/assets/icons/insiders-club-benefits/InsidersClubMainIcon'
import CTypography from 'storybook-component-module/src/components/typographies/CTypography'
import { useClubMembershipPlan } from 'spypoint/src/user-module/club-membership-plan/core/useClubMembershipPlan'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  iconItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
    },
  },
  icon: {
    width: 187,
    height: 131,
    [theme.breakpoints.only('sm')]: {
      width: 147,
      height: 91,
    },
  },
  pricingItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      paddingTop: '1rem',
      paddingBottom: '1rem',
    },
  },
  title: {
    fontSize: '1.313rem',
    [theme.breakpoints.only('sm')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.only('xs')]: {},
  },
  price: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '-2.75rem',
    [theme.breakpoints.only('sm')]: {
      marginLeft: '-2rem !important',
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      marginLeft: 0,
    },
  },
  dollars: {
    fontSize: '3.875rem',
    color: theme.palette.text.primary,
    [theme.breakpoints.only('sm')]: {
      fontSize: '3rem',
    },
  },
  cents: {
    paddingTop: 8,
    fontSize: '2.25rem',
    color: theme.palette.text.primary,
    [theme.breakpoints.only('sm')]: {
      fontSize: '1.5rem',
    },
  },
  dividerItem: {
    display: 'flex',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  divider: {
    border: `1px solid ${theme.palette.primary.main}`,
    height: '7rem',
    width: 1,
    [theme.breakpoints.only('sm')]: {
      height: '6.25rem',
    },
    [theme.breakpoints.only('xs')]: {
      width: '40vw',
      height: 1,
      marginTop: '1rem',
      marginBottom: '1rem',
    },
  },
}))

const HeaderPricingSection = ({ product, eCommerceEnabled }) => {
  const theme = useTheme()
  const classes = useStyles()
  const { t } = useTranslation()

  const membershipPlan = useClubMembershipPlan()
  const membershipPrice = membershipPlan.price.toString()
  const xsBreakpoints = useMediaQuery(theme.breakpoints.down('xs'))
  const productPrice = product && product[0]?.price

  return (
    <Grid container item sm={6} md={6} className={classes.container}>
      <Grid item xs={12} sm md className={classes.iconItem}>
        <InsidersClubMainIcon className={classes.icon} />
      </Grid>

      <Grid item xs={12} sm={6} md={6} className={classes.pricingItem}>
        <CTitle className={classes.title}>{ t('app:insiders_club.header.yearly_membership') }</CTitle>

        <Box className={classes.price}>
          <CTypography variant="h1" className={classes.dollars}>
            { eCommerceEnabled && product && `$${(productPrice.toString().substring(0, 2))}` }
            { !eCommerceEnabled && `$${membershipPrice.substring(0, 2)}` }
          </CTypography>

          <CTypography variant="h1" className={classes.cents}>
            { eCommerceEnabled && product && productPrice.toString().substring(3) }
            { !eCommerceEnabled && membershipPrice.substring(3) }
          </CTypography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={1} md={1} className={classes.dividerItem}>
        <Divider className={classes.divider} orientation={xsBreakpoints ? 'horizontal' : 'vertical'} />
      </Grid>
    </Grid>
  )
}

export default HeaderPricingSection
