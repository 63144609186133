import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import WarningIcon from 'assets/icons/navigation/WarningIcon'
import makeStyles from '@material-ui/core/styles/makeStyles'
import useCameraActions from 'camera-module/camera/core/useCameraActions'
import usePurpose from 'camera-module/camera/core/usePurpose'

const useStyles = makeStyles(theme => ({
  helper: {
    margin: '0px',
  },
  icon: {
    width: '1rem',
    top: '0.375rem',
    position: 'relative',
    marginRight: '0.313rem',
    color: theme.palette.status?.error || theme.palette.error?.main,
  },
  message: {
    fontSize: 12,
    color: theme.palette.status?.error || theme.palette.error?.main,
  },
}))

const PrimaryDeviceUseSelect = ({ formik, name, showErrorIcon }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const cameraActions = useCameraActions()
  const purposeList = usePurpose()

  useEffect(() => {
    cameraActions.getPurpose()
  }, [])

  const primaryDevices = [
    {
      name: t('app:active_device.steps.step_2.device_uses.hunting'),
      value: 'hunting',
    },
    {
      name: t('app:active_device.steps.step_2.device_uses.wildlife'),
      value: 'wildlife_observation_conservation',
    },
    {
      name: t('app:active_device.steps.step_2.device_uses.security'),
      value: 'security_surveillance',
    },
    {
      name: t('app:active_device.steps.step_2.device_uses.Business'),
      value: 'business_activity_monitoring',
    },
    {
      name: t('app:active_device.steps.step_2.device_uses.other'),
      value: 'other',
    },
  ]

  const renderPrimaryDeviceRow = (purpose) => {
    return (
      <MenuItem value={purpose} key={purpose}>
        { t(`app:active_device.steps.step_2.device_uses.${purpose}`) }
      </MenuItem>
    )
  }

  const renderPrimaryDevicesRows = () => {
    return purposeList?.map((purpose) => renderPrimaryDeviceRow(purpose))
  }

  return (
    <FormControl name={name} variant="outlined" required fullWidth margin="dense">
      <InputLabel>{ t('app:active_device.steps.step_2.device_uses.title') }</InputLabel>

      <Select
        error={!!formik.errors[name]}
        name={name}
        label={t('app:active_device.steps.step_2.device_uses.title')}
        id="selectCameraModel"
        value={formik?.values.primaryDevice}
        onChange={event => formik?.handleChange(event)}
      >
        { renderPrimaryDevicesRows() }
      </Select>

      { formik.errors[name] && (
        <div className={classes.helper}>
          { showErrorIcon && <WarningIcon className={classes.icon} /> }
          <span className={classes.message}>{ t(formik.errors[name]?.key || formik.errors[name]) }</span>
        </div>
      ) }
    </FormControl>
  )
}

export default PrimaryDeviceUseSelect
