import { useTranslation } from 'react-i18next'
import Amount from 'shared-module/components/amount/Amount'
import DiscountLine from './DiscountLine'

const ItemDiscountLine = ({ product, currency }) => {
  const { t } = useTranslation()

  const insidersLabel = 'app:checkout_process.cart.insiders_club_rebate'
  const promoCodeLabel = 'app:promo_code.label'

  return (
    <DiscountLine label={
      product?.promocode && !product?.promocode?.metadata?.insidersClubDiscount
        ? `${t(promoCodeLabel)} ${product?.promocode?.code}`
        : t(insidersLabel, { discount: Math.floor(product?.discountPercentage) })
    }
    >
      <Amount
        value={-product?.discountAmount}
        currencyCode={currency}
      />
    </DiscountLine>
  )
}

export default ItemDiscountLine
