import clubMembershipPlanApi from 'spypoint/src/user-module/club-membership-plan/api/clubMembershipPlanApi'

const type = 'SET_CLUB_MEMBERSHIP'

const get = () => dispatch => clubMembershipPlanApi.get()
  .then(club => dispatch(({ type, club })))

const reducer = (state = null, action) => action.type === type ? action.club : state

const clubMembershipPlanState = { get: get, reducer: reducer }
export default clubMembershipPlanState
