import SvgIcon from '@material-ui/core/SvgIcon'

const CameraSettingsIcon = (props) => (
  <SvgIcon {...props} data-testid="CameraSettingsIcon">
    <path
      d="M3.68862 10C3.68862 9.52604 3.7611 9.11979 3.83364 8.71354L1.875 6.8177L3.3259 4.65103L6.00999 5.46353C6.59035 5.05728 7.24325 4.71874 7.9687 4.44791L8.62168 1.875H11.3785L12.0313 4.44791C12.7567 4.65103 13.4096 4.98958 13.99 5.46353L16.6741 4.65103L18.125 6.8177L16.1663 8.71354C16.2389 9.11979 16.3114 9.59375 16.3114 10C16.3114 10.4062 16.2389 10.8802 16.1663 11.2865L18.125 13.1823L16.6741 15.349L13.99 14.5365C13.4096 14.9427 12.7567 15.2812 12.0313 15.5521L11.3785 18.125H8.62168L7.9687 15.5521C7.24325 15.349 6.59035 15.0104 6.00999 14.5365L3.3259 15.349L1.875 13.1823L3.83364 11.2865C3.7611 10.8802 3.68862 10.474 3.68862 10ZM9.99996 12.9792C11.7411 12.9792 13.1921 11.625 13.1921 10C13.1921 8.375 11.7411 7.02082 9.99996 7.02082C8.25888 7.02082 6.80806 8.375 6.80806 10C6.80806 11.625 8.25888 12.9792 9.99996 12.9792Z"
      fill="#F5F4F4"
    />
  </SvgIcon>
)

export default CameraSettingsIcon
