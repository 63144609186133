import { Trans } from 'react-i18next'
import { Grid, Typography, useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: '12px',
    lineHeight: '135%',
    color: theme.palette.text.secondary,
  },
}))
const NoteInformationLine = () => {
  const theme = useTheme()
  const classes = useStyles()

  return (
    <Grid item xs={12}>
      <Typography className={classes.root}>
        <Trans i18nKey="app:checkout.billing.note">
          <strong style={{ color: theme.palette.text.primary }}> Note: </strong> Any changes...
        </Trans>
      </Typography>
    </Grid>
  )
}

export default NoteInformationLine
