import IconButton from '@material-ui/core/IconButton'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import useConfirmationDialogActions from 'shared-module/components/confirmation/useConfirmationDialogActions'
import SpypointVideoHdIcon from './SpypointVideoHdIcon'
import DialogFullHdVideoError from 'video-module/video-clip/ui/full-hd-video-status-button/dialog/DialogFullHdVideoError'

const useStyles = makeStyles(theme => ({
  error: {
    height: '10px',
    width: '10px',
    borderRadius: '50%',
    zIndex: 2,
    marginTop: -10,
    marginLeft: -6,
    background: theme.palette.error.main,
  },
}))

const SpypointVideoHdErrorButton = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const hdVideoErrorDialogActions = useConfirmationDialogActions()
  const shared = JSON.parse(localStorage.getItem('isShared'))
  return (
    <>
      <IconButton disabled={shared} data-testid="VideoErrorIcon" color="secondary" onClick={() => hdVideoErrorDialogActions.open()}>
        <SpypointVideoHdIcon>{ t('app:video_viewer.button.error') }</SpypointVideoHdIcon>
        <span className={classes.error} />
      </IconButton>
      <DialogFullHdVideoError open={hdVideoErrorDialogActions.isOpen} onClose={hdVideoErrorDialogActions.close} />
    </>
  )
}

export default SpypointVideoHdErrorButton
