import TextField from '@material-ui/core/TextField'
import { WarningIcon } from 'assets/icons/SpypointIcons'
import { useTranslation } from 'react-i18next'

const ErrorMessage = ({ hint, error }) => {
  const { t } = useTranslation()

  return error?.message || error?.key
    ? (
      <>
        <WarningIcon style={{ marginRight: '5px' }} />
        { error.message ? error.message : t(error.key) }
      </>
      )
    : hint || null
}

const FormTextField = ({ label, hint, error, ...props }) => (
  <TextField
    margin="dense"
    variant="outlined"
    label={label}
    error={!!error}
    helperText={<ErrorMessage hint={hint} error={error} />}
    FormHelperTextProps={{ style: { marginLeft: '5px' } }}
    {...props}
  />
)

export default FormTextField
