import Divider from '@material-ui/core/Divider'
import MuiBox from '@material-ui/core/Box'
import React from 'react'

export interface ICDivider {
  className?: string
  marginBottom?: number
  marginTop?: number
  orientation: 'horizontal' | 'vertical'
  variant?: 'fullWidth' | 'inset' | 'middle'
  size?: string
}

// Bypasses Typescript error: cannot be used as a JSX component
const Box: any = MuiBox

const CDivider = (props: ICDivider) => (
  <Box
    display="flex"
    flexDirection="column"
    marginBottom={props.marginBottom}
    marginTop={props.marginTop}
    height={props.orientation === 'vertical' && props.size}
    width={props.orientation === 'horizontal' && props.size}
  >
    <Divider className={props.className} orientation={props.orientation} variant={props.variant} />
  </Box>
)

export default CDivider
