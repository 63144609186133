import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'

import Grid from '@material-ui/core/Grid'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core'

import Input from 'form-module/ui/inputs/Input'
import PrimaryDeviceUseSelect from 'camera-module/camera/ui/add-camera-form/camera-select/PrimaryDeviceUseSelect'
import CButton from 'storybook-component-module/src/components/buttons/CButton'

const useStyles = makeStyles((theme) => ({
  button: {
    position: 'relative',
    top: 165,
    left: 387,
    [theme.breakpoints.down('sm')]: {
      top: 110,
    },
    [theme.breakpoints.down('xs')]: {
      left: 0,
      top: 90,
      width: '100%',
    },
  },
  last: {
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 0,
      marginBottom: '-1.25rem',
    },
  },
}))

const NewActivateCameraForm = ({ setValidationOnChange, isCellularCamera }) => {
  const theme = useTheme()
  const classes = useStyles()
  const { t } = useTranslation()
  const formik = useFormikContext()

  const isMobileResolution = useMediaQuery(theme.breakpoints.only('xs'))
  const getSpacingByResolution = isMobileResolution ? 3 : 2

  const handleChange = (event) => {
    const { value } = event.target
    const formattedValue = value.replace(/\W/g, '').toUpperCase()

    let joinedValue = ''
    if (formattedValue.length <= 4) {
      joinedValue = formattedValue
    } else if (formattedValue.length <= 7) {
      joinedValue = formattedValue.slice(0, 4) + '-' + formattedValue.slice(4)
    } else if (formattedValue.length <= 11) {
      joinedValue = formattedValue.slice(0, 4) + '-' + formattedValue.slice(4, 7) + '-' + formattedValue.slice(7)
    } else {
      joinedValue = formattedValue.slice(0, 14)
    }
    joinedValue = joinedValue.slice(0, 14)
    formik.setFieldValue('uic', joinedValue)
  }

  return (
    <>
      <Grid container direction="column" spacing={getSpacingByResolution}>
        { isCellularCamera && (
          <Grid item>
            <Input
              required
              id="activationId"
              name="uic"
              label={t('app:active_device.steps.step_2.device_name_field')}
              showErrorIcon
              margin="dense"
              onChange={handleChange}
            />
          </Grid>
        ) }

        <Grid item>
          <Input
            id="activationName"
            name="name"
            label={t('app:active_device.steps.step_2.primary_device_use_field')}
            showErrorIcon
            margin="dense"
            maxLength={30}
          />
        </Grid>

        <Grid item className={classes.last}>
          <PrimaryDeviceUseSelect name="primaryDevice" formik={formik} form="new-activation-form" showErrorIcon />
        </Grid>
      </Grid>

      <CButton
        color="primary"
        variety="primary"
        type="submit"
        className={classes.button}
        disabled={!formik.isValid}
        onClick={setValidationOnChange}
      >
        { t('button.continue') }
      </CButton>
    </>
  )
}

export default NewActivateCameraForm
