import userPaymentHistoryApi from '../api/userPaymentHistory.api'

const typeSet = 'SET_PAYMENT_HISTORY'
const typeClear = 'CLEAR_PAYMENT_HISTORY'

const defaultState = {
  isFetched: false,
  hasMore: false,
  invoices: [],
}

const get = (lastLoadedInvoiceId) => dispatch => userPaymentHistoryApi.getUserPaymentHistory(lastLoadedInvoiceId).then(data => dispatch({ type: typeSet, data }))
const clear = () => dispatch => dispatch({ type: typeClear })

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case typeSet:
      return {
        ...state,
        isFetched: true,
        hasMore: action.data.hasMore,
        invoices: [
          ...state.invoices,
          ...action.data.invoices,
        ],
      }
    case typeClear:
      return defaultState
    default:
      return state
  }
}

const userPaymentHistoryState = { get, clear, reducer }
export default userPaymentHistoryState
