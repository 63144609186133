import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const LastUpdate = ({ props }) => {
  const theme = useTheme()
  return (
    <SvgIcon width="32" height="32" viewBox="0 0 32 32" {...props} data-testid="LastUpdate">
      <path
        d="M15.918 23.6055L25.8164 13.707H19.9922V4H11.8438V13.707H6.01953L15.918 23.6055ZM27.5664 22.293V26.3398H4.21484V22.293H2V28.6094H29.8359V22.293H27.5664Z"
        fill={theme.palette.background.logo}
      />
    </SvgIcon>
  )
}

export default LastUpdate
