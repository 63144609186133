import restapi from 'ecommerce-module/api/restapi'
import { customerId, orgId, siteId } from 'ecommerce-module/config/default'
import buildCameraPlans from 'spypoint/src/ecommerce-module/core/cameraPlans.adapters'

const getProductById = id =>
  restapi
    .get(`/product/shopper-products/v1/organizations/${orgId}/products/${id}?siteId=${siteId}&perPricebook=true`)
    .then(res => buildCameraPlans(res.data))
    .catch(e => console.log(e))

const getProductsByIds = ids =>
  restapi
    .get(`/product/shopper-products/v1/organizations/${orgId}/products?ids=${ids}&siteId=${siteId}`)
    .then(res => res.data.data.map(p => buildCameraPlans(p)))
    .catch(e => console.log(e))

const searchProductsByCategory = cgid =>
  restapi
    .get(`/search/shopper-search/v1/organizations/${orgId}/product-search?siteId=${siteId}&refine=${cgid}`)
    .then(res => res.data)
    .catch(e => console.log(e))

const registerCustomer = (login, password, user) =>
  restapi
    .post(`/customer/shopper-customers/v1/organizations/${orgId}/customers?siteId=${siteId}`, {
      customer: {
        login: login,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
      },
      password: password,
    })
    .then(res => res.data)
    .catch(e => console.log(e))

const getCustomer = () =>
  restapi
    .get(`/customer/shopper-customers/v1/organizations/${orgId}/customers/${customerId}?siteId=${siteId}`)
    .then(res => res.data)
    .catch(e => console.log(e))

const createBasket = () =>
  restapi
    .post(`/checkout/shopper-baskets/v1/organizations/${orgId}/baskets?siteId=${siteId}`)
    .then(res => {
      return res.data
    })
    .catch((e) => console.error('Error creating new basket:', e),
    )

const fetchBaskets = async () => {
  try {
    const response = await restapi
      .get(`/checkout/shopper-baskets/v1/organizations/${orgId}/baskets?siteId=${siteId}`, {
        method: 'GET',
      })
    if (!response.ok) {
      throw new Error('Failed to fetch baskets')
    }
    const data = await response.json()
    const baskets = data.baskets
    if (baskets && baskets.length > 0) {
      const basketId = baskets[0].basketId
      return basketId
    } else {
      return null
    }
  } catch (error) {
    return null
  }
}

const deleteBasket = (id) => {
  return restapi
    .delete(`/checkout/shopper-baskets/v1/organizations/${orgId}/baskets/${id}?siteId=${siteId}`)
    .then(res => res.data)
    .catch(e => console.log(e))
}

const getBasket = (id) =>
  restapi
    .get(`/checkout/shopper-baskets/v1/organizations/${orgId}/baskets/${id}?siteId=${siteId}`)
    .then(res => res.data)
    .catch(e => console.log(e))

const addItemToBasket = async (id, newCartItem) =>
  await restapi
    .post(`/checkout/shopper-baskets/v1/organizations/${orgId}/baskets/${id}/items?siteId=${siteId}`, newCartItem)
    .then(res => res.data)
    .catch(e => console.log(e))

const deleteItemFromBasket = (id, itemId) => {
  return restapi
    .delete(`/checkout/shopper-baskets/v1/organizations/${orgId}/baskets/${id}/items/${itemId}?siteId=${siteId}`)
    .then(res => res.data)
    .catch(e => console.log(e))
}

const shopperApi = { getProductById, getProductsByIds, searchProductsByCategory, registerCustomer, getCustomer, createBasket, fetchBaskets, deleteBasket, getBasket, addItemToBasket, deleteItemFromBasket }
export default shopperApi
