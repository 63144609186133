import { createContext, useContext, useEffect, useState } from 'react'

const FormContext = createContext(null)

// @todo validation
// @todo move usestate to form?
const useFormField = (name) => {
  const [value, setValue] = useState('')
  const [error, setError] = useState(null)
  const form = useContext(FormContext)

  const onChange = e => {
    setError(null)
    setValue(e.target.value)
  }
  const field = { name: name, value: value, setValue: setValue, onChange: onChange, error: error, setError: setError }

  useEffect(() => {
    form.setField(name, field)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return field
}

/// / status
// @todo submit data?
const FormProvider = ({ form, children }) => {
  const [fields, setFields] = useState({})
  const [isSubmitting, setSubmitting] = useState(false)
  const [isSubmitted, setSubmitted] = useState(false)
  const [data, setData] = useState({})

  const setField = (name, field) => setFields(fields => ({ ...fields, [name]: field }))

  const success = (data) => {
    setData(data)
    setSubmitted(true)
  }

  const status = {
    success: success,
    data: data,
    isSubmitted: isSubmitted,
    setSubmitted: setSubmitted,
    isSubmitting: isSubmitting,
    setSubmitting: setSubmitting,
  }

  const reset = () => {
    setSubmitted(false)
    setSubmitting(false)
    setFields({})
    setData({})
  }

  const value = { fields: fields, setField: setField, status: status, reset: reset }

  return (
    <FormContext.Provider value={form ?? value}>
      { children }
    </FormContext.Provider>
  )
}
const useForm = () => useContext(FormContext)

export { useFormField, useForm, FormProvider }
