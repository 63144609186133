import cameraModelsApi from 'camera-module/model/api/cameraModelsApi'
import { FETCH_SORTED_CAMERA_MODELS, SET_SORTED_CAMERA_MODELS } from 'camera-module/model/core/sorted-camera-models/sortedCameraModelsActionTypes'

const getModels = user => dispatch => {
  dispatch({ type: FETCH_SORTED_CAMERA_MODELS })
  return cameraModelsApi.getSortedModels(user)
    .then(models => {
      dispatch({ type: SET_SORTED_CAMERA_MODELS, models: models })
    })
    .catch(() => dispatch({ type: SET_SORTED_CAMERA_MODELS, models: {} }))
}

const sortedCameraModelsActions = { getModels }
export default sortedCameraModelsActions
