import SvgIcon from '@material-ui/core/SvgIcon'

const ReferralIcon = (props) => (
  <SvgIcon {...props} data-testid="ReferralIcon">
    <path
      d="M3.469 12.336v7.023c0 .645.547 1.168 1.219 1.168h6.093v-8.191zm0 0M20.531 7.656h-3.855c.277-.18.515-.36.68-.523a2.374 2.374 0 000-3.442c-.958-.921-2.622-.921-3.575 0-.531.512-1.93 2.582-1.738 3.965h-.086c.191-1.383-1.21-3.453-1.738-3.965-.953-.921-2.621-.921-3.574 0a2.38 2.38 0 000 3.442c.168.164.402.344.68.523H3.468c-.672 0-1.219.528-1.219 1.172v1.754c0 .324.273.586.61.586h7.921v-2.34h2.438v2.34h7.922c.336 0 .609-.262.609-.586V8.828c0-.644-.547-1.172-1.219-1.172zm-9.82-.039s-.055.04-.227.04c-.843 0-2.449-.84-2.976-1.348a1.246 1.246 0 010-1.793c.25-.239.578-.368.926-.368.347 0 .675.13.921.368.82.796 1.637 2.82 1.356 3.101zm2.805.04c-.172 0-.227-.036-.227-.04-.281-.281.535-2.304 1.356-3.101.492-.473 1.351-.477 1.847 0 .512.496.512 1.3 0 1.793-.527.507-2.133 1.347-2.976 1.347zm0 0M13.219 12.336v8.191h6.094c.671 0 1.218-.523 1.218-1.168v-7.023zm0 0"
    />
  </SvgIcon>
)

export default ReferralIcon
