import SvgIcon from '@material-ui/core/SvgIcon'

const CameraIcon = (props) => (
  <SvgIcon {...props} data-testid="CameraIcon">
    <path
      d="M5.676 2.25h13.761v18.883H5.677zM7.52 3.676a.357.357 0 00-.356.355v15.32c0 .196.16.356.356.356h.652a.71.71 0 00.66-.445l2.496-6.145c-1.574-.66-2.676-2.168-2.676-3.922 0-2.36 2-4.273 4.465-4.273.504 0 .992.082 1.445.23l.2-.496a.711.711 0 00-.66-.98zm8.57 5.52c0 1.574-1.332 2.85-2.973 2.85-1.644 0-2.976-1.276-2.976-2.85 0-1.575 1.332-2.848 2.976-2.848 1.64 0 2.973 1.273 2.973 2.847zm-3.465-1.423c.254 0 .496.059.703.168l-1.07 2.637c-.645-.156-1.121-.715-1.121-1.383 0-.785.668-1.422 1.488-1.422zm0 0"
      fillRule="evenodd"
    />
  </SvgIcon>
)

export default CameraIcon
