import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

import CameraModelRow from 'camera-module/camera/ui/add-camera-form/camera-select/CameraModelRow'
import useCameraModels from 'camera-module/model/core/useCameraModels'
import cameraModelsState from 'camera-module/model/core/cameraModelsState'
import alphabeticalyCellularFirst from 'camera-module/camera/ui/add-camera-form/camera-select/cameraModelSort'

const CameraModelSelect = ({ onChange }) => {
  const { t } = useTranslation()
  const models = useCameraModels()
  const [selectedModel, setSelectedModel] = useState('')

  const renderModelRow = model => {
    const { name, icon } = model
    return (
      <MenuItem value={name} key={name}>
        <CameraModelRow key={name} name={name} icon={icon} />
      </MenuItem>
    )
  }

  const renderModelsRows = () => {
    return cameraModelsState.hasModels(models)
      ? Object.values(models).sort(alphabeticalyCellularFirst).map(model => renderModelRow(model))
      : null
  }

  const handleChange = (e) => {
    const model = e.target.value
    setSelectedModel(model)
    onChange(model)
  }

  return (
    <div>
      <FormControl variant="outlined" fullWidth required margin="dense">
        <InputLabel>{ t('add_camera.models') }</InputLabel>
        <Select
          label={t('add_camera.models')}
          id="selectCameraModel"
          value={selectedModel}
          onChange={handleChange}
        >
          { renderModelsRows() }
        </Select>
      </FormControl>
    </div>
  )
}

export default CameraModelSelect
