import { useTheme } from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { alpha } from '@material-ui/core/styles/colorManipulator'
import CCheckbox from 'storybook-component-module/src/components/buttons/CCheckbox'
import usePhotosDelete from 'photo-module/photos/ui/photos-gallery/usePhotosDelete'

const SelectPhoto = ({ label, photoId, disable = false, allShouldBeChecked }) => {
  const theme = useTheme()
  const { multiSelectEnabled, photoIdsToDelete } = usePhotosDelete()

  const renderControls = allShouldBeChecked
    ? <CCheckbox roundedCheckbox checked={allShouldBeChecked} style={{ background: alpha(theme.palette.background.default, 0.4), padding: 0 }} />
    : (

      <CCheckbox
        roundedCheckbox
        checked={photoIdsToDelete?.includes(photoId)}
        style={{ background: alpha(theme.palette.background.default, 0.4), padding: 0 }}
      />
      )

  return (
    multiSelectEnabled && (
      <FormControlLabel
        control={renderControls}
        label={label}
        disabled={disable}
      />
    )
  )
}

export default SelectPhoto
