import { useSelector } from 'react-redux'
import useLoad from 'shared-module/loading/useLoad'
import useClubMembershipPlanActions from 'spypoint/src/user-module/club-membership-plan/core/useClubMembershipPlanActions'
import useReload from 'shared-module/loading/useReload'

const useClubMembershipPlan = () => useSelector(state => state.clubMembershipPlan)
const useLoadClubMembershipPlan = () => useLoad('club-membership-plan', useClubMembershipPlan(), useClubMembershipPlanActions().get)
const useReloadClubMembershipPlan = () => useReload(useClubMembershipPlan(), useClubMembershipPlanActions().get)

export { useClubMembershipPlan, useLoadClubMembershipPlan, useReloadClubMembershipPlan }
