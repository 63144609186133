import { useDispatch } from 'react-redux'
import useConfirmationDialogActions from 'shared-module/components/confirmation/useConfirmationDialogActions'
import messageActions from 'shared-module/message/messageActions'
import PhotoTileIcon from 'assets/icons/navigation/PhotoTileIcon'
import VideoTileIcon from 'assets/icons/navigation/VideoTileIcon'
import DialogCameraCommandsRequest from 'camera-module/camera/ui/camera-settings-page/commands/DialogCameraCommandsRequest'
import { TAKE_PHOTO, TAKE_VIDEO } from 'camera-module/camera/core/cameraSettingsButtonInputTypes'
import cameraCommandsApi from 'camera-module/camera/api/cameraCommandsApi'
import { useEffect, useState } from 'react'
import SpypointCameraTileCommand from './SpypointCameraTileCommand'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles((theme) => ({
  instantRequest: {
    width: '100%',
    textAlign: 'center',
    alignItems: 'center',
    height: 30,
    fontFamily: 'oswald-normal',
    border: '1px solid white',
    padding: '0px 10px 0px 10px',
    borderRadius: 5,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.background.tile,
    },
    '&:disabled': {
      cursor: 'none',
      border: `1px solid ${theme.palette.secondary.light}`,
    },
  },
}))

const SpypointCameraInstantRequest = ({ camera, className }) => {
  const commandName = camera?.config?.captureMode === 'video' ? TAKE_VIDEO : TAKE_PHOTO
  const [requested, setRequested] = useState(false)
  const dispatch = useDispatch()
  const dialogActions = useConfirmationDialogActions()
  const classes = useStyles()
  const { t } = useTranslation()
  useEffect(() => {
    camera && camera.commands && setRequested(camera.commands[commandName].isSet)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [camera])

  const sendCommand = (cancel) => {
    cameraCommandsApi
      .requestCommand(camera.id, commandName, cancel)
      .then((command) => {
        setRequested(command.isSet)
      })
      .catch(() => dispatch(messageActions.showError('errors.catch_all')))
  }
  return (
    <div className={className}>
      <Button
        className={classes.instantRequest}
        onClick={() => {
          requested ? sendCommand(true) : dialogActions.open()
        }}
        disabled={requested}
      >
        { commandName === TAKE_VIDEO
          ? (
            <SpypointCameraTileCommand
              Icon={VideoTileIcon}
              disabled={requested}
              text={
              !requested
                ? t('app:camera.settings.fields.takeVideo.label').toUpperCase()
                : t('camera.settings.buttons.processing').toUpperCase()
            }
            />
            )
          : (
            <SpypointCameraTileCommand
              Icon={PhotoTileIcon}
              disabled={requested}
              text={
              !requested
                ? t('app:camera.settings.fields.takePhoto.label').toUpperCase()
                : t('camera.settings.buttons.processing').toUpperCase()
            }
            />
            ) }
      </Button>
      { !requested && (
        <DialogCameraCommandsRequest
          camera={camera}
          commandName={commandName}
          open={dialogActions.isOpen}
          onClose={() => {
            dialogActions.close()
          }}
          onConfirm={() => {
            sendCommand()
            dialogActions.close()
          }}
        />
      ) }
    </div>
  )
}
export default SpypointCameraInstantRequest
