import Box from '@material-ui/core/Box'
import INPUT_TYPES from 'camera-module/camera/core/cameraSettingsInputTypes'
import ScheduleField from 'camera-module/camera/ui/camera-settings-page/schedule/ScheduleField'
import DetectionField from 'camera-module/camera/ui/camera-settings-page/detection-zone/DetectionField'
import { AlertSelect, ConfirmationSwitch, DurationInput, Input, LabelledInput, Select, Switch, TimeObjectInput } from 'form-module/ui'
import CameraCommandButton from '../commands/CameraCommandButton'
import { getTranslatedAlert, getTranslatedConfirmation, getTranslatedHelpText, getTranslatedLabel, getTranslatedOption } from './cameraSettings.helper'
import ButtonSettingsField from '../button/ButtonSettingsField'
import CustomScheduleField from '../schedule/custom/CustomScheduleField'
import DetectionSwitch from 'camera-module/camera/ui/camera-settings-page/detection-zone/DetectionSwitch'
import clsx from 'clsx'

const getInputs = ({ settings, t, isNewUi, importedClasses, classes, dialogActions }) => {
  const indexedInputs = {}
  if (!settings) {
    return indexedInputs
  }
  Object.values(settings).flat().forEach(settingInput => {
    indexedInputs[settingInput.name] = getInput(settingInput, t, isNewUi, importedClasses, classes, dialogActions)
  })
  return indexedInputs
}

const getInput = (settingInput, t, isNewUi, importedClasses, classes, dialogActions) => {
  switch (settingInput.type) {
    case INPUT_TYPES.TEXT:
      return getTextInput(settingInput, t, isNewUi, importedClasses, classes)
    case INPUT_TYPES.NUMBER:
      return getNumberInput(settingInput, t, isNewUi)
    case INPUT_TYPES.SELECT:
      return getSelectInput(settingInput, t, isNewUi)
    case INPUT_TYPES.TOGGLE:
      return getToggleSwitchInput(settingInput, t, isNewUi)
    case INPUT_TYPES.BOOLEAN:
      return getToggleSwitchInput(settingInput, t, isNewUi)
    case INPUT_TYPES.TIME:
      return getTimeField(settingInput, t, isNewUi)
    case INPUT_TYPES.DURATION:
      return getDurationField(settingInput, t, isNewUi)
    case INPUT_TYPES.SCHEDULE:
      if (settingInput.name === 'customSchedule') {
        return getCustomScheduleField(settingInput, t, isNewUi)
      } else {
        return getScheduleField(settingInput, t, isNewUi)
      }
    case INPUT_TYPES.DETECTION:
      if (settingInput.name === 'transmitTime') {
        return getTimeField(settingInput, t, isNewUi)
      } else {
        return getDetectionZoneInput(settingInput, t, isNewUi, dialogActions)
      }
    case INPUT_TYPES.BUTTON:
      if (settingInput.isSet === undefined) {
        return getButtonField(settingInput, t, isNewUi)
      } else {
        return getCommandButtonField(settingInput, t, isNewUi)
      }
    default:
      console.error(`Unknown setting type : ${settingInput.type}`)
  }
}

const SettingInput = ({ settingInput, t, children, isNewUi, isAligned }) => (
  <LabelledInput
    label={getTranslatedLabel(t, settingInput.name, settingInput.label)}
    help={getTranslatedHelpText(t, settingInput.name)}
    isNewUi={isNewUi}
    isAligned={isAligned}
  >
    { children }
  </LabelledInput>
)

const getTextInput = (settingInput, t, isNewUi, importedClasses, classes) => {
  return (
    <SettingInput settingInput={settingInput} t={t} isNewUi={isNewUi}>
      <Input
        className={isNewUi ? clsx(importedClasses.inputCameraSetting, classes.input) : ''}
        defaultValue={settingInput.currentValue}
        isNewUi={isNewUi}
        name={settingInput.name}
        maxLength={settingInput.maxLength}
        size="small"
      />
    </SettingInput>
  )
}

const getNumberInput = (settingInput, t, isNewUi) => {
  return (
    <SettingInput settingInput={settingInput} t={t} isNewUi={isNewUi}>
      <Input
        defaultValue={settingInput.currentValue}
        isNewUi={isNewUi}
        name={settingInput.name}
        type="number"
        size="small"
        min={settingInput.minimum}
        max={settingInput.maximum}
      />
    </SettingInput>
  )
}

const getToggleSwitchInput = (settingInput, t, isNewUi) => {
  let SwitchElement = Switch
  if (settingInput.confirmation) { SwitchElement = ConfirmationSwitch }
  if (settingInput.isZone) { SwitchElement = DetectionSwitch }

  return (
    <SettingInput isAligned settingInput={settingInput} t={t} isNewUi={isNewUi}>
      <SwitchElement
        defaultValue={settingInput.currentValue}
        isAligned
        isNewUi={isNewUi}
        name={settingInput.name}
        confirmation={settingInput.confirmation && getTranslatedConfirmation(t, settingInput.name, settingInput.label, settingInput.confirmation)}
      />
    </SettingInput>
  )
}

const getSelectInput = (settingInput, t, isNewUi) => {
  let SelectComponent = Select
  if (settingInput.alert) {
    SelectComponent = AlertSelect
  }
  const options = settingInput.options?.map(({ name, label, value }) => ({
    value: value,
    label: getTranslatedOption(t, settingInput.name, name, label),
  }))
  return (
    <SettingInput settingInput={settingInput} t={t} isNewUi={isNewUi}>
      <SelectComponent
        defaultValue={settingInput.currentValue}
        name={settingInput.name}
        size="small"
        options={options}
        alert={settingInput.alert && getTranslatedAlert(t, settingInput.name, settingInput.label, settingInput.alert)}
      />
    </SettingInput>
  )
}

const getTimeField = (settingInput, t, isNewUi) => {
  return (
    <SettingInput settingInput={settingInput} t={t} isNewUi={isNewUi}>
      <TimeObjectInput
        defaultValue={settingInput.currentValue}
        name={settingInput.name}
        maxLength={settingInput.maxLength}
        size="small"
        required
      />
    </SettingInput>
  )
}

const getDurationField = (settingInput, t, isNewUi) => {
  return (
    <SettingInput settingInput={settingInput} t={t} isNewUi={isNewUi}>
      <DurationInput
        defaultValue={settingInput.currentValue}
        name={settingInput.name}
        min={settingInput.min}
        max={settingInput.max}
        size="small"
        required
      />
    </SettingInput>
  )
}

const getScheduleField = (settingInput, t, isNewUi) => {
  return (
    <SettingInput isAligned settingInput={settingInput} t={t} isNewUi={isNewUi}>
      <ScheduleField
        defaultValue={settingInput.currentValue}
        isNewUi={isNewUi}
        name={settingInput.name}
      />
    </SettingInput>
  )
}

const getCustomScheduleField = (settingInput, t, isNewUi) => {
  return (
    <SettingInput isAligned settingInput={settingInput} t={t} isNewUi={isNewUi}>
      <CustomScheduleField defaultValue={settingInput.currentValue} isNewUi={isNewUi} name="customSchedule" />
    </SettingInput>
  )
}

const getDetectionZoneInput = (settingInput, t, isNewUi, dialogActions) => {
  return (
    <SettingInput isAligned settingInput={settingInput} t={t} isNewUi={isNewUi}>
      <DetectionField dialogActions={dialogActions} isNewUi={isNewUi} />
    </SettingInput>
  )
}

const getCommandButtonField = (settingInput, t, isNewUi) => {
  return (
    !settingInput.disabled && (
      <SettingInput settingInput={settingInput} t={t} isNewUi={isNewUi}>
        <Box display="flex" alignItems="flex-end" justifyContent="flex-end">
          <CameraCommandButton command={settingInput} />
        </Box>
      </SettingInput>
    )
  )
}

const getButtonField = (settingInput, t, isNewUi) => {
  return (
    <SettingInput settingInput={settingInput} t={t} isNewUi={isNewUi}>
      <ButtonSettingsField settingInput={settingInput} />
    </SettingInput>
  )
}

export default getInputs
