export const cards = [
  {
    image: <img alt="off-icon" src="/images/card/tutorial/off.png" />,
    label: 'app:active_device.first_step.title',
    title: 'app:active_device.first_step.subTitle',
    subtitle: 'app:active_device.first_step.description',
    complementText: '',
    infoIcon: false,
  },
  {
    image: <img alt="battery-icon" src="/images/card/tutorial/battery.png" />,
    label: 'app:active_device.second_step.title',
    title: 'app:active_device.second_step.subTitle',
    subtitle: 'app:active_device.second_step.description',
    complementText: 'app:active_device.second_step.text',
    infoIcon: false,
  },
  {
    image: <img alt="sdcard-icon" src="/images/card/tutorial/sdcard.png" />,
    label: 'app:active_device.third_step.title',
    title: 'app:active_device.third_step.subTitle',
    subtitle: 'app:active_device.third_step.description',
    complementText: 'app:active_device.third_step.text',
    infoIcon: true,
  },
]
