import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { translateDateAndPrice, translatePriceState } from 'spypoint/src/user-module/account/plan-and-subscription/helper/Helpers'

const useStyles = makeStyles(theme => ({
  subTitle: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.secondary.main,
    fontWeight: 400,
    ontSize: theme.typography.fontSize.h4,
    width: '100%',
    marginBottom: 18,
  },
}))

const PlanListItem = ({ camera, userClubMembership, subscription }) => {
  const classes = useStyles()
  const { t, i18n } = useTranslation()

  const translate = translateDateAndPrice(camera, userClubMembership, subscription, i18n, false)

  return (
    <div className={classes.subTitle}>
      <div>
        { camera.subscriptions?.[0].plan.name }
        { ' ' }
        { t('app:checkout_process.insiders_club_subscription.label') }
        { ' ' }
        { camera.subscriptions?.[0].plan.name === 'Premium' && camera.subscriptions?.[0]?.isFree && t('app:checkout.Trial') }
        { ' - ' }
        { camera.subscriptions?.[0].paymentFrequency !== 'month_by_month' ? t('app:checkout.Annual') : t('app:checkout.Monthly') }
      </div>

      <div>
        { t('app:checkout.' + translatePriceState(camera, userClubMembership), translate) }
      </div>
    </div>
  )
}

export default PlanListItem
