import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { Typography } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/core/styles'

import { useReloadCameras } from 'camera-module/cameras/core/useCameras'
import cameraActions from 'camera-module/camera/core/cameraActions'
import SecondaryButton from 'shared-module/new-components/buttons/SecondaryButton'
import PlanListItem from 'spypoint/src/user-module/account/plan-and-subscription/components/PlanListItem'

const useStyles = makeStyles(theme => ({
  plans: {
    fontFamily: theme.typography.h3.fontFamily,
    fontSize: theme.typography.h3.fontFamily,
    fontWeight: 400,
    marginBottom: '15px',
    color: theme.palette.background.filter,
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.primary,
    fontWeight: 400,
    fontSize: theme.typography.fontSize.h4,
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    marginRight: '10px',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0.75rem',
      marginRight: '-0.25rem',
    },
  },
  button: {
    height: '24px',
    width: 'max-content',
    fontSize: 11,
  },
  divider: {
    backgroundColor: theme.palette.background.tile,
    marginBottom: '18px',
  },
}))

const PlansPanel = ({ userClubMembership, subscription }) => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const cameras = useReloadCameras()

  const syncedOnlineCameras = cameras?.filter(camera => !camera?.permanentlyShutdown && camera?.status?.installDate)

  const navigateToCamera = camera => {
    dispatch(cameraActions.set(camera))
    history.push(`/camera/${camera.id}/plan`)
  }

  return (
    <Grid item md={6} xs={12} sm={12}>
      <Typography variant="h3" className={classes.plans}>
        { t('app:checkout.plans') }
      </Typography>

      { syncedOnlineCameras?.map(camera => (
        <div key={camera.id}>
          <div data-testid="planDescriptor" className={classes.title}>{ camera.config.name }</div>

          <Box display="flex" width="100%">
            <PlanListItem camera={camera} userClubMembership={userClubMembership} subscription={subscription} />

            <div className={classes.btnContainer}>
              <SecondaryButton className={classes.button} onClick={() => navigateToCamera(camera)}>
                { t('app:checkout.change_plan_button') }
              </SecondaryButton>
            </div>
          </Box>

          <Divider variant="fullWidth" className={classes.divider} />
        </div>
      )) }
    </Grid>
  )
}

export default PlansPanel
