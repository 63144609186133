import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  root: {
    margin: '20px',
    width: '200px',
    textAlign: 'center',
  },
  disable: {
    opacity: 0.5,
  },
  icon: {
    width: '35px',
  },
  value: {
    fontSize: '11pt',
    fontWeight: 'bold',
  },
  valueLowSignal: {
    color: theme.palette.error.main,
  },
  label: {
    fontSize: '10pt',
    color: theme.palette.text.primary,
  },
}))

const SpypointCellularSignalTile = ({ value, disable }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <div className={classes.root} data-testid="tile">
      <div className={clsx(disable && classes.disable)}>
        <img src={`/images/status/signal/signal-${value.bar}${value.lowSignal ? '-red' : ''}.svg`} className={classes.icon} alt="icon" />
      </div>
      <div className={clsx(classes.value, value.lowSignal && classes.valueLowSignal, disable && classes.disable)}>
        { !disable ? `${value?.percentage && value?.percentage !== 0 ? value?.percentage : value?.processed?.percentage || 0}%` : t('hspa.n_a') }
      </div>
      <div className={classes.label}>
        { t('camera.signal') }
      </div>
    </div>
  )
}

export default SpypointCellularSignalTile
