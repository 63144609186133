import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  textBold: {
    fontFamily: 'ibm-plex-sans-regular',
    fontSize: '12px',
    fontWeight: '700',
    lineHeight: '18px',
    textAlign: 'left',
    color: '#D3CFCF',
  },
  textNormal: {
    fontFamily: 'ibm-plex-sans-regular',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '18px',
    textAlign: 'left',
    color: '#D3CFCF',
  },
}))
const FormDisclaimer = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <>
      <span className={classes.textBold}>{ t('camera_sharing.please_note') }</span><span className={classes.textNormal}>{ t('camera_sharing.note_email') }</span>
    </>
  )
}

export default FormDisclaimer
