import { parse, stringify } from 'qs'

const orderRedirect = (urlParams) => {
  const parsedParams = parse(urlParams, { encode: false })
  // Get the first product
  const { p, ...otherParams } = parsedParams
  const product = p && p[0]
  const otherParamsQueryString = stringify(otherParams)

  if (!product) {
    return '/'
  }

  if (product.id === 'TransmissionPlan') {
    return `/camera/${product.cameraId}/subscribe?p=${product.planId}&f=${product.frequency}&${otherParamsQueryString}`
  }

  return '/'
}

export default orderRedirect
