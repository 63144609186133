import { useState } from 'react'
import HspaInformationDialog from './HspaInformationDialog'
import HspaOfflineBanner from './HspaOfflineBanner'
import HspaSunsetBanner from './HspaSunsetBanner'
import {
  useNotificationsActions,
  useOfflineNotification,
  useSunsetNotification,
} from 'spypoint/src/notifications-module/core/notifications.hooks'
import { Box } from '@material-ui/core'
import TEMPLATE_TYPES from 'spypoint/src/notifications-module/core/notificationTemplateNameTypes'

const HspaSunsetBanners = ({ camera }) => {
  const [showHspaInformationDialog, setShowHspaInformationDialog] = useState(false)
  const notificationsActions = useNotificationsActions()
  const isCameraOffline = camera.status?.permanentlyShutdown || false
  const offlineNotification = useOfflineNotification(camera.id)
  const sunsetNotification = useSunsetNotification(camera.id)
  const notification = sunsetNotification || offlineNotification
  const promotionDate = new Date(notification?.metadata?.promotionExpireOn)
  const isPromotionalPeriod = promotionDate > Date.now()

  const openDialog = () => {
    setShowHspaInformationDialog(true)
    notificationsActions.readMultipleNotifications([TEMPLATE_TYPES.HSPA_3G_SUNSET, TEMPLATE_TYPES.POST_HSPA_3G_SUNSET])
  }

  const closeDialog = () => {
    setShowHspaInformationDialog(false)
  }

  if (!isCameraOffline && !notification) return null

  return (
    <Box data-testid="hspa-banners">
      { isCameraOffline && <HspaOfflineBanner statusBarOffset={camera.isCellular} openDialog={openDialog} /> }
      { !isCameraOffline && notification && <HspaSunsetBanner notification={notification} openDialog={openDialog} /> }
      <HspaInformationDialog
        showDialog={showHspaInformationDialog}
        closeDialog={closeDialog}
        promotionDate={promotionDate}
        isPromotionalPeriod={isPromotionalPeriod}
      />
    </Box>
  )
}

export default HspaSunsetBanners
