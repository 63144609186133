import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import { useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import SpypointFilterLabel from './SpypointFilterLabel'

const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: -4,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      width: '-webkit-fill-available',
    },
  },
  box: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '4px 10px 4px 4px',
    borderRadius: 5,
    padding: '0px 10px 0px 10px',
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down('xs')]: {
      marginLeft: -10,
      marginRight: -10,
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  divider: {
    marginBottom: '0px !important',
    marginTop: '0px !important',
    color: theme.palette.background.paper,
    opacity: 0.25,
    width: '-webkit-fill-available',
  },
}))

const SpypointFilterItem = ({ Icon, hasCategory, label }) => {
  const classes = useStyles()
  const theme = useTheme()
  const breakpoint = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <div className={classes.root}>
      <Box className={classes.box}>
        <SpypointFilterLabel
          Icon={Icon}
          label={label}
          hasCategory={hasCategory}
          style={{ padding: 0, margin: breakpoint ? '0px 0px 8px 0px' : '-1px 0px' }}
        />
        { breakpoint && <Divider className={classes.divider} /> }
      </Box>
    </div>
  )
}

export default SpypointFilterItem
