import { useTranslation } from 'react-i18next'
import Chip from '@material-ui/core/Chip'
import LinkMenuItem from 'shared-module/navigation/menu/items/LinkMenuItem'
import ClubMembershipIcon from 'spypoint/src/assets/icons/ClubMembershipIcon'
import { useUserClubMembership } from 'spypoint/src/user-module/club-membership/core/useUserClubMembership'
import { useTheme } from '@material-ui/core'

const ClubMembershipMenuItem = ({ onClick }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const club = useUserClubMembership()

  const Icon = () => <ClubMembershipIcon color="primary" style={{ display: 'block' }} fill={theme.palette.text.primary} />

  return (
    <LinkMenuItem Icon={Icon} text="menu.club_membership" onClick={onClick} path="/membership">
      { club && !club.isMember && <Chip label={t('menu.club_membership_badge')} color="primary" size="small" style={{ fontWeight: '600', fontSize: 10 }} /> }
    </LinkMenuItem>
  )
}

export default ClubMembershipMenuItem
