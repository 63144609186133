import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Fragment } from 'react'
import useConfirmationDialogActions from 'shared-module/components/confirmation/useConfirmationDialogActions'
import WarningDialog from 'shared-module/components/dialog/WarningDialog'
import { useCart } from 'spypoint/src/checkout-module/order/core/cart.hooks'

import { INSIDERS_CLUB_MEMBERSHIP, PHOTO_TRANSMISSION_PLAN } from '../../core/productTypes'
import ItemInCart from '../cart-items/ItemInCart'
import CartDivider from '../cart-lines/CartDivider'
import NoteUnavailableItemLine from '../cart-lines/UnavailableItemNoteLine'
import RemoveCartItemButton from './RemoveCartItemButton'

const useStyles = makeStyles({
  unavailableItemWrapper: {
    opacity: 0.4,
  },
  itemWrapper: {
    opacity: 1,
  },
})
const CartContent = ({ products, setPlanIndex, isNewUi }) => {
  const classes = useStyles()
  const { deleteFromCart, isUnavailableItemInCart } = useCart()
  const dialogActions = useConfirmationDialogActions()

  const deleteItem = (product) => {
    if (product.productId === INSIDERS_CLUB_MEMBERSHIP) {
      dialogActions.open()
    } else {
      deleteFromCart({ itemId: product.id })
    }
  }
  const isProductUnavailable = (productId) => {
    return isUnavailableItemInCart && !(productId === PHOTO_TRANSMISSION_PLAN)
  }

  return products.map((product) => (
    <Fragment key={product.id}>
      <Grid
        container
        justifyContent="space-between"
        spacing={3}
        className={isProductUnavailable(product.productId) ? classes.unavailableItemWrapper : classes.itemWrapper}
      >
        <ItemInCart product={product} isUnavailable={isUnavailableItemInCart} />
      </Grid>
      <RemoveCartItemButton
        productId={product.productId}
        onDelete={() => {
          deleteItem(product)
          products?.length === 1 && isNewUi && setPlanIndex(0)
        }}
      />
      { product.productId === INSIDERS_CLUB_MEMBERSHIP && (
        <WarningDialog itemId={product.id} isOpen={dialogActions.isOpen} onClose={dialogActions.close} />
      ) }

      { isUnavailableItemInCart && !(product.productId === PHOTO_TRANSMISSION_PLAN) && <NoteUnavailableItemLine /> }
      <CartDivider />
    </Fragment>
  ))
}

export default CartContent
