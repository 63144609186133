import { useTranslation } from 'react-i18next'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContentText from '@material-ui/core/DialogContentText'
import PrimaryButton from 'shared-module/components/button/PrimaryButton'
import SecondaryButton from 'shared-module/components/button/SecondaryButton'
import VoskerPrimaryButton from 'vosker/src/components/buttons/VoskerPrimaryButton'
import VoskerSecondaryButton from 'vosker/src/components/buttons/VoskerSecondaryButton'
import VoskerErrorButton from 'vosker/src/components/buttons/VoskerErrorButton'
import CloseIcon from './icons/CloseIcon'

const useStyles = makeStyles(theme => ({
  dialog: {
    '& .MuiDialog-paper': {
      padding: '1rem !important',
      [theme.breakpoints.down('xs')]: {
        padding: '0.125rem !important',
      },
    },
  },
  dialogActions: {
    padding: '0.5rem 1rem 1rem 1rem !important',
  },
  dialogCloseIcon: {
    padding: '0.5rem 1rem 0 1rem !important',
    '& > div:hover': {
      cursor: 'pointer',
    },
  },
  dialogTitle: {
    padding: '1rem 1.5rem',
  },
  newDialogTitle: {
    padding: '1rem 1.5rem 0rem',
  },
  confirmButton: {
    [theme.breakpoints.up('lg')]: {
      minWidth: '33%',
      flexWrap: 'nowrap',
    },
  },
  contentText: {
    padding: '0rem 1rem 0rem 0rem',
  },
  cancelButton: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.primary,
    border: 'none',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      border: 'none',
      color: theme.palette.text.primary,
    },
  },
}
))

const ConfirmationDialog = ({ open, onClose, title, text, onConfirm, onSecondConfirm, confirmText, confirmSecondText, onCancel, cancelText, isNewUi = false, closeActionIcon, model, useSecondaryButtonForConfirm = false }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const SecondConfirmButton = isNewUi ? VoskerSecondaryButton : SecondaryButton
  const CancelButton = isNewUi ? VoskerSecondaryButton : SecondaryButton

  const ConfirmButton = useSecondaryButtonForConfirm
    ? VoskerErrorButton
    : isNewUi
      ? VoskerPrimaryButton
      : PrimaryButton

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth className={classes.dialog}>
      {
        closeActionIcon && (
          <Grid container display="flex" flexDirection="row" justifyContent="flex-end" spacing={1} className={classes.dialogCloseIcon}>
            <Grid item onClick={onClose}>
              <CloseIcon />
            </Grid>
          </Grid>
        )
      }
      <DialogTitle className={isNewUi ? classes.newDialogTitle : classes.dialogTitle}>{ title }</DialogTitle>
      { text && (
        <DialogContent style={{ scrollbarWidth: 'thin', scrollbarColor: '#313030 #0E0B0B' }}>
          <DialogContentText className={classes.contentText} i18nkey="multiline">{ text }</DialogContentText>
        </DialogContent>
      ) }
      <DialogActions className={classes.dialogActions}>
        <Grid container display="flex" flexDirection="row" justifyContent="flex-end" spacing={1}>

          { onCancel && (
            <Grid item xs={isNewUi ? 12 : 'auto'} sm="auto">
              <CancelButton onClick={onCancel} className={model ? classes.cancelButton : ''} value={cancelText || t('button.cancel')}>{ cancelText || t('button.cancel') }</CancelButton>
            </Grid>
          ) }

          { onSecondConfirm && (
            <Grid item xs={isNewUi ? 12 : 'auto'} sm="auto" className={isNewUi ? classes.confirmButton : ''}>
              <SecondConfirmButton onClick={onSecondConfirm} value={confirmSecondText}>{ confirmSecondText }</SecondConfirmButton>
            </Grid>
          ) }
          { onConfirm && (
            <Grid item xs={isNewUi ? 12 : 'auto'} sm="auto" className={isNewUi ? classes.confirmButton : ''}>
              <ConfirmButton onClick={onConfirm} value={confirmText}>{ confirmText }</ConfirmButton>
            </Grid>
          ) }
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog
