import * as actions from './notifications.actions'
import notificationState from './notificationState'

const reducer = (state = notificationState.defaultState, action) => {
  switch (action.type) {
    case actions.SET_NOTIFICATIONS_SPYPOINT:
      return notificationState.set(state, action)
    case actions.MARK_AS_READ_SPYPOINT:
      return notificationState.isRead(state, action)
    case actions.MARK_AS_MULTIPLE_READ_SPYPOINT:
      return notificationState.setMultipleRead(state, action)
    default:
      return state
  }
}

export default reducer
