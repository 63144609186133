import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import TextField from '@material-ui/core/TextField'

import { sanitizeActivationInput, isAValidActivationId } from 'camera-module/camera/ui/add-camera-form/activation-id-field/isActivationIdValid'

const ActivationIdField = ({ onChange, isCellCam }) => {
  const { t } = useTranslation()
  const [activationId, setActivationId] = useState('')
  const [isValidActivationId, setIsValidActivationId] = useState(false)

  const validate = e => {
    const inputValue = sanitizeActivationInput(e.target.value)
    const isInputValid = isAValidActivationId(inputValue)

    setIsValidActivationId(isInputValid)
    setActivationId(inputValue)

    onChange(isInputValid, { target: { value: inputValue } })
  }

  return (
    <div>
      <TextField
        id="activation"
        value={activationId}
        onChange={validate}
        margin="dense"
        label={t('app:active_device.activationId')}
        required
        error={!isValidActivationId && isCellCam}
        variant="outlined"
        autoComplete="off"
        inputProps={{ maxLength: 20 }}
        disabled={!isCellCam}
        fullWidth
      />
    </div>
  )
}

export default ActivationIdField
