import { useDispatch } from 'react-redux'
import { useActions } from 'shared-module/actions/ActionsContext'
import useUser from 'user-module/user/core/useUser'
import { useHistory } from 'react-router-dom'
import coreCameraActions from 'camera-module/camera/core/cameraActions'

const useCameraActions = () => {
  const dispatch = useDispatch()
  const user = useUser()
  const history = useHistory()
  const cameraActions = useActions().cameraActions

  return {
    cameraMigrationError: (camera) => cameraActions.cameraMigrationError(camera, user, history),
    cameraDatamatrixActivationMigrationError: (camera) => cameraActions.cameraActivationMigrationError(camera, user, history),
    cameraSimActivationMigrationError: (sim) => cameraActions.cameraActivationMigrationError({ status: { sim: sim } }, user, history),
    update: cameraId => dispatch(coreCameraActions.update(cameraId)),
    updateSettings: (camera, newSettings) => dispatch(coreCameraActions.updateSettings(camera, newSettings)),
    getPurpose: () => dispatch(coreCameraActions.getPurpose()),
  }
}

export default useCameraActions
