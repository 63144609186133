import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLoadClubMembershipPlan } from 'spypoint/src/user-module/club-membership-plan/core/useClubMembershipPlan'
import supportedFiltersState from 'photo-module/filters/core/supportedFiltersState'

const useSupportedFilters = () => {
  const dispatch = useDispatch()
  const plan = useLoadClubMembershipPlan()
  const filters = useSelector(state => state.supportedFilters)

  useEffect(() => {
    if (plan && !filters) {
      dispatch(supportedFiltersState.get(plan.filters))
    }
  }, [plan, filters, dispatch])

  return filters
}

export default useSupportedFilters
