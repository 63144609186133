import * as Yup from 'yup'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import Form from 'form-module/ui/Form'
import NewActivationCameraForm from 'spypoint/src/cameras-module/NewActivationCameraForm'
import NewActivationHelp from 'camera-module/camera/ui/add-camera-form/new-activation-help/new-activation-help'
import { useReloadCameras } from 'camera-module/cameras/core/useCameras'
import messageActions from 'shared-module/message/messageActions'
import cameraApi from 'camera-module/camera/api/cameraApi'
import { FORBIDDEN } from 'shared-module/api/httpStatusCode'
import useActivatedCameraActions from 'camera-module/new-activation/core/activatedCamera.hook'
import BatteryHelperDialog from 'camera-module/camera/ui/add-camera-form/camera-select/BatteryHelperDialog'
import useConfirmationDialogActions from 'shared-module/components/confirmation/useConfirmationDialogActions'

const useStyles = makeStyles((theme) => ({
  form: {
    width: '21.625rem',
    '& .MuiFormHelperText-root.Mui-error': {
      marginTop: '-4px',
      marginLeft: '0px',
    },
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    padding: '2rem 0',
    [theme.breakpoints.up('md')]: {
      padding: '2rem 10rem 0',
    },
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 0,
    },
    '& > h1': {
      paddingBottom: '40px',
    },
  },
  flexBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '34px',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
}))

const NewActivationCamera = ({ activatedModel, handleNext }) => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const cameras = useReloadCameras()
  const dialogActions = useConfirmationDialogActions()
  const activatedCameraActions = useActivatedCameraActions()

  const isFlexSCamera = activatedModel.name === 'FLEX-S'
  const isCellularCamera = activatedModel.isCellular
  const [validationOnChange, setValidationOnChange] = useState(false)

  const cellularInitialValues = {
    uic: '',
    name: '',
    primaryDevice: '',
  }

  const nonCellularInitialValues = {
    name: '',
    primaryDevice: '',
  }

  const cellularValidationSchema = Yup.object().shape({
    uic: Yup.string()
      .required(t('form.validation.required'))
      .test('uic-validation', t('app:active_device.steps.step_2.wrong_activation_code'), (value) => {
        const activationCodePattern = /^[A-Z2-7]{11,14}$/
        const formattedValue = value?.replace(/-/g, '')
        const isValidMaxLength = formattedValue?.length <= 14
        const isValidMinLength = formattedValue?.length >= 11
        const isActivationCodeValid = activationCodePattern.test(formattedValue)
        const isValidFormat = value?.charAt(4) === '-' && value?.charAt(8) === '-'
        const acceptAlphanumeric = !/[^a-zA-Z0-9]/g.test(formattedValue)
        return (
          (isValidMinLength && isValidFormat && acceptAlphanumeric) ||
          (isValidMaxLength && isActivationCodeValid && acceptAlphanumeric)
        )
      }),
    primaryDevice: Yup.string().required(t('form.validation.required')),
  })

  const nonCellularValidationSchema = Yup.object().shape({ primaryDevice: Yup.string().required(t('form.validation.required')) })

  useEffect(() => {
    isFlexSCamera && dialogActions.open()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFlexSCamera])

  const onSubmit = (value, actions) => {
    if (value.name === '') value.name = `${activatedModel?.name} - ${cameras.length + 1}`

    const newCamera = isCellularCamera
      ? {
          ucid: value.uic.replaceAll('-', ''),
          name: value.name,
          modelName: activatedModel.name,
          purpose: value.primaryDevice,
        }
      : {
          name: value.name,
          modelName: activatedModel.name,
        }

    cameraApi.add(newCamera)
      .then(camera => {
        dispatch(messageActions.showMessage(t('add_camera.camera_created')))
        activatedCameraActions.setActivatedCamera(camera)
        activatedModel.isCellular
          ? handleNext && handleNext()
          : history.push('/confirm-activation')
      })
      .catch((error) => {
        if (error.response?.data.error?.field === 'ucid') {
          actions.setFieldError('uic', t('app:active_device.steps.step_2.wrong_activation_code'))
          return
        }
        if (error.response?.status === FORBIDDEN) {
          dispatch(messageActions.showError(t('add_camera.error.camera_already_exist')))
        } else {
          dispatch(messageActions.showError())
        }
      })
  }

  const closeDialog = () => dialogActions.close()

  return (
    <Box className={classes.box}>
      <Typography variant="h1" align="center">
        { isCellularCamera
          ? t('app:active_device.steps.step_2.activation_code_field', { camera: activatedModel?.name })
          : t('app:active_device.steps.step_2.non_cellular.activation_code_field', { camera: activatedModel?.name }) }
      </Typography>
      <Box className={classes.flexBox}>
        { isCellularCamera && <NewActivationHelp helpImage={activatedModel?.helpImage} /> }
        <Form
          id="new-activation-form"
          onSubmit={onSubmit}
          data={isCellularCamera ? cellularInitialValues : nonCellularInitialValues}
          validationSchema={isCellularCamera ? cellularValidationSchema : nonCellularValidationSchema}
          className={classes.form}
          validateOnBlur={false}
          validateOnChange={validationOnChange}
        >
          <NewActivationCameraForm setValidationOnChange={setValidationOnChange} isCellularCamera={isCellularCamera} />
        </Form>
      </Box>
      <BatteryHelperDialog isOpen={dialogActions.isOpen} onConfirm={closeDialog} />
    </Box>
  )
}

export default NewActivationCamera
