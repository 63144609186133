import { useReloadClubMembershipPlan } from 'spypoint/src/user-module/club-membership-plan/core/useClubMembershipPlan'
import Spinner from 'shared-module/components/Spinner'
import MembershipPageContent from './MembershipPageContent'
import MembershipPageHeader from './MembershipPageHeader'
import MembershipPageFooter from './MembershipPageFooter'

const MembershipPage = () => {
  const membershipPlan = useReloadClubMembershipPlan()

  return membershipPlan
    ? (
      <>
        <MembershipPageHeader />
        <MembershipPageContent />
        <MembershipPageFooter />
      </>
      )
    : <Spinner />
}

export default MembershipPage
