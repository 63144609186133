import { useDispatch } from 'react-redux'
import favoriteFilterState from 'photo-module/filters/core/favorite/favoriteFilterState'
import hdFilterState from 'photo-module/filters/core/hd/hdFilterState'
import speciesFilterState from 'photo-module/filters/core/species/speciesFilterState'
import filtersState from 'photo-module/filters/core/filtersState'
import camerasFilterState from 'photo-module/filters/core/cameras/camerasFilterState'

const useFiltersActions = () => {
  const dispatch = useDispatch()

  return ({
    selected: (state) => filtersState.selected(state),
    setCamerasFilter: (cameraIds) => dispatch(camerasFilterState.set(cameraIds)),
    toggleFavoriteFilter: () => dispatch(favoriteFilterState.toggle()),
    toggleHdFilter: () => dispatch(hdFilterState.toggle()),
    toggleSpeciesFilter: (species) => dispatch(speciesFilterState.toggle(species)),
  })
}

export default useFiltersActions
