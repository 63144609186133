import { useSelector } from 'react-redux'

const usePaymentHistory = () => {
  const paymentHistory = useSelector(state => state.paymentHistory)

  if (!paymentHistory.isFetched) return null

  return paymentHistory
}

export default usePaymentHistory
