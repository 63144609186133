import { useTranslation } from 'react-i18next'
import photoApi from 'photo-module/photo/api/photoApi'
import useSelectedPhoto from 'photo-module/photo/core/useSelectedPhoto'
import usePhotoActions from 'photo-module/photo/core/usePhotoActions'
import usePhotosActions from 'photo-module/photos/core/usePhotosActions'
import useUserHdPackageActions from 'user-module/hd-package/core/useUserHdPackageActions'
import ConfirmationDialog from 'shared-module/components/confirmation/ConfirmationDialog'

const DialogHdPhotoError = ({ open, onClose }) => {
  const { t } = useTranslation()
  const photo = useSelectedPhoto()
  const photoAction = usePhotoActions()
  const photosActions = usePhotosActions()
  const userHdPackageActions = useUserHdPackageActions()

  const acknowledgePhotoHdError = () => {
    onClose()
    photoApi.getHdPhotoStatus(photo.cameraId, photo.id)
      .then(() => photoAction.get(photo.cameraId, photo.id))
      .then(({ photo }) => photosActions.updatePhoto(photo))
      .finally(() => userHdPackageActions.get())
  }

  return (
    <ConfirmationDialog
      open={open}
      onClose={onClose}
      title={t('photo_viewer.hd.error.title')}
      text={t('photo_viewer.hd.error.text')}
      confirmText="OK"
      onConfirm={acknowledgePhotoHdError}
    />
  )
}

export default DialogHdPhotoError
