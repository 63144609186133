import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { useMediaQuery, useTheme } from '@material-ui/core'

import SecondaryButton from 'shared-module/new-components/buttons/SecondaryButton'
import MembershipRenewalSection from 'spypoint/src/user-module/club-membership/ui/header/MembershipRenewalSection'
import CDivider from 'storybook-component-module/src/components/dividers/CDivider'
import MembershipMainBenefitsMobile from 'spypoint/src/user-module/club-membership/ui/content/MembershipMainBenefitsMobile'
import MembershipFooterMobile from 'spypoint/src/user-module/club-membership/ui/footer/MembershipFooterMobile'
import CTypography from 'storybook-component-module/src/components/typographies/CTypography'

const useStyles = makeStyles(theme => ({
  container: {
    padding: '0 0.5rem 0.5rem 0.5rem',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      margin: '0 -0.625rem',
    },
  },
  memberGrid: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '1rem',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '0.5rem',
    },
  },
  noneMemberGrid: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '2rem',
    alignItems: 'center',
  },
  title: {
    color: '#B1AFAF',
    marginLeft: '1.25rem',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0.75rem',
    },
  },
  autoRenewalTitle: {
    fontSize: '0.875rem',
  },
  button: {
    height: '24px',
    width: 'max-content',
    fontSize: 11,
    [theme.breakpoints.down('xs')]: {
      padding: '0.625rem',
      marginRight: '-0.75rem',
    },
  },
  membershipSection: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '0.75rem',
    [theme.breakpoints.only('sm')]: {
      marginLeft: '0.25rem',
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      paddingTop: '0.5rem',
      paddingLeft: '0.25rem',
      paddingRight: 0,
    },
  },
  divider: {
    backgroundColor: theme.palette.background.tile,
    marginLeft: '-0.5rem',
    marginTop: '1.125rem',
    marginBottom: '1.5rem',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0.5rem',
      marginRight: '-0.5rem',
    },
  },
  benefits: {
    [theme.breakpoints.down('xs')]: {
      marginTop: '1.5rem',
    },
  },
  footer: {
    marginTop: '0.625rem',
  },
}))

const SubscriptionPanel = ({ userClubMembership }) => {
  const theme = useTheme()
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()

  const navigateToInsidersClub = () => history.push('/membership')
  const isXsSmallResolution = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <Grid container item md={6} sm={12} xs={12} className={classes.container}>
      <Grid container item className={!userClubMembership.isMember ? classes.noneMemberGrid : classes.memberGrid}>
        <Grid item>
          <CTypography variant="h3" className={classes.title}>{ t('app:checkout.club_membership_title') }</CTypography>
        </Grid>

        { !userClubMembership.isMember && (
          <Grid item>
            <SecondaryButton className={classes.button} onClick={navigateToInsidersClub}>
              { t('storage_plan.club_membership.button') }
            </SecondaryButton>
          </Grid>
        ) }
      </Grid>

      <MembershipRenewalSection membershipClass={classes.membershipSection} titleClass={classes.autoRenewalTitle} />

      { userClubMembership.isMember && (
        <CDivider orientation="horizontal" className={classes.divider} size="100%" marginBottom={isXsSmallResolution ? 0 : '2rem'} />
      ) }

      <Grid container item data-testid="insider-club-benefits" spacing={2} className={userClubMembership.isMember ? classes.benefits : null}>
        <MembershipMainBenefitsMobile />
      </Grid>

      <Grid item className={classes.footer}>
        <MembershipFooterMobile ml={isXsSmallResolution ? 5 : 4} pt={1} pb={0.25} />
      </Grid>
    </Grid>
  )
}

export default SubscriptionPanel
