import { useTranslation } from 'react-i18next'
import usePhotoActions from 'photo-module/photo/core/usePhotoActions'
import ConfirmationDialog from 'shared-module/components/confirmation/ConfirmationDialog'
import useSelectedPhoto from 'photo-module/photo/core/useSelectedPhoto'
import { useUserHdPackage } from 'user-module/hd-package/core/useUserHdPackage'

const DialogFullHdConfirmRequest = ({ open, onClose }) => {
  const { t } = useTranslation()
  const photo = useSelectedPhoto()
  const photoAction = usePhotoActions()
  const userHdPackage = useUserHdPackage()

  const sendRequest = () => {
    onClose()
    photoAction.requestHdPhoto(photo.cameraId, photo.id)
  }

  return (
    <ConfirmationDialog
      open={open}
      onClose={onClose}
      title={t('photo_viewer.request_full_hd.title', { remaining: userHdPackage.remaining })}
      confirmText={t('photo_viewer.request_full_hd.confirm')}
      onConfirm={sendRequest}
      onCancel={onClose}
    />
  )
}

export default DialogFullHdConfirmRequest
