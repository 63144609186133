import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import makeStyles from '@material-ui/core/styles/makeStyles'
import AppBarTitle from 'shared-module/components/app-bar/AppBarTitle'

const useStyles = makeStyles((theme) => ({
  appbar: {
    boxShadow: 'none',
    background: theme.palette.background.paper,
    [theme.breakpoints.down('sm')]: {
      marginTop: '56px',
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: '64px',
    },
  },
}))

const AppBarContainer = ({ Icon, title, children }) => {
  const classes = useStyles()

  return (
    <div>
      <AppBar className={classes.appbar}>
        <Toolbar>
          <AppBarTitle Icon={Icon} title={title} />
          { children }
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default AppBarContainer
