import { combineReducers } from 'redux'

const initialState = {
  consent: null,
}

const consent = (state = initialState.consent, action) => {
  switch (action.type) {
    case 'SET_TERRITORY_REQUIRED':
      return action?.user?.data
    default:
      return state
  }
}

export default combineReducers({
  consent,
})
