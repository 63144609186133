import { useState } from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Paper from '@material-ui/core/Paper'
import ProfileSelect from 'spypoint/src/user-module/account/profile/preferences/ProfileSelect'
import ProfileRadio from 'spypoint/src/user-module/account/profile/preferences/ProfileRadio'
import ProfileCheckbox from 'spypoint/src/user-module/account/profile/preferences/ProfileCheckbox'
import ProfileLanguage from 'spypoint/src/user-module/account/profile/preferences/ProfileLanguage'
import * as Yup from 'yup'
import { makeStyles } from '@material-ui/core/styles'
import appConfig from 'shared-module/config/appConfig'
import switches from 'shared-module/config/switches'
import PreferencesDialog from 'spypoint/src/cameras-module/PreferencesDialog'
import PreferencesConfirmation from 'spypoint/src/cameras-module/PreferencesConfirmation'
import { useLDFlags } from 'spypoint/src/launchDarkly-module/launchDarklyFlagsContext'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginTop: 24,
  },
  title: {
    fontWeight: 600,
    marginBottom: 16,
  },
  paper: {
    background: theme.palette.primary.contrastText,
    padding: 32,
  },
}))

export const userPreferencesInitialValues = (user) => ({
  measurementUnits: user.measurementUnits || 'metric',
  dateFormat: user.dateFormat || 'mdy',
  timeFormat: user.timeFormat || '12',
  notificationEmail: user.notificationEmail === true ? 'yes' : 'no',
  language: user.language || 'en',
  marketingEmail: user.marketingEmail || false,
})

export const userPreferencesValidationSchema = {
  measurementUnits: Yup.string().trim().required(),
  dateFormat: Yup.string().trim().required(),
  timeFormat: Yup.number().required(),
}

const ProfilePreferences = ({ form, formik, readOnly }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const germanLanguageEnabled = appConfig.newLanguagesSwitch?.german.switch === switches.on
  const { flags } = useLDFlags()
  const marketingConsentEnabled = flags && flags['marketing-consent']

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.title}>
        <Typography variant="h3">{ t('app:account.profile.preferences.title') }</Typography>
      </Box>
      <Paper className={classes.paper} square elevation={3}>
        <Grid container spacing={2}>
          <Grid xs={12} sm={4} item>
            <ProfileSelect
              form={form}
              formik={formik}
              name="measurementUnits"
              label={t('app:account.profile.preferences.measurement-units.label')}
              disabled={readOnly}
              items={[
                {
                  key: 'metric',
                  name: t('app:account.profile.preferences.measurement-units.options.metric'),
                  value: 'metric',
                },
                {
                  key: 'imperial',
                  name: t('app:account.profile.preferences.measurement-units.options.imperial'),
                  value: 'imperial',
                },
              ]}
            />
          </Grid>
          <Grid xs={12} sm={4} item>
            <ProfileSelect
              form={form}
              formik={formik}
              name="dateFormat"
              label={t('app:camera.settings.fields.dateFormat.label')}
              disabled={readOnly}
              items={[
                {
                  key: 'mdy',
                  name: t('app:camera.settings.fields.dateFormat.options.mdy'),
                  value: 'mdy',
                },
                {
                  key: 'dmy',
                  name: t('app:camera.settings.fields.dateFormat.options.dmy'),
                  value: 'dmy',
                },
              ]}
            />
          </Grid>
          <Grid xs={12} sm={4} item>
            <ProfileSelect
              form={form}
              formik={formik}
              name="timeFormat"
              label={t('app:camera.settings.fields.timeFormat.label')}
              disabled={readOnly}
              items={[
                {
                  key: '12',
                  name: t('app:camera.settings.fields.timeFormat.ampm'),
                  value: '12',
                },
                {
                  key: '24',
                  name: t('app:camera.settings.fields.timeFormat.notampm'),
                  value: '24',
                },
              ]}
            />
          </Grid>
          <Grid className={classes.wrapper} xs={12} item>
            <ProfileRadio
              form={form}
              formik={formik}
              name="notificationEmail"
              legendKey="input.email_notifications_subscription"
              disabled={readOnly}
              items={[
                {
                  key: 'yes',
                  name: t('yes'),
                  value: 'yes',
                },
                {
                  key: 'no',
                  name: t('no'),
                  value: 'no',
                },
              ]}
            />
          </Grid>
          <Grid xs={12} item>
            <ProfileLanguage
              form={form}
              formik={formik}
              name="language"
              legendKey="app:account.profile.preferences.language-preference"
              disabled={readOnly}
              items={[
                {
                  key: 'en',
                  name: t('language.english'),
                  value: 'en',
                },
                {
                  key: 'fr',
                  name: t('language.french'),
                  value: 'fr',
                },
                ...(germanLanguageEnabled
                  ? [{
                      key: 'de',
                      name: t('language.deutsch'),
                      value: 'de',
                    }]
                  : []),
              ]}
            />
          </Grid>
          <Grid xs={12} item>
            <ProfileCheckbox
              setOpen={setOpen}
              form={form}
              formik={formik}
              name="marketingEmail"
              label={t('app:account.profile.preferences.communication.label')}
              legendKey="app:account.profile.preferences.communication.legend"
              disabled={readOnly}
            />
            { marketingConsentEnabled && open && <PreferencesDialog setOpenConfirmation={setOpenConfirmation} onClose={() => setOpen(false)} /> }
            { marketingConsentEnabled && openConfirmation && <PreferencesConfirmation formik={formik} onClose={() => setOpenConfirmation(false)} /> }
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}

export default ProfilePreferences
