import { useTranslation } from 'react-i18next'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import InformationBarElement from 'photo-module/photos/ui/photos-gallery/information-bar/InformationBarElement'
import CameraIcon from 'assets/icons/navigation/CameraIcon'
import Tooltip from '@material-ui/core/Tooltip'
import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles(theme => ({
  divider: {
    margin: '0.5rem 0',
    [theme.breakpoints.down('md')]: {
      margin: '0.875rem 0',
    },
  },
  text: {
    maxWidth: 100,
    overflow: 'hidden',
    whiteSpace: 'noWrap',
    textOverflow: 'ellipsis',
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
}))

const CameraNameElement = ({ className, customHover, isNewUi = false }) => {
  const camera = useSelectedCamera()
  const { t } = useTranslation()
  const classes = useStyles()

  return camera
    ? (
      <Tooltip arrow title={t('app:photo_gallery_page.tooltip.cameraPlan')} enterDelay={300} leaveDelay={100}>
        { isNewUi
          ? (
            <Box px={1} display="flex" alignItems="center">
              <Divider orientation="vertical" flexItem className={classes.divider} />
              <InformationBarElement text={camera.config.name} className={classes.text} />
            </Box>
            )
          : <InformationBarElement text={camera?.OwnerName ? `${camera?.name}(${camera?.OwnerName})` : camera.config.name} Icon={CameraIcon} className={className} customHover={customHover} hasTooltip /> }
      </Tooltip>
      )
    : null
}

export default CameraNameElement
