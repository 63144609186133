import CartPriceLine from '../cart-lines/CartPriceLine'
import ItemDiscountLine from '../cart-lines/ItemDiscountLine'
import ItemProrateLine from '../cart-lines/ItemProrateLine'
import PhotoTransmissionCartPart from './PhotoTransmissionCartPart'
import PhotoTransmissionCheckoutPart from './PhotoTransmissionCheckoutPart'
const PhotoTransmissionInCart = ({ product, checkout, currency, discount }) => {
  return (
    <>
      { checkout
        ? (
          <PhotoTransmissionCheckoutPart product={product} />
          )
        : (
          <PhotoTransmissionCartPart product={product} />
          ) }
      { product.proratedPrice && (
        <ItemProrateLine
          value={-product.proratedDiscount}
          currencyCode={currency}
        />
      ) }
      { discount && (
        <ItemDiscountLine
          product={product}
          currency={currency}
        />
      ) }
      <CartPriceLine
        value={product.subTotal}
        currencyCode={currency}
      />
    </>
  )
}
export default PhotoTransmissionInCart
