const validFormats = ['image/jpeg']

/**
 * Check if the photo format is valid
 * @param {Object} photo - photo definition: https://developer.mozilla.org/fr/docs/Web/API/File
 * @return {boolean} is it a valid photo format
 */
export const isPhotoFormatValid = (photo = {}) => {
  const isPhotoObject = typeof photo === 'object' && photo !== null && !Array.isArray(photo)
  if (isPhotoObject) {
    const isPhotoFormatString = typeof photo.type === 'string'
    return isPhotoFormatString && validFormats.indexOf(photo.type.toLowerCase()) !== -1
  }

  return false
}
