import SvgIcon from '@material-ui/core/SvgIcon'

const cameraSharingIcon = (props) => (
  <SvgIcon {...props} data-testid="UserIcon">
    <g clip-path="url(#clip0_18043_49697)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.4044 7.3848C6.67358 7.3848 6.0813 7.97362 6.0813 8.69975C6.0813 9.31427 6.50577 9.83037 7.07899 9.97445L8.03141 7.54172C7.84487 7.44161 7.6311 7.3848 7.4044 7.3848ZM7.4044 7.3848C6.67358 7.3848 6.0813 7.97362 6.0813 8.69975C6.0813 9.31427 6.50577 9.83037 7.07899 9.97445L8.03141 7.54172C7.84487 7.44161 7.6311 7.3848 7.4044 7.3848ZM0.788208 2.29004V19.7101H13.028V2.29004H0.788208ZM3.4385 18.386C3.43646 18.3918 3.43067 18.3955 3.42454 18.3955H2.11914C2.11506 18.3955 2.11165 18.3924 2.11165 18.388V3.61243C2.11165 3.60837 2.11506 3.60499 2.11914 3.60499H9.20201C9.21257 3.60499 9.21971 3.61547 9.21597 3.62528L8.69075 4.96695C8.28738 4.82964 7.85474 4.75523 7.40474 4.75523C5.21228 4.75523 3.4351 6.52101 3.4351 8.69941C3.4351 10.316 4.41406 11.7057 5.81477 12.3145L3.4385 18.386ZM7.4044 11.329C5.94276 11.329 4.75786 10.1517 4.75786 8.69941C4.75786 7.24715 5.94276 6.07018 7.4044 6.07018C8.86605 6.07018 10.0506 7.24748 10.0506 8.69941C10.0506 10.1513 8.86571 11.329 7.4044 11.329ZM6.0813 8.69975C6.0813 9.31427 6.50577 9.83037 7.07899 9.97445L8.03141 7.54172C7.84487 7.44161 7.6311 7.3848 7.4044 7.3848C6.67358 7.3848 6.0813 7.97362 6.0813 8.69975ZM7.4044 7.3848C6.67358 7.3848 6.0813 7.97362 6.0813 8.69975C6.0813 9.31427 6.50577 9.83037 7.07899 9.97445L8.03141 7.54172C7.84487 7.44161 7.6311 7.3848 7.4044 7.3848ZM7.4044 7.3848C6.67358 7.3848 6.0813 7.97362 6.0813 8.69975C6.0813 9.31427 6.50577 9.83037 7.07899 9.97445L8.03141 7.54172C7.84487 7.44161 7.6311 7.3848 7.4044 7.3848Z" fill="#FBF9F9" />
      <path d="M21.2117 4.30957L19.1077 10.3425L17.7499 8.07619L14.3514 9.87444V6.53363L16.2277 5.54132L14.8658 3.27466L21.2117 4.30957Z" fill="#FBF9F9" />
      <path d="M14.8658 18.7257L16.2277 16.459L14.3514 15.4667V12.1259L17.7499 13.9241L19.1077 11.6575L21.2117 17.6908L14.8658 18.7257Z" fill="#FBF9F9" />
    </g>
    <defs>
      <clipPath id="clip0_18043_49697">
        <rect width="20.4235" height="17.4201" fill="white" transform="translate(0.788208 2.29004)" />
      </clipPath>
    </defs>
  </SvgIcon>
)

export default cameraSharingIcon
