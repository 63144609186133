import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const Battery5 = ({ props }) => {
  const theme = useTheme()
  return (
    <SvgIcon width="32" height="32" viewBox="0 0 32 32" {...props} data-testid="BatteryType">
      <g id="Icons / Status / Battery5-duotone--dark">
        <path
          id="Battery front-color"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8 12.75C8.41421 12.75 8.75 13.0858 8.75 13.5V18.5C8.75 18.9142 8.41421 19.25 8 19.25C7.58579 19.25 7.25 18.9142 7.25 18.5V13.5C7.25 13.0858 7.58579 12.75 8 12.75ZM11.5 12.75C11.9142 12.75 12.25 13.0858 12.25 13.5V18.5C12.25 18.9142 11.9142 19.25 11.5 19.25C11.0858 19.25 10.75 18.9142 10.75 18.5V13.5C10.75 13.0858 11.0858 12.75 11.5 12.75ZM15 12.75C15.4142 12.75 15.75 13.0858 15.75 13.5V18.5C15.75 18.9142 15.4142 19.25 15 19.25C14.5858 19.25 14.25 18.9142 14.25 18.5V13.5C14.25 13.0858 14.5858 12.75 15 12.75ZM18.5 12.75C18.9142 12.75 19.25 13.0858 19.25 13.5V18.5C19.25 18.9142 18.9142 19.25 18.5 19.25C18.0858 19.25 17.75 18.9142 17.75 18.5V13.5C17.75 13.0858 18.0858 12.75 18.5 12.75ZM22 12.75C22.4142 12.75 22.75 13.0858 22.75 13.5V18.5C22.75 18.9142 22.4142 19.25 22 19.25C21.5858 19.25 21.25 18.9142 21.25 18.5V13.5C21.25 13.0858 21.5858 12.75 22 12.75Z"
          fill="#FBF9F9"
        />
        <path
          id="Battery front-color_2"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.75 9.75V22.25H25.25V19C25.25 18.5858 25.5858 18.25 26 18.25H28.25V13.75H26C25.5858 13.75 25.25 13.4142 25.25 13V9.75H4.75ZM3.25 9.5C3.25 8.80965 3.80964 8.25 4.5 8.25H25.5C26.1904 8.25 26.75 8.80964 26.75 9.5V12.25H28.5C29.1904 12.25 29.75 12.8096 29.75 13.5V18.5C29.75 19.1904 29.1904 19.75 28.5 19.75H26.75V22.5C26.75 23.1904 26.1904 23.75 25.5 23.75H4.5C3.80964 23.75 3.25 23.1904 3.25 22.5V9.5Z"
          fill="#FBF9F9"
        />
      </g>
    </SvgIcon>
  )
}

export default Battery5
