import { useHistory, useLocation } from 'react-router-dom'
import orderRedirect from 'checkout-module/order/orderRedirect'
import { useEffect } from 'react'

const Order = () => {
  const history = useHistory()
  const search = useLocation().search.replace('?', '')

  const redirect = orderRedirect(search)
  useEffect(() => {
    history.replace(redirect)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

export default Order
