import { makeStyles } from '@material-ui/core/styles'
import SpeciesFilterButton from 'photo-module/photos/ui/photos-gallery/filter-bar/filter-buttons/SpeciesFilterButton'
import useSupportedFilters from 'photo-module/filters/core/useSupportedFilters'

const useStyles = makeStyles({
  button: {
    marginRight: '5px',
  },
})

const SpeciesFilterButtons = () => {
  const filters = useSupportedFilters()
  const classes = useStyles()

  return filters
    ? filters.map(filter => <SpeciesFilterButton key={filter.filter} className={classes.button} species={filter.filter} speciesIcon={filter.icon} />)
    : null
}

export default SpeciesFilterButtons
