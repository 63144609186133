import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'

import ActivationHelp from 'camera-module/camera/ui/add-camera-form/activation-help/ActivationHelp'
import PrimaryButton from 'shared-module/components/button/PrimaryButton'
import SecondaryButton from 'shared-module/components/button/SecondaryButton'
import messageActions from 'shared-module/message/messageActions'
import { FORBIDDEN } from 'shared-module/api/httpStatusCode'
import CameraModelSelect from 'camera-module/camera/ui/add-camera-form/camera-select/CameraModelSelect'
import ActivationIdField from 'camera-module/camera/ui/add-camera-form/activation-id-field/ActivationIdField'
import SdCardWarning from 'camera-module/camera/ui/add-camera-form/sd-card-warning/SdCardWarning'
import useCameraModels from 'camera-module/model/core/useCameraModels'
import cameraApi from 'camera-module/camera/api/cameraApi'
import cameraModelsState from 'camera-module/model/core/cameraModelsState'
import CameraName from 'camera-module/camera/ui/add-camera-form/components/CameraName'
import Referrer from 'camera-module/camera/ui/add-camera-form/components/Referrer'
import useUser from 'user-module/user/core/useUser'
import ReferralLink from 'camera-module/camera/ui/add-camera-form/components/ReferralLink'
import PromoCode from 'camera-module/camera/ui/add-camera-form/promo-code/PromoCode'
import CameraSimActivationMigrationError from 'spypoint/src/cameras-module/ui/camera-activation-migration-error/CameraSimActivationMigrationError'
import CameraDatamatrixActivationMigrationError from 'spypoint/src/cameras-module/ui/camera-activation-migration-error/CameraDatamatrixActivationMigrationError'
import useCameraActions from 'camera-module/camera/core/useCameraActions'
import BatteryHelper from './BatteryHelper'

const useStyles = makeStyles({
  dialog: {
    maxWidth: '816px',
    margin: 'auto',
  },
  title: {
    padding: '0px 24px 16px 0',
  },
  content: {
    overflowY: 'hidden !important',
  },
})

const migrationErrorTypes = {
  datamatrix: 'datamatrix',
  sim: 'sim',
}

const NewCameraForm = ({ onClose, onAddCameraSuccess }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const models = useCameraModels()
  const user = useUser()
  const classes = useStyles()
  const singleCameraActions = useCameraActions()

  const [modelName, setModelName] = useState('')
  const [name, setName] = useState('')
  const [ucid, setUcid] = useState('')
  const [referrer, setReferrer] = useState('')
  const [helpImage, setHelpImage] = useState('')
  const [isActivationIdValid, setIsActivationIdValid] = useState(false)
  const [isCellCam, setIsCellCam] = useState(false)
  const [isNameValid, setIsNameValid] = useState(false)
  const [isPromoCodeValid, setIsPromoCodeValid] = useState(true)
  const [promoCode, setPromoCode] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [cameraMigrationError, setCameraMigrationError] = useState({ error: null, camera: null })

  const onChange = model => {
    const isCellular = cameraModelsState.hasModels(models) ? models[model].isCellular : false

    if (!isCellular) {
      setUcid('')
      setReferrer('')
      setIsPromoCodeValid(true)
      setPromoCode('')
    }

    if (model === 'CELL-LINK') {
      setIsPromoCodeValid(true)
      setPromoCode('')
    }

    setModelName(model)
    setHelpImage(models[model].helpImage)
    setIsCellCam(isCellular)
  }

  const isCellLink = modelName === 'CELL-LINK'

  const isSubmitEnable = () => {
    const areFieldsWithActivationIdValid = isNameValid && modelName && isActivationIdValid
    const areFieldsNoActivationIdValid = isNameValid && modelName

    if (!isCellCam) {
      if (ucid !== '') {
        return areFieldsWithActivationIdValid
      } else {
        return areFieldsNoActivationIdValid
      }
    }

    if (isCellCam) {
      return areFieldsWithActivationIdValid
    }

    return false
  }

  const setActivationIdState = (isValid, event) => {
    setIsActivationIdValid(isValid)
    setUcid(event.target.value)
  }

  const onSubmit = e => {
    e.preventDefault()

    const newCamera = { name: name, modelName: modelName }

    if (ucid !== '') { newCamera.ucid = ucid }
    if (referrer !== '') { newCamera.referrer = referrer }
    if (promoCode !== '') { newCamera.promocode = promoCode }

    if (referrer === user.email) {
      dispatch(messageActions.showError(t('add_camera.error.same_referrer')))
      return
    }

    if (!isSubmitEnable() || !isPromoCodeValid) return

    setIsSubmitting(true)
    cameraApi.add(newCamera)
      .then((camera) => {
        if (camera.jtMigration?.hasMigrationError && camera.jtMigration?.apocalypseNow) {
          onAddCameraMigrationError(migrationErrorTypes.datamatrix, camera)
        } else {
          onAddCameraSuccess(t('add_camera.camera_created'), camera)
          close()
        }
      })
      .catch(error => {
        if (error.response?.status === FORBIDDEN) {
          if (error.response.data?.message === 'Cannot be activated') {
            onAddCameraMigrationError(migrationErrorTypes.sim, ucid)
          } else {
            dispatch(messageActions.showError(t('add_camera.error.camera_already_exist')))
          }
        } else if (error.message === 'invalid-referrer') {
          dispatch(messageActions.showError(t('add_camera.error.invalid_referrer', { email: referrer })))
        } else if (error.message === 'invalid-promocode') {
          dispatch(messageActions.showError(t('add_camera.error.invalid_promocode')))
        } else {
          dispatch(messageActions.showError())
        }
        setIsSubmitting(false)
      })
  }

  const close = () => {
    setCameraMigrationError({ error: null, camera: null })
    onClose()
  }

  const onAddCameraMigrationError = (errorType, camera) => {
    setCameraMigrationError({ error: errorType, camera })
  }

  return (
    <>
      <Dialog open onClose={close} aria-labelledby="form-dialog-title" maxWidth={false} className={classes.dialog}>
        <form name="newCamera" id="newCameraForm" noValidate onSubmit={onSubmit}>
          <DialogContent className={classes.content}>
            <DialogTitle className={classes.title} id="form-dialog-title">
              { t('add_camera.title') }
            </DialogTitle>
            <Grid container spacing={3}>
              <Grid item sm={7}>
                <CameraModelSelect id="modelName" name="cameraModel" onChange={onChange} />
                <ActivationIdField id="activationId" onChange={setActivationIdState} isCellCam={isCellCam} />
                <CameraName name={name} setName={setName} isNameValid={isNameValid} setIsNameValid={setIsNameValid} />
                <SdCardWarning />
                <Grid container spacing={2} alignItems="flex-end">
                  { isCellCam && (
                    <Grid item sm xs={12}>
                      <Referrer referrer={referrer} setReferrer={setReferrer} />
                    </Grid>
                  ) }
                  { isCellCam && !isCellLink && (
                    <Grid item sm={6} xs={12}>
                      <PromoCode valid={setIsPromoCodeValid} getPromoCode={setPromoCode} cameraModel={modelName} />
                    </Grid>
                  ) }
                </Grid>
                { isCellCam && <ReferralLink /> }
              </Grid>
              <Grid item sm={5}>
                { ((!isActivationIdValid && modelName === 'FLEX-S') || (modelName !== 'FLEX-S')) && <ActivationHelp isSelected={modelName !== ''} helpImage={helpImage} isCell={isCellCam} /> }
                { isActivationIdValid && modelName === 'FLEX-S' && <BatteryHelper /> }
                <br />
                <DialogActions style={{ paddingRight: 0 }}>
                  <SecondaryButton onClick={close} value={t('button.cancel')} />
                  <PrimaryButton disabled={!isSubmitEnable() || isSubmitting} value={t('add_camera.create')} />
                </DialogActions>
              </Grid>
            </Grid>
          </DialogContent>
        </form>
      </Dialog>

      <CameraDatamatrixActivationMigrationError
        displayError={cameraMigrationError.error === migrationErrorTypes.datamatrix}
        onClose={close}
        onProceed={() => singleCameraActions.cameraDatamatrixActivationMigrationError(cameraMigrationError.camera)}
      />
      <CameraSimActivationMigrationError
        displayError={cameraMigrationError.error === migrationErrorTypes.sim}
        onClose={close}
        onProceed={() => singleCameraActions.cameraSimActivationMigrationError(cameraMigrationError.camera)}
      />
    </>
  )
}

export default NewCameraForm
