import { useTheme } from '@material-ui/core'
import SvgIcon from '@material-ui/core/SvgIcon'

const AccessoriesIcon = (props) => {
  const theme = useTheme()

  return (
    <SvgIcon data-testid="AccessoriesIcon" viewBox="0 0 134 149" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        stroke="none"
        fillRule="nonzero"
        fill={theme.palette.primary.main}
        fillOpacity={1}
        d="m66.52 148.05-.317-.16c-.32-.16-30.152-15.374-46.902-29.64-9.57-8.242-14.676-22.668-16.11-32.652C1.594 74.66.481 42.324.797 20.449c0-.793.16-1.746.64-2.379.477-.633 1.114-1.425 1.915-1.742C7.496 13.633 30.629.316 66.68.793c35.894-.477 59.023 12.84 63.332 15.375.797.477 1.437 1.11 1.754 1.742.48.793.64 1.586.64 2.38.477 21.874-.8 54.21-2.394 65.148-1.434 9.984-6.54 24.41-16.114 32.652C97.148 132.355 67.32 147.574 67 147.73ZM64.605 2.38c-34.296 0-56.308 12.68-60.457 15.215-.48.316-.957.793-1.277 1.27a3.032 3.032 0 0 0-.476 1.585c-.32 21.875.796 54.051 2.39 64.989 1.438 9.667 6.383 23.777 15.633 31.703 15.633 13.312 42.754 27.421 46.262 29.164 3.511-1.743 30.629-16.008 46.261-29.164 9.254-7.926 14.2-22.036 15.637-31.704 1.594-10.937 2.711-42.957 2.23-64.988 0-.637-.16-1.11-.476-1.586a4.816 4.816 0 0 0-1.277-1.27C124.906 15.06 102.094 1.904 66.68 2.38h-2.075Zm0 0"
      />

      <path
        stroke="none"
        fillRule="nonzero"
        fill={theme.palette.primary.main}
        fillOpacity={1}
        d="M37.488 112.227c.16 2.375 2.711 4.277 5.742 4.12l10.688-.792L33.5 62.137ZM54.875 64.988c-.16-.633-.637-1.11-1.117-1.425-.156-.16-.477-.16-.797-.16-.316 0-.637 0-.797.16-.32.16-.477.316-.637.476-.16.156-.32.477-.48.633-.16.633-.16 1.27 0 1.742l5.105 13.95c.16.636.637 1.109 1.118 1.429.32.156.476.156.796.316.32 0 .637 0 .797-.16.32-.156.48-.316.64-.476.157-.157.317-.473.477-.633.16-.633.16-1.266 0-1.742ZM83.43 83.219c-.16-.633-.637-1.11-1.118-1.426-.316-.16-.476-.16-.796-.32-.317 0-.637 0-.797.16-.32.16-.477.316-.637.476-.16.157-.32.477-.48.633-.16.633-.16 1.27 0 1.742l5.105 13.95c.16.636.637 1.109 1.117 1.43.32.156.477.156.797.316.32 0 .637 0 .797-.16.32-.157.48-.317.64-.477.157-.156.317-.473.477-.633.16-.633.16-1.27 0-1.742ZM53.918 33.129v-.316c0-.317 0-.793-.16-1.11v-.637c-.637-2.851-1.914-7.289-3.508-9.668-2.074-3.488-5.105-6.18-8.613-8.242-1.754-1.11-3.828-1.586-5.903-1.586a7.43 7.43 0 0 0-3.672.953 5.57 5.57 0 0 0-2.23 2.059c-.64.793-1.117 1.746-1.277 2.855-.957 3.961.48 9.192 3.828 14.266.637.793 1.277 1.586 1.914 2.375v-.473a9.2 9.2 0 0 1 .957-4.12c-2.39-3.333-2.871-5.708-3.031-8.563-.16-2.692.48-4.594 1.914-5.387.32-.16.8-.316 1.117-.316h.64c1.434.156 2.711.472 3.989 1.265 2.71 1.586 5.262 4.121 7.176 7.293 1.754 2.696 3.351 10.778 2.71 13.79 0 .316 0 .792.16 1.109.16.316.321.636.641.953.317.316.637.473 1.117.473.317 0 .637-.157.797-.317.317-.156.477-.316.637-.633 1.117-1.109 1.117-3.011.797-6.023Zm0 0"
      />

      <path
        stroke="none"
        fillRule="nonzero"
        fill={theme.palette.primary.main}
        fillOpacity={1}
        d="M104.168 103.191 81.355 43.75l-18.82-19.34c-1.117-1.11-2.555-1.586-4.148-1.586-.797 0-1.438.16-2.075.317l-2.074.793a52.69 52.69 0 0 1 1.914 6.66l.16.472v.32c.16.473.16.95.16 1.427v.156c0 .793.16 1.586.16 2.379.317.316.638.793.798 1.27.16.632.316 1.265.316 1.902 0 .949-.476 1.902-1.113 2.69-.64.794-1.438 1.43-2.395 1.587-.636.16-1.117.316-1.754.316-.64 0-1.277-.156-1.914-.316-.48-.156-.8-.317-1.117-.633-.16 0-.16-.16-.32-.316-.797-.477-1.278-1.27-1.754-2.22-.16-.636-.32-1.269-.32-1.901v-.793c.48-1.903-.477-6.977-1.434-9.989l-4.945 1.746c-1.598.473-2.555 1.266-3.192 2.22-.16.155-.16.316-.16.476-.16.789-.32 1.582-.32 2.375v.476l-.317 5.864-.796 18.39 23.128 60.551c.797 1.902 2.711 3.012 5.106 3.012.797 0 1.433-.16 2.074-.32L100.5 109.53c2.395-.793 3.988-2.695 3.988-4.597 0-.633-.16-1.11-.32-1.743Zm-40.52-20.449c-.316.793-.636 1.426-1.273 1.903-.48.476-1.277.953-1.914 1.27-.8.315-1.438.315-2.234.315-.797 0-1.594-.316-2.235-.632-1.433-.793-2.55-2.063-3.191-3.649L47.699 68a6.523 6.523 0 0 1 0-4.914c.317-.793.637-1.426 1.274-1.902.48-.473 1.277-.95 1.914-1.266.8-.316 1.597-.316 2.234-.316.797 0 1.594.316 2.234.789 1.434.793 2.551 2.222 3.192 3.804l5.101 13.95c.641 1.27.641 3.011 0 4.597Zm6.704 28.692-3.832 1.425 3.19-59.918 3.83-1.425Zm21.695-10.461c-.32.793-.64 1.425-1.277 1.902-.48.473-1.278.95-1.915 1.266-.796.316-1.437.316-2.234.316-.797 0-1.594-.316-2.234-.633-1.434-.793-2.551-2.058-3.188-3.644l-5.105-13.95a6.523 6.523 0 0 1 0-4.914c.316-.793.636-1.425 1.273-1.902.48-.477 1.278-.953 1.918-1.27.797-.316 1.594-.316 2.23-.316.797 0 1.598.317 2.235.793 1.438.793 2.55 2.219 3.191 3.805l5.106 13.949c.637 1.266.637 3.012 0 4.598Zm0 0"
      />
    </SvgIcon>
  )
}

export default AccessoriesIcon
