import restapi from 'shared-module/api/restapi'
import clubMembershipId from 'spypoint/src/user-module/club-membership-plan/api/clubMembershipId'

const get = () => restapi
  .get('/api/v3/storage/plan')
  .then(response => findClubMembership(response.data))

const findClubMembership = (items) => items
  .filter(item => item.id === clubMembershipId)
  .map(buildClubMembership)[0]

const buildClubMembership = (club) => ({
  price: club.price,
  filters: club.filters.species,
  isInternational: !!club.isInternational,
})

const clubMembershipPlanApi = { get: get }
export default clubMembershipPlanApi
