import { useTranslation } from 'react-i18next'
import HdPackageIcon from 'assets/icons/navigation/HdPackageIcon'
import { useLoadCameras } from 'camera-module/cameras/core/useCameras'
import useCamerasActions from 'camera-module/cameras/core/useCamerasActions'
import useConfirmationDialogActions from 'shared-module/components/confirmation/useConfirmationDialogActions'
import ConfirmationDialog from 'shared-module/components/confirmation/ConfirmationDialog'
import MenuItem from 'shared-module/navigation/menu/MenuItem'
import { useCart } from 'spypoint/src/checkout-module/order/core/cart.hooks'
import { PHOTOHD } from 'spypoint/src/checkout-module/order/core/productTypes'

const HdPackageMenuItem = ({ onClick }) => {
  const { t } = useTranslation()
  const { addToCart, navigateToCart, updateQuantity, hdPhotosItemInCart } = useCart()
  const cameras = useLoadCameras()
  const camerasActions = useCamerasActions()
  const nonCellCameras = camerasActions.getNonCellular(cameras)
  const hdPackageDialogActions = useConfirmationDialogActions()
  const noCameraWithHdSupport = cameras?.length === nonCellCameras?.length
  const notAllCamerasSupportHD = nonCellCameras?.length > 0

  const cartDialogText = () => {
    if (noCameraWithHdSupport) {
      return t('app:checkout_process.hd_photos.no_device_supports')
    } else if (notAllCamerasSupportHD) {
      return t('app:checkout_process.hd_photos.not_all_device_supports')
    }
  }

  const showCart = () => {
    if (hdPhotosItemInCart) {
      updateQuantity({
        itemId: hdPhotosItemInCart.id,
        quantity: hdPhotosItemInCart.quantity + 1,
      })
      onClick()
    } else {
      addToCart({ productId: PHOTOHD })
      if (noCameraWithHdSupport || notAllCamerasSupportHD) {
        hdPackageDialogActions.open()
      } else {
        onClick()
      }
    }
    navigateToCart()
  }

  const hideCartHDWarning = () => {
    onClick()
    hdPackageDialogActions.close()
  }

  return (
    <>
      <MenuItem Icon={HdPackageIcon} text="menu.hd_package" onClick={showCart} path="/cart" />
      <ConfirmationDialog
        open={hdPackageDialogActions.isOpen}
        title={t('app:checkout_process.warning_title')}
        text={cartDialogText()}
        confirmText={t('button.continue')}
        onConfirm={onClick}
        onClose={hideCartHDWarning}
      />
    </>
  )
}

export default HdPackageMenuItem
