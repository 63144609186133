import IconButton from '@material-ui/core/IconButton'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import SpypointVideoHdIcon from './SpypointVideoHdIcon'

const useStyles = makeStyles(theme => ({
  pending: {
    height: '10px',
    width: '10px',
    borderRadius: '50%',
    zIndex: 2,
    marginTop: -10,
    marginLeft: -6,
    background: theme.palette.primary.pending,
  },
}))

const SpypointVideoHdPendingButton = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <IconButton color="secondary" disabled>
      <SpypointVideoHdIcon>
        { t('app:video_viewer.button.pending') }
      </SpypointVideoHdIcon>
      <span className={classes.pending} />
    </IconButton>

  )
}

export default SpypointVideoHdPendingButton
