// React Core imports
import { useTranslation } from 'react-i18next'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import PlanBlockTitle from 'spypoint/src/checkout-module/transmission-plans/ui/transmission-plans-page/PlanBlockTitle'
import PrimaryButton from 'shared-module/new-components/buttons/PrimaryButton'
import { useUserClubMembership } from 'spypoint/src/user-module/club-membership/core/useUserClubMembership'
import FormatedPrice from 'checkout-module/checkout/ui/transmission-plans-page/FormatedPrice'
import { PHOTO_TRANSMISSION_PLAN } from 'spypoint/src/checkout-module/order/core/productTypes'
import { useCart } from 'spypoint/src/checkout-module/order/core/cart.hooks'
import { useActivatedCamera } from 'camera-module/new-activation/core/activatedCamera.hook'
import appConfig from 'shared-module/config/appConfig'
import switches from 'shared-module/config/switches'

const useStyles = makeStyles((theme) => ({
  root: {
    '--root-height': '297px',
    height: 'var(--root-height)',
    width: '235px',
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '7px auto',
      marginTop: '25px',
    },
    margin: '7px',
    fontSize: '10pt',
    borderRadius: '0',
  },
  rootFix: {
    '--root-height': '100px',
    width: '235px',
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '7px auto',
    },
    margin: '7px',
    marginTop: '16px',
    fontSize: '10pt',
    borderRadius: '0',
  },
  activePlan: {
    padding: 0,
    borderBottom: `3px solid ${theme.palette.secondary.main}`,
    borderLeft: `3px solid ${theme.palette.secondary.main}`,
    borderRight: `3px solid ${theme.palette.secondary.main}`,
    height: '326px',
    borderColor: theme.palette.secondary.main,
  },
  inactivePlan: {
    padding: '0',
  },
  section: {
    height: '160px',
    width: '100%',
    position: 'relative',
  },
  row: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  basicPlanBanner: {
    height: '38px',
    background: theme.palette.secondary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: '600',
    fontSize: '14px',
  },
  popularPlanBanner: {
    height: '38px',
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    textShadow: '0px 0px 1px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: '700',
    fontSize: '14px',
  },
  basicPlanLayout: ({ userIsNotAMember }) => ({
    marginTop: userIsNotAMember ? '54px' : '54px',
    [theme.breakpoints.down('xs')]: {
      height: '289px',
    },
  }),
  planLayout: {
    '--root-height': '334px',
    height: 'var(--root-height)',
    [theme.breakpoints.down('xs')]: {
      '--root-height': '320px',
      marginTop: '40px',
    },
  },
  planLayoutNotPopular: {
    '--root-height': '320px',
    [theme.breakpoints.down('xs')]: {
      height: '200px',
    },
  },
  disablePlan: {
    pointerEvents: 'none',
    opacity: '0.4',
  },
  photoCount: {
    textAlign: 'center',
    fontSize: '16px',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
      paddingLeft: '17px',
      marginTop: '5px',
      marginBottom: '8px',
      fontSize: '19px',
    },
  },
  annualButton: {
    width: '150px',
    fontSize: '12pt',
    margin: '20px 0px 10px',
  },
  monthlyButton: {
    width: '150px',
    fontSize: '10pt',
    margin: '20px 0px 10px',
  },
  note: {
    margin: '0px 25px',
    textAlign: 'center',
    fontSize: '8pt',
  },
  infos: {
    margin: '10px 10px 20px 10px',
    lineHeight: '1.5em',
  },
  divider: {
    margin: '20px',
    backgroundColor: theme.palette.background.secondary,
  },
  check: {
    color: theme.palette.primary.main,
    width: '15px',
    marginRight: '10px',
    paddingTop: '10px',
  },
  triangle: {
    width: 0,
    height: 0,
    borderLeft: '116px solid transparent',
    borderRight: '117px solid transparent',
    borderTop: `22px solid ${theme.palette.secondary.main}`,
    [theme.breakpoints.down('xs')]: {
      margin: '0 auto',
      borderLeft: '135px solid transparent',
      borderRight: '135px solid transparent',
      borderTop: `17px solid ${theme.palette.secondary.main}`,
    },
  },
  brightTriangle: {
    borderTopColor: theme.palette.primary.main,
    marginBottom: '8px',
  },
  planPrice: {
    fontSize: '40px',
    textAlign: 'center',
    fontWeight: 'bolder',
  },
  currentPlan: {
    color: theme.palette.primary.main,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: '700',
    position: 'absolute',
    top: '-30px',
    width: '100%',
    padding: '30px',
  },
  mainPrice: {
    textAlign: 'center',
    display: 'inline-block',
    fontSize: '40px',
    margin: '10px 0',
    [theme.breakpoints.down('xs')]: {
      fontSize: '40px',
    },
  },
  mainPriceBloc: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'right',
      paddingRight: '15px',
      marginTop: '-78px',
    },
  },
  secondaryPrice: {
    fontSize: '30px',
    display: 'inline-block',
  },
  secondaryPricePeriod: {
    display: 'inline-block',
    fontSize: '15px',
  },
  secondaryPriceWrapper: {
    color: theme.palette.primary.main,
  },
  insidersClub: {
    fontSize: '.85rem',
    [theme.breakpoints.down('xs')]: {
      display: 'inline-block',
      marginRight: '10px',
    },
  },
  alreadyAMemberPrice: {
    textAlign: 'center',
    marginTop: '30px',
    marginBottom: '40px',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      marginTop: '10px',
    },
  },
  buyNowButtonWrapper: {
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      marginTop: '40px',
    },
  },
}))

const PlanBlock = ({ plan, subscription, periodType, setStep }) => {
  const { t } = useTranslation()

  const { addToCart } = useCart()
  const selectedCamera = useActivatedCamera()
  const userClubMembership = useUserClubMembership()
  const userIsNotAMember = !userClubMembership.isMember
  const classes = useStyles({ userIsNotAMember })

  const isCurrentPlanBasic = plan.id === 'Basic'
  const userIsAlreadyAMember = userClubMembership.isMember

  const eCommerceEnabled = appConfig.eCommerce.switch === switches.on
  const planNameMatchSubscription = subscription?.plan?.id === plan.name
  const planPeriodMatchSelection = subscription?.paymentFrequency === periodType
  const isThisCurrentPlan = eCommerceEnabled
    ? userIsNotAMember ? planNameMatchSubscription && planPeriodMatchSelection : planNameMatchSubscription
    : userIsAlreadyAMember && isCurrentPlanBasic

  const ifMonthly = periodType === 'month_by_month'
  const ifAnnually = periodType === 'annual'
  const canNotUpgradeMonthlyPlan = !plan.canUpgradeMonthByMonth
  const canNotUpgradeAnnualPlan = !plan.canUpgradeAnnual
  const buyNowButtonShouldNotBeVisible = (ifMonthly && canNotUpgradeMonthlyPlan) || (ifAnnually && canNotUpgradeAnnualPlan)
  const canShowBuyNowButton = !buyNowButtonShouldNotBeVisible
  const showBasicBanner = plan.showBanner === 'insidersBasic'

  const mostPopular = eCommerceEnabled ? plan.name === 'Premium' : plan.id === 'Premium'

  const currentPlanText = t('billing.current_plan')
  const insidersClubBannerText = t('billing.insiders_club_banner')
  const mostPopularBannerText = t('billing.most_popular_banner')
  const buyCartText = t('app:checkout_process.buy_cart')

  const getPhotoCountString = () => {
    return plan.photoCountPerMonth ? plan.photoCountPerMonth + ' ' + t('billing.photos') : t('billing.unlimited_photos')
  }

  const renderAddToCartButton = () => {
    const addPhotoTransmissionPlanToCart = () => {
      addToCart({
        productId: PHOTO_TRANSMISSION_PLAN,
        cameraId: selectedCamera.id,
        paymentFrequency: periodType,
        plan: plan.id,
      })
      setStep(1)
    }
    return <PrimaryButton onClick={addPhotoTransmissionPlanToCart} id={`${plan.id}_buy`} data-testid={`${plan.id}_buy`}>{ buyCartText }</PrimaryButton>
  }

  const renderBuyNowButton = () => {
    if (isThisCurrentPlan) {
      return (<Typography variant="h2" className={classes.currentPlan}>{ currentPlanText }</Typography>)
    } else if (!canShowBuyNowButton) {
      return renderAddToCartButton()
    }

    return null
  }

  const renderButtonsSection = () => {
    const {
      pricePerMonthIfPaidPerMonth,
      pricePerMonthIfPaidPerMonthInsidersClub,
      pricePerMonthIfPaidAnnually,
      pricePerMonthIfPaidAnnuallyInsidersClub,
      price,
      priceWithInsidersClub,
    } = plan

    return (
      <div className={classes.section}>

        { userIsNotAMember && (
          <Box component="div" align="center" className={classes.mainPriceBloc}>
            <Typography className={classes.mainPrice} component="h2" variant="h2">
              { eCommerceEnabled
                ? <FormatedPrice price={plan.period === 'annual' ? (price / 12) : price} />
                : ifMonthly
                  ? <FormatedPrice price={pricePerMonthIfPaidPerMonth} />
                  : <FormatedPrice price={pricePerMonthIfPaidAnnually} /> }
            </Typography>
            <Typography component="h6" variant="h6" fontWeight={700} style={{ display: 'inline-block' }}>/MO</Typography>
          </Box>
        ) }

        { userIsAlreadyAMember && (
          <Box component="div" className={classes.alreadyAMemberPrice}>
            <Typography className={classes.mainPrice} component="h2" variant="h2">
              { eCommerceEnabled
                ? <FormatedPrice price={plan.period === 'annual' ? (priceWithInsidersClub / 12) : priceWithInsidersClub} />
                : ifMonthly
                  ? <FormatedPrice price={pricePerMonthIfPaidPerMonthInsidersClub} />
                  : <FormatedPrice price={pricePerMonthIfPaidAnnuallyInsidersClub} /> }
            </Typography>
            <Typography component="h6" variant="h6" fontWeight={700} style={{ display: 'inline-block' }}>/MO</Typography>
          </Box>
        ) }

        <Box align="center" mt="27px" className={classes.buyNowButtonWrapper}>
          { renderBuyNowButton() }
        </Box>
      </div>
    )
  }

  const renderBasicPlanBanner = () => {
    return (
      <Box className={classes.basicPlanBanner} fontWeight={600} clone>
        <Typography variant="body2" align="center">{ insidersClubBannerText }</Typography>
      </Box>
    )
  }
  const renderMostPopularBanner = () => {
    return (
      <Box
        className={classes.popularPlanBanner}
        clone
      >
        <Typography variant="body2" align="center">{ mostPopularBannerText }</Typography>
      </Box>
    )
  }
  const cssClasses = plan.photoCountPerMonth
    ? classes.triangle
    : classes.triangle + ' ' + classes.brightTriangle
  const activePlanBorderStyle = isThisCurrentPlan ? classes.activePlan : classes.inactivePlan
  const activePlanStyle = !mostPopular && !isThisCurrentPlan ? classes.basicPlanLayout : classes.planLayout
  const rootStyle = `${classes.rootFix} ${userIsNotAMember ? classes.planLayoutFix : ''}`
  const notPopularStyle = `${rootStyle} ${userIsNotAMember && !mostPopular ? classes.planLayoutNotPopular : ''}`
  return (
    <Paper className={`${rootStyle} ${activePlanStyle} ${activePlanBorderStyle} ${notPopularStyle}`}>
      { userIsAlreadyAMember && showBasicBanner && renderBasicPlanBanner() }
      { mostPopular && renderMostPopularBanner() }
      <div className={cssClasses} />
      <PlanBlockTitle title={t('billing.plans.' + plan.name)} />

      <Typography component="h5" variant="h5" className={classes.photoCount}>
        { getPhotoCountString() }
      </Typography>
      { renderButtonsSection() }
    </Paper>
  )
}

export default PlanBlock
