import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import './SchedulePage.css'
import CreateScheduleContent from './CreateScheduleContent'
import ScheduleGrid from './ScheduleGrid'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import CameraModesList from './CameraModesList'

const SchedulePage = ({ formattedSchedule, onSelectPeriod, onCreate }) => {
  const largeScreen = useMediaQuery(theme => theme.breakpoints.down('xs'))
  return (
    <Container>
      <Grid container item direction="row" justifyContent={largeScreen ? 'flex-start' : 'center'}>
        <Grid container justifyContent={largeScreen ? 'flex-start' : 'center'} item xs={12} sm={2}>
          <CreateScheduleContent onCreate={onCreate} />
        </Grid>
        <Grid item xs={12} sm={8}>
          <ScheduleGrid formattedSchedule={formattedSchedule} onSelectPeriod={onSelectPeriod} />
        </Grid>
        { largeScreen && <CameraModesList /> }
      </Grid>
    </Container>
  )
}

export default SchedulePage
