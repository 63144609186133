import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core'
import { Trans } from 'react-i18next'

const ProfileRadio = ({ form, formik, name, legendKey, disabled, items }) => {
  return (
    <FormControl component="fieldset" disabled={disabled} data-testid={name}>
      <FormLabel component="legend">
        <Trans i18nKey={legendKey} />
      </FormLabel>
      <RadioGroup
        {...formik.getFieldProps(name)}
        row
      >
        {
          items.map(item => (
            <FormControlLabel
              key={item.key}
              name={name}
              control={(
                <Radio
                  value={item.value}
                  color="primary"
                  inputProps={{
                    form: form,
                  }}
                />
              )}
              label={item.name}
              labelPlacement="end"
            />
          ))
        }
      </RadioGroup>
    </FormControl>
  )
}

export default ProfileRadio
