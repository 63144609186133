import { useTranslation } from 'react-i18next'

import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import { useMediaQuery, useTheme } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'

import CTypography from 'storybook-component-module/src/components/typographies/CTypography'

const useStyles = makeStyles(() => ({
  caption: {
    fontSize: '0.625rem',
  },
}))

const MembershipFooterMobile = ({ ml, pt, pb }) => {
  const theme = useTheme()
  const classes = useStyles()
  const { t } = useTranslation()

  const mdBreakpoints = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Container>
      <Box display="flex" ml={ml || 5} pt={pt} pb={pb || 8}>
        <CTypography variant="caption" className={mdBreakpoints && classes.caption}>
          { t('app:insiders_club.footer.caption') }
        </CTypography>
      </Box>
    </Container>
  )
}

export default MembershipFooterMobile
