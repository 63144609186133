import mixpanel from 'mixpanel-browser'
mixpanel.init('128b6fe2001ce14f59b900ca0c735bee')

const actions = {
  identify: (id) => {
    mixpanel.identify(id)
  },
  alias: (id) => {
    mixpanel.alias(id)
  },
  track: (name, props) => {
    mixpanel.track(name, props)
  },
  people: {
    set: (props) => {
      mixpanel.people.set(props)
    },
  },
}

export const Mixpanel = actions
