import { useTranslation } from 'react-i18next'

import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import { useMediaQuery, useTheme } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'

import CTitle from 'storybook-component-module/src/components/typographies/CTitle'
import FullScreenBackground from 'shared-module/new-components/layout/FullScreenBackground'
import MembershipMainBenefitsDesktop from 'spypoint/src/user-module/club-membership/ui/content/MembershipMainBenefitsDesktop'
import MembershipMainBenefitsMobile from 'spypoint/src/user-module/club-membership/ui/content/MembershipMainBenefitsMobile'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  mainGrid: {
    marginTop: 10,
    marginBottom: 5,
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  pageTitle: {
    marginTop: 60,
    textAlign: 'center',
    fontWeight: 700,
    fontSize: '3rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '2.5rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
      marginLeft: '16px',
      marginRight: '16px',
    },
  },
}))

const MembershipPageContent = () => {
  const theme = useTheme()
  const classes = useStyles()
  const { t } = useTranslation()

  const img = '/images/background/ic.png'
  const smBreakpoints = useMediaQuery(theme.breakpoints.only('sm'))
  const mdBreakpoints = useMediaQuery(theme.breakpoints.down('md'))
  const xsBreakpoints = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <FullScreenBackground image={img} marginTop="-8px" paddingBottom={xsBreakpoints ? undefined : 80}>
      <Container maxWidth={smBreakpoints ? 'sm' : 'md'} disableGutters className={classes.container}>
        <CTitle marginBottom={xsBreakpoints ? undefined : 4} className={classes.pageTitle}>
          { t('app:insiders_club.title') }
        </CTitle>

        <Grid container spacing={mdBreakpoints ? 4 : 6} className={classes.mainGrid}>
          { !xsBreakpoints
            ? <MembershipMainBenefitsDesktop />
            : <MembershipMainBenefitsMobile /> }
        </Grid>
      </Container>
    </FullScreenBackground>
  )
}

export default MembershipPageContent
