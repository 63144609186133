import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import { useReloadCameras } from 'camera-module/cameras/core/useCameras'
import BigCheckIcon from 'assets/icons/navigation/BigCheckIcon'
import cameraActions from 'camera-module/camera/core/cameraActions'
import filtersState from 'photo-module/filters/core/filtersState'
import useQueryParams from 'shared-module/url/useQueryParams'
import CameraTile from 'camera-module/cameras/ui/cameras-page/CameraTile'
import { useCameraPlansActions } from 'vosker/src/camera-module/transmission-plan/core/cameraPlans.hook'
import CAppBar from 'storybook-component-module/src/components/containers/CAppBar'
import CButton from 'storybook-component-module/src/components/buttons/CButton'
import { useHistory } from 'react-router-dom'
import appConfig from 'shared-module/config/appConfig'
import switches from 'shared-module/config/switches'
import useAllSharedCameras from 'camera-module/camera/core/useAllSharedCameras'
import useFetchingSharedCameras from 'camera-module/camera/core/useFetchingSharedCameras'
import cameraSharingActions from 'camera-module/camera/core/CameraSharingActions'
import useOwnedSharedCameras from 'camera-module/camera/core/useOwnedSharedCameras'
import useMetaData from 'camera-module/camera/core/useMetaData'
import useSharedCameras from 'camera-module/camera/core/useSharedCameras'
import Grid from '@material-ui/core/Grid'
import CTypography from 'storybook-component-module/src/components/typographies/CTypography'
import useFetchingPendingSharedCameras from 'camera-module/camera/core/useFetchingPendingSharedCameras'
import { uniqBy } from 'lodash'
import useFetchingOwnedSharedCameras from 'camera-module/camera/core/useFetchingOwnedSharedCameras'
import AlertBox from '../camera-sharing-page/AlertBox/AlertBox'
import CircleAlert from 'assets/icons/CircleAlert'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material'
import { Box } from '@material-ui/core'
import CameraSharingIcon from 'assets/icons/navigation/cameraSharingIcon'
import SecondaryButton from 'shared-module/new-components/buttons/SecondaryButton'
import { useUserClubMembership } from 'spypoint/src/user-module/club-membership/core/useUserClubMembership'

const useStyles = makeStyles((theme) => ({
  icon: {
    width: '1.25rem',
    paddingRight: 0,
  },
  title: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.125rem',
      marginLeft: '-0.5rem',
    },
  },
  addButton: {
    minWidth: 'auto',
    whiteSpace: 'noWrap',
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
      padding: '0.25rem 0.5rem',
    },
  },
  paperWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  paper: {
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: '#1F1E1E',
    boxShadow: 'none',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  paperNoCamera: {
    marginTop: '50px',
    padding: '20px',
    margin: '110px 20px',
    justifyContent: 'center',
    textAlign: 'center',
  },
  content: {
    width: '100%',
  },
  noCameraMessage: {
    padding: '20px',
    color: theme.palette.text.primary,
    textAlign: 'left',
    fontSize: '16px',
  },
  paperContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '2.5rem 1.25rem 1.25rem 1.25rem',
    padding: '1.75rem',
    backgroundColor: '#1F1E1E',
    [theme.breakpoints.down('xs')]: {
      margin: '1rem 0',
      padding: '1rem 0.5rem 1rem 1rem',
    },
  },
  paperCameraContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    height: '100%',
    margin: '3.1rem 1.25rem 1.25rem 1.25rem',
    padding: '1.75rem',
    paddingBottom: '15rem',
    backgroundColor: '#1F1E1E',
    [theme.breakpoints.down('xs')]: {
      margin: '1rem 0',
      padding: '1rem 0.5rem 1rem 1rem',
    },
  },
  noCamerasTextTitle: {
    textAlign: 'center',
    marginTop: '75px',
    marginBottom: '20px',
    fontFamily: 'ibm-plex-sans-regular',
    color: '#B1AFAF',
    fontSize: '14px',
    fontWeight: 'bold',
  },
  noCamerasTextContent: {
    textAlign: 'center',
    marginBottom: '75px',
    fontFamily: 'ibm-plex-sans-regular',
    color: '#B1AFAF',
    fontSize: '14px',
    fontWeight: 400,
  },
  titleInfo: {
    fontFamily: 'Oswald-regular',
    fontSize: '21px',
    lineHeight: '31.5px',
    fontWeight: '500',
  },
}))

const CameraPanel = () => {
  const classes = useStyles()
  const theme = useTheme()
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const [isOpenAddCameraDialog, setIsOpenAddCameraDialog] = useState(false)
  const queryParams = useQueryParams()
  const cameraPlansActions = useCameraPlansActions()
  const history = useHistory()
  const cameras = useReloadCameras()
  const AllSharedCameras = useAllSharedCameras()
  const ownedSharedCameras = useOwnedSharedCameras()
  const metaData = useMetaData()
  const sharedCameras = useSharedCameras()
  const FetchingSharedCameras = useFetchingSharedCameras()
  const FetchingPendingSharedCameras = useFetchingPendingSharedCameras()
  const cameraActivationEnabled = appConfig.cameraActivation.switch === switches.on
  const isMobileResolution = useMediaQuery(theme.breakpoints.down('sm'))
  const FetchingOwnedSharedCameras = useFetchingOwnedSharedCameras()
  const userMembership = useUserClubMembership()
  const cameraGuests = () => {
    const sharedCams = []
    const shar = sharedCams.concat.apply([], ownedSharedCameras?.map((ownedSharedCamera) => ownedSharedCamera?.sharedCameras))
    const uniqueSharedCameras = uniqBy(shar, 'cameraId').map(x => {
      const guests = ownedSharedCameras.filter(ownedSharedCamera => {
        const ownedSharedCameraFiltred = ownedSharedCamera.sharedCameras.map(x => x.cameraId)
        return ownedSharedCameraFiltred.includes(x.cameraId)
      })
      return { ...x, status: { model: x.model }, guests: guests }
    })
    return uniqueSharedCameras
  }
  const cameraGuest = cameraGuests()

  const result = AllSharedCameras.reduce((accumulator, current) => {
    const exists = accumulator.find(item => {
      return item.config.id === current.config.id
    })
    if (!exists) {
      accumulator = accumulator.concat(current)
    }
    return accumulator
  }, [])

  const pageTitle = queryParams.transmission_plan
    ? t('app:purchasing_flow.transmission_plan.select_device')
    : t('menu.select_camera')

  useEffect(() => {
    dispatch(cameraActions.clear())
    dispatch(cameraPlansActions.reset())
    dispatch(filtersState.clear())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    dispatch(cameraSharingActions.fetchAllSharedCameras())
    dispatch(cameraSharingActions.fetchOwnedSharedCameras())
    dispatch(cameraSharingActions.fetchMetaData())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const toggleAddCamera = () => {
    if (cameraActivationEnabled) {
      history.push({
        pathname: '/add-camera',
        state: {
          data: true,
        },
      })
    }
    setIsOpenAddCameraDialog(!isOpenAddCameraDialog)
  }

  const renderNoCamerasContainer = () => (
    <Paper className={classes.paperContainer}>
      <Grid container display="flex" justifyContent="center">
        <Grid item md={6} xs={12}>
          <CTypography variant="body2" color="textPrimary" className={classes.noCamerasTextTitle}>{ t('camera_list.empty_state_title_insiders_club') }</CTypography>
          <CTypography variant="body2" className={classes.noCamerasTextContent}>{ t('camera_list.empty_state_subTitle_insiders_club') }</CTypography>
        </Grid>
      </Grid>
    </Paper>
  )
  const renderNoInsidersClubContainer = () => (
    <Paper className={classes.paperContainer}>
      <div style={{ width: isMobileResolution ? '100%' : '38%', marginLeft: isMobileResolution ? 0 : '30%', paddingBottom: '32px' }}>
        <Grid container style={{ paddingTop: isMobileResolution ? '0px' : '20px' }}>
          <Grid container alignItems="center">
            <Box pr={1} mt={1}><CameraSharingIcon /></Box>
            <Box><div className={classes.titleInfo}>{ t('camera_sharing.renew_membership.title') }</div></Box>
          </Grid>
          <Grid container alignItems="center" style={{ fontSize: '16px' }}>
            <Box mt={isMobileResolution ? 2 : 6}>
              <div>
                { t('camera_sharing.renew_membership.paragraph_one_join') }
              </div>
            </Box>
            <Box mt={3}>
              <div>
                { t('camera_sharing.renew_membership.paragraph_two') }
              </div>
            </Box>
            <Box mt={3}>
              <div>
                { t('camera_sharing.renew_membership.paragraph_four') }
              </div>
            </Box>
            <Box mt={3}>
              <div>
                &#9989; { t('camera_sharing.renew_membership.accessible_title') }
                <ul>
                  <li>{ t('camera_sharing.renew_membership.accessible_list.list1') }</li>
                  <li>{ t('camera_sharing.renew_membership.accessible_list.list2') }</li>
                  <li>{ t('camera_sharing.renew_membership.accessible_list.list3') }{ i18n.language === 'fr' && <sup style={{ fontSize: 7 }}>MC</sup> }</li>
                  <li>{ t('camera_sharing.renew_membership.accessible_list.list4') }</li>
                  <li>{ t('camera_sharing.renew_membership.accessible_list.list5') }</li>
                </ul>
              </div>
            </Box>
            <Box mt={1}>
              <div>
                &#x1F512; { t('camera_sharing.renew_membership.inaccessible_title') }
                <ul>
                  <li>{ t('camera_sharing.renew_membership.inaccessible_list.list1') }</li>
                  <li>{ t('camera_sharing.renew_membership.inaccessible_list.list2') }</li>
                  <li>{ t('camera_sharing.renew_membership.inaccessible_list.list3') }</li>
                  <li>{ t('camera_sharing.renew_membership.inaccessible_list.list4') }</li>
                  <li>{ t('camera_sharing.renew_membership.inaccessible_list.list5') }</li>
                </ul>
              </div>
            </Box>
            <Box mt={3}>
              <div>
                { t('camera_sharing.renew_membership.paragraph_three') }
              </div>
            </Box>
          </Grid>
          <Box mt={isMobileResolution ? 2 : 3}>
            <SecondaryButton
              onClick={() => {
                history.push('/membership')
              }}
            >
              { t('camera_sharing.renew_membership.button_join') }
            </SecondaryButton>
          </Box>
        </Grid>
      </div>
    </Paper>
  )
  const renderCameras = () => {
    if (!cameras) {
      return (
        <Paper className={classes.paper} elevation={1}>
          <div className=" center-align">{ t('camera_list.loading') }</div>
        </Paper>
      )
    }

    return (
      <Paper className={classes.paper} elevation={1}>
        { sharedCameras.length === result.length && cameraGuest?.map((camera) => (
          <CameraTile ownedSharedCameras={ownedSharedCameras} isShared isSpypoint key={camera.id} camera={camera} isGuest />
        )) }
      </Paper>
    )
  }

  return (
    <div className={classes.root}>
      <CAppBar Icon={BigCheckIcon} title={pageTitle} titleClassName={classes.title} iconClassName={classes.icon}>
        <CButton variety="secondary" color="primary" className={classes.addButton} onClick={toggleAddCamera}>
          { t('app:active_device.activate_camera') }
        </CButton>
      </CAppBar>
      { cameraGuest && !FetchingOwnedSharedCameras && cameraGuest?.length === 0 && userMembership?.isMember && renderNoCamerasContainer() }
      { cameraGuest?.length > 0 && !FetchingOwnedSharedCameras && (
        <Paper className={classes.paperCameraContainer}>
          { cameraGuest?.length > 0 && !FetchingOwnedSharedCameras && !userMembership?.isMember && (
            <AlertBox
              text={t('camera_sharing.insiders_club_membership_expired')}
              icon={CircleAlert}
              link={t('camera_sharing.renew')}
              url="/membership"
              style={{
                marginBottom: '25px',
                marginLeft: '10px',
                marginRight: '11px',
                marginTop: '0px',
              }}
            />
          ) }
          { renderCameras() }
        </Paper>
      ) }
      { !userMembership?.isMember && cameraGuest?.length === 0 && renderNoInsidersClubContainer() }
    </div>
  )
}

export default CameraPanel
