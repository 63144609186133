import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import PlanIcon from 'assets/icons/navigation/Plan'
import Box from '@material-ui/core/Box'
import Subscription from 'spypoint/src/checkout-module/transmission-plans/ui/transmission-plans-page/Subscription'
import PlanList from 'checkout-module/checkout/ui/transmission-plans-page/PlanList'
import AppBarContainer from 'shared-module/components/app-bar/AppBarContainer'
import { disableAutoRenew, enableAutoRenew } from 'checkout-module/checkout/api/billingApi'
import CameraUnavailableError from 'checkout-module/checkout/ui/transmission-plans-page/CameraUnavailableError'
import Spinner from 'shared-module/components/Spinner'
import cameraActions from 'camera-module/camera/core/cameraActions'
import apm from 'shared-module/monitoring/apm'
import ClubMembershipInfo from 'checkout-module/checkout/ui/transmission-plans-page/ClubMembershipInfo'

import SwitchToggle from 'shared-module/ui/switch-toggle/spypoint'
import FooterInfo from './FooterInfo'

import { getPlanPeriodInitialState } from './PlanHelper'
import appConfig from 'shared-module/config/appConfig'
import switches from 'shared-module/config/switches'
import Typography from '@material-ui/core/Typography'
import { useUserClubMembership } from 'spypoint/src/user-module/club-membership/core/useUserClubMembership'

const useStyles = makeStyles(theme => ({
  paperWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  toggleMargin: {
    margin: '24px',
    [theme.breakpoints.down('xs')]: {
      margin: '44px 0px 0px 0px',
    },
  },
  paper: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '20px',
    marginTop: '170px',
    marginBottom: '30px',
    margin: '20px',
    flexGrow: '2',
    justifyContent: 'space-evenly',
    maxWidth: '50%',
    position: 'sticky',
    left: '25%',
  },
  footNotesContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: 30,
    paddingTop: 20,
    gap: '0.2rem',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'center',
      padding: '10px 10px 30px',
      width: '90%',
    },
  },
  footNotes1: {
    fontFamily: theme.typography.caption1?.fontFamily,
    fontSize: theme.typography.caption1?.fontSize,
    color: theme.palette.text.primary,
  },
  footNotes2: {
    fontFamily: theme.typography.caption1?.fontFamily,
    fontSize: theme.typography.caption1?.fontSize,
    color: theme.palette.text?.tertiary,
  },
}))

const Plan = ({ match }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const userClubMembership = useUserClubMembership()

  const camera = useSelector(state => state.selectedCamera.camera)
  const clubMembership = useSelector(state => state.clubMembership)

  const [activeSubscription, setActiveSubscription] = useState(null)
  const [isSelectedCamera, setIsSelectedCamera] = useState(false)
  const [switchToggleState, setSwitchToggleState] = useState(false)
  const [noActiveSubscription, setNoActiveSubscription] = useState({ i18nKey: 'loading_data' })
  const eCommerceEnabled = appConfig.eCommerce.switch === switches.on
  const freePhotos = userClubMembership.isMember ? 250 : 100

  const getActiveSubscription = useCallback(() => {
    if (camera && camera.subscription && camera.subscription !== 'error') {
      const subscription = camera.subscription
      if (subscription !== 'no_subscription') {
        if (subscription !== undefined) {
          setActiveSubscription(subscription)
          setIsSelectedCamera(true)
          setSwitchToggleState(getPlanPeriodInitialState(subscription))
          setNoActiveSubscription(null)
        } else {
          console.error('getActiveSubscription: this camera is linked to no active subscription.')
          setNoActiveSubscription({ i18nKey: 'billing.errors.no_active_subscription' })
        }
      } else {
        console.error('getActiveSubscription: this camera is linked to no subscription(s).')
        setNoActiveSubscription({ i18nKey: 'billing.errors.no_subscription' })
      }
    } else {
      setNoActiveSubscription({ i18nKey: 'billing.errors.loading_subscription_error' })
    }
  }, [camera])

  useEffect(() => {
    if (!camera) {
      dispatch(cameraActions.get(match.params.id))
    } else {
      apm.log('transmission-plan', [{ cameraModel: camera.status.model }])
      getActiveSubscription()
    }
  }, [camera, dispatch, getActiveSubscription, match.params.id])

  useEffect(() => {
    apm.log('transmission-plan', [{ cameraModel: camera?.status.model }])
    getActiveSubscription()
  }, [camera, getActiveSubscription])

  const disableAutoRenewHandler = () => {
    setNoActiveSubscription({ i18nKey: 'loading_data' })

    disableAutoRenew(camera.id, activeSubscription.id)
      .then(() => {
        setActiveSubscription(prevSubscription => ({
          ...prevSubscription,
          isAutoRenew: false,
        }))
        setNoActiveSubscription(null)
      })
      .catch(() => {
        setNoActiveSubscription(null)
      })
  }

  const enableAutoRenewHandler = () => {
    setNoActiveSubscription({ i18nKey: 'loading_data' })

    enableAutoRenew(camera.id, activeSubscription.id)
      .then(() => {
        setActiveSubscription(prevSubscription => ({
          ...prevSubscription,
          isAutoRenew: true,
        }))
        setNoActiveSubscription(null)
      })
      .catch(() => {
        setNoActiveSubscription(null)
      })
  }

  const getCurrentPlanPeriod = () => {
    return switchToggleState ? 'month_by_month' : 'annual'
  }
  const handleSwitchToggleChange = () => {
    setSwitchToggleState(prevState => !prevState)
  }
  if (camera && isSelectedCamera) {
    if (!camera.isCellular) {
      return <CameraUnavailableError />
    }
    return (
      <div className={classes.root}>
        <AppBarContainer Icon={PlanIcon} title={t('menu.manage_my_plan')} />
        { noActiveSubscription
          ? (
            <Paper className={classes.paper} elevation={1}>
              <div>{ t(noActiveSubscription.i18nKey) }</div>
            </Paper>
            )
          : (
            <Subscription
              subscription={activeSubscription}
              onDisableAutoRenew={disableAutoRenewHandler}
              onEnableAutoRenew={enableAutoRenewHandler}
              isMember={clubMembership?.isMember}
            />
            ) }

        <ClubMembershipInfo />

        <Box align="center" className={classes.toggleMargin}>
          <SwitchToggle
            onChange={handleSwitchToggleChange}
            switchToggleState={switchToggleState}
            firstOption={t('app:photo_transmition_plan.billing.monthly')}
            secondOption={t('app:photo_transmition_plan.billing.annually')}
          />
        </Box>

        <PlanList subscription={activeSubscription} planPeriod={getCurrentPlanPeriod()} />

        { eCommerceEnabled
          ? (
            <Typography variant="caption" className={classes.footNotesContainer}>
              <Box className={classes.footNotes1}>{ t('app:active_device.steps.step_4.note') }</Box>
              <Box alignItems="center" className={classes.footNotes2}>
                { t('app:active_device.steps.step_4.footnote', { freePhotos }) }
              </Box>
            </Typography>
            )
          : <FooterInfo /> }
      </div>
    )
  }

  return <Spinner />
}

export default Plan
