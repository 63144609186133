import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import { useForm } from 'shared-module/new-components/form/new/FormContext'
import Title from 'shared-module/new-components/layout/Title'
import RedeemPromoCodesSection from 'user-module/promo-code/ui/redeem-promo-codes/components/RedeemPromoCodesSection'
import Amount from 'shared-module/components/amount/Amount'
import useUser from 'user-module/user/core/useUser'

const RedeemPromoCodesSuccess = () => {
  const { t } = useTranslation()
  const user = useUser()
  const form = useForm()

  return form.status.isSubmitted
    ? (
      <RedeemPromoCodesSection>
        <Title h="h2">{ t('redeem_promo_code.success.title') }</Title>
        <Typography variant="body2" color="textSecondary">
          <Amount value={form.status.data.spydollars} currencyCode={user.currencyCode} /> { t('redeem_promo_code.success.text') }
        </Typography>
        <Box pt={3}>
          <Link href="#" onClick={() => form.reset()}>{ t('redeem_promo_code.success.link') }</Link>
        </Box>
      </RedeemPromoCodesSection>
      )
    : null
}

export default RedeemPromoCodesSuccess
