import React from 'react'
import { useTranslation } from 'react-i18next'

import MuiBox from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core'
import useTheme from '@material-ui/core/styles/useTheme'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import useQueryParams from 'shared-module/url/useQueryParams'

import Title from 'shared-module/new-components/layout/Title'
import CBadge from 'storybook-component-module/src/components/badges/CBadge'
import PublicPageDivider from 'shared-module/new-components/public-page/PublicPageDivider'

// Bypasses Typescript error: cannot be used as a JSX component
const Box: any = MuiBox

export interface IExternalPurchaseInstructions {
  titleTranslationKey: string
  instructionsTranslationKeys: Array<string>
  fromCart: boolean
  shouldDisplayTitle:boolean
}

const useStyles = makeStyles(theme => ({
  chip: {
    width: 24,
    height: 24,
    fontSize: 10,
    marginRight: 12,
    fontWeight: 'normal',
    borderRadius: '100%',
    color: theme.palette.common.black,
    backgroundColor: theme.palette.secondary.dark,
  },
  plainText: {
    color: theme.palette.text.primary,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
  },
}))

const ExternalPurchaseInstructions = (props: IExternalPurchaseInstructions) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()
  const { accept } = useQueryParams()
  const breakpoint = useMediaQuery(theme.breakpoints.down('xs'))
  const title = accept === false ? `${props.titleTranslationKey}reject` : props.titleTranslationKey
  const rejectionInstructions = ['app:share_camera.rejectionstep1', 'app:share_camera.rejectionstep2']
  return props.shouldDisplayTitle
    ? (
      <div style={{ margin: breakpoint ? 10 : undefined }}>
        <Title externalPageVariant="subtitle1">{ t(title) }</Title>
        { accept === false && rejectionInstructions.map((instruction, key) => (
          <Box display="flex" mt={2} py={0.25}>
            <Box ml={3} mr={1.5}><CBadge badgeContent={key + 1} classes={classes.chip} />
              <span className={classes.plainText}>  { t<string>(instruction) }
              </span>
            </Box>
          </Box>
        )) }
        { accept !== false && props.instructionsTranslationKeys.map((instruction:string, key: number) => (
          <Box display="flex" mt={2} py={0.25} key={instruction}>
            { !props.fromCart && <Box ml={3} mr={1.5}><CBadge badgeContent={key + 1} classes={classes.chip} /></Box> }
            <span className={classes.plainText}>{ t<string>(instruction) }</span>
          </Box>
        )) }

        <PublicPageDivider />
      </div>
      )
    : null
}

export default ExternalPurchaseInstructions
