import SvgIcon from '@material-ui/core/SvgIcon'

const GroupIcon = (props) => (
  <SvgIcon {...props} width="18" height="6" viewBox="0 0 18 6" fill="none" data-testid="GroupIcon">
    <path id="Ellipse 11" d="M4.83342 2.99984C4.83342 4.15043 3.90067 5.08317 2.75008 5.08317C1.59949 5.08317 0.666748 4.15043 0.666748 2.99984C0.666748 1.84924 1.59949 0.916504 2.75008 0.916504C3.90067 0.916504 4.83342 1.84924 4.83342 2.99984Z" fill="#1F222E" />
    <path id="Ellipse 12" d="M11.0834 2.99984C11.0834 4.15043 10.1507 5.08317 9.00008 5.08317C7.84949 5.08317 6.91675 4.15043 6.91675 2.99984C6.91675 1.84924 7.84949 0.916504 9.00008 0.916504C10.1507 0.916504 11.0834 1.84924 11.0834 2.99984Z" fill="#1F222E" />
    <path id="Ellipse 13" d="M17.3334 2.99984C17.3334 4.15043 16.4007 5.08317 15.2501 5.08317C14.0995 5.08317 13.1667 4.15043 13.1667 2.99984C13.1667 1.84924 14.0995 0.916504 15.2501 0.916504C16.4007 0.916504 17.3334 1.84924 17.3334 2.99984Z" fill="#1F222E" />
  </SvgIcon>
)

export default GroupIcon
