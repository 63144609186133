import { useTranslation } from 'react-i18next'
import MaskedInput from 'react-text-mask'
import { useFormField } from 'shared-module/new-components/form/new/FormContext'
import FormTextField from 'shared-module/new-components/form/FormTextField'

const PromoCodeInputMask = ({ inputRef, ...other }) => {
  const _ = /[A-Za-z0-9]/
  return (
    <MaskedInput
      {...other}
      ref={(ref) => { inputRef(ref ? ref.inputElement : null) }}
      mask={[_, _, _, _, '-', _, _, _, _, '-', _, _, _, _]}
    />
  )
}

const PromoCodeField = () => {
  const { t } = useTranslation()
  const promocode = useFormField('promocode')

  return (
    <FormTextField
      id={promocode.name}
      label={t('redeem_promo_code.form.input')}
      placeholder="____-____-____"
      InputLabelProps={{ shrink: true }}
      InputProps={{ inputComponent: PromoCodeInputMask }}
      value={promocode.value.toUpperCase()}
      onChange={promocode.onChange}
      error={promocode.error}
      style={{ maxWidth: '195px' }}
    />
  )
}

export default PromoCodeField
