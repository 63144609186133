import IconButton from '@material-ui/core/IconButton'

import { useTranslation } from 'react-i18next'

import SpypointVideoHdIcon from './SpypointVideoHdIcon'

const SpypointVideoHdAvailableButton = () => {
  const { t } = useTranslation()

  return (
    <IconButton color="secondary" disabled>
      <SpypointVideoHdIcon>
        { t('app:video_viewer.button.available') }
      </SpypointVideoHdIcon>
    </IconButton>
  )
}

export default SpypointVideoHdAvailableButton
