import { iCBenefitTypes } from 'spypoint/src/user-module/club-membership/core/insidersClubBenefits.types'

import DiscountIcon from 'spypoint/src/assets/icons/insiders-club-benefits/DiscountIcon'
import PerksIcon from 'spypoint/src/assets/icons/insiders-club-benefits/PerksIcon'
import HistoryIcon from 'spypoint/src/assets/icons/insiders-club-benefits/HistoryIcon'
import FavoriteIcon from 'spypoint/src/assets/icons/insiders-club-benefits/FavoriteIcon'
import FreePhotosIcon from 'spypoint/src/assets/icons/insiders-club-benefits/FreePhotosIcon'
import BucktrackerIcon from 'spypoint/src/assets/icons/insiders-club-benefits/BucktrackerIcon'
import FreeHDPhotosIcon from 'spypoint/src/assets/icons/insiders-club-benefits/FreeHDPhotosIcon'
import CameraSharingIcon from 'spypoint/src/assets/icons/insiders-club-benefits/CameraSharingIcon'
import PremiumServiceIcon from 'spypoint/src/assets/icons/insiders-club-benefits/PremiumServiceIcon'
import MonthlyGiveawayIcon from 'spypoint/src/assets/icons/insiders-club-benefits/MonthlyGiveawayIcon'

import FullHDIcon from 'spypoint/src/assets/icons/insiders-club-benefits/FullHDIcon'
import PhotoTransmissionIcon from 'spypoint/src/assets/icons/insiders-club-benefits/PhotoTransmissionIcon'
import AccessoriesIcon from 'spypoint/src/assets/icons/insiders-club-benefits/AccessoriesIcon'

const mainBenefits = [
  {
    id: iCBenefitTypes.free_photos,
    icon: FreePhotosIcon,
    title: 'app:insiders_club.main_benefits.free_photos.title',
    description: 'app:insiders_club.main_benefits.free_photos.description',
    isExclusiveToNa: false,
  },
  {
    id: iCBenefitTypes.bucktracker,
    icon: BucktrackerIcon,
    title: 'app:insiders_club.main_benefits.bucktracker.title',
    description: 'app:insiders_club.main_benefits.bucktracker.description',
    isExclusiveToNa: false,
  },
  {
    id: iCBenefitTypes.camera_sharing,
    icon: CameraSharingIcon,
    title: 'app:insiders_club.main_benefits.camera_sharing.title',
    description: 'app:insiders_club.main_benefits.camera_sharing.description',
    isExclusiveToNa: false,
  },
  {
    id: iCBenefitTypes.photo_history,
    icon: HistoryIcon,
    title: 'app:insiders_club.main_benefits.photo_history.title',
    description: 'app:insiders_club.main_benefits.photo_history.description',
    isExclusiveToNa: false,
  },
  {
    id: iCBenefitTypes.favorite,
    icon: FavoriteIcon,
    title: 'app:insiders_club.main_benefits.favorite.title',
    description: 'app:insiders_club.main_benefits.favorite.description',
    isExclusiveToNa: false,
  },
  {
    id: iCBenefitTypes.free_hd_photos,
    icon: FreeHDPhotosIcon,
    title: 'app:insiders_club.main_benefits.free_hd_photos.title',
    description: 'app:insiders_club.main_benefits.free_hd_photos.description',
    isExclusiveToNa: false,
  },
  {
    id: iCBenefitTypes.monthly_giveaway,
    icon: MonthlyGiveawayIcon,
    title: 'app:insiders_club.main_benefits.monthly_giveaway.title',
    description: 'app:insiders_club.main_benefits.monthly_giveaway.description',
    isExclusiveToNa: true,
  },
  {
    id: iCBenefitTypes.premium_services,
    icon: PremiumServiceIcon,
    title: 'app:insiders_club.main_benefits.premium_services.title',
    description: 'app:insiders_club.main_benefits.premium_services.description',
    isExclusiveToNa: true,
  },
  {
    id: iCBenefitTypes.perks,
    icon: PerksIcon,
    title: 'app:insiders_club.main_benefits.perks.title',
    description: 'app:insiders_club.main_benefits.perks.description',
    isExclusiveToNa: false,
  },
]

const additionalBenefits = [
  {
    id: iCBenefitTypes.photo_transmission,
    icon: PhotoTransmissionIcon,
    description: 'app:insiders_club.additional_benefits.photo_transmission.description',
    isExclusiveToNa: false,
  },
  {
    id: iCBenefitTypes.full_hd_photo_video,
    icon: FullHDIcon,
    description: 'app:insiders_club.additional_benefits.full_hd_photo_video.description',
    isExclusiveToNa: false,
  },
  {
    id: iCBenefitTypes.accessories,
    icon: AccessoriesIcon,
    description: 'app:insiders_club.additional_benefits.accessories.description',
    isExclusiveToNa: true,
  },
]

const mobileBenefits = [
  ...mainBenefits,
  {
    id: iCBenefitTypes.discount,
    icon: DiscountIcon,
    title: 'app:insiders_club.main_benefits.discount.title',
    description: 'app:insiders_club.main_benefits.discount.description',
    isExclusiveToNa: true,
  },
]
export { mainBenefits, additionalBenefits, mobileBenefits }
