import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import AppButton from 'shared-module/components/button/AppButton'
import PrimaryButton from 'shared-module/components/button/PrimaryButton'
import { isExternalWebApp } from 'shared-module/webview/isExternalWebApp'

const useStyles = makeStyles((theme) => ({
  continueButton: {
    backgroundColor: theme.palette.background.tile,
    marginRight: 15,
    '&:hover': {
      background: theme.palette.background.paper,
    },
  },
  continueItem: {
    paddingRight: 15,
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0,
      marginBottom: 10,
    },
  },
  cartButtons: {
    marginBottom: 15,
    [theme.breakpoints.down('xs')]: {
      padding: '0px 10px',
    },
  },
}))

const NewCartButtons = ({ isUnavailableItemInCart, products, deleteFromCart, isNewUi, setStep, itemId }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const isExternal = isExternalWebApp()

  const onCancel = () => {
    deleteFromCart({ itemId: itemId })
    setStep(0)
  }

  const goToCheckout = () => {
    setStep(2)
  }

  return (
    <Grid
      container
      item
      direction="row"
      justifyContent="center"
      alignItems="flex-end"
      xs={12}
      sm={8}
      md={6}
      lg={isNewUi ? 6 : 4}
      xl={isNewUi ? 6 : 3}
      className={classes.cartButtons}
    >
      { !isExternal && (
        <Grid item xs={12} sm={6} className={classes.continueItem}>
          <AppButton
            fullWidth
            color="secondary"
            className={classes.continueButton}
            value={t('camera.settings.buttons.cancel')}
            onClick={onCancel}
            style={{ whiteSpace: 'nowrap' }}
          />
        </Grid>
      ) }
      <Grid item xs={12} sm={isExternal ? 12 : 6} style={{ padding: 0 }}>
        <PrimaryButton
          fullWidth
          disabled={isUnavailableItemInCart}
          value={t('menu.checkout')}
          onClick={goToCheckout}
          style={{ whiteSpace: 'nowrap' }}
        />
      </Grid>
    </Grid>
  )
}

export default NewCartButtons
