import restapi from 'shared-module/api/restapi'

const getTerritoryRequired = userId => {
  return restapi.get(`/api/v3/user/consent/${userId}`)
}
const consentApi = {
  getTerritoryRequired,
}

export default consentApi
