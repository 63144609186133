import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link } from '@material-ui/core'
import { Trans } from 'react-i18next'
import PrimaryButton from 'shared-module/new-components/buttons/PrimaryButton'
import SecondaryButton from 'shared-module/new-components/buttons/SecondaryButton'
import { WarningRounded, FiberManualRecord } from '@material-ui/icons'
import Box from '@material-ui/core/Box'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  itemIcon: {
    color: theme.palette.secondary.main,
    fontSize: 8,
    marginRight: 16,
  },
  noTopPadding: {
    paddingTop: 0,
  },
  noBottomPadding: {
    paddingBottom: 0,
  },
  noMargin: {
    margin: 0,
  },
  buttons: {
    marginTop: 3,
  },
}))

const options = { timeZone: 'GMT', year: 'numeric', month: 'long', day: 'numeric' }

const HspaInformationDialog = ({ showDialog, closeDialog, promotionDate, isPromotionalPeriod }) => {
  const classes = useStyles()

  return (
    <Dialog data-testid="hspa-dialog" maxWidth="xs" aria-labelledby="pop-up-title" open={showDialog} onClose={closeDialog}>
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <WarningRounded fontSize="large" color="primary" />
          <Box ml={1}>
            <Trans i18nKey="hspa.dialog.title" />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Trans i18nKey="hspa.dialog.paragraph.first" />
        </DialogContentText>
        <List>
          <DialogContentText className={classes.noMargin}>
            <Trans i18nKey="hspa.dialog.list.header" />
          </DialogContentText>
          <ListItem className={clsx(classes.noTopPadding, classes.noBottomPadding)}>
            <FiberManualRecord className={classes.itemIcon} />
            <DialogContentText className={classes.noMargin}>
              <Trans i18nKey="hspa.dialog.list.item.first" />
            </DialogContentText>
          </ListItem>
          <ListItem className={classes.noTopPadding}>
            <FiberManualRecord className={classes.itemIcon} />
            <DialogContentText className={classes.noMargin}>
              <Trans i18nKey="hspa.dialog.list.item.second" />
            </DialogContentText>
          </ListItem>
        </List>
        { isPromotionalPeriod && (
          <DialogContentText>
            <Trans i18nKey="hspa.dialog.paragraph.second" />
          </DialogContentText>
        ) }
        <DialogContentText>
          <Trans i18nKey="hspa.dialog.paragraph.third">
            You may consult our FAQ section at <Link target="_blank" href="https://spypoint.com/HSPA">spypoint.com</Link> for more information.
          </Trans>
        </DialogContentText>
        { isPromotionalPeriod && (
          <DialogContentText variant="body2">
            <Trans i18nKey="hspa.dialog.note" />{ promotionDate.toLocaleDateString('en-US', options) }.
          </DialogContentText>
        ) }
        <DialogActions style={{ marginTop: '24px' }}>
          <SecondaryButton onClick={closeDialog}>
            <Trans i18nKey="hspa.dialog.buttons.close" />
          </SecondaryButton>
          { isPromotionalPeriod && (
            <PrimaryButton href="https://spypoint.com/HSPA" target="_blank">
              <Trans i18nKey="hspa.dialog.buttons.proceed" />
            </PrimaryButton>
          ) }
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

export default HspaInformationDialog
