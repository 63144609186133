import userPaymentMethodsApi from 'spypoint/src/user-module/payment-methods/api/userPaymentMethods.api'
import handleError from 'shared-module/api/apiError'

const typeSet = 'SET_PAYMENT_METHODS'
const typeClear = 'CLEAR_PAYMENT_METHODS'

export const defaultState = {
  isFetched: false,
  list: [],
}

const get = () => dispatch => userPaymentMethodsApi.getUserPaymentMethods().then(data => dispatch({ type: typeSet, data })).catch(handleError)
const updateDefault = (token) => dispatch => userPaymentMethodsApi.updateUserDefaultPaymentMethods(token).then(data => dispatch({ type: typeSet, data })).catch(handleError)
const clear = () => dispatch => dispatch({ type: typeClear })

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case typeSet:
      return {
        ...state,
        isFetched: true,
        list: [
          ...action.data,
        ],
      }
    case typeClear:
      return defaultState
    default:
      return state
  }
}

const userPaymentMethodsState = { get, updateDefault, clear, reducer }
export default userPaymentMethodsState
