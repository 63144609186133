import { Box, Divider, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  divider: {
    backgroundColor: theme.palette.divider,
  },
}))

const CartDivider = () => {
  const classes = useStyles()

  return (
    <Box mt={2} mb={3}>
      <Divider className={classes.divider} />
    </Box>
  )
}

export default CartDivider
