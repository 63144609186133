import { useDispatch, useSelector } from 'react-redux'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import CameraIcon from 'assets/icons/navigation/CameraIcon'
import useCamera from 'camera-module/camera/core/useCamera'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import AppBarContainer from 'shared-module/components/app-bar/AppBarContainer'
import apm from 'shared-module/monitoring/apm'
import SpypointCameraStatusTiles from 'spypoint/src/cameras-module/ui/camera-status-page/SpypointCameraStatusTiles'
import useUser from 'user-module/user/core/useUser'
import cameraActions from 'camera-module/camera/core/cameraActions'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '150px',
  },
  paper: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '20px',
    margin: '20px',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
}))

const SpypointCameraStatusPage = ({ match }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const camera = useCamera(match.params.id)
  const user = useUser()
  const dispatch = useDispatch()

  useEffect(() => {
    if (camera) {
      apm.log('camera-status', [{ cameraModel: camera.status.model }])
    }
  }, [camera])
  useEffect(() => {
    dispatch(cameraActions.get(match.params.id))
  }, [])

  const renderCameraStatus = () => (
    <Paper className={classes.paper} elevation={1}>
      <SpypointCameraStatusTiles user={user} camera={camera} />
    </Paper>
  )

  const renderSelectCameraMessage = () => (
    <Paper className={classes.paper} elevation={1}>
      <div>{ t('camera_list.choose') }</div>
    </Paper>
  )

  return (
    <div className={classes.root}>
      <AppBarContainer Icon={CameraIcon} title={t('menu.camera_status')} />
      { camera ? renderCameraStatus() : renderSelectCameraMessage() }
    </div>
  )
}

export default SpypointCameraStatusPage
