import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const ModemFirmware = ({ props }) => {
  const theme = useTheme()
  return (
    <SvgIcon width="32" height="32" viewBox="0 0 32 32" {...props} data-testid="ModemFirmware">
      <path
        d="M20.7148 14.2773C20.7148 13.7305 20.5234 13.2747 20.1406 12.9102C19.7578 12.5273 19.3021 12.3359 18.7734 12.3359H9.94141C9.39453 12.3359 8.92969 12.5273 8.54688 12.9102C8.18229 13.2747 8 13.7305 8 14.2773V27.2656C8 27.8125 8.18229 28.2682 8.54688 28.6328C8.92969 29.0156 9.39453 29.207 9.94141 29.207H18.7734C19.3021 29.207 19.7578 29.0156 20.1406 28.6328C20.5234 28.2682 20.7148 27.8125 20.7148 27.2656V14.2773ZM22.4102 16.5742C22.1914 16.5742 22.0091 16.5013 21.8633 16.3555C21.7174 16.1914 21.6445 16.0091 21.6445 15.8086V2.76562C21.6445 2.54688 21.7174 2.36458 21.8633 2.21875C22.0091 2.07292 22.1914 2 22.4102 2C22.6107 2 22.7839 2.07292 22.9297 2.21875C23.0938 2.36458 23.1758 2.54688 23.1758 2.76562V15.8086C23.1758 16.0091 23.0938 16.1914 22.9297 16.3555C22.7839 16.5013 22.6107 16.5742 22.4102 16.5742Z"
        fill={theme.palette.background.logo}
      />
    </SvgIcon>
  )
}

export default ModemFirmware
