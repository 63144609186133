import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import LineTitle from 'spypoint/src/checkout-module/order/ui/cart-lines/LineTitle'
import { useTranslation } from 'react-i18next'
import { formatDate } from 'shared-module/common-utils'
import Amount from 'shared-module/components/amount/Amount'
import useUser from 'user-module/user/core/useUser'
import CartTitleLine from '../cart-lines/CartTitleLine'
import { useLoadCameras } from 'camera-module/cameras/core/useCameras'

const useStyles = makeStyles((theme) => ({
  dates: {
    fontSize: '14px',
    lineHeight: '150%',
    color: theme.palette.secondary.main,
    flex: 'none',
    order: '0',
    flexGrow: '0',
    marginLeft: '20px',
  },
}))

const PhotoTransmissionCheckoutPart = ({ product }) => {
  const classes = useStyles()
  const { t, i18n } = useTranslation()
  const user = useUser()
  const cameras = useLoadCameras()

  const yearly = t('app:checkout_process.photo_transmission_plan.yearly')
  const monthly = t('app:checkout_process.photo_transmission_plan.monthly')
  const checkedState = product.paymentFrequency === 'annual'

  return (
    <>
      <CartTitleLine>
        { product.plan + ' ' +
          t('app:checkout_process.photo_transmission_plan.title') +
          ' - ' +
          (checkedState ? yearly : monthly) }
      </CartTitleLine>
      <LineTitle>{ t('app:checkout_process.device') }</LineTitle>
      <LineTitle align="right" overflow="hidden"> { cameras?.find(camera => camera.id === product?.cameraId)?.config?.name }</LineTitle>
      <Grid item xs={6}>
        <Typography variant="body2">{ t(
          'app:checkout_process.initial_payment') }
        </Typography>
      </Grid>
      <Grid item>
        <LineTitle align="right">
          <Amount
            value={product.basePrice}
            currencyCode={user.currencyCode}
          />
        </LineTitle>
      </Grid>
      { product.period && (
        <Grid item xs={12}>
          <Typography className={classes.dates}>
            { formatDate(
              new Date(product.period.start * 1000).toString(),
              i18n, true) }
            { ' - ' }
            { formatDate(
              new Date(product.period.end * 1000).toString(),
              i18n, true) }
          </Typography>
        </Grid>
      ) }
      <LineTitle full>
        { checkedState
          ? t('app:checkout.renew_yearly_text')
          : t('app:checkout.renew_monthly_text') }{ ' ' }
        <Amount
          value={product.basePrice}
          currencyCode={user.currencyCode}
        />
      </LineTitle>
    </>

  )
}
export default PhotoTransmissionCheckoutPart
