import produce from 'immer'

const defaultState = { notifications: [] }

const set = (state, action) => ({ notifications: action.notifications })

const isRead = (state, action) => produce(state, newState => {
  const notification = newState.notifications.find(n => n.id === action.id)
  if (notification) notification.read = true
})

const setMultipleRead = (state, action) => produce(state, newState => {
  newState.notifications.forEach(notification => {
    if (action.templateNames.includes(notification?.templateName)) notification.read = true
  })
})

const notificationState = { defaultState, set, isRead, setMultipleRead }
export default notificationState
