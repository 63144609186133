import { SET_CART, CLEAR_ERROR, SET_ERROR } from './cart.actions'

const defaultCartState = {
  cartItems: { isFetched: false, products: [] },
  cartTotal: null,
  cartDiscount: null,
  cartBaseBeforeTaxes: null,
  totalTaxes: null,
  error: false,
  message: null,
  creditCardNotRequired: false,
}

const updateStateWithCart = (state, action) => {
  const cart = action.payload
  return {
    ...state,
    cartItems: {
      isFetched: true,
      products: cart.items,
    },
    cartTotal: cart.cartTotal,
    cartBaseBeforeTaxes: cart.cartBaseBeforeTaxes,
    creditCardNotRequired: !!cart?.creditCardNotRequired,
    cartDiscount: cart.cartDiscountAmount,
    totalTaxes: cart.totalTaxes,
    error: false,
    message: action.message,
  }
}

const cartReducer = (state = defaultCartState, action) => {
  switch (action.type) {
    case SET_CART: return updateStateWithCart(state, action)
    case CLEAR_ERROR: return { ...state, error: false, message: null }
    case SET_ERROR: return { ...state, error: true, message: action.message }
    default:
      return state
  }
}

export default cartReducer
