import clsx from 'clsx'
import React, { ReactNode } from 'react'

import Grid from '@material-ui/core/Grid'
import AppBar from '@material-ui/core/AppBar'
import Divider from '@material-ui/core/Divider'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/core/styles/makeStyles'

export interface ICAppBar {
  Icon: ReactNode
  title: string
  variant?: any
  iconClassName?: string
  titleClassName?: string
  children?: ReactNode
  className?: string
  showDivider?: boolean
}

const useStyles = makeStyles((theme) => ({
  appbar: {
    boxShadow: 'none',
    background: theme.palette.background.paper,
    [theme.breakpoints.down('sm')]: {
      marginTop: '56px',
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: '64px',
    },
  },
  grid: {
    alignItems: 'center',
  },
  icon: {
    color: `${theme.palette.text.primary} !important`,
    marginLeft: 0.5,
  },
  divider: {
    backgroundColor: theme.palette.secondary.main,
  },

}))

const CAppBar = (props: ICAppBar) => {
  const classes = useStyles()
  const Icon: any = props.Icon

  return (
    <div>
      <AppBar className={clsx(classes.appbar, props.className)}>
        <Toolbar>
          <Grid container item xs spacing={2} wrap="nowrap" className={classes.grid}>
            <Grid item style={{ paddingBottom: '2px' }}>
              <div className={classes.icon}>
                <Icon className={props.iconClassName} />
              </div>
            </Grid>

            <Grid item>
              <Typography variant={props.variant || 'h2'} color="textPrimary" noWrap className={props.titleClassName}>
                { props.title }
              </Typography>
            </Grid>
          </Grid>

          { props.children }
        </Toolbar>
        { props.showDivider && <Divider className={classes.divider} /> }
      </AppBar>
    </div>
  )
}

export default CAppBar
