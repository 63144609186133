import Box from '@material-ui/core/Box'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  notice: {
    padding: '0.625rem 1rem',
    background: theme.palette.background.secondary,
  },
  message: {
    fontSize: 10,
    fontWeight: 700,
    textAlign: 'center',
  },
}))

const CameraNotSyncNotice = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Box className={classes.notice}>
      <Typography className={classes.message}>{ t('app:camera.messages.not_synced') }</Typography>
    </Box>
  )
}

export default CameraNotSyncNotice
