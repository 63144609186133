import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import PrimaryButton from 'shared-module/new-components/buttons/PrimaryButton'

const CheckoutDialog = ({ showDialog, dialogData, closeDialog }) => {
  const { t } = useTranslation()

  return (
    <Dialog aria-labelledby="pop-up-title" open={showDialog} onClose={() => closeDialog(dialogData?.errorMessages !== 0)}>
      <DialogTitle>{ dialogData?.title }</DialogTitle>
      <DialogContent>
        <DialogContentText style={{ whiteSpace: 'pre-line' }}>{ dialogData?.text }</DialogContentText>
        { dialogData?.errorMessages && dialogData.errorMessages.map(item => <DialogContentText key={item}>{ item }</DialogContentText>) }
        { dialogData?.footer && <DialogContentText>{ dialogData.footer }</DialogContentText> }
        <DialogActions>
          <PrimaryButton onClick={() => closeDialog(!!dialogData?.errorMessages)}>{ t('button.ok') }</PrimaryButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

export default CheckoutDialog
