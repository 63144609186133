import Typography from '@material-ui/core/Typography'
import useUser from 'user-module/user/core/useUser'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import appConfig from 'shared-module/config/appConfig'
import switches from 'shared-module/config/switches'
import SpyDollars from 'user-module/promo-code/ui/redeem-promo-codes/components/SpyDollars'

const useStyles = makeStyles({
  container: {
    marginTop: 15,
  },
})

const SpyDollarsInfo = ({ config = appConfig }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const user = useUser()

  if (!user.isAuthenticated) {
    return null
  }

  if (config.spydollars.switch !== switches.on) {
    return null
  }

  return (
    <div className={classes.container}>
      <Typography variant="body1" gutterBottom>
        { `${t('user.spydollars.balance')}:` } <SpyDollars />
      </Typography>
      <Typography variant="body1" gutterBottom>
        { `${t('user.spydollars.message')}` }
      </Typography>
    </div>
  )
}

export default SpyDollarsInfo
