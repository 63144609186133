import makeStyles from '@material-ui/core/styles/makeStyles'
import downloadStatus from 'photo-module/download/downloadStatus'
import { useEffect, useState } from 'react'
import SpypointPreviewAgainButton from '../buttons/SpypointPreviewAgainButton'
import SpypointVideoHdRequestableButton from '../buttons/SpypointVideoHdRequestableButton'

const useStyles = makeStyles(theme => ({
  photo: {
    [theme.breakpoints.up('sm')]: {
      minWidth: '600px',
    },
    maxWidth: '100%',
  },
  dimmedPhoto: {
    backgroundColor: theme.palette.background.pureBlack,
    opacity: '12%',
    zIndex: 1,
    [theme.breakpoints.up('sm')]: {
      minWidth: '600px',
    },
    maxWidth: '100%',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconWrapper: {
    position: 'absolute',
    display: 'flex',
    opacity: '100%',
    flexDirection: 'column',
    zIndex: 1000,
    '& svg': {
      '& path': {
        fill: theme.palette.secondary.contrastText,
      },
    },
  },
}))

const VideoPreviewViewer = ({ photo }) => {
  const previewArray = photo.urls.preview
  const [previewUrl, setPreviewUrl] = useState(previewArray[0])
  const [showPreviewAgain, setShowPreviewAgain] = useState(false)
  const classes = useStyles()
  useEffect(() => {
    let previewNo = 0
    let counter = 0
    if (!showPreviewAgain) {
      const interval = setInterval(() => {
        if (++previewNo === previewArray.length) {
          previewNo = 0
          if (++counter === 3) {
            clearInterval(interval)
            setShowPreviewAgain(true)
          }
        }
        setPreviewUrl(previewArray[previewNo])
      }, 1000)
      return () => clearInterval(interval)
    }
    return () => setShowPreviewAgain(false)
  }, [photo, previewArray, showPreviewAgain])

  return (
    <div className={classes.container}>
      { showPreviewAgain && (
        <div className={classes.iconWrapper}>
          { photo.hdVideoStatus === downloadStatus.requestable &&
            <SpypointVideoHdRequestableButton inPreview /> }
          <SpypointPreviewAgainButton
            onClick={() => setShowPreviewAgain(false)}
          />
        </div>
      ) }
      <img
        className={showPreviewAgain ? classes.dimmedPhoto : classes.photo}
        src={previewUrl}
        alt="videoPreview"
      />
    </div>
  )
}

export default VideoPreviewViewer
