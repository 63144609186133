import { Trans, useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import InsidersClubImage from 'spypoint/public/images/insiders-club.png'
import { useUserClubMembership } from 'spypoint/src/user-module/club-membership/core/useUserClubMembership'
import PrimaryButton from 'shared-module/new-components/buttons/PrimaryButton'

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    padding: '0 20px',
    backgroundColor: theme.palette.primary.contrastText,
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      textAlign: 'center',
    },
  },
  container: {
    maxWidth: '1000px',
    padding: 0,
  },
  title: {
    fontSize: '1.5rem',
    marginBottom: '12px',
  },
  description: {
    fontSize: '.95rem',
  },
  titleDescriptionContainer: {
    paddingTop: '15px',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '15px',
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 0,
    },
  },
  buttonContainer: {
    paddingTop: '15px',
    [theme.breakpoints.down('xs')]: {
      margin: 0,
      paddingBottom: '15px',
    },
  },
  alreadyAMemberNotice: {
    textAlign: 'center',
    fontSize: '15px',
    marginTop: '20px',
    marginBottom: '30px',
    [theme.breakpoints.down('xs')]: {
      margin: '30px 40px',
    },
  },
  twentyPercentOff: {
    color: theme.palette.primary.main,
  },
}))

const ClubMembershipInfo = () => {
  const { t } = useTranslation()

  const history = useHistory()

  const userClubMembership = useUserClubMembership()
  const userIsNotAMember = !userClubMembership.isMember

  const classes = useStyles()

  const title = t('app:photo_transmition_plan.club_membership.title')
  const description = t('app:photo_transmition_plan.club_membership.description')
  const buttonText = t('app:photo_transmition_plan.club_membership.button')

  return userIsNotAMember
    ? (
      <Box mt="20px">
        <Container maxWidth="md" component="div" className={classes.container}>
          <Paper className={classes.paper}>
            <Box mr={1}>
              <img src={InsidersClubImage} alt="insiders-club" data-testid="insiders-club-image" />
            </Box>
            <Box className={classes.titleDescriptionContainer}>
              <Typography component="h2" variant="h2" className={classes.title}>{ title }</Typography>
              <Typography component="div" className={classes.description}>{ description }</Typography>
            </Box>
            <Box m={3} className={classes.buttonContainer}>
              <PrimaryButton onClick={() => history.push('/membership')} style={{ whiteSpace: 'nowrap' }} variant="outlined" data-testid="join-the-club-button">{ buttonText }</PrimaryButton>
            </Box>
          </Paper>
        </Container>
      </Box>
      )
    : (
      <Typography component="p" className={classes.alreadyAMemberNotice}>
        <Trans i18nKey="app:photo_transmition_plan.club_membership.members_notice">
          As an Insiders Club member, you benefit from a <strong className={classes.twentyPercentOff}>20% discount</strong> on ALL your Photo Transmission Plans
        </Trans>
      </Typography>
      )
}

export default ClubMembershipInfo
