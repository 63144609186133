import CameraStatusTile from 'camera-module/camera/ui/camera-status-page/CameraStatusTile'
import { useTranslation } from 'react-i18next'
import PowerMode from 'assets/icons/status/PowerMode'
import powerModes from 'camera-module/camera/core/powerModeSettings'

const PowerModeTile = ({ value, isNewUi }) => {
  const { t } = useTranslation()

  return (
    <CameraStatusTile
      label={t('camera.power_mode')}
      icon={<PowerMode />}
      value={t(`camera.power_mode_value.${value || powerModes.Performance}`).toUpperCase()}
      useSVGIcon
      isNewUi={isNewUi}
    />
  )
}

export default PowerModeTile
