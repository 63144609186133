import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const SolarBattery4 = ({ props }) => {
  const theme = useTheme()
  return (
    <SvgIcon width="32" height="32" viewBox="0 0 32 32" {...props} data-testid="BatteryType">
      <g id="Icons / Status / Battery0-duotone--dark">
        <path
          opacity="0.33"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M22 12.75C22.4142 12.75 22.75 13.0858 22.75 13.5V18.5C22.75 18.9142 22.4142 19.25 22 19.25C21.5858 19.25 21.25 18.9142 21.25 18.5V13.5C21.25 13.0858 21.5858 12.75 22 12.75Z"
          fill="#FBF9F9"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8 12.75C8.41421 12.75 8.75 13.0858 8.75 13.5V18.5C8.75 18.9142 8.41421 19.25 8 19.25C7.58579 19.25 7.25 18.9142 7.25 18.5V13.5C7.25 13.0858 7.58579 12.75 8 12.75ZM11.5 12.75C11.9142 12.75 12.25 13.0858 12.25 13.5V18.5C12.25 18.9142 11.9142 19.25 11.5 19.25C11.0858 19.25 10.75 18.9142 10.75 18.5V13.5C10.75 13.0858 11.0858 12.75 11.5 12.75ZM15 12.75C15.4142 12.75 15.75 13.0858 15.75 13.5V18.5C15.75 18.9142 15.4142 19.25 15 19.25C14.5858 19.25 14.25 18.9142 14.25 18.5V13.5C14.25 13.0858 14.5858 12.75 15 12.75ZM18.5 12.75C18.9142 12.75 19.25 13.0858 19.25 13.5V18.5C19.25 18.9142 18.9142 19.25 18.5 19.25C18.0858 19.25 17.75 18.9142 17.75 18.5V13.5C17.75 13.0858 18.0858 12.75 18.5 12.75Z"
          fill="#FBF9F9"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.75 9.75V22.25H25.25V19C25.25 18.5858 25.5858 18.25 26 18.25H28.25V13.75H26C25.5858 13.75 25.25 13.4142 25.25 13V9.75H4.75ZM3.25 9.5C3.25 8.80965 3.80964 8.25 4.5 8.25H25.5C26.1904 8.25 26.75 8.80964 26.75 9.5V12.25H28.5C29.1904 12.25 29.75 12.8096 29.75 13.5V18.5C29.75 19.1904 29.1904 19.75 28.5 19.75H26.75V22.5C26.75 23.1904 26.1904 23.75 25.5 23.75H4.5C3.80964 23.75 3.25 23.1904 3.25 22.5V9.5Z"
          fill="#FBF9F9"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.654 2.40297C15.654 2.73475 15.3612 3.00371 15 3.00371C14.6388 3.00371 14.346 2.73475 14.346 2.40297V0.600742C14.346 0.268962 14.6388 0 15 0C15.3612 0 15.654 0.268962 15.654 0.600742V2.40297ZM22.9736 6.97824C22.6608 7.14413 22.2608 7.04568 22.0802 6.75835C21.8996 6.47102 22.0068 6.10361 22.3196 5.93772L24.0188 5.03661C24.3317 4.87072 24.7317 4.96916 24.9123 5.2565C25.0929 5.54383 24.9857 5.91124 24.6729 6.07713L22.9736 6.97824ZM19.9812 3.78988C19.8006 4.07721 19.4006 4.17565 19.0877 4.00976C18.7749 3.84387 18.6677 3.47646 18.8484 3.18913L19.8294 1.62835C20.01 1.34102 20.41 1.24258 20.7228 1.40847C21.0357 1.57436 21.1429 1.94177 20.9622 2.2291L19.9812 3.78988ZM7.68041 5.93772C7.99323 6.10361 8.10042 6.47102 7.91981 6.75835C7.7392 7.04568 7.33919 7.14413 7.02637 6.97824L5.32713 6.07713C5.01431 5.91124 4.90713 5.54383 5.08773 5.2565C5.26834 4.96916 5.66835 4.87072 5.98117 5.03661L7.68041 5.93772ZM11.1516 3.18913C11.3323 3.47646 11.2251 3.84387 10.9122 4.00976C10.5994 4.17565 10.1994 4.07721 10.0188 3.78988L9.03775 2.2291C8.85714 1.94177 8.96433 1.57436 9.27715 1.40847C9.58997 1.24258 9.98998 1.34102 10.1706 1.62835L11.1516 3.18913Z"
          fill="#FBF9F9"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M20.2943 9.41176H9.70605C9.70609 6.8128 12.0763 4.70593 15.0002 4.70593C17.924 4.70593 20.2943 6.8128 20.2943 9.41176Z"
          fill="#FBF9F9"
        />
      </g>
    </SvgIcon>
  )
}

export default SolarBattery4
