import { Box, useTheme } from '@material-ui/core'
import { Trans } from 'react-i18next'
import Amount from 'shared-module/components/amount/Amount'

const SpydollarsBar = ({ spydolars, currency }) => {
  const theme = useTheme()

  return (
    <Box sx={{
      height: 40,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      bgcolor: 'primary.contrastText',
    }}
    >
      <Box component="span">
        <Trans i18nKey="app:account.spydollars" />
      </Box>
      &nbsp;
      <Box sx={{ fontWeight: 600, color: theme.palette.primary.main }}>
        <Amount
          value={spydolars}
          currencyCode={currency}
        />
      </Box>
    </Box>
  )
}

export default SpydollarsBar
