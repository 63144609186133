import { useTranslation } from 'react-i18next'
import Dialog from '@material-ui/core/Dialog'
import { Button } from 'form-module/ui'
import { useState } from 'react'
import SettingsCustomSchedule from './SettingsCustomSchedule'
import { useTheme } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import VoskerSecondaryButton from 'vosker/src/components/buttons/VoskerSecondaryButton'

const CustomScheduleField = ({ isNewUi }) => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  const onOpen = () => setOpen(true)
  const onClose = () => setOpen(false)
  const theme = useTheme()

  const EditButton = isNewUi ? VoskerSecondaryButton : Button

  return (
    <div>
      <Grid display="flex" flexdirection="row" justifyContent="flex-end" container spacing={1}>
        <Grid item xs={6} sm={3}><EditButton onClick={onOpen} color="primary" variant="outlined">
          { t('camera.settings.buttons.edit') }
        </EditButton>
        </Grid>
      </Grid>
      <Dialog
        fullScreen
        open={open}
        onClose={onClose}
        PaperProps={{
          style: {
            backgroundColor: theme.palette.background.default,
            boxShadow: 'none',
            padding: 0,
          },
        }}
      >
        <SettingsCustomSchedule
          onClose={onClose}
        />
      </Dialog>
    </div>
  )
}

export default CustomScheduleField
