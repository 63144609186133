import { getTranslationKeyByErrorMessage, PROMOCODE_SUCCESS } from 'shared-module/api/errorMessagesHelper'
import dialogActions from 'shared-module/dialog/dialogActions'
import cartApi from 'spypoint/src/checkout-module/order/core/cart.api'

export const SET_CART = 'SET_CART'
export const CLEAR_ERROR = 'CLEAR_ERROR'
export const SET_ERROR = 'SET_ERROR'

const set = cart => ({ type: SET_CART, payload: cart })

const fetchCart = () => async (dispatch) => {
  const cart = await cartApi.getCartItems()
  dispatch(set(cart))
}

const fetchCheckout = () => async (dispatch) => {
  const cart = await cartApi.getCheckoutItems()
  dispatch(set(cart))
}
const addToCart = ({
  productId,
  cameraId,
  paymentFrequency,
  plan,
}) => async (dispatch) => {
  try {
    const cart = await cartApi.addCartItem({
      productId,
      cameraId,
      paymentFrequency,
      plan,
    })
    dispatch(set(cart))
  } catch (error) {
    const message =
      getTranslationKeyByErrorMessage(error.response.data.message, productId)
    dispatch(dialogActions.showError(message))
  }
}

const deleteFromCart = ({ itemId }) => async (dispatch) => {
  try {
    const cart = await cartApi.removeCartItem(itemId)
    dispatch(set(cart))
  } catch (error) {
    dispatch(dialogActions.showError(error.response.data.message))
  }
}

const updateQuantity = ({ itemId, quantity }) => async (dispatch) => {
  try {
    const cart = await cartApi.updateQuantity({ itemId, quantity })
    dispatch(set(cart))
  } catch (error) {
    dispatch(dialogActions.showError(error.response.data.message))
  }
}

const updatePaymentFrequency = ({ itemId, paymentFrequency }) =>
  async (dispatch) => {
    try {
      const cart = await cartApi.updatePaymentFrequency({
        itemId,
        paymentFrequency,
      })
      dispatch(set(cart))
    } catch (error) {
      dispatch(dialogActions.showError(error.response.data.message))
    }
  }

const applyPromoCode = (promocode) => async (dispatch) => {
  try {
    const cart = await cartApi.applyPromoCode(promocode)
    dispatch({
      type: SET_CART,
      message: PROMOCODE_SUCCESS,
      payload: cart,
    })
  } catch (error) {
    dispatch({
      type: SET_ERROR,
      message: error.response.data.message,
    })
  }
}

const cartActions = {
  addToCart,
  deleteFromCart,
  fetchCart,
  updateQuantity,
  updatePaymentFrequency,
  fetchCheckout,
  applyPromoCode,
}
export default cartActions
