import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'

const EmptyCart = ({ isNewUi }) => {
  const useStyles = makeStyles(theme => ({
    icon: {
      width: 60,
      height: 60,
      color: theme.palette.background.paper,
    },
    wrapper: {
      padding: 30,
      marginTop: 20,
      background: theme.palette.primary.contrastText,
    },
  }))

  const { t } = useTranslation()
  const classes = useStyles()
  return (
    <Grid
      container
      item
      direction="column"
      justifyContent="center"
      alignItems="center"
      className={classes.wrapper}
      xs={12}
      sm={8}
      md={6}
      lg={isNewUi ? 6 : 4}
      xl={isNewUi ? 6 : 3}
    >
      <ShoppingCartIcon className={classes.icon} />
      <Typography variant="body2">
        { t('app:checkout_process.cart.is_empty') }
      </Typography>
    </Grid>
  )
}

export default EmptyCart
