const buildHdPackage = (apiFullHdPackage) => ({
  activated: buildActivated(apiFullHdPackage.photoLimit),
  remaining: buildRemaining(apiFullHdPackage.photoLimit, apiFullHdPackage.photoCount),
})

const buildActivated = limit => limit > 0

const buildRemaining = (limit, count) => {
  const remaining = limit - count
  return remaining >= 0 ? remaining : 0
}

export default buildHdPackage
