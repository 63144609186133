import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import { FullHdActive } from 'assets/icons/SpypointIcons'

const useStyles = makeStyles({
  videoIcon: {
    paddingRight: '3px',
    paddingTop: '1px',
    maxWidth: 23,
    maxHeight: 18,
  },
  videoButton: {
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    padding: '0px 7px 0px 5px',
    color: 'white',
    height: 25,
  },
  previewButton: {
    height: '25px',
  },
})

const SpypointVideoHdIcon = ({ style, inPreview, ...props }) => {
  const classes = useStyles()
  const FullHdIcon = props.icon || FullHdActive
  return (
    <div style={style} className={!inPreview ? classes.videoButton : classes.previewButton}>
      <FullHdIcon className={!inPreview && classes.videoIcon} />
      <Typography component="h4" variant="h4">
        { props.children }
      </Typography>
    </div>
  )
}

export default SpypointVideoHdIcon
