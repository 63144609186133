import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import MuiTooltip from '@material-ui/core/Tooltip'
import InfoRoundedIcon from '@material-ui/icons/InfoRounded'
import './SchedulePage.css'
import { makeStyles } from '@material-ui/core/styles'
import { Trans, useTranslation } from 'react-i18next'
import CameraModeListItem from './CameraModesListItem'
import { useTheme } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  infoIcon: {
    color: theme.palette.background.filter,
    fontSize: '1em',
    display: 'inline-block',
    marginLeft: '.5rem',
  },
}))

const Tooltip = withStyles(theme => ({
  tooltip: {
    whiteSpace: 'pre-line',
    maxWidth: 'none',
    backgroundColor: theme.palette.background.secondary,
    boxShadow: theme.shadows[1],
    fontSize: '1em',
  },
}))(MuiTooltip)

const CameraModesList = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <List>
      <Typography
        variant="body2"
        style={{
          fontSize: 13,
          paddingLeft: 16,
          paddingTop: 16,
          fontWeight: 600,
          color: theme.palette.secondary.main,
        }}
      >
        { t('app:schedule.camera_modes') }
        <Tooltip
          title={(
            <Trans i18nKey="app:schedule.help">
              <strong>Standard mode</strong>: The camera is active and syncs with the app on the configured sync frequency.\n
              <strong> Instant mode</strong>: The camera is active and responds to commands instantly.
              <strong>Sleep mode</strong>: The camera is inactive during this period."
            </Trans>
          )}
          arrow
          className={classes.infoIcon}
        >
          <InfoRoundedIcon />
        </Tooltip>
      </Typography>
      <CameraModeListItem text={t('app:schedule.standard')} fill="none" border={`1px solid ${theme.palette.background.tile}`} />
      <CameraModeListItem text={t('app:schedule.instant')} color="primary" />
      <CameraModeListItem text={t('app:schedule.sleep')} color="secondary" />
    </List>
  )
}

export default CameraModesList
