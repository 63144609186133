import EXIF from 'exif-js'
import { isPhotoFormatValid } from 'photo-module/photos/ui/photos-gallery/upload-photo/isPhotoFormatValid'
import { CameraApiHelper } from 'photo-module/photo/api/CameraApi.helper'

export class SpypointPhotoHelper {
  cameraModel

  constructor (cameraModel) {
    this.cameraModel = cameraModel
  }

  /**
   * Get uniq photo id
   * @param {Object} formattedPhoto
   * @param {string} formattedPhoto.name - Photo name
   * @param {string} formattedPhoto.model - Camera model
   * @param {number} formattedPhoto.size - Photo size
   * @param {string} formattedPhoto.originalDateTime - Photo date from EXIF
   * @returns {string} Uniq photo id
   */
  static getPhotoUniqId ({ name, model, size, originalDateTime }) {
    const date = originalDateTime.replace(' ', '_').replace(/:/g, '')
    const formattedName = name.replace('.', '_')

    return `${formattedName}/${model}/${size}/${date}`
  }

  /**
   * Get the formatted photo
   * @param {File} file
   * @return {Object} The formatted photo object
   */
  getFormattedPhoto (file) {
    const formattedPhoto = {
      name: file.name,
      size: file.size,
      type: file.type,
      make: 'Spypoint',
      model: this.cameraModel,
      originalDateTime: file.exifdata.DateTimeOriginal,
    }
    formattedPhoto.uniqId = SpypointPhotoHelper.getPhotoUniqId(formattedPhoto)

    return formattedPhoto
  }

  /**
   * Check the spypoint EXIF data
   * @param {string} make EXIF Make value
   * @param {string} model EXIF Model value
   * @return {boolean}
   */
  hasSpypointEXIF (make, model) {
    if (!make || !model) {
      return false
    }

    return make.toLowerCase().indexOf('spypoint') !== -1
  }

  /**
   * Check if the photo is a Spypoint photo by analyzing EXIF data
   * @param {File} file
   * @return {Promise} Promise with the valid file or null
   */
  isSpypointPhoto (file) {
    return new Promise((resolve) => {
      EXIF.getData(file, () => {
        const isSpypointValid = this.hasSpypointEXIF(EXIF.getTag(file, 'Make'), EXIF.getTag(file, 'Model'))
        if (isSpypointValid) {
          const formattedPhoto = this.getFormattedPhoto(file)
          // Check if the camera model is the same as the photo camera model (in EXIF data)
          if (this.isPhotoFromCameraModel(EXIF.getTag(file, 'Model'), this.cameraModel)) {
            resolve({ spypointData: formattedPhoto, originFile: file })
          } else {
            resolve(null)
          }
        } else {
          resolve(null)
        }
      })
    })
  }

  /**
   * The model stored in the photo exif can be:
   * - prefixed with anything
   * - suffixed with anything
   * - the model words can be separated by spaces or dashes. ex: force dark or force-dark or forcedark
   */
  isPhotoFromCameraModel (modelInPhoto, modelInCamera) {
    const cameraModelRegexp = modelInCamera.toLowerCase().replace('-', '[- ]{0,1}')
    return modelInPhoto.toLowerCase().match(new RegExp(cameraModelRegexp, 'i'))
  }

  /**
   * Sort the uploaded files to return only Spypoint photos
   * @param {FileList} filesList List of uploaded files
   * @return {Promise} Promise of photos array
   */
  getSpypointPhotos = async (filesList) => {
    const filesArray = Array.from(filesList)
    const validPhotos = filesArray.filter(file => isPhotoFormatValid(file))

    return Promise.all(validPhotos.map((file) => this.isSpypointPhoto(file)))
      .then(result => result.filter(file => file !== null))
  }

  /**
   * Upload photo to API and to S3
   * @param {File[]} photosList photos list from <input file>
   * @param {string} cameraId
   * @return {Promise<Object>} status message
   */
  async uploadPhotos (photosList, cameraId) {
    // Get Spypoint photos
    const spypointPhotos = await this.getSpypointPhotos(photosList)

    return new Promise((resolve, reject) => {
      // Send photo to the backend
      if (spypointPhotos.length > 0) {
        const originFiles = spypointPhotos.map(photo => photo.originFile)
        const spypointPhotosData = spypointPhotos.map(photo => photo.spypointData)

        // API: POST photo to get Amazon S3 URL and send it to Amazon
        CameraApiHelper.postUploadedPhotos(spypointPhotosData, cameraId).then(uploadedPhotos => {
          // Uploaded photo are valid photos that do not exist in database
          const photosListUploaded = uploadedPhotos.data
          const photosLength = photosListUploaded.length
          let currentPhotoIndex = 0

          // Process with S3
          if (photosLength > 0) {
            photosListUploaded.forEach(photoUploaded => {
              // Amazon S3 upload
              CameraApiHelper.putToAwsS3(photoUploaded, originFiles)
                .catch(() => {
                  reject(new Error('camera_upload.upload_error'))
                }).finally(() => {
                  currentPhotoIndex++
                  // Check if it is the last photo uploaded
                  if (photosLength === currentPhotoIndex) {
                    resolve({ fileMessage: 'camera_upload.upload_ended' })
                  }
                })
            })
          }
        }).catch(() => {
          reject(new Error('camera_upload.upload_error'))
        })
      } else {
        reject(new Error('camera_upload.invalid_photo'))
      }
    })
  }
}
