import { useDispatch, useSelector } from 'react-redux'
import activatedCameraActions from 'camera-module/new-activation/core/activatedCamera.actions'

export const useActivatedCamera = () => useSelector(state => state.activatedCamera)

const useActivatedCameraActions = () => {
  const dispatch = useDispatch()

  return {
    setActivatedCamera: camera => dispatch(activatedCameraActions.setActivatedCamera(camera)),
  }
}

export default useActivatedCameraActions
