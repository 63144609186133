import Box from '@material-ui/core/Box'
import { Link } from '@material-ui/core'
import { Trans } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  offlineBanner: {
    background: theme.palette.background.secondary,
    position: 'absolute',
    height: 'auto',
    width: 200,
    zIndex: 21,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontWeight: 600,
  },
  offlineBannerLink: {
    color: theme.palette.text.primary,
    textDecoration: 'underline',
    '&:hover': {
      color: theme.palette.text.primary,
      opacity: 0.7,
      cursor: 'pointer',
    },
  },
}))

const HspaOfflineBanner = ({ openDialog, statusBarOffset }) => {
  const classes = useStyles()

  return (
    <Box
      className={classes.offlineBanner}
      data-testid="hspa-offline-banner"
      sx={{ top: statusBarOffset ? 130 : 160 }}
    >
      <Box>
        <Trans i18nKey="hspa.banner.offline.content" />
      </Box>
      <Link className={classes.offlineBannerLink} onClick={openDialog}>
        <Trans i18nKey="hspa.banner.offline.link" />
      </Link>
    </Box>
  )
}

export default HspaOfflineBanner
