import { deleteFavoritePhotos, deleteRemotePhotos, resetPhotos, setFavoritesPhotos } from 'photo-module/photos/core/photosActions'
import { useDispatch } from 'react-redux'
import useUserActions from 'user-module/user/core/useUserActions'
import { useState } from 'react'

const usePhotosActionsOld = (cameraId) => {
  const dispatch = useDispatch()
  const userActions = useUserActions()

  const [isLoading, setLoading] = useState(false)

  const deletePhotos = (ids) => dispatch(deleteRemotePhotos(cameraId, ids)).then(() => userActions.updateFavoritesCount())

  const setFavorite = (ids) => {
    setLoading(true)
    dispatch(setFavoritesPhotos(cameraId, ids)).then(() => userActions.updateFavoritesCount())
    setLoading(false)
  }
  const deleteFavorite = (ids) => {
    setLoading(true)
    dispatch(deleteFavoritePhotos(cameraId, ids)).then(() => {
      userActions.updateFavoritesCount()
      dispatch(resetPhotos())
    })
    setLoading(false)
  }

  return { isLoading, deletePhotos, setFavorite, deleteFavorite }
}

export default usePhotosActionsOld
