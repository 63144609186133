import SpypointCameraStatusTile from '../SpypointCameraStatusTile'

const SpypointSolarBatteryTile = ({ value, disable }) => (
  <SpypointCameraStatusTile
    label="app:camera.battery2"
    icon="/images/status/sun.svg"
    value={`${value.percentage}%`}
    disable={disable}
  />
)

export default SpypointSolarBatteryTile
