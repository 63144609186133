import { INSIDERS_CLUB_MEMBERSHIP, PHOTO_TRANSMISSION_PLAN, PHOTOHD, VIDEOHD } from '../../core/productTypes'
import InsidersClubInCart from './InsidersClubInCart'
import HdPackageInCart from './HdPackageInCart'
import PhotoTransmissionInCart from './PhotoTransmissionInCart'

const ItemInCart = ({ product, isUnavailable, checkout, promocode }) => {
  const hasDiscount = (product) => {
    return product.discountAmount >= 0 && product.discountPercentage >= 0 && product.discountPrice >= 0
  }
  const renderCartItem = () => {
    switch (product.productId) {
      case PHOTO_TRANSMISSION_PLAN:
        return (
          <PhotoTransmissionInCart
            product={product}
            currency={product.currency}
            discount={hasDiscount(product)}
            checkout={checkout}
          />
        )
      case PHOTOHD:
      case VIDEOHD:
        return (
          <HdPackageInCart
            product={product}
            currency={product.currency}
            disabled={isUnavailable}
            discount={hasDiscount(product)}
            checkout={checkout}
            promocode={promocode}
          />
        )
      case INSIDERS_CLUB_MEMBERSHIP:
        return (
          <InsidersClubInCart
            product={product}
            currency={product.currency}
            discount={hasDiscount(product)}
            checkout={checkout}
          />
        )
      default:
        return null
    }
  }
  return renderCartItem()
}
export default ItemInCart
