import { useEffect, useState } from 'react'
import { Dialog } from '@material-ui/core'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import ScheduleForm from './ScheduleForm'

const ScheduleCreationForm = ({
  open,
  onClose,
  onAddPeriods,
  onDeletePeriod,
  selectedPeriod,
}) => {
  const { t } = useTranslation()
  const [startValue, setStartValue] = useState(-1)
  const InitialValues = {
    start_time: selectedPeriod ? selectedPeriod.startTime.hour : 0,
    end_time: selectedPeriod && selectedPeriod.endTime.minute !== 59 ? selectedPeriod.endTime.hour : 24,
    mode: selectedPeriod ? selectedPeriod.scheduleType : 'standard',
    days: selectedPeriod ? [selectedPeriod.weekDay - 1] : [0],
  }
  const validationSchema = Yup.object().shape({
    start_time: Yup.string().required(t('form.validation.default')),
    end_time: Yup.string().required(t('form.validation.default')),
    mode: Yup.string().required(t('form.validation.default')),
  })

  const getPeriods = (values) =>
    values.days.map((x) => {
      return {
        weekDay: x + 1,
        startTime: {
          hour: values.start_time,
          minute: 0,
        },
        endTime: {
          hour: values.end_time === 24 ? 23 : values.end_time,
          minute: values.end_time === 24 ? 59 : 0,
        },
      }
    })
  const onSubmit = (values) => {
    onAddPeriods(getPeriods(values), values.mode)
  }

  const formik = useFormik({
    initialValues: InitialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: onSubmit,
  })

  useEffect(() => {
    if (selectedPeriod) {
      setStartValue(selectedPeriod.startTime.hour)
    }
  }, [selectedPeriod])
  const onCancel = () => {
    onClose()
  }
  return (
    <Dialog maxWidth="xs" open={open} onCancel={onCancel} onClose={onCancel}>
      { open && <ScheduleForm onDeletePeriod={onDeletePeriod} selectedPeriod={selectedPeriod} startValue={startValue} setStartValue={setStartValue} formik={formik} /> }
    </Dialog>
  )
}

export default ScheduleCreationForm
