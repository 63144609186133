import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Paper from '@material-ui/core/Paper'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import PrimaryButton from 'shared-module/components/button/PrimaryButton'
import SecondaryButton from 'shared-module/components/button/SecondaryButton'
import CameraBlock from 'shared-module/components/CameraBlock'
import SubscriptionInfos from 'checkout-module/checkout/ui/transmission-plans-page/SubscriptionInfos'
import SubscriptionGraphs from 'checkout-module/checkout/ui/transmission-plans-page/SubscriptionGraphs'
import { formatDate } from 'shared-module/common-utils'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { useLoadUserDefaultPaymentInfo } from '../../../../user-module/payment-methods/core/userPaymentMethods.hooks'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '150px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '120px',
    },
  },
  paperWrapper: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
    justifyContent: 'center',
  },
  paper: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '20px 100px',
    [theme.breakpoints.down('sm')]: {
      padding: '20px 50px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '20px auto',
    },
    margin: '20px',
    flexGrow: '2',
    maxWidth: '1000px',
  },
}))

const Subscription = (props) => {
  const [disableAutoRenewDialogIsOpen, setDisableAutoRenewDialogIsOpen] = useState(false)
  const { t, i18n } = useTranslation()
  const classes = useStyles()
  const paymentInfo = useLoadUserDefaultPaymentInfo()
  const isAvailablePaymentMethod = paymentInfo?.isAvailable
  const warning = props.isMember ? 'app:billing.disable_auto_renew_warning_member' : 'app:billing.disable_auto_renew_warning'

  const openAutoRenewDialog = () => {
    setDisableAutoRenewDialogIsOpen(true)
  }

  const closeAutoRenewDialog = () => {
    setDisableAutoRenewDialogIsOpen(false)
  }

  const confirmDisableAutoRenew = () => {
    closeAutoRenewDialog()
    props.onDisableAutoRenew()
  }

  return (
    <div className={classes.root}>
      <div className={classes.paperWrapper}>
        <Paper className={classes.paper}>
          <Grid container direction="row" justifyContent="space-between" spacing={1}>
            <Grid item>
              <Grid container direction="column" justifyContent="flex-start">
                <CameraBlock />
                <SubscriptionGraphs subscription={props.subscription} />
              </Grid>
            </Grid>
            <Grid item>
              <SubscriptionInfos
                subscription={props.subscription}
                i18n={i18n}
                disableAutoRenew={!isAvailablePaymentMethod}
                onDisableAutoRenew={openAutoRenewDialog}
                onEnableAutoRenew={() => { props.onEnableAutoRenew() }}
                isMember={props.isMember}
              />
            </Grid>
          </Grid>
        </Paper>
      </div>
      <Dialog
        open={disableAutoRenewDialogIsOpen}
        onClose={closeAutoRenewDialog}
      >
        <DialogTitle>{ t('billing.disable_auto_renew.title') }</DialogTitle>
        <DialogContent>
          <DialogContentText>
            { t(warning, { date: formatDate(props.subscription.endDateBillingCycle.toString(), i18n) }) }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <SecondaryButton id="auto-renew-cancel-button" onClick={closeAutoRenewDialog} value={t('button.cancel')} />
          <PrimaryButton id="auto-renew-confirm-button" onClick={confirmDisableAutoRenew} value={t('billing.disable_auto_renew.action')} />
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default Subscription
