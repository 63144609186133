import restapi from 'shared-module/api/restapi'
import handleError from 'shared-module/api/apiError'

export const getSubscriptions = () => {
  return restapi.get('/api/v3/storage/subscription')
    .then(subscriptions => subscriptions.data)
    .catch(handleError)
}

export const disableAutoRenew = (subscriptionId) => {
  return restapi.delete(`/api/v3/storage/subscription/${subscriptionId}`)
    .catch(handleError)
}

export const enableAutoRenew = (subscriptionId) => {
  return restapi.post(`/api/v3/storage/subscription/${subscriptionId}`)
    .catch(handleError)
}

export const getPlans = () => {
  return restapi.get('/api/v3/storage/plan')
    .then(plans => plans.data)
    .catch(handleError)
}

export const getPlan = (id) => {
  return restapi.get(`/api/v3/storage/plan/${id}`)
    .then(plan => plan.data)
    .catch(handleError)
}

export const getBill = (planId) => {
  return restapi.get(`/api/v3/storage/plan/${planId}/bill`)
    .then(bill => bill.data)
    .catch(handleError)
}

export const postPayment = (tokenId, planId) => {
  const subscription = {
    customerToken: tokenId,
    storagePlanId: planId,
  }
  return restapi.post('/api/v3/storage/subscription', subscription)
    .catch(handleError)
}

export const upgradeStorage = (tokenId, planId, subscriptionId) => {
  const subscription = {
    customerToken: tokenId,
    subscriptionId: subscriptionId,
    storagePlanId: planId,
  }
  return restapi.put('/api/v3/storage/subscription', subscription)
    .catch(handleError)
}
