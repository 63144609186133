import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const ReplayIcon = (props) => {
  const theme = useTheme()

  return (
    <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M14.1159 1.88405L12.7136 3.28633C11.5073 2.0798 9.84091 1.33337 7.99995 1.33337C4.32626 1.33337 1.33057 4.33136 1.33325 8.00501C1.33594 11.6846 4.31968 14.6667 7.99992 14.6667C9.71836 14.6667 11.2849 14.0165 12.4671 12.9488C12.6045 12.8246 12.611 12.6111 12.48 12.4801L11.9494 11.9495C11.8285 11.8286 11.6335 11.8209 11.5059 11.9347C10.5748 12.7647 9.34683 13.2689 7.99992 13.2689C5.08806 13.2689 2.7311 10.9124 2.7311 8.00004C2.7311 5.08819 5.08761 2.73122 7.99992 2.73122C9.45591 2.73122 10.773 3.32055 11.7261 4.27386L10.271 5.72894C10.0678 5.93216 10.2117 6.27961 10.4991 6.27961H14.344C14.5221 6.27961 14.6666 6.13518 14.6666 5.95703V2.11216C14.6666 1.82477 14.3191 1.68085 14.1159 1.88405V1.88405Z"
        fill={theme.palette.text.primary}
      />
    </SvgIcon>
  )
}

export default ReplayIcon
