import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const FullHdShadowIcon = (props) => {
  const theme = useTheme()

  return (
    <SvgIcon {...props} data-testid="FullHdShadowIcon" height="32" width="32" viewBox="0 0 32 32">
      <g
        filter="url(#filter0_ddd)"
        fill={theme.palette.background.default}
      >
        <path d="M19.725 16.336v7.92h1.547c.873 0 1.397-.431 1.397-1.629v-4.662c0-1.198-.524-1.629-1.397-1.629h-1.547z" />
        <path
          d="M25.432 3H6.71C5.214 3 4 4.201 4 5.683v20.634C4 27.799 5.214 29 6.71 29h18.722c1.497 0 2.71-1.201 2.71-2.683V5.683C28.143 4.201 26.93 3 25.433 3zM6.67 12.21h1.56V9.877h1.71V8.311H8.23V6.874h2.022V5.348H6.67v6.863zm16.78-1.525V5.348h-1.56v6.863h3.351v-1.526h-1.79zM9.347 26.652H6.674V13.94h2.673v5.158h3.038V13.94h2.721v12.712h-2.721v-5.158H9.347v5.158zm7.635-12.712h4.34c2.745 0 4.092 1.462 4.092 4.145v4.422c0 2.684-1.347 4.145-4.092 4.145h-4.34V13.94zm1.69-8.592h-1.56v6.863h3.351v-1.526h-1.79V5.348zm-4.463 0h1.56V10.3c0 1.255-.832 2.092-2.138 2.092s-2.138-.837-2.138-2.091V5.347h1.56V10.3c0 .34.208.566.578.566s.578-.226.578-.566V5.348z"
          clipRule="evenodd"
          fillRule="evenodd"
        />
      </g>
      <defs id="defs43">
        <filter colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse" height="30" width="28.143" y="2" x="2" id="filter0_ddd">
          <feFlood id="feFlood8" result="BackgroundImageFix" floodOpacity="0" />
          <feColorMatrix id="feColorMatrix10" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" type="matrix" in="SourceAlpha" />
          <feOffset id="feOffset12" dy="1" />
          <feGaussianBlur id="feGaussianBlur14" stdDeviation="1" />
          <feColorMatrix id="feColorMatrix16" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" type="matrix" />
          <feBlend id="feBlend18" result="effect1_dropShadow" in2="BackgroundImageFix" mode="normal" />
          <feColorMatrix id="feColorMatrix20" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" type="matrix" in="SourceAlpha" />
          <feOffset id="feOffset22" dy="1" />
          <feGaussianBlur id="feGaussianBlur24" stdDeviation=".5" />
          <feColorMatrix id="feColorMatrix26" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0" type="matrix" />
          <feBlend id="feBlend28" result="effect2_dropShadow" in2="effect1_dropShadow" mode="normal" />
          <feColorMatrix id="feColorMatrix30" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" type="matrix" in="SourceAlpha" />
          <feOffset id="feOffset32" dy=".75" />
          <feGaussianBlur id="feGaussianBlur34" stdDeviation=".5" />
          <feColorMatrix id="feColorMatrix36" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" type="matrix" />
          <feBlend id="feBlend38" result="effect3_dropShadow" in2="effect2_dropShadow" mode="normal" />
          <feBlend id="feBlend40" result="shape" in2="effect3_dropShadow" in="SourceGraphic" mode="normal" />
        </filter>
      </defs>
    </SvgIcon>
  )
}

export default FullHdShadowIcon
