const formatFormData = (formData) => {
  const { name, email, cameraIds } = formData

  const cameras = cameraIds.map(cameraId => ({
    cameraId,
    permissions: ['photo:read', 'geolocation:read'],
  }))

  return {
    guestEmail: email,
    guestUsername: name,
    cameras,
  }
}

export const formatCameraError = {
  ALREADY_SHARED: 'This camera is already shared with this user',
  GUEST_USERNAME: 'User name already exists',
  get: function (key) {
    return this[key] ? this[key] : 'An error occurred'
  },
}
export default formatFormData
