import { Box, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginLeft: 32,
  },
}))

const ProfileNote = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Box className={classes.wrapper}>
      <Typography variant="caption">{ `*${t('app:profile.note')}` }</Typography>
    </Box>
  )
}

export default ProfileNote
