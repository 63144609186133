import SvgIcon from '@material-ui/core/SvgIcon'
import mainBatterySignal from 'camera-module/camera/core/mainBatterySignal'

const MainBatteryIcon = ({ signal, props }) => {
  const iconKey = {
    [mainBatterySignal.null]: undefined,
    [mainBatterySignal.low]: <path d="M12.21 28.07H3.798V21.8h8.414zm0 0" />,
    [mainBatterySignal.medium]: <path d="M12.21 28.07H3.798V21.8h8.414zm0 0M12.21 20.453H3.798v-6.336h8.414zm0 0" />,
    [mainBatterySignal.high]: <path d="M12.21 28.07H3.798V21.8h8.414zm0 0M12.21 20.453H3.798v-6.336h8.414zm0 0M12.21 12.672H3.798V6.418h8.414zm0 0" />,
  }

  return (
    <SvgIcon width="36" height="32" viewBox="0 0 32 36" {...props} data-testid="MainBatteryIcon">
      <path
        d="M2.656 29.648c-.11 0-.152-.05-.152-.168V5.04c0-.067.09-.185.152-.185h10.696c.058 0 .152.118.152.184V29.48c0 .118-.047.168-.152.168zm12.004 1.278c.367-.39.555-.871.555-1.446V5.04a2.02 2.02 0 00-.555-1.427c-.37-.406-.8-.605-1.308-.605h-2.528V1.426c0-.52-.37-.942-.847-.942H5.969c-.477 0-.848.422-.848.942v1.582H2.656c-.508 0-.941.199-1.308.605A2.027 2.027 0 00.793 5.04V29.48c0 .575.18 1.063.539 1.446.363.39.816.59 1.324.59h10.696c.507 0 .949-.207 1.308-.59zm0 0"
      />
      { iconKey[signal] }
    </SvgIcon>
  )
}

export default MainBatteryIcon
