import { useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import useDirectPurchaseLogic from './useDirectPurchaseLogic'

const DirectPurchaseWrapper = ({ children, isNewUi }) => {
  const useStyles = makeStyles({
    root: {
      marginTop: isNewUi ? '0px' : '150px',
      overflow: 'hidden',
      marginBottom: 10,
    },
  })
  const classes = useStyles()
  const { directPurchase, isCartItemsFetched } = useDirectPurchaseLogic()

  useEffect(() => {
    isCartItemsFetched && directPurchase()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCartItemsFetched])

  return (
    <Grid
      className={classes.root}
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      { children }
    </Grid>
  )
}

export default DirectPurchaseWrapper
