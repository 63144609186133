import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  root: {
    margin: '20px',
    width: '200px',
    textAlign: 'center',
  },
  disable: {
    opacity: 0.5,
  },
  icon: {
    width: '35px',
    filter: 'invert(100%) sepia(0%) saturate(13%) hue-rotate(235deg) brightness(104%) contrast(104%)',
  },
  value: {
    fontSize: '11pt',
    fontWeight: 'bold',
  },
  label: {
    fontSize: '10pt',
    color: theme.palette.text.primary,
  },
}))

const SpypointCameraStatusTile = ({ label, icon, value, useSVGIcon = false, disable = false }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <div className={classes.root} data-testid="tile">
      { useSVGIcon
        ? (
          <div className={clsx(disable && classes.disable)} style={{ paddingLeft: '3px' }}>
            <svg width="32pt" height="26pt" viewBox="0 0 20 32"> { icon } </svg>
          </div>
          )
        : <div className={clsx(disable && classes.disable)}><img src={icon} className={classes.icon} alt="icon" /></div> }
      <div className={clsx(classes.value, disable && classes.disable)}>
        { !disable ? value : t('hspa.n_a') }
      </div>
      <div className={classes.label}>
        { t(label) }
      </div>
    </div>
  )
}

export default SpypointCameraStatusTile
