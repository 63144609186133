import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const VersionIcon = ({ props }) => {
  const theme = useTheme()
  return (
    <SvgIcon width="32" height="32" viewBox="0 0 32 32" {...props} data-testid="VersionIcon">
      <path d="M29.7539 27.5L17.0938 3.68359C16.9844 3.48307 16.8294 3.31901 16.6289 3.19141C16.4284 3.0638 16.2096 3 15.9727 3C15.7357 3 15.5169 3.0638 15.3164 3.19141C15.1159 3.31901 14.9609 3.48307 14.8516 3.68359L2.16406 27.5273C2.05469 27.7279 2 27.9466 2 28.1836C2.01823 28.4023 2.08203 28.6029 2.19141 28.7852C2.30078 28.9857 2.44661 29.1406 2.62891 29.25C2.82943 29.3594 3.04818 29.4141 3.28516 29.4141H28.6602C29.0065 29.4141 29.2982 29.2865 29.5352 29.0312C29.7904 28.776 29.918 28.4753 29.918 28.1289C29.918 28.0195 29.8997 27.9102 29.8633 27.8008C29.8451 27.6914 29.8086 27.5911 29.7539 27.5ZM17.668 27.5H14.1406V24.6016H17.668V27.5ZM17.6953 22.7969H14.1406L13.6211 11.1758H18.2148L17.6953 22.7969Z" fill={theme.palette.background.logo} />
    </SvgIcon>
  )
}

export default VersionIcon
