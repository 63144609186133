import usePaymentMethods from 'spypoint/src/user-module/payment-methods/core/usePaymentMethods'

const useDefaultPaymentMethod = () => {
  const paymentMethods = usePaymentMethods()

  if (!paymentMethods) return null

  const defaultPaymentMethod = paymentMethods.find(item => item.isDefault)
  if (!defaultPaymentMethod) return { hasDefault: false }

  return {
    hasDefault: true,
    card: {
      id: defaultPaymentMethod.id,
      brand: defaultPaymentMethod.card.brand,
      last4: defaultPaymentMethod.card.last4,
      exp_month: defaultPaymentMethod.card.exp_month,
      exp_year: defaultPaymentMethod.card.exp_year,
    },
  }
}

export default useDefaultPaymentMethod
