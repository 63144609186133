import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const PendingIcon = (props) => {
  const theme = useTheme()

  return (
    <SvgIcon width="22" height="22" fill="none" viewBox="0 0 22 22" {...props} data-testid="PendingIcon">
      <path
        d="M5.01288 5.20958C6.1575 4.06052 7.61755 3.27724 9.20791 2.95906C10.7983 2.64087 12.4473 2.80212 13.9459 3.42236C15.4445 4.04259 16.7252 5.09387 17.6255 6.4429C18.5258 7.79192 19.0053 9.37792 19.0031 10.9998H21.0531C21.0558 8.97239 20.4564 6.98985 19.3309 5.30353C18.2055 3.6172 16.6046 2.30305 14.7313 1.5277C12.858 0.752339 10.7967 0.550699 8.80868 0.948341C6.82066 1.34598 4.99548 2.325 3.56456 3.76125L1.0656 1.26201V7.41201H7.2156L5.01288 5.20958ZM16.5943 16.79C15.4496 17.9389 13.9895 18.7221 12.3991 19.0402C10.8088 19.3583 9.15974 19.197 7.66114 18.5768C6.16253 17.9566 4.88182 16.9054 3.98134 15.5565C3.08087 14.2076 2.60119 12.6217 2.60311 10.9998H0.55311C0.550412 13.0273 1.14987 15.01 2.27546 16.6964C3.40106 18.3828 5.00209 19.6969 6.87555 20.4722C8.749 21.2474 10.8105 21.4489 12.7986 21.051C14.7867 20.6532 16.6119 19.6739 18.0427 18.2373L20.5406 20.737V14.587H14.3906L16.5943 16.79Z"
        fill={theme.palette.primary.pending}
      />

    </SvgIcon>
  )
}

export default PendingIcon
