import { Trans } from 'react-i18next'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    lineHeight: '135%',
    fontSize: 14,
    textAlign: 'justify',
    textJustify: 'inter-word',
    color: theme.palette.error.main,
    marginTop: 20,
  },
}))
const UnavailableItemNoteLine = () => {
  const classes = useStyles()

  return (
    <Grid item data-testid="unavailable_item_note" xs={12}>
      <Typography className={classes.root}>
        <Trans i18nKey="app:checkout_process.unavailable_item_note">
          <strong> Note: </strong> This item is only available for purchase online. Please visit the website or remove this item from the cart to continue.
        </Trans>
      </Typography>
    </Grid>
  )
}

export default UnavailableItemNoteLine
