import appConfig from 'shared-module/config/appConfig'
import switches from 'shared-module/config/switches'

const eCommerceEnabled = appConfig?.eCommerce?.switch === switches.on

export const getPlanPeriodInitialState = (subscription) => {
  if (subscription.plan?.name === 'Free') {
    return eCommerceEnabled
  } else {
    return subscription.paymentFrequency === 'month_by_month'
  }
}
