import { useDispatch } from 'react-redux'
import clubMembershipPlanState from 'spypoint/src/user-module/club-membership-plan/core/clubMembershipPlanState'

const useClubMembershipPlanActions = () => {
  const dispatch = useDispatch()

  return {
    get: () => dispatch(clubMembershipPlanState.get()),
  }
}

export default useClubMembershipPlanActions
