import PrimaryButton from 'shared-module/new-components/buttons/PrimaryButton'
import { Box, Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import ProfileNote from 'spypoint/src/user-module/account/profile/ProfileNote'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginTop: 24,
    marginBottom: 32,
  },
}))

const ProfileSave = ({ form }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Box className={classes.wrapper}>
      <Grid container spacing={3} justifyContent="space-between" alignItems="flex-start">
        <Grid xs={12} sm={6} item>
          <ProfileNote />
        </Grid>
        <Grid xs={12} sm={3} item>
          <PrimaryButton fullWidth type="submit" form={form}>{ t('profile.save') }</PrimaryButton>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ProfileSave
