import SvgIcon from '@material-ui/core/SvgIcon'

const SquareIcon = (props) => (
  <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" data-testid="SquareIcon">
    <rect height="15" transform="translate(0.75 0.377808)" />
    <rect x="1.25" y="0.877808" width="14" height="14" />
  </SvgIcon>
)

export default SquareIcon
