import SvgIcon from '@material-ui/core/SvgIcon'

const BigCheckIcon = (props) => (
  <SvgIcon {...props} data-testid="BigCheckIcon">
    <path
      d="M1.5 14.309l5.723-3.325s.109.133.328.391a15.933 15.933 0 011.68 2.934c.316.695.574 1.476.78 2.336 1.177-2.844 2.384-5.196 3.63-7.051 1.257-1.86 2.398-3.332 3.425-4.41 1.024-1.094 1.868-1.86 2.524-2.297.656-.426.984-.637.984-.637l1.598 7.176-.8.289c-.548.207-1.325.726-2.337 1.558-1.012.832-2.258 2.09-3.734 3.774-1.477 1.695-3.153 4.031-5.024 7.015-.601-1.042-1.402-2.03-2.398-2.976-.984-.93-1.95-1.75-2.89-2.461a32.12 32.12 0 00-2.462-1.703c-.683-.406-1.027-.613-1.027-.613zm0 0"
    />
  </SvgIcon>
)

export default BigCheckIcon
