import TextField from '@material-ui/core/TextField'
import { useTranslation } from 'react-i18next'

const CameraName = ({ name, setName, isNameValid, setIsNameValid }) => {
  const { t } = useTranslation()

  const validateName = e => {
    const namePattern = /^.{0,30}$/
    const name = e.target.value
    const isNameValid = (name !== '' && namePattern.test(name))

    setIsNameValid(isNameValid)
    setName(name)
  }

  return (
    <TextField
      id="InputName"
      name="name"
      label={t('add_camera.name')}
      value={name}
      onChange={validateName}
      margin="dense"
      fullWidth
      autoComplete="nope"
      variant="outlined"
      required
      error={!isNameValid}
      inputProps={{ maxLength: 30 }}
    />
  )
}

export default CameraName
