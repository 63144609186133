const webViewNavigate = (history, to) => {
  const message = JSON.stringify({ action: 'navigate', to: to })
  // Android is the name to identify the Webview in the Android app
  // https://developer.android.com/guide/webapps/webview
  // webView.addJavascriptInterface(WebAppInterface(this), "Android")
  // show() is the function defined in the Android App
  if (typeof Android !== 'undefined') {
    // eslint-disable-next-line no-undef
    Android.postMessage(message)
    return
  }

  // iOS is the name to identify the Webview in the iOS app
  // userContentController.add(self, name: "iOS")
  if (typeof window.webkit?.messageHandlers?.iOS !== 'undefined') {
    window.webkit.messageHandlers.iOS.postMessage(message)
    return
  }

  return history.push({ pathname: '/' + to })
}

export default webViewNavigate
