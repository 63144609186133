
import { useTranslation } from 'react-i18next'
import Amount from 'shared-module/components/amount/Amount'
import DiscountLine from './DiscountLine'

const ItemProrateLine = ({ value, currency }) => {
  const { t } = useTranslation()

  return (
    <DiscountLine label={t('app:checkout.upgrade_credit_text')}>
      <Amount
        value={value}
        currencyCode={currency}
      />
    </DiscountLine>
  )
}

export default ItemProrateLine
