import useTheme from '@material-ui/core/styles/useTheme'

const IconMore = ({ width, height }) => {
  const theme = useTheme()

  const styleCircle = {
    fill: theme.palette.text.primary,
    stroke: theme.palette.background.default,
    strokeWidth: 2,
  }

  return (
    <svg id="icon-more" width={width} height={height} viewBox="0 0 12 42">
      <circle id="topCircle" r="5" cx="6" cy="6" style={styleCircle} />
      <circle id="middleCircle" r="5" cx="6" cy="21" style={styleCircle} />
      <circle id="bottomCircle" r="5" cx="6" cy="36" style={styleCircle} />
    </svg>
  )
}

export default IconMore
