import { useHistory } from 'react-router-dom'
import { isExternalWebApp } from 'shared-module/webview/isExternalWebApp'
import webViewNavigate from 'checkout-module/checkout/ui/shared/web-view-bridge/webViewNavigate'

const useOrderNav = (MobileMessage, url) => {
  const history = useHistory()
  const isExternal = isExternalWebApp()
  const handleBuyMore = () => {
    if (isExternal) {
      webViewNavigate(history, MobileMessage)
    } else {
      history.push(url)
    }
  }

  return { handleBuyMore }
}

export default useOrderNav
