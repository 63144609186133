import React, { ReactElement, useEffect, useState } from 'react'

import { useTheme } from '@material-ui/core'
import Checkbox from '@mui/material/Checkbox'
import { CheckCircle, RadioButtonUnchecked } from '@material-ui/icons'

export interface ICCheckbox {
  checked: boolean,
  icon?: ReactElement
  checkedIcon?: ReactElement
  roundedCheckbox?: boolean,
  disabled?: boolean
  disableRipple?: boolean
  onChange?: () => void
  disabledSx?: boolean
}

const CCheckbox = (props: ICCheckbox) => {
  const theme: any = useTheme()
  const [isChecked, setIsChecked] = useState(props.checked)

  const sxCheckbox = {
    color: '#8D8E8E',
    '&.Mui-checked': {
      color: `${theme.palette.primary.main} !important`,
    },
    '&.Mui-disabled': {
      color: 'rgba(255, 255, 255, 0.12) !important',
    },
  }

  useEffect(() => {
    setIsChecked(props.checked)
  }, [props.checked])

  const toggleCheckedState = () => setIsChecked(!isChecked)

  return props.roundedCheckbox
    ? (
      <Checkbox
        checked={isChecked}
        disabled={props.disabled}
        onChange={props.onChange}
        icon={<RadioButtonUnchecked style={{ color: theme.palette.background.light }} />}
        checkedIcon={<CheckCircle color="primary" />}
        disableRipple={props.disableRipple}
      />
      )
    : (
      <Checkbox
        icon={props.icon}
        checkedIcon={props.checkedIcon}
        checked={isChecked}
        onChange={props.onChange}
        disabled={props.disabled}
        sx={!props.disabledSx ? sxCheckbox : undefined}
        disableRipple={props.disableRipple}
      />
      )
}

export default CCheckbox
