import SpypointCameraStatusTile from '../SpypointCameraStatusTile'

const SpypointBatteryTypeTile = ({ value, disable }) => (
  <SpypointCameraStatusTile
    label="camera.battery_type"
    icon="/images/status/battery.svg"
    value={value}
    disable={disable}
  />
)

export default SpypointBatteryTypeTile
