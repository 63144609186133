import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  activationHelpWrapper: {
    display: 'flex',
    height: '380px',
    width: '261px',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    textAlign: 'center',
    flexDirection: 'column',
    marginTop: '1rem',
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
    },
  },
  grey: {
    background: theme.palette.text.primary,
  },
  helpImage: {
    width: '234.83px',
    minHeight: '315px',
  },
  paragraph: {
    padding: '0 22px',
    color: theme.palette.background.default,
  },
}))

const ActivationHelpLayout = ({ children, grey }) => {
  const classes = useStyles()

  return <div className={`${classes.activationHelpWrapper} ${grey ? classes.grey : null}`}>{ children }</div>
}

const ActivationHelp = ({ helpImage }) => {
  const classes = useStyles()
  return (
    <ActivationHelpLayout>
      <img src={helpImage} alt="" className={classes.helpImage} />
    </ActivationHelpLayout>
  )
}

export default ActivationHelp
