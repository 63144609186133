import FiltersBar from 'photo-module/photos/ui/photos-gallery/filter-bar/FiltersBar'
import SpypointNewFiltersBar from './SpypointNewFiltersBar'
import appConfig from 'shared-module/config/appConfig'
import switches from 'shared-module/config/switches'

const SpypointFiltersBar = () => {
  const flexFeatureDisabled = appConfig.flex.switch === switches.off
  if (flexFeatureDisabled) {
    return <FiltersBar />
  } else {
    return <SpypointNewFiltersBar />
  }
}

export default SpypointFiltersBar
