import { Box, Typography } from '@material-ui/core'
import { Trans } from 'react-i18next'

const PaymentHistoryTitle = () => {
  return (
    <Box
      color="text.tertiary"
      paddingLeft={{
        xs: 0,
        sm: 3,
      }}
      marginBottom={2}
    >
      <Typography variant="h3">
        <Trans i18nKey="app:account.payment.history.title" />
      </Typography>
    </Box>
  )
}

export default PaymentHistoryTitle
