import { Box, Dialog, DialogContent, DialogContentText, makeStyles, Typography, useTheme } from '@material-ui/core'
import WarningIcon from 'assets/icons/navigation/WarningIcon'
import PrimaryButton from 'shared-module/new-components/buttons/PrimaryButton'
import SecondaryButton from 'shared-module/new-components/buttons/SecondaryButton'
import { Trans, useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  badge: {
    display: 'inline-flex',
    width: 26,
    minWidth: 26,
    height: 26,
    marginRight: 13,
    marginTop: 4,
    borderRadius: '100%',
    fontSize: 10,
    color: theme.palette.common.black,
    backgroundColor: theme.palette.background.secondary,
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

const CameraSimActivationMigrationError = ({ displayError = false, onClose, onProceed }) => {
  const theme = useTheme()
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Dialog
      open={displayError}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
    >
      <Box py="16px" px="24px" display="flex" alignItems="center">
        <WarningIcon htmlColor={theme.palette.primary.pending} />
        <Typography variant="h1" style={{ marginLeft: 10 }}>{ t('app:camera.error.sim_activation_migration_dialog.title') }</Typography>
      </Box>

      <DialogContent>
        <DialogContentText variant="body1" style={{ fontWeight: 700 }}>{ t('app:camera.error.sim_activation_migration_dialog.summary') }</DialogContentText>

        <DialogContentText variant="caption" style={{ lineHeight: 1.1 }}>
          <Trans i18nKey="app:camera.error.sim_activation_migration_dialog.note">
            <strong>Note</strong> that this device can't be activated until the manual upgrade is successfully completed. Please perform the activation process again after the manual upgrade is complete.
          </Trans>
        </DialogContentText>

        <DialogContentText variant="body2">{ t('app:camera.error.sim_activation_migration_dialog.text') }</DialogContentText>

        <Box mb={2} display="flex">
          <span className={classes.badge}>1</span>
          <Typography variant="body2" color="textSecondary">{ t('app:camera.error.sim_activation_migration_dialog.step1') }</Typography>
        </Box>

        <Box mb={2} display="flex">
          <span className={classes.badge}>2</span>
          <Typography variant="body2" color="textSecondary">{ t('app:camera.error.sim_activation_migration_dialog.step2') }</Typography>
        </Box>

        <Box mb={2} display="flex">
          <span className={classes.badge}>3</span>
          <Typography variant="body2" color="textSecondary">{ t('app:camera.error.sim_activation_migration_dialog.step3') }</Typography>
        </Box>

        <Box display="flex" justifyContent="flex-end" mt={3.5}>
          <SecondaryButton onClick={onClose}>{ t('app:camera.error.sim_activation_migration_dialog.close') }</SecondaryButton>
          <PrimaryButton style={{ marginLeft: '25px' }} onClick={onProceed}>{ t('app:camera.error.sim_activation_migration_dialog.proceed') }</PrimaryButton>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default CameraSimActivationMigrationError
