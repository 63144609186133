import MainBatteryIcon from 'assets/icons/navigation/MainBatteryIcon'
import SpypointCameraStatusTile from '../SpypointCameraStatusTile'
import mainBatterySignal from 'camera-module/camera/core/mainBatterySignal'

const SpypointMainBatteryTile = ({ value, disable }) => {
  const iconKeyByBatteryPercentage = (val) => {
    if (val < 25) return mainBatterySignal.null
    if (val < 50) return mainBatterySignal.low
    if (val < 75) return mainBatterySignal.medium
    return mainBatterySignal.high
  }

  return (
    <SpypointCameraStatusTile
      label="app:camera.battery1"
      icon={<MainBatteryIcon signal={iconKeyByBatteryPercentage(value.percentage)} />}
      value={`${value.percentage}%`}
      useSVGIcon
      disable={disable}
    />
  )
}

export default SpypointMainBatteryTile
