import { makeStyles, TableRow, Table, TableCell, TableHead, TableBody, TableContainer } from '@material-ui/core'
import './SchedulePage.css'
import Paper from '@material-ui/core/Paper'
import { useTranslation } from 'react-i18next'
import useCameraSchedule, { SCHEDULE_TYPES } from '../../../../core/useCameraSchedule'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles((theme) => ({
  getHoursArray: {
    marginTop: 24,
    paddingRight: 5,
    backgroundColor: theme.palette.background.default,
  },
  instant: { backgroundColor: theme.palette.primary.dark },
  standard: { backgroundColor: theme.palette.background.default },
  sleep: { backgroundColor: theme.palette.secondary.main },
  tableHeader: {
    maxHeight: 33,
  },
  timeTitle: {
    textAlign: 'end',
    minHeight: 33,
    minWidth: 40,
  },
}))

const days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']
const ScheduleGrid = ({ formattedSchedule, onSelectPeriod }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { getHoursArray } = useCameraSchedule()
  const renderTimeColumn = () => {
    return (
      <Grid item xs={2} className={classes.getHoursArray}>
        { getHoursArray().map((x, i) => {
          const key = `sch_grid_cell_${i}`
          return (
            <div key={key} align="center">
              <div className={classes.timeTitle}>{ i % 3 === 0 ? x : '-' } </div>
            </div>
          )
        }) }
      </Grid>
    )
  }

  const onCellClick = (clickedHour) => {
    if (clickedHour.scheduleType === SCHEDULE_TYPES.sleep) return
    const weekDay = clickedHour.weekDay + 1
    const time = clickedHour.time
    onSelectPeriod(weekDay, clickedHour.scheduleType, time)
  }

  return (
    <Grid container className="custom-schedule">
      { renderTimeColumn() }
      <Grid item xs={8}>
        <TableContainer style={{ display: 'inline-flex' }} component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              { days.map((day, i) => {
                const key = `tb_row_${i}`
                const key2 = `tb_cell_${i}`
                return (
                  <TableRow key={key} style={{ minWidth: '100%' }}>
                    <TableCell style={{ padding: 5 }} key={key2} align="center">
                      { t(`app:schedule.${day}`) }
                    </TableCell>
                  </TableRow>
                )
              }) }
            </TableHead>

            <TableBody>
              { formattedSchedule.map((column, i) => {
                const key = `tb_row_${i}`
                return (
                  <TableRow key={key}>
                    { column.map((x, i) => {
                      const key = `tb_cell_2_${i}`
                      return (
                        <TableCell
                          key={key}
                          align="center"
                          className={`${column[i].scheduleType}`}
                          onDoubleClick={() => column && column[i] && onCellClick(column[i])}
                        />
                      )
                    }) }
                  </TableRow>
                )
              }) }
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}

export default ScheduleGrid
