import { Grid, withStyles } from '@material-ui/core'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCart } from '../../core/cart.hooks'
import LineTitle from './LineTitle'

const ItemFrequencyLine = ({ product }) => {
  const { t } = useTranslation()

  const styles = theme => {
    return {
      switchBase: {
        color: theme.palette.primary.main,
        '&$checked': {
          color: theme.palette.primary.main,
        },
        '&$disabled': {
          color: theme.palette.background.paper,
        },
      },
      checked: {},
      disabled: {},
      track: {},
    }
  }

  const FrequencySwitch = withStyles(styles)(Switch)
  const { updatePaymentFrequency } = useCart()
  const checkedState = product.paymentFrequency === 'annual'
  const [checkedAnnual, setCheckedAnnual] = useState(checkedState)
  const yearly = t('app:checkout_process.photo_transmission_plan.yearly')
  const monthly = t('app:checkout_process.photo_transmission_plan.monthly')
  const setPaymentFrequency = (product) => {
    updatePaymentFrequency({
      itemId: product.id,
      paymentFrequency: !checkedAnnual ? 'annual' : 'month_by_month',
    })
    setCheckedAnnual((prev) => !prev)
  }

  return (
    product.changeFrequencyEnabled
      ? (
        <Typography
          variant="body2"
          component="div"
          align="right"
          style={{ padding: '0px 10px' }}
        >
          <Grid component="label" container alignItems="center">
            <Grid item>{ monthly }</Grid>
            <Grid item>
              <FrequencySwitch
                checked={checkedAnnual}
                onChange={() => setPaymentFrequency(product)}
              />
            </Grid>
            <Grid item>{ yearly }</Grid>
          </Grid>
        </Typography>
        )
      : (
        <LineTitle align="right"> { checkedState
          ? yearly
          : monthly }
        </LineTitle>
        )
  )
}

export default ItemFrequencyLine
