import errorActions from 'shared-module/api/errorActions'
import store from 'shared-module/redux/store'
import { formatCameraError } from 'camera-module/camera/ui/camera-sharing-page/guest-panel/GuestFormHelper'

const handleError = error => {
  return store.dispatch(errorActions.apiError(error))
}
export const handleCustomError = error => {
  return store.dispatch(errorActions.apiCustomError(formatCameraError[error]))
}
export default handleError
