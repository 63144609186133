import { useTranslation } from 'react-i18next'
import Divider from '@material-ui/core/Divider'
import PrivatePageLayout from 'shared-module/new-components/private-page/PrivatePageLayout'
import { FormProvider } from 'shared-module/new-components/form/new/FormContext'
import Title from 'shared-module/new-components/layout/Title'
import SpydollarsBalance from 'user-module/promo-code/ui/redeem-promo-codes/components/SpydollarsBalance'
import RedeemPromoCodesForm from 'user-module/promo-code/ui/redeem-promo-codes/components/form/RedeemPromoCodesForm'
import RedeemPromoCodesSuccess from 'user-module/promo-code/ui/redeem-promo-codes/components/form/RedeemPromoCodesSuccess'
import RedeemPromoCodesInfo from 'user-module/promo-code/ui/redeem-promo-codes/components/RedeemPromoCodesInfo'

const RedeemPromoCodesPage = () => {
  const { t } = useTranslation()

  return (
    <PrivatePageLayout>
      <Title mb={4}>{ t('redeem_promo_code.title') }</Title>
      <SpydollarsBalance />
      <FormProvider>
        <RedeemPromoCodesForm />
        <RedeemPromoCodesSuccess />
      </FormProvider>
      <Divider />
      <RedeemPromoCodesInfo />
    </PrivatePageLayout>
  )
}

export default RedeemPromoCodesPage
