import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import PhotoGallery from 'photo-module/photos/ui/photos-gallery/PhotoGallery'
import DeletePhotosContainer from 'photo-module/photos/ui/photos-gallery/actions-buttons/DeletePhotosContainer'
import SpypointPhotoOverlay from '../../../../video-module/core/SpypointPhotoOverlay'
import useFiltersActions from 'photo-module/filters/core/useFiltersActions'
import useFilters from 'photo-module/filters/core/useFilters'
import useFilterCollection from 'photo-module/filters-bar/core/filters.hooks'
import appConfig from 'shared-module/config/appConfig'
import switches from 'shared-module/config/switches'
import SpypointCameraInstantRequest from '../../../../cameras-module/ui/cameras-page/SpypointCameraInstantRequest'
import { useReloadCameras } from 'camera-module/cameras/core/useCameras'
import { useParams } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import CTypography from 'storybook-component-module/src/components/typographies/CTypography'
import { useTranslation } from 'react-i18next'
import Error from 'photo-module/photos/core/error'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'wrap',
    width: '100%',
  },
  push: {
    textAlign: 'right',
    minHeight: 40,
    paddingRight: 10,
    float: 'right',
  },
  command: {
    float: 'left',
  },
  header: {
    width: '100%',
    display: 'inline-block',
  },
  paperContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '1.25rem',
    padding: '1.25rem',
    [theme.breakpoints.down('xs')]: {
      margin: '1rem 0',
      padding: '1rem 0.5rem 1rem 1rem',
    },
  },
  noCamerasTextTitle: {
    textAlign: 'center',
    marginTop: '75px',
    marginBottom: '20px',
    fontFamily: 'ibm-plex-sans-regular',
    color: '#B1AFAF',
    fontSize: '14px',
    fontWeight: 'bold',
  },
  noCamerasTextContent: {
    textAlign: 'center',
    marginBottom: '75px',
    fontFamily: 'ibm-plex-sans-regular',
    color: '#B1AFAF',
    fontSize: '14px',
    fontWeight: 400,
  },
}))

const SpypointPhotoContainer = () => {
  const classes = useStyles()
  const filters = useFilters()
  const dispatch = useDispatch()
  const filtersActions = useFiltersActions()
  const filterCollection = useFilterCollection()
  const flexFeatureDisabled = appConfig.flex.switch === switches.off
  const cameras = useReloadCameras()
  const { id: cameraId } = useParams()
  const error = useSelector(state => state.galleryPhoto.error)
  const { t } = useTranslation()
  const camera = cameras?.find(camera => (camera.id === cameraId || camera.ucid === cameraId))

  useEffect(() => {
    dispatch(Error.set({}))
  }, [])
  const checkTakePhotoVideo = (object) => object && Object.keys(object).length > 0

  const checkFulfillsRequirements = (commands) => Object
    .values(commands)
    .map(command => command.requires.map(requirement => camera?.config[requirement.name] === requirement.value))
    .map(values => values.every(value => value === true))
    .includes(true)

  const renderNoInsiderClubContainer = () => (
    <Grid container display="flex" justifyContent="center">
      <Grid item md={6} xs={12}>
        <CTypography variant="body2" color="textPrimary" className={classes.noCamerasTextTitle}>{ t('camera_list.empty_state_title') }</CTypography>
        <CTypography variant="body2" className={classes.noCamerasTextContent}>{ t('camera_list.empty_state_subTitle') }</CTypography>
      </Grid>
    </Grid>
  )

  return (
    <>
      { error?.category === 'CameraShareError' && renderNoInsiderClubContainer() }
      { error?.category !== 'CameraShareError' && (
        <div className={classes.root}>
          <div className={classes.header}>
            <div className={classes.push}>
              <DeletePhotosContainer />
            </div>
            { camera &&
          (checkTakePhotoVideo(camera?.commands?.takePhoto) || checkTakePhotoVideo(camera?.commands?.takeVideo)) &&
          checkFulfillsRequirements(camera?.commands) &&
          !flexFeatureDisabled &&
            <SpypointCameraInstantRequest className={classes.command} camera={camera} /> }
          </div>

          { flexFeatureDisabled
            ? <PhotoGallery PhotoOverlay={SpypointPhotoOverlay} filters={filters} filtersActions={filtersActions} />
            : (
              <PhotoGallery
                PhotoOverlay={SpypointPhotoOverlay}
                filters={filterCollection.selectedFilters}
                filterCollectionActions={filterCollection}
                oldImpl
              />
              ) }
        </div>
      ) }
    </>
  )
}

export default SpypointPhotoContainer
