import MP4Box from 'mp4box'
import { copyArray, findFirstKey, processFrame } from './h265Helper'
import { useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import H265Player from 'h265-stream-player'
import audioBufferToWav from 'audiobuffer-to-wav'
import videoState from 'video-module/video-clip/core/videoState'

const useStyles = makeStyles(() => ({
  video: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  canvas: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
  },
}))
const H265VideoPlayer = ({ photo, videoJS }) => {
  const playerRef = useRef(null)
  const classes = useStyles()
  const [frames, setFrames] = useState()
  const [keyFrames, setKeyFrames] = useState()
  let seek = false; let ended = false; let stop = false
  let curFrame = 0; let curOffset = 0
  const h265Player = useRef(null)
  const timer = useRef(null)
  const extractFrames = () => {
    const mp4box = MP4Box.createFile()
    fetch(videoState.getVideoUrl(photo)).then((res) => {
      res.arrayBuffer().then((bytes) => {
        handleSrcError(copyArray(bytes))
        bytes.fileStart = 0
        mp4box.appendBuffer(bytes)
        mp4box.flush()
      })
      mp4box.onReady = function (info) {
        mp4box.setExtractionOptions(info.tracks[0].id)
        mp4box.start()
        mp4box.onSamples = (id, user, samples) => {
          const frameBuffer = []
          const keyFramesBuffer = []
          for (let i = 0; i < samples.length; i++) {
            const sample = samples[i]
            if (sample.data == null || sample.data.length < 4 || !sample.data) {
              continue
            }
            const frame = processFrame(sample)
            const idxPts = sample.dts / sample.timescale
            const smp = [frame.buffer, idxPts]
            frameBuffer.push(smp)
            if (sample.is_sync) {
              keyFramesBuffer.push(i)
            }
          }
          setFrames(frameBuffer)
          setKeyFrames(keyFramesBuffer)
        }
      }
    })
  }
  const feedNext = (i, offset) => {
    if (ended) {
      curFrame = 0
      curOffset = 0
      return
    }
    if (stop) {
      curFrame = i
      curOffset = offset
      return
    }
    if (i === frames.length - 1) {
      return
    }
    timer.current = setTimeout(() => feedNext(i + 1, frames[i][1]), (frames[i][1] - offset) * 1000)
    const framesSend = copyArray(frames[i][0])
    h265Player.current.feed(framesSend, frames[i][1])
  }
  const initPlayer = () => {
    if (h265Player.current) {
      h265Player.current.destroy()
    }
    const newPlayer = new H265Player(playerRef.current, {
      baseLibPath: '/player/',
    })
    newPlayer.on('ready', () => {
      newPlayer.start()
    })
    h265Player.current = newPlayer
  }

  const togglePlayback = () => {
    if (!frames) return
    if (videoJS.paused()) {
      videoJS.play()
    } else {
      videoJS.pause()
    }
  }
  const toggleSpacebar = (e) => {
    if (e.keyCode === 32) {
      togglePlayback()
    }
  }
  const initVideojs = () => {
    videoJS.off('play')
    videoJS.off('seeked')
    videoJS.off('pause')
    videoJS.off('ended')
    videoJS.on('play', () => {
      stop = false
      ended = false
      if (seek) {
        const time = videoJS.currentTime()
        const firstKey = findFirstKey(keyFrames, frames, time)
        curFrame = firstKey
        curOffset = frames[firstKey][1]
        seek = false
      }
      feedNext(curFrame, curOffset)
    })
    videoJS.on('pause', () => {
      stop = true
    })
    videoJS.on('ended', () => {
      ended = true
      initPlayer()
    })
    videoJS.on('seeked', () => {
      seek = true
    })
  }
  const handleSrcError = (bytes) => {
    videoJS.on('error', () => {
      if (videoJS.error().code === 4 && bytes.byteLength !== 0) {
        videoJS.error(null)
        const audioContainer = new AudioContext()
        audioContainer.decodeAudioData(bytes).then(function (buffer) {
          const newSrc = new Blob([audioBufferToWav(buffer)], { type: 'wav', name: 'audio.wav' })
          videoJS.src({ type: 'video/mp4', src: window.URL.createObjectURL(newSrc) })
        })
      }
    })
  }

  useEffect(() => {
    if (videoJS && photo) {
      initPlayer()
      extractFrames()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoJS, photo])
  useEffect(() => {
    if (videoJS && frames && keyFrames) {
      initVideojs()
      videoJS.bigPlayButton.show()
    }
    return () => {
      clearTimeout(timer.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frames, keyFrames])

  return (
    <div onClick={togglePlayback} tabIndex="0" onKeyDown={toggleSpacebar} className={classes.video}>
      <canvas width="960" key={photo.id} height="540" className={classes.canvas} id="video" ref={playerRef} />
    </div>
  )
}

export default H265VideoPlayer
