import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import ConfirmationDialog from 'shared-module/components/confirmation/ConfirmationDialog'
import useUser from 'user-module/user/core/useUser'

const DialogBuyHdPackage = ({ open, onClose }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const user = useUser()

  const confirmBuy = () => {
    user.cartEnabled && history.push('/cart?photo_hd=true', { referrer: history.location.pathname })
  }

  return (
    <ConfirmationDialog
      open={open}
      onClose={onClose}
      title={t('photo_viewer.buy_full_hd.title')}
      text={t('photo_viewer.buy_full_hd.text')}
      confirmText={t('photo_viewer.buy_full_hd.confirm')}
      onConfirm={confirmBuy}
      onCancel={onClose}
    />
  )
}

export default DialogBuyHdPackage
