import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const TimeSynchronized = ({ props }) => {
  const theme = useTheme()
  return (
    <SvgIcon width="32" height="32" viewBox="0 0 32 32" {...props} data-testid="TimeSynchronized">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14 26.5938C21.2487 26.5938 27.125 20.7175 27.125 13.4688C27.125 6.22001 21.2487 0.34375 14 0.34375C6.75126 0.34375 0.875 6.22001 0.875 13.4688C0.875 20.7175 6.75126 26.5938 14 26.5938ZM13.6488 4.09375L15.0588 13.1941L20.3395 20.4231L12.9311 14.9885C12.8627 14.9543 12.7944 14.9116 12.726 14.8604L12.5722 14.7065C12.3329 14.4844 12.1877 14.2195 12.1364 13.9119C12.1022 13.6042 12.1449 13.3137 12.2646 13.0403L13.6488 4.09375Z"
        fill={theme.palette.background.logo}
      />
    </SvgIcon>
  )
}

export default TimeSynchronized
