import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const SyncPendingIcon = (props) => {
  const theme = useTheme()

  return (
    <SvgIcon viewBox="0 0 21 20" {...props} data-testid="SyncPendingIcon">
      <circle cx="10.85" cy="10" r="8.33354" fill={theme.palette.primary.pending} />
      <path d="M8.02542 7.17571C8.58378 6.61517 9.29603 6.23307 10.0718 6.07786C10.8476 5.92264 11.6521 6.0013 12.3831 6.30387C13.1142 6.60643 13.7389 7.11926 14.1781 7.77734C14.6173 8.43542 14.8512 9.2091 14.8501 10.0003H15.8501C15.8515 9.01128 15.5591 8.04416 15.01 7.22153C14.461 6.39891 13.6801 5.75785 12.7663 5.37961C11.8524 5.00138 10.8469 4.90302 9.87708 5.09699C8.90729 5.29097 8.01693 5.76855 7.3189 6.46919L6.34987 5.50016V8.50024H9.34996L8.02542 7.17571ZM13.6751 12.8249C13.1166 13.3853 12.4044 13.7674 11.6286 13.9225C10.8528 14.0777 10.0483 13.999 9.31729 13.6965C8.58624 13.394 7.96148 12.8812 7.52222 12.2231C7.08295 11.5651 6.84895 10.7915 6.84989 10.0003H5.84986C5.84854 10.9894 6.14097 11.9565 6.69006 12.7792C7.23914 13.6018 8.02016 14.2429 8.93406 14.6211C9.84797 14.9993 10.8536 15.0976 11.8234 14.9035C12.7933 14.7094 13.6836 14.2317 14.3816 13.5309L15.3501 14.5004V11.5003H12.35L13.6751 12.8249Z" fill="#1F222E" />
    </SvgIcon>
  )
}

export default SyncPendingIcon
