import InformationBarElement from 'photo-module/photos/ui/photos-gallery/information-bar/InformationBarElement'
import FavoriteIcon from 'assets/icons/photo/FavoriteIcon'
import { useUserClubMembership } from 'spypoint/src/user-module/club-membership/core/useUserClubMembership'

const formatFavoriteCount = userMembership => `${userMembership.favoriteCount}/${userMembership.favoriteLimit}`

const FavoritesCountElement = ({ style, className }) => {
  const userMembership = useUserClubMembership()

  return <InformationBarElement text={formatFavoriteCount(userMembership)} Icon={FavoriteIcon} style={style} customHover className={className} />
}

export default FavoritesCountElement
