import { CircularProgress, Grid, useTheme } from '@material-ui/core'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { makeStyles } from '@material-ui/core/styles'
import useMessageActions from 'shared-module/message/useMessageActions'
import useUserPaymentMethodsActions from 'spypoint/src/user-module/payment-methods/core/useUserPaymentMethods.actions'
import SecondaryButton from 'shared-module/new-components/buttons/SecondaryButton'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  cardElement: {
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: '3px',
    padding: '16px 8px',
  },
}))

const options = (theme) => ({
  hidePostalCode: true,
  style: {
    base: {
      iconColor: theme.palette.text.primary,
      color: theme.palette.text.primary,
      '::placeholder': {
        color: theme.palette.text.secondary,
      },
    },
    invalid: {
      iconColor: theme.palette.error.main,
      color: theme.palette.error.main,
    },
  },
})

const PaymentCardUpdate = ({ closeUpdateCardDefault }) => {
  const classes = useStyles()
  const theme = useTheme()
  const stripe = useStripe()
  const elements = useElements()
  const { t } = useTranslation()
  const { showError } = useMessageActions()
  const { updateDefault } = useUserPaymentMethodsActions()
  const [complete, setComplete] = useState(false)
  const [spinner, setSpinner] = useState(false)

  const showSpinner = () => setSpinner(true)
  const hideSpinner = () => setSpinner(false)

  const update = async () => {
    if (!stripe || !elements) return
    showSpinner()
    const cardElement = elements.getElement(CardElement)
    const { error, token } = await stripe.createToken(cardElement)
    if (error) {
      hideSpinner()
      showError(error)
      return
    }
    updateDefault(token.id)
      .then(() => {
        hideSpinner()
        closeUpdateCardDefault()
      })
      .catch(error => {
        hideSpinner()
        showError(t(`stripe.error.${error.response?.data?.error?.decline_code || error.response?.data?.error?.code}`))
      })
  }

  return (
    <Grid container direction="column" spacing={3}>
      <Grid xs={12} sm={9} item>
        <CardElement
          className={classes.cardElement}
          options={options(theme)}
          onChange={(event) => setComplete(event.complete)}
        />
      </Grid>
      <Grid xs={12} sm={9} item container justifyContent="flex-end">
        { spinner && <CircularProgress /> }
        { !spinner && (
          <SecondaryButton onClick={update} disabled={!complete}>
            <Trans i18nKey="button.save" />
          </SecondaryButton>
        ) }
      </Grid>
    </Grid>
  )
}

export default PaymentCardUpdate
