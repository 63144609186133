import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import CheckoutContent from './CheckoutContent'
import CheckoutTotalLine from './checkout-lines/CheckoutTotalLine'
import CartSavingsLine from './checkout-lines/CartSavingsLine'
import NoteInformationLine from './checkout-lines/NoteInformationLine'
import CartDivider from '../cart-lines/CartDivider'

const useStyles = makeStyles((theme) => ({
  paperWrapper: {
    padding: 30,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 15,
    background: theme.palette.primary.contrastText,

  },
  orderTitle: {
    fontFamily: 'oswald-normal',
    marginBottom: '30px',
    fontSize: '18px',
  },

}))
const OrderSummary = ({ products, promocode }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.paperWrapper}>
      <div className={classes.orderTitle}>
        { t('menu.summary') }
      </div>
      <CheckoutContent products={products} promocode={promocode} />
      <CheckoutTotalLine />
      <CartSavingsLine />
      { products.some(p => p.paymentFrequency === 'month_by_month') && (
        <>
          <CartDivider />
          <NoteInformationLine />
        </>
      ) }
    </div>
  )
}

export default OrderSummary
