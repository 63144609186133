import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import TruncatedText from 'shared-module/components/truncated-text/TruncatedText'
import useCameraModelsAndVariants from 'camera-module/model/core/useCameraModelsAndVariants'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'no-wrap',
    [theme.breakpoints.down('xs')]: {
      margin: '7px auto',
    },
    marginBottom: '10px',
  },
  picture: {
    margin: '0px 10px 0px 0px',
  },
  cameraName: {
    fontSize: '20pt',
    fontFamily: theme.typography.h3.fontFamily,
    whiteSpace: 'nowrap',
  },
  modelName: {
    fontSize: '12pt',
    fontFamily: theme.typography.h3.fontFamily,
  },
  divider: {
    margin: '20px 0px',
  },
}))

const CameraBlock = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const camera = useSelector(state => state.selectedCamera.camera)
  const model = useCameraModelsAndVariants(camera.status.model)

  if (camera) {
    let cameraModelName = t('n_a')
    let cameraModelPicture = <div>{ t('n_a') }</div>
    if (camera.status) {
      cameraModelName = camera.status.model
      if (model) {
        cameraModelPicture = <img src={model.icon} height="55px" alt={model.name} />
      }

      return (
        <div className={classes.root}>
          <div className={classes.picture}>{ cameraModelPicture }</div>
          <div>
            <div className={classes.cameraName}><TruncatedText text={camera.config.name} length={15} /></div>
            <div className={classes.modelName}>{ cameraModelName }</div>
          </div>
        </div>
      )
    } else {
      return (<div className={classes.divider} />)
    }
  }
}

export default CameraBlock
