import { Box, makeStyles } from '@material-ui/core'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import CButtonIcon from 'storybook-component-module/src/components/buttons/CButtonIcon'

const useStyles = makeStyles(() => ({
  backButton: {
    top: 42,
    position: 'relative',
    '& .MuiSvgIcon-root': {
      position: 'relative',
      left: '0.25rem',
    },
  },
}))

const BackArrowButton = ({ steps, activeStep, handleBack }) => {
  const classes = useStyles()

  return (
    <Box display="flex" height={48} width="100%" justifyContent="flex-start">
      { steps[activeStep].handlesBackAction &&
        (
          <CButtonIcon onClick={steps[activeStep].backwardAction || handleBack} className={classes.backButton}>
            <ArrowBackIosIcon data-testid="ArrowBackIosIcon" />
          </CButtonIcon>
        ) }
    </Box>
  )
}

export default BackArrowButton
