import { useTranslation } from 'react-i18next'
import makeStyles from '@material-ui/core/styles/makeStyles'

import CBenefitTile from 'storybook-component-module/src/components/tiles/CBenefitTile'
import { mainBenefits } from 'spypoint/src/user-module/club-membership/core/insidersClubBenefits.selectors'

import { iCBenefitTypes } from 'spypoint/src/user-module/club-membership/core/insidersClubBenefits.types'

const useStyles = makeStyles(theme => ({
  description: {
    textAlign: 'center',
    padding: '0rem 0.375rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.875rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.75rem',
    },
  },
}))

const MembershipMainBenefitsDesktop = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return mainBenefits.map(benefit => (
    <CBenefitTile
      key={benefit.id}
      icon={benefit.icon}
      title={t(benefit.title)}
      description={t(benefit.description)}
      showTradeMark={benefit.id === iCBenefitTypes.bucktracker}
      showAsterisk={benefit.isExclusiveToNa}
      descriptionClass={classes.description}
      showDivider
    />
  ))
}

export default MembershipMainBenefitsDesktop
