import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { makeStyles } from '@material-ui/core'
import Container from '@material-ui/core/Container'

import CameraIcon from 'assets/icons/navigation/CameraIcon'
import CAppBar from 'storybook-component-module/src/components/containers/CAppBar'
import cameraActivationHelper from 'camera-module/new-activation/helper/cameraActivationHelper'
import CHorizontalSteps from 'storybook-component-module/src/components/steps/CHorizontalSteps'

import HelpRoundedIcon from '@material-ui/icons/HelpRounded'
import ConfirmationDialog from 'shared-module/components/confirmation/ConfirmationDialog'
import useLanguage from 'shared-module/components/language/useLanguage'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '125px',
    marginBottom: '50px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      '&.MuiContainer-maxWidthSm': {
        maxWidth: '645px !important',
        minWidth: '645px !important',
      },
      '& .MuiContainer-maxWidthSm': {
        maxWidth: '645px !important',
        minWidth: '645px !important',
      },
    },
    [theme.breakpoints.up('sm')]: {
      '&.MuiContainer-maxWidthSm': {
        paddingLeft: 0 + ' !important',
        paddingRight: 0 + ' !important',
      },
      '& .MuiContainer-maxWidthSm': {
        paddingLeft: '2rem !important',
        paddingRight: '2rem !important',
      },
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 100,
    },
  },
  helpIcon: {
    color: theme.palette.basic?.white,
    width: '1.875rem',
    height: '1.875rem',
  },
  link: {
    color: theme.palette.primary.main,
    paddingLeft: 5,
  },
  list: {
    color: theme.palette.primary.main,
    paddingLeft: 25,
  },
}))

const CameraActivationPanel = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()

  const [activatedModel, setActivatedModel] = useState()
  const [activeStep, setActiveStep] = useState(0)
  const [planIndex, setPlanIndex] = useState(0)
  const [showSteps, setShowSteps] = useState(true)
  const [dialogOpen, setDialogOpen] = useState(false)

  const language = useLanguage()
  const isFrench = language === 'fr'

  const steps = cameraActivationHelper.activationSteps(t, history, activatedModel, setActivatedModel, planIndex, setPlanIndex, setShowSteps)

  const isCameraPlanStep = steps[activeStep].id === 'camera_plan_purchase'
  const isCameraSelectionStep = steps[activeStep].id === 'camera_selection' || 'camera_activation' || 'camera_startup'

  const handleHelpIconClick = () => {
    setDialogOpen(true)
  }

  const handleDialogClose = () => {
    setDialogOpen(false)
  }

  return (
    <Container className={classes.root} maxWidth={steps[activeStep].maxWidth}>
      <CAppBar Icon={CameraIcon} title={t('menu.add_camera')}>
        { (isCameraPlanStep || isCameraSelectionStep) && (
          <HelpRoundedIcon className={classes.helpIcon} onClick={handleHelpIconClick} />
        ) }
      </CAppBar>

      <CHorizontalSteps steps={steps} setRefOfActiveStep={setActiveStep} showSteps={showSteps} planIndex={planIndex} />

      { isCameraPlanStep && (
        <ConfirmationDialog
          open={dialogOpen}
          onClose={handleDialogClose}
          title={t('app:active_device.steps.step_4.tooltip_header')}
          text={(
            <div>
              { t('app:active_device.steps.step_4.tooltip_body') }
              <a
                href={
                isFrench
                  ? 'https://www.spypoint.com/fr/blog/200/how-to-choose-photo-transmission-plan-for-cellular-trail-camera'
                  : 'https://www.spypoint.com/en/blog/200/how-to-choose-photo-transmission-plan-for-cellular-trail-camera'
              }
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
              >
                { t('app:active_device.steps.step_4.learn_more') }
              </a>
            </div>
          )}
          confirmText={t('app:active_device.first_step.tooltip.confirm_button')}
          onConfirm={handleDialogClose}
        />
      ) }
      { isCameraSelectionStep && !isCameraPlanStep && (
        <ConfirmationDialog
          open={dialogOpen}
          onClose={handleDialogClose}
          title={t('app:active_device.tooltip_header')}
          text={(
            <div>
              { t('app:active_device.tooltip_body') }
              <ul className={classes.list}>
                <li>
                  <a
                    href={
                isFrench
                  ? 'https://www.spypoint.com/fr/support'
                  : 'https://www.spypoint.com/en/support'
              }
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    { t('app:active_device.support_center') }
                  </a>
                </li>
                <li>
                  <a
                    href={
                isFrench
                  ? 'https://www.spypoint.com/fr/support#chat'
                  : 'https://www.spypoint.com/en/support#chat'
              }
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    { t('app:active_device.chat_url') }
                  </a>
                </li>
              </ul>
            </div>
          )}
          confirmText={t('app:active_device.first_step.tooltip.confirm_button')}
          onConfirm={handleDialogClose}
        />
      ) }
    </Container>
  )
}

export default CameraActivationPanel
