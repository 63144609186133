import SvgIcon from '@material-ui/core/SvgIcon'

const ProductsIcon = (props) => (
  <SvgIcon {...props} width="14" height="20" viewBox="0 0 14 20" fill="none" data-testid="ShareIcon">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.4375 0.75H13.6187V19.6312H0.4375V0.75ZM2.33759 2.17499C2.07525 2.17499 1.86259 2.38766 1.86259 2.64999V17.7312C1.86259 17.9936 2.07526 18.2062 2.33759 18.2062H2.63773C3.02952 18.2062 3.38113 17.9657 3.52313 17.6006L5.85071 11.6154C4.34182 10.9553 3.2875 9.4492 3.2875 7.69687C3.2875 5.33586 5.20148 3.42188 7.5625 3.42188C8.04737 3.42188 8.51338 3.5026 8.94783 3.65133L9.01862 3.46932C9.2608 2.84656 8.80141 2.17499 8.13321 2.17499H2.33759ZM10.4125 7.69687C10.4125 9.27088 9.13652 10.5469 7.5625 10.5469C5.98849 10.5469 4.7125 9.27088 4.7125 7.69687C4.7125 6.12285 5.98849 4.84687 7.5625 4.84687C9.13652 4.84687 10.4125 6.12285 10.4125 7.69687ZM7.56241 6.27188C7.80668 6.27188 8.03659 6.33334 8.23753 6.44165L7.21209 9.0785C6.59447 8.92239 6.13741 8.36301 6.13741 7.69688C6.13741 6.90988 6.7754 6.27188 7.56241 6.27188Z" fill="#F89021" />

  </SvgIcon>
)

export default ProductsIcon
