export const getUserDefaultPaymentInfoSelector = state => {
  const paymentMethods = state.paymentMethods
  if (!paymentMethods.isFetched) return null

  const defaultPaymentMethod = paymentMethods.list.find(item => item.isDefault)
  if (!defaultPaymentMethod) return { isAvailable: false }

  return {
    isAvailable: true,
    card: {
      id: defaultPaymentMethod.id,
      brand: defaultPaymentMethod.card.brand,
      last4: defaultPaymentMethod.card.last4,
      exp_month: defaultPaymentMethod.card.exp_month,
      exp_year: defaultPaymentMethod.card.exp_year,
    },
  }
}
