import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import InformationBarElement from 'photo-module/photos/ui/photos-gallery/information-bar/InformationBarElement'
import { useUserHdPackage } from 'user-module/hd-package/core/useUserHdPackage'
import HdPackageIcon from 'assets/icons/navigation/HdPackageIcon'
import { useTheme } from '@material-ui/core'
import useMediaQuery from '@mui/material/useMediaQuery'

const HdPhotosCountElement = ({ style, className }) => {
  const userHdPackage = useUserHdPackage()
  const history = useHistory()
  const theme = useTheme()
  const breakpoint = useMediaQuery(theme.breakpoints.down('md'))

  const { t } = useTranslation()

  return userHdPackage?.activated
    ? <InformationBarElement text={`${userHdPackage.remaining} ${t('user.fullHd.left')}`} Icon={HdPackageIcon} onClick={() => history.push('/cart?photo_hd=true')} style={breakpoint ? style : undefined} customHover className={className} />
    : <div style={{ marginLeft: '-4px' }} />
}

export default HdPhotosCountElement
