import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const ExpandedIcon = (props) => {
  const theme = useTheme()

  return (
    <SvgIcon {...props} height="10" width="16" viewBox="0 0 16 10" data-testid="ExpandedIcon">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5379 0.587087C15.1718 0.220971 14.5782 0.220971 14.2121 0.587087L8 6.79918L1.78791 0.587088C1.4218 0.220972 0.828203 0.220972 0.462088 0.587088C0.0959708 0.953205 0.0959708 1.5468 0.462088 1.91291L7.33709 8.78791C7.7032 9.15403 8.2968 9.15403 8.66291 8.78791L15.5379 1.91291C15.904 1.5468 15.904 0.953204 15.5379 0.587087Z" fill="#F5F4F4" />
    </SvgIcon>
  )
}

export default ExpandedIcon
