import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import FavoritesFilterButton from 'photo-module/photos/ui/photos-gallery/filter-bar/filter-buttons/FavoritesFilterButton'
import SpeciesFilterButtons from 'photo-module/photos/ui/photos-gallery/filter-bar/SpeciesFilterButtons'
import HdFilterButton from 'photo-module/photos/ui/photos-gallery/filter-bar/filter-buttons/HdFilterButton'
import { useUserClubMembership } from 'spypoint/src/user-module/club-membership/core/useUserClubMembership'
import BuckTrackerLogo from 'photo-module/photos/ui/photos-gallery/filter-bar/BuckTrackerLogo'

const useStyles = makeStyles((theme) => ({
  bar: {
    display: 'flex',
    alignItems: 'center',
  },
  divider: {
    display: 'inline-block',
    height: '100%',
    width: 1,
    background: theme.palette.background.filter,
    marginRight: 5,
    marginLeft: 5,
  },
  paper: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '20px',
    margin: '20px',
    [theme.breakpoints.down('sm')]: {
      padding: '5px',
      margin: '10px 0',
      overflowX: 'scroll',
    },
  },
  filtersBarStyle: {
    [theme.breakpoints.down('sm')]: {
      cursor: 'ew-resize',
    },
  },
}))

const FiltersBar = () => {
  const classes = useStyles()
  const userClubMembership = useUserClubMembership()
  const filtersBarCSSClasses = `${classes.paper} ${classes.filtersBarStyle}`

  return userClubMembership
    ? (
      <Paper className={filtersBarCSSClasses} elevation={1}>
        <div className={classes.bar}>
          <HdFilterButton />
          <span className={classes.divider} />
          <FavoritesFilterButton />
          <span className={classes.divider} />
          <BuckTrackerLogo />
          <SpeciesFilterButtons />
        </div>
      </Paper>
      )
    : null
}

export default FiltersBar
