import { makeStyles } from '@material-ui/core/styles'
import SchedulePage from './SchedulePage'
import useCameraSchedule from '../../../../core/useCameraSchedule'
import ScheduleCreationForm from './ScheduleCreationForm'
import useConfirmationDialogActions from 'shared-module/components/confirmation/useConfirmationDialogActions'
import { useState } from 'react'
import ScheduleConflictsDialog from './ScheduleConflictsDialog'
import ScheduleDeletePeriodDialog from './ScheduleDeletePeriodDialog'
import ScheduleAppBar from './ScheduleAppBar'
import { useField, useFormikContext } from 'formik'
import cameraApi from '../../../../api/cameraApi'
import messageActions from 'shared-module/message/messageActions'
import useSelectedCamera from '../../../../core/useSelectedCamera'
import { useDispatch } from 'react-redux'
const useStyles = makeStyles({
  root: {
    marginTop: '150px',
  },
})

const SettingsCustomSchedule = ({ onClose }) => {
  const classes = useStyles()
  const [instantSchedule] = useField('instantSchedule')
  const [detectionSchedule] = useField('detectionSchedule')
  const [operationMode] = useField('operationMode')
  const instValue = instantSchedule.value || []
  const detValue = detectionSchedule.value || []
  const schedule = useCameraSchedule(instValue, detValue)
  const conflictActions = useConfirmationDialogActions()
  const deleteActions = useConfirmationDialogActions()
  const [openCreateDialog, setOpenCreateDialog] = useState(false)
  const [selectedPeriod, setSelectedPeriod] = useState()
  const [periodsToAdd, setPeriodsToAdd] = useState({ periodsToAdd: [], scheduleType: null })
  const camera = useSelectedCamera()
  const { setFieldValue } = useFormikContext()
  const dispatch = useDispatch()
  const hasChanges = !(schedule.instantSchedule === instValue && schedule.detectionSchedule === detValue)

  const saveScheduleSettings = async () => {
    try {
      const updatedCamera = await cameraApi.saveSettings(camera.id, {
        instantSchedule: schedule.instantSchedule,
        detectionSchedule: schedule.detectionSchedule,
        operationMode: operationMode.value,
      })
      setFieldValue('instantSchedule', updatedCamera.config.instantSchedule, false)
      setFieldValue('detectionSchedule', updatedCamera.config.detectionSchedule, false)
      setFieldValue('operationMode', updatedCamera.config.operationMode, false)
      dispatch(messageActions.showMessage('camera.settings.messages.delayedSave'))
    } catch (error) {
      dispatch(messageActions.showError('errors.catch_all'))
    }
  }

  const onSave = () => {
    saveScheduleSettings().then(() => onClose())
  }

  const onDeletePeriod = (period) => {
    schedule.removeSchedulePeriod(period)
    setSelectedPeriod(null)
  }

  const onAddPeriods = (periods, scheduleType) => {
    schedule.addSchedulePeriods(periods, scheduleType)
    setOpenCreateDialog(false)
    setPeriodsToAdd(null)
    setSelectedPeriod(null)
  }
  const onCheckConflicts = (periods, scheduleType) => {
    if (selectedPeriod) {
      schedule.removeSchedulePeriod(selectedPeriod)
    }
    if (schedule.hasConflicts(periods, scheduleType)) {
      conflictActions.open()
      setPeriodsToAdd({
        periodsToAdd: periods,
        scheduleType: scheduleType,
      })
    } else {
      onAddPeriods(periods, scheduleType)
    }
  }

  const onSelectPeriod = (weekDay, scheduleType, time) => {
    const period = schedule.getSelectedPeriod(weekDay, scheduleType, time)
    if (period) {
      period.scheduleType = scheduleType
      setSelectedPeriod(period)
      setOpenCreateDialog(true)
    }
  }
  return (
    <div>
      <ScheduleAppBar onSave={onSave} onCancel={onClose} hasChanges={hasChanges} />
      <div className={classes.root}>
        <SchedulePage
          formattedSchedule={schedule.formattedSchedule}
          onSelectPeriod={onSelectPeriod}
          onCreate={() => {
            setSelectedPeriod(null)
            setOpenCreateDialog(true)
          }}
        />
        <ScheduleCreationForm
          open={openCreateDialog}
          onClose={() => {
            setOpenCreateDialog(false)
            setSelectedPeriod(null)
          }}
          onAddPeriods={onCheckConflicts}
          onDeletePeriod={deleteActions.open}
          selectedPeriod={selectedPeriod}
        />
        <ScheduleConflictsDialog
          open={conflictActions.isOpen}
          onConfirm={() => {
            onAddPeriods(periodsToAdd.periodsToAdd, periodsToAdd.scheduleType)
            conflictActions.close()
          }}
          onClose={conflictActions.close}
        />
        <ScheduleDeletePeriodDialog
          open={deleteActions.isOpen}
          onConfirm={() => {
            onDeletePeriod(selectedPeriod)
            deleteActions.close()
            setOpenCreateDialog(false)
          }}
          onClose={deleteActions.close}
        />
      </div>
    </div>
  )
}

export default SettingsCustomSchedule
