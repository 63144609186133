import Grid from '@material-ui/core/Grid'
import { useTheme } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'

const GuestList = ({ children }) => {
  const theme = useTheme()
  const isMobileResolution = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Grid item md={6} xs={12} sm={12} style={{ flexBasis: isMobileResolution ? '0px' : undefined }}>
      { children }
    </Grid>
  )
}

export default GuestList
