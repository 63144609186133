import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles({
  commandText: {
    fontWeight: 600,
    alignItems: 'center',
  },
  textWrapper: {
    display: 'inline-flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  icon: {
    height: '20px',
    marginTop: 1,
  },
})

const SpypointCameraTileCommand = ({ Icon, text, disabled }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <div className={classes.textWrapper}>
      <Icon disabled={disabled} className={classes.icon} />
      <Typography component="p" style={{ fontSize: 10 }} className={classes.commandText}>
        { t(text) }
      </Typography>
    </div>
  )
}
export default SpypointCameraTileCommand
