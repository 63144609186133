import { useSelector } from 'react-redux'
import ToggleButton from 'shared-module/components/button/ToggleButtton'
import usePhotosDelete from 'photo-module/photos/ui/photos-gallery/usePhotosDelete'

const FilterButton = ({ Icon, iconImage, text, disabled, selected, onToggle }) => {
  const isGalleryFetching = useSelector(state => state.galleryPhoto.fetching)
  const { photosToDeleteActions } = usePhotosDelete()

  const isDisabled = (disabled, isGalleryFetching) => (isGalleryFetching ? true : disabled)

  const toggleFilter = () => {
    photosToDeleteActions.reset()
    onToggle()
  }

  return (
    <div>
      <ToggleButton Icon={Icon} iconImage={iconImage} text={text} selected={selected} disabled={isDisabled(disabled, isGalleryFetching)} onClick={toggleFilter} />
    </div>
  )
}

export default FilterButton
