import userApi from 'user-module/user/api/userApi'
import apm from 'shared-module/monitoring/apm'

const type = 'SET_USER_CLUB_MEMBERSHIP'

const get = () => dispatch => userApi
  .getClubMembership()
  .then(club => {
    apm.addLabels({ clubMember: club.isMember })
    return dispatch(({ type, club }))
  })

const reducer = (state = null, action) => action.type === type ? action.club : state

const userClubMembershipState = { get: get, reducer: reducer }
export default userClubMembershipState
