import React from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid'
import MuiBox from '@mui/material/Box'
import makeStyles from '@material-ui/core/styles/makeStyles'

import CTitle from '../typographies/CTitle'
import CTypography from '../typographies/CTypography'
import ICDiscountIcon from 'spypoint/src/assets/icons/insiders-club-benefits/ICDiscountIcon'

export interface IICDiscountBadgeIcon {
  message: string
  discount: number
  className?: string
}

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    position: 'relative',
  },
  icon: {
    width: '100%',
    height: '100%',
  },
  overlay: {
    top: '45%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'default',
    position: 'absolute',
  },
  discountContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  title: {
    marginBottom: '-1.5rem',
    textTransform: 'uppercase',
    color: theme.palette.text.primary,
    // @ts-ignore
    fontFamily: theme.typography.fontFamilySvg,
  },
  en: {
    fontSize: '5rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '3.25rem',
    },
  },
  fr: {
    fontSize: '2.75rem',
    paddingBottom: '0.5rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '2.625rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.75rem',
    },
  },
  discount: {
    fontSize: '7rem',
    color: theme.palette.primary.main,
    // @ts-ignore
    fontFamily: theme.typography.fontFamilySvg,
    [theme.breakpoints.down('sm')]: {
      fontSize: '5rem',
    },
  },
  percentage: {
    fontSize: '3rem',
    marginTop: '0.625rem',
    color: theme.palette.text.primary,
    // @ts-ignore
    fontFamily: theme.typography.fontFamilySvg,
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    },
  },
}))

// Bypasses Typescript error: cannot be used as a JSX component
const Box: any = MuiBox

const ICDiscountBadgeIcon = (props: IICDiscountBadgeIcon) => {
  const { t, i18n } = useTranslation()
  const classes = useStyles()

  return (
    <Grid item sm={4} className={clsx(classes.container, props.className)}>
      <ICDiscountIcon className={classes.icon} />

      <Box className={classes.overlay}>
        <CTitle className={clsx(classes.title, i18n.language === 'en' ? classes.en : classes.fr)}>
          <>{ t(props.message) }</>
        </CTitle>

        <Box className={classes.discountContainer}>
          <CTypography variant="h1" className={classes.discount}>{ props.discount }</CTypography>
          <CTypography variant="h1" className={classes.percentage}>%</CTypography>
        </Box>
      </Box>
    </Grid>
  )
}

export default ICDiscountBadgeIcon
