import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'

import { makeStyles } from '@material-ui/core/styles'
import { Box, Typography } from '@material-ui/core'

import useUser from 'user-module/user/core/useUser'
import messageActions from 'shared-module/message/messageActions'
import TermsAndConditionsLine from 'spypoint/src/checkout-module/order/ui/checkout-page/checkout-lines/TermsAndConditionsLine'
import useUserActions from 'user-module/user/core/useUserActions'
import userClubMembershipState from 'spypoint/src/user-module/club-membership/core/userClubMembershipState'
import userHdPackageState from 'user-module/hd-package/core/userHdPackageState'
import userPaymentMethodsState from 'spypoint/src/user-module/payment-methods/core/userPaymentMethods.state'
import PaymentInformationDisplay from 'spypoint/src/checkout-module/order/ui/checkout-page/payment/PaymentInformationDisplay'
import CheckoutActionButtons from 'spypoint/src/checkout-module/order/ui/checkout-page/new-payment-methods/CheckoutActionButtons'
import NewPurchaseConfirmation from 'shared-module/components/confirmation/NewPurchaseConfirmation'
import CheckoutDiscardChangesButton from 'spypoint/src/checkout-module/order/ui/checkout-page/new-payment-methods/CheckoutDiscardChangesButton'
import cartActions from 'spypoint/src/checkout-module/order/core/cart.actions'
import { useCart } from 'spypoint/src/checkout-module/order/core/cart.hooks'

const useStyles = makeStyles((theme) => ({
  paperWrapper: {
    padding: 30,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 15,
    background: theme.palette.primary.contrastText,
  },
  title: {
    fontSize: 18,
    fontWeight: 400,
    marginBottom: 24,
  },
}))

const CheckoutForm = ({
  disabled, paymentIntentData, paymentInfo, paymentInfoState, updatePaymentInfoState, setCheckoutIsExited,
  setStep, isActivation = false,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const user = useUser()
  const stripe = useStripe()
  const elements = useElements()
  const dispatch = useDispatch()
  const history = useHistory()
  const { reload } = useUserActions()
  const { cartItems, deleteFromCart } = useCart()

  const [spinner, setSpinner] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [formWasSubmitted, setFormWasSubmitted] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false)

  const paymentElementOptions = {
    layout: 'tabs',
    terms: { card: 'never' },
    paymentMethodOrder: ['card', 'apple_pay', 'google_pay'],
    business: { name: 'Spypoint' },
    fields: { billingDetails: 'never' },
  }

  const handlePaymentElementSubmit = async e => {
    e.preventDefault()
    setSpinner(true)
    if (!stripe || !elements) return

    const { error: submitError } = await elements.submit()
    if (submitError) {
      setSpinner(false)
      setButtonDisabled(true)
      setFormWasSubmitted(true)
      return
    }

    await stripe
      .confirmPayment({
        elements,
        redirect: 'if_required',
        clientSecret: paymentIntentData.clientSecret,
        confirmParams: {
          save_payment_method: true,
          payment_method_data: {
            billing_details: {
              name: user.firstName + ' ' + user.lastName,
              email: user.email,
              address: {
                line1: user.address,
                line2: user.apartment || '',
                city: user.city,
                state: user.territory,
                country: user.country,
                postal_code: user.postalCode,
              },
              phone: user.cell,
            },
          },
        },
      })
      .then(response => {
        response.error
          ? dispatch(messageActions.showError(response.error.message))
          : processPlaceOrder()

        setSpinner(false)
      })
      .catch(error => {
        dispatch(messageActions.showError(error.message))
        setSpinner(false)
      })
  }

  const handlePaymentWithExistingCardSubmit = async e => {
    e.preventDefault()
    setSpinner(true)
    if (!stripe || !elements) return

    const { error: submitError } = await elements.submit()
    if (submitError) {
      setSpinner(false)
      setButtonDisabled(true)
      setFormWasSubmitted(true)
      return
    }

    await stripe
      .confirmCardPayment(paymentIntentData.clientSecret, { id: paymentInfo.id, setup_future_usage: 'off_session' })
      .then(response => {
        response.error
          ? dispatch(messageActions.showError(response.error.message))
          : processPlaceOrder()

        setSpinner(false)
      })
      .catch(error => {
        dispatch(messageActions.showError(error.message))
        setSpinner(false)
      })
  }

  const handleConfirmation = () => history.push('/')

  const processPlaceOrder = async () => {
    setCheckoutIsExited(true)

    await deleteFromCart({ itemId: cartItems?.products[0]?.id })
    await reload(user)
    await dispatch(cartActions.fetchCart())
    dispatch(userClubMembershipState.get())
    dispatch(userHdPackageState.get())
    dispatch(userPaymentMethodsState.clear())
    setShowConfirmation(true)
  }

  const updateFormComplete = event => formWasSubmitted && setButtonDisabled(!event.complete)

  return (
    <Box className={classes.paperWrapper}>
      <Typography variant="h2" className={classes.title}>{ t('menu.payment') }</Typography>

      { !paymentInfoState.isOpenForm
        ? <PaymentInformationDisplay card={paymentInfo?.card} openForm={() => updatePaymentInfoState(false, true)} />
        : (
          <form id="payment-form" onSubmit={handlePaymentElementSubmit}>
            { paymentInfoState.isOpenForm && !!paymentInfo?.isAvailable && (
              <CheckoutDiscardChangesButton updatePaymentInfoState={updatePaymentInfoState} />
            ) }

            <PaymentElement id="payment-element" options={paymentElementOptions} onChange={updateFormComplete} />
            <TermsAndConditionsLine newCheckout />

            <CheckoutActionButtons
              stripe={stripe}
              elements={elements}
              spinner={spinner}
              purchaseDisabled={disabled}
              setCheckoutIsExited={setCheckoutIsExited}
              buttonDisabled={buttonDisabled}
            />
          </form>
          ) }

      { !paymentInfoState.isOpenForm && (
        <Box mt={1}>
          <TermsAndConditionsLine newCheckout />

          <CheckoutActionButtons
            stripe={stripe}
            elements={elements}
            spinner={spinner}
            purchaseDisabled={disabled}
            buttonDisabled={buttonDisabled}
            setCheckoutIsExited={setCheckoutIsExited}
            submitPayment={handlePaymentWithExistingCardSubmit}
          />
        </Box>
      ) }

      { showConfirmation && (
        <NewPurchaseConfirmation
          open
          title={t('app:checkout.dialog.title')}
          text={t('app:checkout.dialog.text')}
          onConfirm={handleConfirmation}
          confirmText={t('app:error_dialog.confirm_text').toLocaleUpperCase()}
        />
      ) }
    </Box>
  )
}

export default CheckoutForm
