import { useTranslation } from 'react-i18next'

import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import makeStyles from '@material-ui/core/styles/makeStyles'

import BatteryHelper from 'spypoint/src/cameras-module/BatteryHelper'
import CButton from 'storybook-component-module/src/components/buttons/CButton'

const useStyles = makeStyles(theme => ({
  dialog: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
    '& .MuiDialog-paper': {
      display: 'flex',
      alignItems: 'center',
      alignSelf: 'center',
      padding: '1rem 3.5rem !important',
      background: theme.palette.background?.contrastText,
      overflowX: 'hidden',
    },
    '& .MuiDialog-paperWidthXs': {
      maxWidth: 375,
      minWidth: 375,
    },
    '& .MuiDialog-scrollPaper': {
      alignItems: 'flex-start',
      [theme.breakpoints.down('sm')]: {
        alignItems: 'center',
      },
    },
  },
  dialogActions: {
    width: '100%',
    padding: '1rem 0 !important',
  },
  confirmButton: {
    width: '100%',
  },
}))

const BatteryHelperDialog = ({ isOpen, onConfirm }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Dialog open={isOpen} maxWidth="xs" fullWidth disableEscapeKeyDown className={classes.dialog}>
      <DialogContent>
        <BatteryHelper />
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <Grid container display="flex" flexdirection="row" justifyContent="flex-end" spacing={0}>
          <Grid item xs={12}>
            <CButton color="primary" variety="primary" className={classes.confirmButton} onClick={onConfirm}>
              { t('add_camera.battery_helper.action') }
            </CButton>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default BatteryHelperDialog
