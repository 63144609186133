import { Redirect, Route } from 'react-router-dom'
import userState from 'user-module/user/core/userState'
import useUser from 'user-module/user/core/useUser'

const SpypointPrivateRoute = ({ component: Component, ...options }) => {
  const user = useUser()
  const redirectDestination = '/login'
  const render = props => userState.isReady(user)
    ? <Component {...props} {...options} />
    : <Redirect to={{ pathname: redirectDestination, search: options.location?.search || '', state: { from: props.location } }} />

  return <Route {...options} render={render} />
}

export default SpypointPrivateRoute
