import downloadStatus from 'photo-module/download/downloadStatus'
import SpypointVideoHdAvailableButton from '../buttons/SpypointVideoHdAvailableButton'
import SpypointVideoHdErrorButton from '../buttons/SpypointVideoHdErrorButton'
import SpypointVideoHdPendingButton from '../buttons/SpypointVideoHdPendingButton'
import SpypointVideoHdRequestableButton from '../buttons/SpypointVideoHdRequestableButton'
import SpypointVideoHdUnavailableButton from '../buttons/SpypointVideoHdUnavailableButton'

const SpypointVideoHdButton = ({ photo }) => {
  const iconByState = {
    [downloadStatus.requestable]: <SpypointVideoHdRequestableButton />,
    [downloadStatus.pending]: <SpypointVideoHdPendingButton />,
    [downloadStatus.available]: <SpypointVideoHdAvailableButton />,
    [downloadStatus.unavailable]: <SpypointVideoHdUnavailableButton />,
    [downloadStatus.error]: <SpypointVideoHdErrorButton />,
  }
  return iconByState[photo.hdVideoStatus] || null
}

export default SpypointVideoHdButton
