import { useTranslation } from 'react-i18next'
import Amount from 'shared-module/components/amount/Amount'
import CartPriceLine from '../cart-lines/CartPriceLine'
import CartTitleLine from '../cart-lines/CartTitleLine'
import ItemDiscountLine from '../cart-lines/ItemDiscountLine'
import LineTitle from '../cart-lines/LineTitle'

const InsidersClubInCart = ({
  product,
  currency,
  checkout,
  discount,
}) => {
  const { t } = useTranslation()
  return (
    <>
      <CartTitleLine>
        { t('app:checkout.club_membership_title') }
      </CartTitleLine>
      { checkout && (
        <LineTitle full>
          { t('app:checkout.renew_yearly_text') + ' ' }
          <Amount value={product.basePrice} currencyCode={currency} />
        </LineTitle>
      ) }
      { discount && (
        <ItemDiscountLine
          product={product}
          currency={currency}
        />
      ) }
      <CartPriceLine
        value={product.discountPrice ?? product.basePrice}
        currencyCode={currency}
      />
    </>
  )
}
export default InsidersClubInCart
