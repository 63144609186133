import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const ExpandedIcon = (props) => {
  const theme = useTheme()

  return (
    <SvgIcon {...props} height="10" width="16" viewBox="0 0 16 10" data-testid="ExpandedIcon">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5379 8.78791C15.1718 9.15403 14.5782 9.15403 14.2121 8.78791L8 2.57582L1.78791 8.78791C1.4218 9.15403 0.828203 9.15403 0.462088 8.78791C0.0959708 8.42179 0.0959708 7.8282 0.462088 7.46209L7.33709 0.587087C7.7032 0.220971 8.29679 0.220971 8.66291 0.587087L15.5379 7.46209C15.904 7.8282 15.904 8.4218 15.5379 8.78791Z" fill="#F5F4F4" />
    </SvgIcon>
  )
}

export default ExpandedIcon
