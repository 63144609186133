import { makeStyles } from '@material-ui/core/styles'
import ListItemText from '@material-ui/core/ListItemText'

const useStyles = makeStyles({
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  iconWrapper: {
    position: 'relative',
    height: '22px',
    width: '22px',
    marginLeft: '15px',
    overflow: 'hidden',
  },
  icon: {
    position: 'absolute',
    bottom: '0',
    width: '70%',
  },
  name: {
    marginLeft: '15px',
  },
})

const CameraModelRow = ({ name, icon }) => {
  const classes = useStyles()

  return (
    <div className={classes.row}>
      <div className={classes.iconWrapper}><img alt={name} src={icon} className={classes.icon} /></div>
      <ListItemText className={classes.name} primary={name} />
    </div>
  )
}

export default CameraModelRow
