import React, { ReactNode } from 'react'

import 'swiper/swiper-bundle.min.css'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, * as SwiperComponents from 'swiper'

import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

export interface ICMobileSwiper {
  slides: Array<any>
  children: ReactNode
  className?: string
  centeredSlides?: boolean
  slideToClickedSlide?: boolean
  clickablePagination?: boolean
  breakpoints?: { [width: number]: SwiperComponents.SwiperOptions; [ratio: string]: SwiperComponents.SwiperOptions }
  onReachEnd?: () => void
  setSwiperInstance?: (any) => {}
}

const useStyles = makeStyles(theme => ({
  swiper: {
    '&.swiper-container': {
      height: '17.5rem',
      width: '100vw',
      margin: '2rem 0rem',
    },
    '& .swiper-slide-prev > div': {
      gap: '0.75rem',
      marginTop: '1.566rem',
      maxHeight: '12.25rem',
    },
    '& .swiper-slide-next > div': {
      gap: '0.75rem',
      marginTop: '1.566rem',
      maxHeight: '12.25rem',
    },
    '& .swiper-pagination': {
      bottom: '-0.25rem',
    },
    '& .swiper-pagination-bullet': {
      opacity: 1,
      width: '0.375rem',
      height: '0.375rem',
      background: theme.palette.secondary.light,
    },
    '& .swiper-pagination-bullet-active': {
      width: '0.375rem',
      height: '0.375rem',
      transition: 'width 0.5s',
      borderRadius: '0.375rem',
      backgroundColor: theme.palette.primary.main + ' !important',
      border: '1px solid transparent',
    },
  },
}))

SwiperCore.use([SwiperComponents.Pagination])

const CMobileSwiper = (props: ICMobileSwiper) => {
  const classes = useStyles()
  const Children: ReactNode = props.children

  return (
    <Swiper
      // @ts-ignore: External Error from Swiper lib for module types
      modules={[SwiperComponents.Pagination]}
      centeredSlides={props.centeredSlides}
      slideToClickedSlide={props.slideToClickedSlide || false}
      pagination={{ clickable: props.clickablePagination || false }}
      breakpoints={props.breakpoints || {
        320: { slidesPerView: 1.65, spaceBetween: 8 },
        360: { slidesPerView: 1.9, spaceBetween: 8 },
        375: { slidesPerView: 1.95, spaceBetween: 8 },
        410: { slidesPerView: 2.1, spaceBetween: 10 },
        425: { slidesPerView: 2.15, spaceBetween: 10 },
        500: { slidesPerView: 2.5, spaceBetween: 10 },
      }}
      className={props.className ? clsx(classes.swiper, props.className) : classes.swiper}
      onReachEnd={props.onReachEnd}
      onSwiper={(swiper) => props.setSwiperInstance && props.setSwiperInstance(swiper)}
    >
      { props.slides?.map((slide, index) => (
        <SwiperSlide key={index}>
          { /* @ts-ignore: No constructor needed for children rendering */ }
          <Children slide={slide} />
        </SwiperSlide>
      )) }
    </Swiper>
  )
}

export default CMobileSwiper
