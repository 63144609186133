export const clearEcommerce = { ecommerce: null }

export const buildPrice = (item) => item.subTotal

export const buildItems = (successItems, cartItems) => (
  successItems.reduce((ecommerceItems, successItem) => {
    const item = cartItems.products.find(({ id }) => id === successItem.itemId)
    if (item) {
      ecommerceItems.push(
        {
          item_id: item.id,
          item_name: item.productId,
          price: buildPrice(item),
          currency: item.currency,
          quantity: item.quantity,
          item_variant: successItem?.data?.invoiceId,
        },
      )
    }
    return ecommerceItems
  }, [])
)

export const prepareGTMData = (successItems, cartItems, user) => {
  const ecommerceItems = buildItems(successItems, cartItems)
  if (ecommerceItems.length === 0) return null
  return {
    event: 'purchase',
    ecommerce: {
      transaction_id: `${user.id}-${Date.now()}`,
      value: ecommerceItems.reduce((sum, { price }) => sum + price, 0),
      currency: user.currencyCode,
      items: ecommerceItems,
    },
  }
}
