import { setLocale } from 'yup'

export default (() => {
  const localePrefix = 'form.validation.'

  const keys = {
    MIXED_DEFAULT: localePrefix + 'default',
    MIXED_REQUIRED: localePrefix + 'required',
    MIXED_ONE_OF: localePrefix + 'one_of',
    MIXED_NOT_ONE_OF: localePrefix + 'not_one_of',

    STRING_LENGTH: localePrefix + 'string.length',
    STRING_MIN: localePrefix + 'string.min',
    STRING_MAX: localePrefix + 'string.max',
    STRING_MATCHES: localePrefix + 'string.matches',
    STRING_EMAIL: localePrefix + 'string.email',
    STRING_URL: localePrefix + 'string.url',
    STRING_TRIM: localePrefix + 'string.trim',
    STRING_LOWERCASE: localePrefix + 'string.lowercase',
    STRING_UPPERCASE: localePrefix + 'string.uppercase',

    NUMBER_MIN: localePrefix + 'number.min',
    NUMBER_MAX: localePrefix + 'number.max',
    NUMBER_LESS_THAN: localePrefix + 'number.less_than',
    NUMBER_MORE_THAN: localePrefix + 'number.more_than',
    NUMBER_POSITIVE: localePrefix + 'number.positive',
    NUMBER_NEGATIVE: localePrefix + 'number.negative',
    NUMBER_INTEGER: localePrefix + 'number.integer',

    DATE_MIN: localePrefix + 'date.min',
    DATE_MAX: localePrefix + 'date.max',

    ARRAY_MIN: localePrefix + 'array.min',
    ARRAY_MAX: localePrefix + 'array.max',
  }

  const yupLocale = {
    mixed: {
      default: () => ({ key: keys.MIXED_DEFAULT }),
      required: ({ key: keys.MIXED_REQUIRED }),
      oneOf: ({ values }) => ({ key: keys.MIXED_ONE_OF, values: { values } }),
      notOneOf: ({ values }) => ({ key: keys.MIXED_NOT_ONE_OF, values: { values } }),
      notType: ({ key: keys.MIXED_DEFAULT }),
    },
    string: {
      length: ({ length }) => ({ key: keys.STRING_LENGTH, values: { length } }),
      min: ({ min }) => ({ key: keys.STRING_MIN, values: { min } }),
      max: ({ max }) => ({ key: keys.STRING_MAX, values: { max } }),
      matches: ({ regex }) => ({ key: keys.STRING_MATCHES, values: { regex } }),
      email: ({ regex }) => ({ key: keys.STRING_EMAIL, values: { regex } }),
      url: ({ regex }) => ({ key: keys.STRING_URL, values: { regex } }),
      trim: ({ key: keys.STRING_TRIM }),
      lowercase: ({ key: keys.STRING_LOWERCASE }),
      uppercase: ({ key: keys.STRING_UPPERCASE }),
    },
    number: {
      min: ({ min }) => ({ key: keys.NUMBER_MIN, values: { min } }),
      max: ({ max }) => ({ key: keys.NUMBER_MAX, values: { max } }),
      lessThan: ({ less }) => ({ key: keys.NUMBER_LESS_THAN, values: { less } }),
      moreThan: ({ more }) => ({ key: keys.NUMBER_MORE_THAN, values: { more } }),
      positive: ({ more }) => ({ key: keys.NUMBER_POSITIVE, values: { more } }),
      negative: ({ less }) => ({ key: keys.NUMBER_NEGATIVE, values: { less } }),
      integer: ({ key: keys.NUMBER_INTEGER }),
    },
    date: {
      min: ({ min }) => ({ key: keys.DATE_MIN, values: { min } }),
      max: ({ max }) => ({ key: keys.DATE_MAX, values: { max } }),
    },
    object: {
      noUnknown: ({ key: keys.MIXED_DEFAULT }),
    },
    array: {
      min: ({ min }) => ({ key: keys.ARRAY_MIN, values: { min } }),
      max: ({ max }) => ({ key: keys.ARRAY_MAX, values: { max } }),
    },
  }

  setLocale(yupLocale)
})()
