import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'
import { getTranslationKeyByErrorMessage } from 'shared-module/api/errorMessagesHelper'
import AppButton from 'shared-module/components/button/AppButton'
import { useFormField } from 'shared-module/new-components/form/FormDataContext'
import FormTextField from 'shared-module/new-components/form/FormTextField'
import { useCart } from '../../core/cart.hooks'

const OldCartPromoCode = () => {
  const useStyles = makeStyles((theme) => ({
    paperWrapper: {
      padding: 20,
      paddingBottom: 20,
      marginBottom: 20,
      width: '100%',
      background: theme.palette.primary.contrastText,
    },
  }))
  const { applyPromoCode, cartError, cartMessage, clearError } = useCart()
  const promocode = useFormField('cartPromocode')

  const handleOnClick = () => {
    if (promocode.value) {
      applyPromoCode(promocode.value)
    }
  }
  const handleChange = e => {
    promocode.onChange(e)
    if (cartMessage) {
      clearError()
    }
  }
  const getMessage = () => {
    if (cartMessage && !cartError) return t(getTranslationKeyByErrorMessage(cartMessage))
    else return null
  }

  const handleError = () => {
    if (cartError && promocode.value) {
      return { key: getTranslationKeyByErrorMessage(cartMessage) }
    } else return null
  }

  const { t } = useTranslation()
  const classes = useStyles()
  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      justifyContent="center"
      className={classes.paperWrapper}
      direction="row"
    >
      <Grid item xs={12}>
        <Typography variant="h2">
          { t('app:promo_code.label') }
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <FormTextField
          fullWidth
          id={promocode.name}
          name={promocode.name}
          label={t('app:promo_code.instruction')}
          value={promocode.value || ''}
          inputRef={promocode.inputRef}
          error={handleError()}
          hint={getMessage()}
          onChange={handleChange}
          FormHelperTextProps={{
            style: {
              height: 0,
              minWidth: '150%',
            },
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <AppButton
          fullWidth
          color="primary"
          variant="outlined"
          value={t('app:promo_code.apply_button')}
          onClick={handleOnClick}
          style={{ whiteSpace: 'nowrap' }}
        />
      </Grid>
    </Grid>
  )
}

export default OldCartPromoCode
