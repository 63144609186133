import { useTranslation } from 'react-i18next'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContentText from '@material-ui/core/DialogContentText'
import PrimaryButton from 'shared-module/components/button/PrimaryButton'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles(theme => ({
  dialog: {
    '& .MuiDialog-paper': {
      padding: '1rem !important',
      borderRadius: 'unset',
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
        padding: '0.125rem !important',
      },
    },
  },
  dialogActions: {
    padding: '1rem 1rem 0rem 1rem !important',
  },
  dialogTitle: {
    paddingTop: '1rem',
    paddingBottom: '0px',
    fontSize: '1.75rem',
  },
  confirmButton: {
    flex: '1 1 100%',
    marginBottom: '32px',
    marginTop: '8px',
    [theme.breakpoints.up('lg')]: {
      minWidth: '33%',
      flexWrap: 'nowrap',
    },
  },
  contentText: {
    marginTop: '8px',
    textAlign: 'center',
  },
  cancelButton: {
    color: '#B1AFAF',
    fontSize: '1rem',
    fontWeight: '500',
  },
}))

const SpypointPlansDialog = ({ open, onClose, title, text, onConfirm, confirmText, onCancel, cancelText }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Dialog open={open} onClose={onClose} width="548px" className={classes.dialog}>
      <DialogTitle className={classes.dialogTitle}>{ title }</DialogTitle>
      { text && (
        <DialogContentText className={classes.contentText} i18nkey="multiline">{ text }</DialogContentText>
      ) }
      <DialogActions className={classes.dialogActions}>
        <Grid container display="flex" justifyContent="center">

          { onConfirm && (
            <Grid item className={classes.confirmButton}>
              <PrimaryButton onClick={onConfirm} value={confirmText}>{ confirmText }</PrimaryButton>
            </Grid>
          ) }
          <Grid item>
            <Button variant="text" onClick={onCancel} value={cancelText || t('button.cancel')} className={classes.cancelButton}>{ cancelText || t('button.cancel') }</Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default SpypointPlansDialog
