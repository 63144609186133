import SvgIcon from '@material-ui/core/SvgIcon'

const UploadIcon = (props) => (
  <SvgIcon {...props} data-testid="uploadIcon">
    <svg width="24" height="22" viewBox="0 0 26 34">
      <path
        d="M15.918 4L25.8164 13.8984H19.9922V23.6055H11.8438V13.8984H6.01953L15.918 4ZM27.5664 22.293V26.3398H4.21484V22.293H2V28.6094H29.8359V22.293H27.5664Z"
        fill="#f5f4f4"
      />
    </svg>
  </SvgIcon>
)

export default UploadIcon
