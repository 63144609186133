import eCommerceApi from 'ecommerce-module/api/eCommerceApi'
import { eCommerceRefreshTokenID, eCommerceTokenID } from 'ecommerce-module/core/eCommerce.selectors'
import { SET_CAMERA_PLANS } from 'vosker/src/camera-module/transmission-plan/core/cameraPlans.actions'
import messageActions from 'shared-module/message/messageActions'

export const SET_PRODUCTS = 'SET_PRODUCTS'

const setProducts = products => ({ type: SET_PRODUCTS, products })
const setPlans = plans => ({ type: SET_CAMERA_PLANS, plans })
const authenticateUser = () => eCommerceApi
  .authenticate()
  .then(data => {
    localStorage.setItem(eCommerceTokenID, data.access_token)
    localStorage.setItem(eCommerceRefreshTokenID, data.refresh_token)
  })
  .catch(error => error)

const fetchProducts = () => dispatch => eCommerceApi.fetchProducts()
  .then(plans => dispatch(setProducts({ plans: plans })))
  .catch(error => error)
const fetchPlans = () => dispatch => eCommerceApi.fetchPlans()
  .then(plans => dispatch(setPlans({ plans: plans })))
  .catch(error => error)
const createBaskets = () => eCommerceApi
  .createBasket()
  .then(data => data)
  .catch(error => error)

const addItemsToBasket = (basketId, Item) => eCommerceApi
  .addItemToBasket(basketId, Item)
  .then(data => data)
  .catch(error => error)

const getBasket = (basketId) => eCommerceApi
  .getBasket(basketId)
  .then(data => data)
  .catch(error => error)

const deleteBasket = (storedBasketId) => eCommerceApi
  .deleteBasket(storedBasketId)
  .then(data => data)
  .catch(error => error)

const deleteItemFromBasket = (storedBasketId, item) => eCommerceApi
  .deleteItemFromBasket(storedBasketId, item)
  .then(data => data)
  .catch(error => error)

const proceedToPayment = (camera, user, action, data) => async dispatch => {
  const auth = localStorage.getItem(eCommerceTokenID)
  const redirectionURL = window.location.origin + `/camera/${camera.id}`
  const countryCode = user.country === 'US' || user.country === 'CA' ? user.country.toLowerCase() : 'ca'
  const language = user.language === 'en' || user.language === 'fr' ? user.language : 'en'

  const origin = window.location.hostname === 'localhost'
    ? 'dev.spypoint'
    : window.location.hostname.replace('webapp', '').replace('.com', '').substring(1)

  const sfccOrigin = `https://cc.${origin}.com/${countryCode}/${language}/checkout/external`
  const params = `auth=${auth}&url=${redirectionURL}`

  await action(data)
    .then(() => window.location.assign(`${sfccOrigin}?${params}`))
    .catch(() => dispatch(messageActions.showError('errors.catch_all')))
}

const eCommerceActions = { authenticateUser, fetchProducts, setPlans, fetchPlans, setProducts, createBaskets, addItemsToBasket, getBasket, deleteBasket, deleteItemFromBasket, proceedToPayment }
export default eCommerceActions
