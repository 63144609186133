import { INSIDERS_CLUB_MEMBERSHIP, PHOTO_TRANSMISSION_PLAN } from 'spypoint/src/checkout-module/order/core/productTypes'

const ITEM_EXISTS = 'Item already exists in the cart'
const USER_ALREADY_CLUB_MEMBER = 'User already a club member'
const PROMOCODE_NO_ITEMS_FOUND = 'No items found for promocode'
const PROMOCODE_NOT_ENABLED = 'Promocode not enabled'
const PROMOCODE_NOT_VALID = 'Promocode not valid'
export const PROMOCODE_SUCCESS = 'Promocode success'

const getTranslationKeyByErrorMessage = (message, productId) => {
  switch (message) {
    case USER_ALREADY_CLUB_MEMBER:
      return 'app:checkout_process.insiders_club_subscription.member_error_text'
    case ITEM_EXISTS:
      if (productId === PHOTO_TRANSMISSION_PLAN) {
        return 'app:checkout_process.photo_transmission_plan.error_text'
      } else if (productId === INSIDERS_CLUB_MEMBERSHIP) {
        return 'app:checkout_process.insiders_club_subscription.subs_error_text'
      }
      return message
    case PROMOCODE_NO_ITEMS_FOUND:
      return 'app:promo_code.error.no_items'
    case PROMOCODE_NOT_ENABLED:
      return 'app:promo_code.error.not_enabled'
    case PROMOCODE_NOT_VALID:
      return 'app:promo_code.error.not_recognized'
    case PROMOCODE_SUCCESS:
      return 'app:promo_code.success'
    default:
      return message
  }
}

export { getTranslationKeyByErrorMessage }
