import clsx from 'clsx'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { Typography, useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import CMobileSwiper from 'storybook-component-module/src/components/carousels/CMobileSwiper'

const useStyles = makeStyles(theme => ({
  carousel: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    margin: '3rem 0 ',
    flexWrap: 'noWrap',
    [theme.breakpoints.down('sm')]: {
      margin: '2rem 0',
      flexWrap: 'wrap',
    },
  },
  card: {
    gap: '1rem',
    margin: '0 0.688rem',
    paddingBottom: '1rem',
    width: '11rem',
    height: '14.25rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: theme.palette.background?.viewer + '80',
    boxShadow: `0rem 0rem 0.625rem 0rem ${theme.palette.basic?.black}80`,
    [theme.breakpoints.down('md')]: {
      margin: '0.5rem',
    },
    '&:hover': {
      cursor: 'pointer',
      [theme.breakpoints.down('xs')]: {
        cursor: 'ew-resize',
      },
    },
    [theme.breakpoints.down('xs')]: {
      cursor: 'ew-resize',
    },
  },
  activeCard: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  imgWrapper: {
    width: '95%',
    height: '100%',
    margin: '0.25rem 0.25rem 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.basic?.black,
  },
  image: {
    height: '9.25rem',
    padding: '1.5rem',
  },
  name: {
    fontWeight: 'bold',
  },
}))

const CameraModelTile = ({ models, activatedModel, setActivatedModel }) => {
  const theme = useTheme()
  const classes = useStyles()
  const isMobileResolution = useMediaQuery(theme.breakpoints.down('xs'))

  const getCardStyle = model => activatedModel && activatedModel === model
    ? clsx(classes.card, classes.activeCard)
    : classes.card

  const handleSelection = model => setActivatedModel(activatedModel === undefined || activatedModel.name !== model.name ? model : undefined)

  const renderTileContent = props => {
    const model = isMobileResolution ? props.slide : props

    return (
      <Grid item xs="auto" key={model.name} className={getCardStyle(model)} onClick={() => handleSelection(model)}>
        <Box className={classes.imgWrapper}>
          <img alt={model.name} src={model.icon} className={classes.image} />
        </Box>

        <Typography variant="h3" className={classes.name}>{ model.name }</Typography>
      </Grid>
    )
  }

  return isMobileResolution
    ? <CMobileSwiper centeredSlides slideToClickedSlide slides={models}>{ renderTileContent }</CMobileSwiper>
    : <Grid container className={classes.carousel}>{ models?.map(model => renderTileContent(model)) }</Grid>
}

export default CameraModelTile
