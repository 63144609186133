import { Trans, useTranslation } from 'react-i18next'
import { Redirect, useHistory, useLocation } from 'react-router-dom'

import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import { makeStyles, Typography } from '@material-ui/core'

import CameraIcon from 'assets/icons/navigation/CameraIcon'
import CAppBar from 'storybook-component-module/src/components/containers/CAppBar'
import CButton from 'storybook-component-module/src/components/buttons/CButton'
import { useActivatedCamera } from 'camera-module/new-activation/core/activatedCamera.hook'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 175,
    marginBottom: 50,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'baseline',
    [theme.breakpoints.up('md')]: {
      '&.MuiContainer-maxWidthSm': {
        maxWidth: '645px !important',
        minWidth: '645px !important',
      },
      '& .MuiContainer-maxWidthSm': {
        maxWidth: '645px !important',
        minWidth: '645px !important',
      },
    },
    [theme.breakpoints.up('sm')]: {
      '&.MuiContainer-maxWidthSm': {
        paddingLeft: 0 + ' !important',
        paddingRight: 0 + ' !important',
      },
      '& .MuiContainer-maxWidthSm': {
        padding: '2rem 6rem !important',
      },
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 150,
      '&.MuiContainer-maxWidthSm': {
        paddingLeft: 0 + ' !important',
        paddingRight: 0 + ' !important',
      },
    },
  },
  innerContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'baseline',
    justifyContent: 'space-evenly',
    padding: '1rem',
    margin: '2rem 1rem',
    background: theme.palette.background.paper + '4C', // HEX Opacity prefix
    [theme.breakpoints.down('sm')]: {
      height: '22rem',
      margin: '2rem 0rem',
      padding: '47px 2rem',
    },
    '& > h1': {
      [theme.breakpoints.down('xs')]: {
        paddingBottom: '28px',
      },
    },
  },
  box: {
    gap: '1rem',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: '0 1rem',
    },
  },
  bold: {
    fontWeight: 'bold',
  },
  backButton: {
    top: 42,
    position: 'relative',
    marginBottom: '1.5rem',
    '& .MuiSvgIcon-root': {
      position: 'relative',
      left: '0.25rem',
    },
    [theme.breakpoints.down('sm')]: {
      left: '1rem',
      marginBottom: '2rem',
    },
  },
  paddingBottom: {
    paddingBottom: '28px',
  },
}))

const ActivationConfirmationPanel = () => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()
  const activatedCamera = useActivatedCamera()
  const isFlexM = activatedCamera?.status?.model === 'FLEX-M'
  const location = useLocation()
  const currentPath = location.pathname
  const addAnotherAction = () => history.push('/camera-activation')
  const newPath = activatedCamera ? `/camera/${activatedCamera?.id}` : '/'
  const completeActivation = () => history.push(newPath, { ActivationPath: currentPath })

  return activatedCamera
    ? (
      <Container maxWidth="sm" className={classes.root}>
        <CAppBar Icon={CameraIcon} title={t('menu.add_camera')} />

        <Container
          maxWidth="sm"
          className={classes.innerContainer}
          style={{ height: activatedCamera.isCellular ? '16.375rem' : '6.75rem' }}
        >
          <Typography variant="h1">
            { isFlexM ? t('app:active_device.steps.confirmation.header_flexM') : t('app:active_device.steps.confirmation.header') }
          </Typography>
          { activatedCamera.isCellular && (
            <>
              <Typography variant="body1" className={classes.paddingBottom}>
                { t('app:active_device.steps.confirmation.body_first') }
              </Typography>
              <Typography variant="body1">
                { !isFlexM && <span>{ t('app:active_device.important') }</span> }  { isFlexM ? <><span role="img" aria-label="warning">⚠️</span> <Trans i18nKey="app:active_device.steps.confirmation.body_last_flexM" /> </> : t('app:active_device.steps.confirmation.body_last') }
              </Typography>
            </>

          ) }
        </Container>

        <Grid container className={classes.box}>
          <Grid item xs={12} sm="auto">
            <CButton color="primary" variety="secondary" onClick={addAnotherAction} style={{ width: '100%' }}>
              { t('app:active_device.steps.confirmation.add_another') }
            </CButton>
          </Grid>

          <Grid item xs={12} sm="auto">
            <CButton color="primary" variety="primary" onClick={completeActivation} style={{ width: '100%' }}>
              { t('app:active_device.steps.confirmation.confirmation') }
            </CButton>
          </Grid>
        </Grid>
      </Container>
      )
    : <Redirect to="/" />
}

export default ActivationConfirmationPanel
