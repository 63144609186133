import IconButton from '@material-ui/core/IconButton'
import ReplayIcon from 'assets/icons/video/ReplayIcon'
import { useTranslation } from 'react-i18next'
import SpypointVideoHdIcon from './SpypointVideoHdIcon'

const SpypointPreviewAgainButton = ({ onClick }) => {
  const { t } = useTranslation()

  return (
    <IconButton color="secondary" onClick={onClick}>
      <SpypointVideoHdIcon icon={ReplayIcon}>
        { t('app:video_viewer.button.preview_again').toUpperCase() }
      </SpypointVideoHdIcon>
    </IconButton>
  )
}

export default SpypointPreviewAgainButton
