import SvgIcon from '@material-ui/core/SvgIcon'

const CheckoutPageIcon = (props) => (
  <SvgIcon {...props} data-testid="CheckoutPageIcon" width="24" height="18" viewBox="0 0 24 18" fill="none">
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.87868 0.878679C0.316071 1.44129 0 2.20435 0 3V4.5H24V3C24 2.20435 23.6839 1.44129 23.1213 0.878679C22.5587 0.316071 21.7956 0 21 0H3C2.20435 0 1.44129 0.316071 0.87868 0.878679ZM0 15V7.5H24V15C24 15.7956 23.6839 16.5587 23.1213 17.1213C22.5587 17.6839 21.7956 18 21 18H3C2.20435 18 1.44129 17.6839 0.87868 17.1213C0.316071 16.5587 0 15.7956 0 15ZM3.43934 10.9393C3.72064 10.658 4.10218 10.5 4.5 10.5H6C6.39782 10.5 6.77936 10.658 7.06066 10.9393C7.34196 11.2206 7.5 11.6022 7.5 12V13.5C7.5 13.8978 7.34196 14.2794 7.06066 14.5607C6.77936 14.842 6.39782 15 6 15H4.5C4.10218 15 3.72064 14.842 3.43934 14.5607C3.15804 14.2794 3 13.8978 3 13.5V12C3 11.6022 3.15804 11.2206 3.43934 10.9393Z"
      />
    </g>
  </SvgIcon>
)

export default CheckoutPageIcon
