import SvgIcon from '@material-ui/core/SvgIcon'

const HdPackageIcon = (props) => (
  <SvgIcon {...props} data-testid="HdPackageIcon">
    <g>
      <path d="M14.793 12.254v5.937h1.16c.656 0 1.047-.324 1.047-1.222v-3.496c0-.899-.39-1.22-1.047-1.22zm0 0" />
      <path
        d="M19.074 2.25H5.031A2.023 2.023 0 003 4.262v15.476c0 1.11.91 2.012 2.031 2.012h14.043a2.023 2.023 0 002.031-2.012V4.262c0-1.11-.91-2.012-2.03-2.012zM5.004 9.156h1.168v-1.75h1.281V6.234H6.172V5.156h1.516V4.012H5.003zm12.582-1.144v-4h-1.168v5.144h2.512V8.012zM7.012 19.988H5.004v-9.531h2.008v3.867h2.277v-3.867h2.04v9.531h-2.04v-3.867H7.012zm5.722-9.531h3.258c2.059 0 3.067 1.094 3.067 3.105v3.317c0 2.016-1.008 3.11-3.067 3.11h-3.258zm1.27-6.445h-1.168v5.144h2.512V8.012h-1.344zm-3.348 0h1.172v3.715c0 .941-.625 1.566-1.605 1.566S8.62 8.668 8.62 7.727V4.012H9.79v3.715c0 .253.156.421.434.421.277 0 .433-.168.433-.421zm0 0"
        fillRule="evenodd"
      />
    </g>
  </SvgIcon>
)

export default HdPackageIcon
