import { Box } from '@material-ui/core'

import { FILTER_SPYPOINT_ICON_BY_KEY } from '../core/spypointFilters.types'
import SpypointFilterCategory from './SpypointFilterCategory'
import SpypointFilterItem from './SpypointFilterItem'

const SpypointFilterList = ({
  filterKeys,
  filterValues,
}) => {
  return (
    <Box display="flex" flexWrap="wrap" alignItems="center" margin="10px 20px 10px">
      { filterValues && filterValues.map((filterValue, index) => {
        return filterValue.length === 1
          ? (
            <SpypointFilterItem
              key={filterValue}
              Icon={FILTER_SPYPOINT_ICON_BY_KEY[filterKeys[index]]}
              hasCategory={false}
              label={filterValue[0]}
            />
            )
          : (
            <SpypointFilterCategory
              key={filterValue}
              Icon={FILTER_SPYPOINT_ICON_BY_KEY[filterKeys[index]]}
              label={filterKeys[index]}
              values={filterValue}
            />
            )
      }) }
    </Box>
  )
}

export default SpypointFilterList
