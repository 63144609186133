import IconButton from '@material-ui/core/IconButton'
import Badge from '@material-ui/core/Badge'
import { makeStyles } from '@material-ui/core/styles'
import ShoppingCart from '@material-ui/icons/ShoppingCart'
import { useCart } from 'spypoint/src/checkout-module/order/core/cart.hooks'
import useUser from 'user-module/user/core/useUser'

const useStyles = makeStyles(theme => ({
  badge: {
    top: 3,
    right: 2,
    fontFamily: theme.typography.h1.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    padding: '0 4px',
  },
}))

const SpypointCartButton = () => {
  const classes = useStyles()
  const { cartQuantity, navigateToCart } = useCart()
  const user = useUser()
  const displayCartItemNumber = () => cartQuantity < 10 ? cartQuantity : '..'
  if (!user.cartEnabled) {
    return null
  }

  return (
    <IconButton
      onClick={navigateToCart}
      aria-label="cart"
      data-testid="sp-cart-button"
    >
      { cartQuantity > 0
        ? (
          <Badge
            badgeContent={displayCartItemNumber()}
            color="primary"
            classes={{ anchorOriginTopRightRectangular: classes.badge }}
            data-testid="sp-cart-button-count"
          >
            <ShoppingCart />
          </Badge>
          )
        : <ShoppingCart /> }
    </IconButton>
  )
}

export default SpypointCartButton
