import { useTranslation } from 'react-i18next'
import Amount from 'shared-module/components/amount/Amount'
import LineTitle from './LineTitle'

const CartPriceLine = ({ value, currencyCode }) => {
  const { t } = useTranslation()

  return (
    <>
      <LineTitle bold>{ t('app:checkout_process.price') }</LineTitle>
      <LineTitle bold align="right">
        <Amount value={value} currencyCode={currencyCode} />
      </LineTitle>
    </>
  )
}

export default CartPriceLine
