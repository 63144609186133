import LineTitle from './LineTitle'
import Amount from 'shared-module/components/amount/Amount'
import { useTranslation } from 'react-i18next'
import useUser from 'user-module/user/core/useUser'
import { useCart } from 'spypoint/src/checkout-module/order/core/cart.hooks'
import { Grid } from '@material-ui/core'

const CartTotalLine = () => {
  const { t } = useTranslation()
  const user = useUser()
  const { cartTotalBeforeTaxes } = useCart()
  return (
    <Grid container justifyContent="space-between" spacing={3}>
      { cartTotalBeforeTaxes >= 0 && (
        <>
          <LineTitle bold>{ t('app:checkout_process.cart.total_before_tax').toLocaleUpperCase() }</LineTitle>
          <LineTitle align="right" bold>
            <Amount value={cartTotalBeforeTaxes} currencyCode={user.currencyCode} />
          </LineTitle>
        </>
      ) }
    </Grid>
  )
}

export default CartTotalLine
