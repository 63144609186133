import { useTranslation } from 'react-i18next'
import CameraSettingsIcon from 'assets/icons/navigation/CameraSettingsIcon'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import SaveIcon from 'assets/icons/SaveIcon'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import AppBarTitle from 'shared-module/components/app-bar/AppBarTitle'
import SpypointAppBar from 'spypoint/src/shared-module/navigation/app-bar/SpypointAppBar'
import CloseIcon from '@material-ui/icons/Close'
import Button from '@material-ui/core/Button'
import ConfirmationDialog from 'shared-module/components/confirmation/ConfirmationDialog'
import useConfirmationDialogActions from 'shared-module/components/confirmation/useConfirmationDialogActions'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles((theme) => ({
  appbar: {
    boxShadow: 'none',
    background: theme.palette.background.paper,
    [theme.breakpoints.down('sm')]: {
      marginTop: '56px',
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: '64px',
    },
  },
  saveButton: {
    marginLeft: 7,
  },
}))

const ScheduleAppBar = ({ onSave, onCancel, hasChanges }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const cancelActions = useConfirmationDialogActions()
  const largeScreen = useMediaQuery(theme => theme.breakpoints.down('xs'))

  const onClose = () => {
    if (hasChanges) {
      cancelActions.open()
    } else {
      onCancel()
    }
  }

  return (
    <>
      <SpypointAppBar />
      <AppBar className={classes.appbar}>
        <Toolbar>
          <Grid container alignItems="flex-start">
            <AppBarTitle Icon={CameraSettingsIcon} title={t('menu.schedule')} />
          </Grid>
          <Grid container spacing={2} justifyContent="flex-end">
            <Button variant="text" onClick={onClose} id="cancelSchedule" style={{ marginRight: 0 }}>
              { !largeScreen && t('app:schedule.cancel') }
              <CloseIcon
                style={{
                  color: 'white',
                  stroke: 'white',
                }}
              />
            </Button>
            <Button id="saveSchedule" onClick={onSave}>
              { !largeScreen && t('app:schedule.save') }
              <SaveIcon className={classes.saveButton} id="saveSchedule" />
            </Button>
          </Grid>
        </Toolbar>
      </AppBar>
      <ConfirmationDialog
        open={cancelActions.isOpen}
        onClose={cancelActions.close}
        title={t('camera.settings.leaveGuard.title')}
        text={t('camera.settings.leaveGuard.content')}
        confirmText={t('button.confirm')}
        onConfirm={onCancel}
        onCancel={cancelActions.close}
      />
    </>
  )
}

export default ScheduleAppBar
