import HdPackageIcon from 'assets/icons/navigation/HdPackageIcon'
import InformationBarElement from 'photo-module/photos/ui/photos-gallery/information-bar/InformationBarElement'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import useUser from 'user-module/user/core/useUser'

const VideoCountElement = ({ style, className }) => {
  const user = useUser()
  const { t } = useTranslation()
  const videosCount = user.addOns.hdVideos
  const history = useHistory()

  return videosCount
    ? <InformationBarElement text={`${videosCount} ${t('user.hdVideos.left')}`} Icon={HdPackageIcon} style={{ ...style, borderRight: '0px' }} onClick={() => history.push('/cart?video_hd=true')} customHover className={className} />
    : null
}

export default VideoCountElement
