import BuckTrackerFr from 'spypoint/public/images/buck_tracker_fr.svg'
import BuckTrackerEn from 'spypoint/public/images/buck_tracker_en.svg'
import useLanguage from 'shared-module/components/language/useLanguage'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles({
  logo: {
    height: '32px',
    width: '100px',
    margin: '0 5px',
  },
})

const BuckTrackerLogo = () => {
  const classes = useStyles()
  const language = useLanguage()
  const isFrench = language === 'fr'

  return (
    <img
      className={classes.logo}
      src={isFrench ? BuckTrackerFr : BuckTrackerEn}
      alt="buck-trucker"
      data-testid="buck-trucker-test-id"
    />
  )
}

export default BuckTrackerLogo
