import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import IconNoPhoto from 'shared-module/components/IconNoPhoto'
import IconMore from 'camera-module/cameras/ui/cameras-page/icons/IconMore'
import BatteryIcon from 'camera-module/cameras/ui/cameras-page/icons/BatteryIcon'
import CellularSignalIcon from 'camera-module/cameras/ui/cameras-page/icons/CellularSignalIcon'
import MemoryIcon from 'camera-module/cameras/ui/cameras-page/icons/MemoryIcon'
import cameraActions from 'camera-module/camera/core/cameraActions'
import useConfirmationDialogActions from 'shared-module/components/confirmation/useConfirmationDialogActions'
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded'
import Tooltip from '@material-ui/core/Tooltip'
import { Typography } from '@material-ui/core'
import useQueryParams from 'shared-module/url/useQueryParams'
import useCameraModelsAndVariants from 'camera-module/model/core/useCameraModelsAndVariants'
import CameraExpirationNotice from 'camera-module/cameras/ui/cameras-page/camera-expiration-notice/CameraExpirationNotice'
import HspaSunsetBanners from 'spypoint/src/cameras-module/ui/cameras-page/hspa/HspaSunsetBanners'
import CircleAlert from 'assets/icons/CircleAlert'
import DeleteCameraDialog from './DeleteCameraDialog'
import ManagePlanButton from 'camera-module/cameras/ui/cameras-page/ManagePlanButton'
import CameraNotSyncNotice from 'camera-module/cameras/ui/cameras-page/camera-not-sync-notice/CameraNotSyncNotice'

const useStyles = makeStyles((theme) => ({
  tile: {
    float: 'left',
    position: 'relative',
    margin: '10px',
    width: '200px',
  },
  innerTile: {
    position: 'relative',
    height: '200px',
    '&:hover': {
      opacity: 0.8,
      cursor: 'pointer',
    },
  },
  cameraName: {
    position: 'absolute',
    zIndex: '20',
    maxWidth: 'calc(100% - 60px)',
    padding: '0px 10px 0px 10px',
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'noWrap',
  },
  cameraUpdate: {
    position: 'absolute',
    zIndex: '20',
    marginLeft: '175px',
  },
  updateTooltip: {
    fontSize: '16px',
  },
  photoWrapper: {
    position: 'relative',
    zIndex: '10',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    width: '200px',
    height: '200px',
    textAlign: 'center',
    overflow: 'hidden',
    background: theme.palette.background.tile,
  },
  photoWrapperNoImg: {
    position: 'relative',
    zIndex: '10',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '200px',
    height: '200px',
    textAlign: 'center',
    overflow: 'hidden',
    background: theme.palette.text.primary,
  },
  status: {
    display: 'flex',
    position: 'absolute',
    height: '30px',
    width: '200px',
    zIndex: '20',
    bottom: '0',
    justifyContent: 'space-around',
    alignItems: 'center',
    background: theme.palette.background.secondary,
  },
  tileMenuState: {
    position: 'absolute',
    right: '10px',
    top: '5px',
    zIndex: '20',
    height: '40px',
    width: '40px',
  },
  cameraImage: {
    display: 'block',
    width: '100px',
    margin: '0px auto !important',
    bottom: '25px',
    position: 'relative',
  },
  circleIcon: {
    display: 'block',
    padding: 2,
  },
  command: {
    marginTop: 5,
    height: '30px',
    background: theme.palette.background.secondary,
    width: '200px',
    '&:hover': {
      opacity: 0.8,
      cursor: 'pointer',
    },
  },
  managePlan: {
    width: '100%',
    height: '53px',
    background: theme.palette.background.tile,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

const SpypointCameraTile = ({ camera }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const [menuAnchorElement, setMenuAnchorElement] = useState(null)
  const queryParams = useQueryParams()
  const location = useLocation()
  const cameraModel = useCameraModelsAndVariants(camera.status.model)
  const isCameraOffline = camera.status?.permanentlyShutdown || false
  const deleteDialogActions = useConfirmationDialogActions()

  const handleCameraClick = () => {
    dispatch(cameraActions.set(camera))

    if (queryParams.transmission_plan === true) {
      showTransmissionPlan()
    } else {
      showCameraDashboard()
    }
  }

  const showTransmissionPlan = () => {
    history.push('/camera/' + camera.id + '/plan' + location.search)
  }

  const showCameraDashboard = () => {
    history.push('/camera/' + camera.id)
  }

  const openDeleteDialog = () => {
    setMenuAnchorElement(null)
    deleteDialogActions.open()
  }

  const openMenu = (e) => {
    e.stopPropagation()
    setMenuAnchorElement(e.currentTarget)
  }

  const renderCameraTileMessage = () => {
    return (
      <>
        <HspaSunsetBanners camera={camera} />
        <CameraExpirationNotice camera={camera} />

        { camera.status?.lastUpdate === undefined && camera.isCellular && <CameraNotSyncNotice /> }
      </>
    )
  }

  return (
    <div className={classes.tile}>
      <div id={camera.id} onClick={handleCameraClick} className={classes.innerTile}>
        <Typography title={camera.config.name} className={classes.cameraName}>{ camera.config.name }</Typography>
        { camera.status.updateInProgress && (
          <Tooltip title={t('camera.update_warning')} enterDelay={300} leaveDelay={300} classes={{ tooltip: classes.updateTooltip }}>
            <div className={classes.cameraUpdate}><ErrorRoundedIcon color="error" /></div>
          </Tooltip>
        ) }
        { cameraModel
          ? (
            <div className={classes.photoWrapper}>
              <img src={cameraModel.icon} className={classes.cameraImage} alt={camera.status.model} />
            </div>
            )
          : (
            <div className={classes.photoWrapperNoImg}>
              <IconNoPhoto />
            </div>
            ) }
        { /* Display status only for cell cam */ }
        { (camera.isCellular) && (
          <div className={classes.status}>
            <div id={'battery-status-' + camera.id}>
              <BatteryIcon
                battery={
                  camera.status.model === 'FLEX' && camera.status.batteryType === '12V'
                    ? camera.status.solarBattery
                    : camera.status.mainBattery
                }
                disabled={isCameraOffline}
              />
            </div>
            <div id={'cellular-signal-status-' + camera.id}>
              <CellularSignalIcon signal={camera.status.signal} disabled={isCameraOffline} />
            </div>
            <div id={'memory-status-' + camera.id}>
              <MemoryIcon memory={camera.status.memory} disabled={isCameraOffline} />
            </div>
            { camera?.status?.update &&
              Object.keys(camera.status.update).length !== 0 &&
              !camera.status.update.disabled && (
                <div id={'update-status-' + camera.id}>
                  <CircleAlert className={classes.circleIcon} />
                </div>
            ) }
          </div>
        ) }
        <IconButton id="camera-open-menu-button" className={classes.tileMenuState} onClick={openMenu}>
          <IconMore height="15" />
        </IconButton>
      </div>

      { (camera.isCellular) && <ManagePlanButton camera={camera} /> }

      { renderCameraTileMessage() }

      <Menu
        id="camera-menu"
        anchorEl={menuAnchorElement}
        open={Boolean(menuAnchorElement)}
        onClose={() => setMenuAnchorElement(false)}
      >
        <MenuItem onClick={openDeleteDialog}>{ t('camera.menu.delete') }</MenuItem>
      </Menu>
      <DeleteCameraDialog camera={camera} open={deleteDialogActions.isOpen} onClose={deleteDialogActions.close} />
    </div>
  )
}
export default SpypointCameraTile
