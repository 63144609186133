import { useEffect, useState } from 'react'
import useLanguage from 'shared-module/components/language/useLanguage'
import { Elements } from '@stripe/react-stripe-js'
import userPaymentInformationApi from 'spypoint/src/user-module/payment-methods/api/userPaymentMethods.api'
import { loadStripe } from '@stripe/stripe-js'

const StripeProvider = ({ children, options }) => {
  const [stripe, setStripe] = useState(null)
  const language = useLanguage()

  useEffect(() => {
    userPaymentInformationApi
      .getStripePublicKey()
      .then(publicKey => setStripe(loadStripe(publicKey, { locale: language })))
    // stripe does not support reloading when it is already load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return stripe && <Elements stripe={stripe} options={options}>{ children }</Elements>
}

export default StripeProvider
