import { WarningRounded } from '@material-ui/icons'
import { Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import clsx from 'clsx'
import { getFormattedDate } from 'shared-module/common-utils'
import { Trans } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  bannerHspaBefore: {
    height: 'auto',
    padding: '8px',
    fontSize: '13px',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  bannerHspaBeforeLink: {
    color: theme.palette.text.primary,
    textDecoration: 'underline',
    '&:hover': {
      color: theme.palette.text.primary,
      opacity: 0.7,
      cursor: 'pointer',
    },
  },
  centerText: {
    textAlign: 'center',
  },
}))

const HspaSunsetBanner = ({ notification, openDialog }) => {
  const classes = useStyles()
  const isRead = notification.read
  const expireOn = getFormattedDate(notification?.metadata?.expireOn)

  return (
    <Box
      className={classes.bannerHspaBefore}
      data-testid="hspa-sunset-banner"
      bgcolor={isRead ? 'background.secondary' : 'error.secondary'}
    >
      { !isRead && <WarningRounded fontSize="large" /> }
      <Box display="flex" flexDirection="column" ml={!isRead ? 1 : 0}>
        <Box textAlign={isRead ? 'center' : 'left'}>
          <Trans i18nKey="hspa.banner.sunset.content" />{ expireOn }
        </Box>
        <Link
          data-testid="hspa-dialog-link"
          className={clsx(classes.bannerHspaBeforeLink, isRead && classes.centerText)}
          onClick={openDialog}
        >
          <Trans i18nKey="hspa.banner.sunset.link" />
        </Link>
      </Box>
    </Box>
  )
}

export default HspaSunsetBanner
