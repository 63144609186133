import SecondaryButton from 'shared-module/components/button/SecondaryButton'
import './SchedulePage.css'
import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import CameraModesList from './CameraModesList'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const CreateScheduleContent = ({ onCreate }) => {
  const { t } = useTranslation()
  const largeScreen = useMediaQuery(theme => theme.breakpoints.down('xs'))
  return (
    <Box marginBottom={3}>
      <SecondaryButton
        color="primary"
        variant="outlined"
        value={t('app:schedule.create_event')}
        onClick={onCreate}
      />
      { !largeScreen && <CameraModesList /> }
    </Box>
  )
}

export default CreateScheduleContent
