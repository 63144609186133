import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { alpha } from '@material-ui/core'
import MuiSwitch from '@material-ui/core/Switch'
import { makeStyles } from '@material-ui/core/styles'
import CTypography from 'storybook-component-module/src/components/typographies/CTypography'
import MuiBox from '@material-ui/core/Box'

export interface ICToggleSwitch {
  disabled?: boolean
  onChange?: () => void
  className?: string
  titleClassName?: string
  checked: boolean
  value?: boolean
  title?: string
  size?: 'medium' | 'small'
}

const useStyles = makeStyles((theme) => ({
  switch: {
    '& .MuiSwitch-switchBase': {
      color: theme.palette.text.secondary,
      '&:hover': {
        backgroundColor: alpha(theme.palette.text.secondary, 0.1),
      },
    },
    '& .MuiSwitch-switchBase + .MuiSwitch-track': {
      backgroundColor: theme.palette.secondary.dark,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: theme.palette.primary.main,
      filter: 'brightness(85%)',
    },
    '& .MuiSwitch-switchBase.Mui-disabled': {
      color: theme.palette.secondary.main,
      filter: 'brightness(85%)',
    },
    '& .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track': {
      backgroundColor: theme.palette.secondary.main,
      opacity: 0.35,
    },
  },
  title: {
    textAlign: 'left',
    marginRight: '4px',
    fontWeight: 600,
    display: 'inline',
    paddingRight: '10px',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}))

// Bypasses Typescript error: cannot be used as a JSX component
const Box: any = MuiBox

const CToggleSwitch = (props: ICToggleSwitch) => {
  const classes = useStyles()
  const [isActive, setIsActive] = useState(props.checked)

  useEffect(() => {
    setIsActive(props.checked)
  }, [props.checked])

  const toggleActiveState = () => setIsActive(!isActive)

  return (
    <Box className={classes.container}>
      { props.title && (
        <CTypography variant="body1" color="textPrimary" noWrap className={clsx(classes.title, props.titleClassName)}>
          { props.title }
        </CTypography>
      ) }
      <MuiSwitch
        value={props.value}
        checked={props.checked}
        onChange={props.onChange}
        className={clsx(classes.switch, props.className)}
        disabled={props.disabled}
        onClick={toggleActiveState}
        size={props.size || 'small'}
      />
    </Box>
  )
}

export default CToggleSwitch
