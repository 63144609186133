import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@material-ui/core/Box'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import useFilterCollection from 'photo-module/filters-bar/core/filters.hooks'
import { isFilterSelected } from 'photo-module/filters-bar/core/filters.selectors'
import Checkbox from '@mui/material/Checkbox'
import { useUserClubMembership } from 'spypoint/src/user-module/club-membership/core/useUserClubMembership'

const useStyles = makeStyles(theme => ({
  checkbox: {
    color: '#8d8e8e !important',
    '&.Mui-checked': {
      color: `${theme.palette.primary.main} !important`,
    },
  },
}))

const SpypointFilterLabel = ({ Icon, hasCategory = true, label, style }) => {
  const { t } = useTranslation()
  const userMembership = useUserClubMembership()
  const classes = useStyles()

  const { selectedFilters, addSelectedFilter, deleteSelectedFilter, filterCollection } = useFilterCollection()
  const [isChecked, setIsChecked] = useState(isFilterSelected(label, selectedFilters))
  const [isDisabled, setIsDisabled] = useState(isFilterSelected(label, selectedFilters))
  const shared = JSON.parse(localStorage.getItem('isShared'))

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setIsChecked(isFilterSelected(label, selectedFilters)), [selectedFilters])

  useEffect(() => {
    const isFilterDisabled = () => {
      if (filterCollection.mediaTypes.includes(label)) {
        return false
      } else if (filterCollection.favorite.includes(label)) {
        return !userMembership.isMember || shared
      } else {
        return !userMembership.filters.includes(label)
      }
    }

    if (filterCollection) {
      setIsDisabled(isFilterDisabled(label, filterCollection.filters))
    }
  }, [label, filterCollection, userMembership])

  const sxMenuItem = {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  }

  const setSelectedFilter = () => {
    isChecked ? deleteSelectedFilter(label) : addSelectedFilter(label)
    setIsChecked(!isChecked)
  }

  return (
    <MenuItem
      sx={sxMenuItem}
      style={{
        ...style,
        justifyContent: 'space-between',
        width: '-webkit-fill-available',
        minWidth: '-webkit-fill-available',
      }}
      disabled={isDisabled}
      disableTouchRipple
      onClick={setSelectedFilter}
    >
      <Box display="flex" flexDirection="row" alignItems="center">
        { Icon && (
          <Icon style={{
            marginBottom: 2,
            marginRight: 8,
          }}
          />
        ) }
        { hasCategory
          ? <Typography style={{ fontSize: '14' }} variant="body2"> { t(`app:filters.tags.${label}`) } </Typography>
          : (
            <Typography
              variant="body2"
              noWrap
              style={{
                fontSize: '14',
              }}
            >{ t('app:filters.tags.' + label).toUpperCase() }
            </Typography>
            ) }
      </Box>

      <Checkbox className={classes.checkbox} checked={isChecked} />
    </MenuItem>
  )
}

export default SpypointFilterLabel
