import Amount from 'shared-module/components/amount/Amount'
import { getUserCurrency } from 'shared-module/common-utils'

const FormatedPrice = ({ price }) => {
  const userCurrency = getUserCurrency()

  const isInteger = (n) => n % 1 === 0

  return <Amount currencyCode={userCurrency} hideZerosDecimals={isInteger(price)} value={price} />
}

export default FormatedPrice
