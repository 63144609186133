import { useSelector } from 'react-redux'
import useReload from 'shared-module/loading/useReload'
import useLoad from 'shared-module/loading/useLoad'
import useUserClubMembershipActions from 'spypoint/src/user-module/club-membership/core/useUserClubMembershipActions'

const useUserClubMembership = () => useSelector(state => state.clubMembership)
const useLoadUserClubMembership = () => useLoad('user-club-membership', useUserClubMembership(), useUserClubMembershipActions().get)
const useReloadUserClubMembership = () => useReload(useUserClubMembership(), useUserClubMembershipActions().get)

export { useUserClubMembership, useLoadUserClubMembership, useReloadUserClubMembership }
