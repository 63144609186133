import { alpha } from '@material-ui/core'
import useTheme from '@material-ui/core/styles/useTheme'
import Box from '@material-ui/core/Box'

const RedeemPromoCodesSection = ({ children }) => {
  const theme = useTheme()

  return (
    <Box display="flex" flexDirection="column" px={3} py={4} bgcolor={alpha(theme.palette.background.default, 0.8)}>
      { children }
    </Box>
  )
}

export default RedeemPromoCodesSection
