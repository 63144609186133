import { useDispatch } from 'react-redux'
import userPaymentMethodsState from 'spypoint/src/user-module/payment-methods/core/userPaymentMethods.state'

const usePaymentMethodsActions = () => {
  const dispatch = useDispatch()

  return {
    getPaymentMethods: () => dispatch(userPaymentMethodsState.get()),
    updateDefaultPaymentMethod: (token) => dispatch(userPaymentMethodsState.updateDefault(token)),
    clearPaymentMethods: () => dispatch(userPaymentMethodsState.clear()),
  }
}

export default usePaymentMethodsActions
