import axios from 'axios'
import restapi from 'shared-module/api/restapi'

export class CameraApiHelper {
  /**
   * POST photos to the API
   * @param {Object[]} photosList
   * @param {String} cameraId
   * @return {Promise}
   */
  static postUploadedPhotos (photosList, cameraId) {
    return restapi.post(
      `/api/v3/camera/upload/${cameraId}`, photosList,
    )
  }

  /**
   * PUT photo to AWS S3
   * @param {Object} photoUploaded photo that has been uploaded to the API
   * @param {File[]} originFiles origin File uploaded
   * @return {Promise}
   */
  static putToAwsS3 (photoUploaded, originFiles) {
    // Get first array element by destructuring
    const [fileToUpload] = originFiles.filter(photo => photo.name === photoUploaded.originName)

    // Set HTTP headers for S3
    let headers = {}
    photoUploaded.s3Data.httpHeaders.forEach(s3Header => {
      // remove Host HTTP header (CORS)
      if (s3Header.name.toLowerCase() !== 'host') {
        headers = Object.assign(headers, { [s3Header.name]: s3Header.value })
      }
    })

    // S3 host
    const s3Host = 'https://s3.amazonaws.com'

    return axios.put(`${s3Host}${photoUploaded.s3Data.bucketPath}`, fileToUpload, { headers: headers })
  }
}
