import cameraSharingApi from 'camera-module/camera/api/cameraSharingApi'
import { handleCustomError } from 'shared-module/api/apiError'
import messageActions from 'shared-module/message/messageActions'

const fetchOwnedSharedCameras = () => async dispatch => {
  dispatch({ type: 'FETCHING_OWNED_SHARED_CAMERAS' })
  try {
    const cameras = await cameraSharingApi.listOwnedSharedCameras()
    dispatch(setOwnedSharedCameras(cameras))
    dispatch(setFetchingOwnedSharedCameras())
  } catch (error) {
    dispatch(setOwnedSharedCameras([]))
  }
}

const share = camera => dispatch => {
  return cameraSharingApi.shareCamera(camera)
    .then(() => dispatch(messageActions.showSuccess('camera_sharing.invitation_sent')))
    .catch(error => {
      handleCustomError(error.response?.data?.error)
    })
    .then(() => dispatch(fetchOwnedSharedCameras()))
}

const edit = camera => dispatch => {
  return cameraSharingApi.EditCamera(camera)
    .then(() => dispatch(messageActions.showSuccess('camera_sharing.invitation_accepted')))
    .catch(error => {
      handleCustomError(error.response?.data?.error)
    })
    .then(() => dispatch(fetchOwnedSharedCameras()))
}

const rejectInvitation = invitationId => dispatch => {
  return cameraSharingApi.rejectSharedCameraInvitation(invitationId)
    .then((response) => { if (response?.data?.invitation === 'rejected')dispatch(messageActions.showError('camera_sharing.invitation_rejected')) })
    .catch(error => {
      handleCustomError(error.response?.data?.error)
    })
}

const acceptInvitation = invitationId => dispatch => {
  return cameraSharingApi.acceptSharedCameraInvitation(invitationId)
    .then((response) => {
      if (response?.data?.invitation === 'accepted') dispatch(messageActions.showSuccess('camera_sharing.invitation_accepted'))
    })
    .catch(error => {
      handleCustomError(error.response?.data?.error)
    })
}

const fetchAllSharedCameras = (isMyCamera) => (dispatch, getState) => {
  isMyCamera && dispatch(clearAllSharedCameras())
  return cameraSharingApi.listAllSharedCameras()
    .then(cameras => {
      const sharedCams = []
      const shar = sharedCams.concat.apply([], cameras?.data?.map((x) => x?.sharedCameras)).map(x => x.cameraId)
      dispatch(setSharedCameras({ data: shar }))
      dispatch(setFetchingSharedCameras())
      shar.forEach((element) => {
        const cameraInfos = cameras?.data?.map(x => x.sharedCameras.map(d => ({ OwnerName: x.ownerName, ...d })))
        const cameraInfo = sharedCams.concat.apply([], cameraInfos.map(x => x))
        return cameraSharingApi.listSharedCameraInfo(element)
          .then(camera => {
            const cam = cameraInfo.find(x => x.cameraId === camera?.data?.config?.id)
            const allSharedCamera = getState()
            dispatch(setAllSharedCameras({ data: allSharedCamera?.cameraSharing?.allSharedCameras.concat({ ...camera.data, ...cam }) }))
          })
      })
    })
    .catch(() => {
      dispatch(setFetchingSharedCameras())
      dispatch(setAllSharedCameras({ cameras: [] }))
    })
}

const fetchPendingSharedCameras = () => dispatch => {
  return cameraSharingApi.listPendingSharedCameras()
    .then(cameras => {
      dispatch(setPendingSharedCameras(cameras))
      dispatch(setFetchingPendingSharedCamera())
    })
    .catch(dispatch(setPendingSharedCameras({ cameras: [] })))
}

const fetchMetaData = () => dispatch => {
  return cameraSharingApi.listMetaData()
    .then(meteData => {
      dispatch(setMetaData(meteData))
    })
    .catch(dispatch(setMetaData({ meteData: [] })))
}

const fetchSharedCameraPhotos = cameraId => dispatch => {
  return cameraSharingApi.listSharedCameraPhotos(cameraId)
    .then(photos => dispatch(setSharedCameraPhotos(photos)))
    .catch(error => {
      handleCustomError(error.category)
    })
}

const setOwnedSharedCameras = cameras => ({ type: 'SET_OWNED_SHARED_CAMERAS', cameras })
const setAllSharedCameras = cameras => ({ type: 'SET_ALL_SHARED_CAMERAS', cameras })
const clearAllSharedCameras = () => ({ type: 'CLEAR_ALL_SHARED_CAMERAS' })
const setSharedCameras = cameras => ({ type: 'SET_SHARED_CAMERAS', cameras })
const setFetchingSharedCameras = () => ({ type: 'SET_FETCHING_SHARED_CAMERAS' })
const setFetchingOwnedSharedCameras = () => ({ type: 'SET_FETCHING_OWNED_SHARED_CAMERAS' })
const setFetchingPendingSharedCamera = () => ({ type: 'SET_FETCHING_PENDING_SHARED_CAMERAS' })

const setPendingSharedCameras = cameras => ({ type: 'SET_PENDING_SHARED_CAMERAS', cameras })
const setSharedCameraPhotos = photos => ({ type: 'SET_SHARED_CAMERA_PHOTOS', photos })
const setMetaData = metaData => ({ type: 'SET_META_DATA', metaData })

const cameraSharingActions = {
  fetchOwnedSharedCameras,
  share,
  rejectInvitation,
  acceptInvitation,
  fetchAllSharedCameras,
  clearAllSharedCameras,
  fetchPendingSharedCameras,
  fetchSharedCameraPhotos,
  setSharedCameras,
  setFetchingSharedCameras,
  setFetchingPendingSharedCamera,
  edit,
  setFetchingOwnedSharedCameras,
  setMetaData,
  fetchMetaData,
}

export default cameraSharingActions
