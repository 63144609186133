import PhotoStatusIcons from 'photo-module/photos/ui/photos-gallery/PhotoStatusIcons'
import makeStyles from '@material-ui/core/styles/makeStyles'
import VideoThumbnailOverlay from '../preview/VideoThumbnailOverlay'

const useStyles = makeStyles({
  galleryStatusIcons: {
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
    position: 'absolute',
    height: '20px',
    top: '5px',
    right: 0,
    zIndex: 3,
  },
})
const SpypointPhotoOverlay = ({ photo }) => {
  const classes = useStyles()
  return (
    <div data-testid="spypointThumbnail">
      <div className={classes.galleryStatusIcons}>
        <PhotoStatusIcons photo={photo} />
      </div>
      <VideoThumbnailOverlay photo={photo} />
    </div>
  )
}

export default SpypointPhotoOverlay
