import { Box } from '@material-ui/core'
import { Trans } from 'react-i18next'

const PaymentHistoryEmpty = () => {
  return (
    <Box
      paddingLeft={{ xs: 0, sm: 3 }}
      marginTop={{ xs: 0, sm: 4 }}
    >
      <Trans i18nKey="app:account.payment.history.empty" />
    </Box>
  )
}

export default PaymentHistoryEmpty
