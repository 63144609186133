import { useEffect, useState } from 'react'
import useSelectedCamera from './useSelectedCamera'

export const SCHEDULE_TYPES = {
  instant: 'instant',
  standard: 'standard',
  sleep: 'sleep',
}
const useCameraSchedule = (instantScheduleValue, detectionScheduleValue) => {
  const camera = useSelectedCamera()
  const [instantSchedule, setInstantSchedule] = useState(instantScheduleValue || [])
  const [detectionSchedule, setDetectionSchedule] = useState(detectionScheduleValue || [])
  const [formattedSchedule, setFormattedSchedule] = useState([])
  useEffect(() => {
    if (detectionSchedule.length === 0 && instantSchedule.length === 0) {
      setFormattedSchedule(
        [...Array(7)].fill(
          new Array(24).fill({
            scheduleType: SCHEDULE_TYPES[camera.config.operationMode] || SCHEDULE_TYPES.sleep,
          }),
        ),
      )
    } else {
      const rows = [...Array(7)]
      for (let j = 0; j < 7; j++) {
        rows[j] = createPeriod(j)
      }
      setFormattedSchedule(rows)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instantSchedule, detectionSchedule])

  const createScheduleDay = (scheduleType, weekDay, time) => {
    return {
      scheduleType: scheduleType,
      weekDay: weekDay,
      time: time,
    }
  }
  const createPeriod = (day) => {
    const days = new Array(24).fill({ scheduleType: SCHEDULE_TYPES.sleep })
    const periodsInstant = instantSchedule.filter((per) => {
      return per.weekDay - 1 === day
    })
    const periodsStandard = detectionSchedule.filter((per) => per.weekDay - 1 === day)
    periodsStandard.forEach((period) => {
      for (let i = period.startTime.hour; i < period.endTime.hour; i++) {
        days[i] = createScheduleDay(SCHEDULE_TYPES.standard, day, i)
      }
      if (period.endTime.minute === 59) {
        days[23] = createScheduleDay(SCHEDULE_TYPES.standard, day, 23)
      }
    })
    periodsInstant.forEach((period) => {
      for (let i = period.startTime.hour; i < period.endTime.hour; i++) {
        days[i] = createScheduleDay(SCHEDULE_TYPES.instant, day, i)
      }
      if (period.endTime.minute === 59) {
        days[23] = createScheduleDay(SCHEDULE_TYPES.instant, day, 23)
      }
    })
    return days
  }

  const addSchedulePeriods = (periods, scheduleType) => {
    if (!periods) return
    let formattedSch = formattedSchedule
    if (detectionSchedule.length === 0 && instantSchedule.length === 0) {
      formattedSch = [...Array(7)].map((_) =>
        new Array(24).fill({
          scheduleType: SCHEDULE_TYPES.sleep,
        }),
      )
    }

    periods.forEach((period) => {
      for (let i = period.startTime.hour; i < period.endTime.hour; i++) {
        formattedSch[period.weekDay - 1][i] = createScheduleDay(
          scheduleType,
          period.weekDay - 1,
          i,
        )
      }
      if (period.endTime.hour === 23 && period.endTime.minute === 59) {
        formattedSch[period.weekDay - 1][23] = createScheduleDay(scheduleType, period.weekDay - 1, 23)
      }
    })
    generateSchedules(formattedSch)
  }

  const getSelectedPeriod = (weekDay, scheduleType, timeClicked) => {
    if (scheduleType === SCHEDULE_TYPES.instant) {
      return instantSchedule.find(
        (period) => period.weekDay === weekDay && period.startTime.hour <= timeClicked && timeClicked <= period.endTime.hour,
      )
    } else {
      return detectionSchedule.find(
        (period) => period.weekDay === weekDay && period.startTime.hour <= timeClicked && timeClicked <= period.endTime.hour,
      )
    }
  }

  const removeSchedulePeriod = (period) => {
    if (!period) return
    for (let i = period.startTime.hour; i <= period.endTime.hour; i++) {
      formattedSchedule[period.weekDay - 1][i] = createScheduleDay(SCHEDULE_TYPES.sleep)
    }
    if (period.scheduleType === SCHEDULE_TYPES.instant) {
      setInstantSchedule(instantSchedule.filter((schedule) => schedule !== period))
    } else {
      setDetectionSchedule(detectionSchedule.filter((schedule) => schedule !== period))
    }
  }

  const hasConflicts = (newPeriods, scheduleType) => {
    if (detectionSchedule.length === 0 && instantSchedule.length === 0) return false
    return newPeriods.some((period) => {
      for (let i = period.startTime.hour; i < period.endTime.hour; i++) {
        if (formattedSchedule[period.weekDay - 1][i].scheduleType !== SCHEDULE_TYPES.sleep && formattedSchedule[period.weekDay - 1][i].scheduleType !== scheduleType) {
          return true
        }
      }
      return false
    })
  }

  const getHoursArray = () => {
    if (camera?.config?.timeFormat === 12) {
      return [
        '12 AM',
        ...Array(11)
          .fill(0)
          .map((_, i) => `${i + 1} AM`),
        '12 PM',
        ...Array(11)
          .fill(0)
          .map((_, i) => `${i + 1} PM`),
        '12 AM',
      ]
    } else {
      return [
        ...Array(24)
          .fill(0)
          .map((_, i) => (i < 10 ? `0${i}:00` : `${i}:00`)),
        '00:00',
      ]
    }
  }
  const getHoursForSelect = () => {
    if (camera?.config?.timeFormat === 12) {
      return [
        '12:00 AM',
        ...Array(11)
          .fill(0)
          .map((_, i) => `${i + 1}:00 AM`),
        '12:00 PM',
        ...Array(11)
          .fill(0)
          .map((_, i) => `${i + 1}:00 PM`),
        '11:59 PM',
      ]
    } else {
      return [
        ...Array(24)
          .fill(0)
          .map((_, i) => (i < 10 ? `0${i}:00` : `${i}:00`)),
        '23:59',
      ]
    }
  }
  const generateSchedules = (formattedSchedule) => {
    const instantSchedule = []
    const detectionSchedule = []
    formattedSchedule.forEach((day) => {
      let startTime, endTime, scheduleType
      for (let i = 0; i < 24; i++) {
        const hour = day[i]
        if (hour.scheduleType !== SCHEDULE_TYPES.sleep) {
          if (typeof startTime === 'undefined') startTime = day[i].time
          if (!endTime || endTime <= day[i].time + 1) endTime = day[i].time + 1
          scheduleType = day[i].scheduleType
          if (scheduleType !== day[i + 1]?.scheduleType) {
            const schedulePeriod = {
              weekDay: hour.weekDay + 1,
              startTime: {
                hour: startTime,
                minute: 0,
              },
              endTime: {
                hour: i === 23 ? 23 : endTime,
                minute: i === 23 ? 59 : 0,
              },
            }
            scheduleType === SCHEDULE_TYPES.instant
              ? instantSchedule.push(schedulePeriod)
              : detectionSchedule.push(schedulePeriod)
            startTime = undefined
            endTime = undefined
            scheduleType = undefined
          }
        }
      }
    })
    setDetectionSchedule(detectionSchedule)
    setInstantSchedule(instantSchedule)
  }

  return {
    addSchedulePeriods,
    hasConflicts,
    getHoursArray,
    instantSchedule,
    detectionSchedule,
    removeSchedulePeriod,
    formattedSchedule,
    getSelectedPeriod,
    getHoursForSelect: getHoursForSelect(),
  }
}

export default useCameraSchedule
