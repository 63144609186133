// React Core imports
import { Component } from 'react'
// Material-UI imports
import withStyles from '@material-ui/core/styles/withStyles'

const styles = theme => ({
  root: {
    margin: '15px 0px 15px 0px',
    width: '100%',
  },
  title: {
    textAlign: 'center',
    fontSize: '0.75em',
  },
  labels: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '0.75em',
  },
  filler: {
    display: 'inline',
    fill: theme.palette.primary.main,
    stroke: 'none',
  },
  outline: {
    display: 'inline',
    fill: 'none',
    stroke: theme.palette.primary.main,
    strokeWidth: '0.5',
    strokeLinecap: 'round',
  },
  lines: {
    fill: 'none',
    stroke: theme.palette.primary.main,
    strokeWidth: '0.5',
    strokeLinecap: 'round',
    strokeLinejoin: 'miter',
    strokeMiterlimit: '4',
  },
})

class Meter extends Component {
  /**
   * Rendering of the inside of the progress bar.
   *
   * @return {JSX} The inside of the progress bar.
   */
  renderFiller = () => {
    const fillerMaxWidth = 98 // max width of the filler
    const fillerStartPoint = 1.5 // base start point of the filler

    let width = parseInt(this.props.fill)
    if (isNaN(width)) {
      return null
    }
    width = width > 100 ? 100 : width
    width = width < 0 ? 0 : width
    width = width * (fillerMaxWidth / 100)

    const x = this.props.anchor === 'right' ? (fillerMaxWidth - width) + fillerStartPoint : fillerStartPoint

    return (
      <rect
        id="meter-filler"
        x={x}
        width={width}
        y="0.75"
        height="2"
        className={this.props.classes.filler}
      />
    )
  }

  /**
   * Rendering of the component.
   */
  render () {
    const { classes } = this.props

    return (
      <div className={classes.root}>
        { (this.props.title || this.props.strongTitle) && (
          <div className={classes.title}>
            <strong>{ this.props.strongTitle } </strong>
            { this.props.title }
            <strong> { this.props.appendStrongTitle }</strong>
          </div>
        ) }
        <svg
          data-testid="photos-meter"
          data-value={this.props.fill}
          id="meter-time-subscription"
          viewBox="0 0 101 5"
          preserveAspectRatio={this.props.preserveAspectRatio}
          width={this.props.width}
          height={this.props.height}
        >
          <rect
            id="meter-outline"
            rx={this.props.squared ? 0 : 1}
            x="0.25"
            y="0.25"
            height="3"
            width="100.5"
            className={classes.outline}
          />
          { this.renderFiller() }
          { this.props.startLabel
            ? (
              <path
                id="vertical-line-left"
                d="m 1.5,0.25 v 4.5"
                className={classes.lines}
              />
              )
            : null }
          { this.props.endLabel
            ? (
              <path
                id="vertical-line-right"
                d="m 99.5,0.25 v 4.5"
                className={classes.lines}
              />
              )
            : null }
        </svg>
        { this.props.startLabel || this.props.endLabel
          ? (
            <div className={classes.labels}>
              <div>{ this.props.startLabel }</div>
              <div>{ this.props.endLabel }</div>
            </div>
            )
          : null }
      </div>
    )
  }
}

Meter.defaultProps = {
  width: '100%',
  fill: '0',
  anchor: 'left',
  appendStrongTitle: '',
}

export default withStyles(styles)(Meter)
