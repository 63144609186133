import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import SquareIcon from 'assets/icons/SquareIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  listText: {
    fontSize: 12,
    fontWeight: 600,
  },
})

const CameraModeListItem = ({ text, color, fill, border }) => {
  const classes = useStyles()

  return (
    <ListItem>
      <ListItemIcon style={{ minWidth: '35px' }}>
        <SquareIcon
          fontSize="small"
          color={color}
          opacity="0.8"
          style={{
            fill: fill,
            border: border,
          }}
        />
      </ListItemIcon>
      <ListItemText primary={text} primaryTypographyProps={{ className: classes.listText }} />
    </ListItem>
  )
}
export default CameraModeListItem
