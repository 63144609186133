import restapi from 'shared-module/api/restapi'
import store from 'shared-module/redux/store'
import cameraActions from 'camera-module/camera/core/cameraActions'
import handleError from 'shared-module/api/apiError'

export const disableAutoRenew = (cameraId, subscriptionId) => {
  return restapi.delete(`/api/v3/subscription/${cameraId}/${subscriptionId}`)
    .catch(handleError)
}

export const enableAutoRenew = (cameraId, subscriptionId) => {
  return restapi.post(`/api/v3/subscription/${cameraId}/${subscriptionId}`)
    .catch(handleError)
}

export const getPlans = () => {
  return restapi.get('/api/v3/plan')
    .then((plans) => plans.data)
    .catch(handleError)
}

export const getPlan = (id) => {
  return restapi.get('/api/v3/plan/' + id)
    .then(plan => plan.data)
    .catch(handleError)
}

export const getBill = (planId, frequency) => {
  return restapi.get(`/api/v3/plan/${planId}/${frequency}/bill`)
    .then(bill => bill.data)
    .catch(handleError)
}

export const sendPayment = (tokenId, cameraId, planId, paymentFrequency, subscriptionId) => {
  const subscription = {
    customerToken: tokenId,
    cameraId: cameraId,
    planId: planId,
    paymentFrequency: paymentFrequency,
  }
  if (subscriptionId) {
    subscription.subscriptionId = subscriptionId
    return restapi.put('/api/v3/subscription', subscription)
      .then(() => store.dispatch(cameraActions.get(cameraId)))
      .catch(handleError)
  } else {
    return restapi.post('/api/v3/subscription', subscription)
      .then(() => store.dispatch(cameraActions.get(cameraId)))
      .catch(handleError)
  }
}

export const getStripePublicKey = () => {
  return restapi.get('/api/v3/paymentservice/publickey')
    .then(publicKey => publicKey.data.key)
    .catch(handleError)
}
