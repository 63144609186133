/* eslint-disable react-hooks/exhaustive-deps */
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import useSelectedCamera from '../../core/useSelectedCamera'
import useConfirmationDialogActions from 'shared-module/components/confirmation/useConfirmationDialogActions'
import SecondaryButton from 'shared-module/components/button/SecondaryButton'
import { useHistory } from 'react-router-dom'
import appConfig from 'shared-module/config/appConfig'
import switches from 'shared-module/config/switches'

const useStyles = makeStyles(theme => ({
  root: ({ isNewUi }) => ({
    margin: '20px',
    width: isNewUi ? '10.25rem' : '200px',
    height: isNewUi && '7.25rem',
    textAlign: 'center',
  }),
  icon: {
    width: '35px',
    filter: 'invert(100%) sepia(0%) saturate(13%) hue-rotate(235deg) brightness(104%) contrast(104%)',
  },
  value: ({ isNewUi }) => ({
    fontSize: isNewUi ? '0.813rem' : '0.916rem',
    fontFamily: isNewUi && theme.typography.fontFamilyBold,
    fontWeight: !isNewUi && 'bold',
    marginTop: '5px',
    whiteSpace: 'nowrap',
  }),
  label: ({ isNewUi }) => ({
    fontSize: isNewUi ? theme.typography.caption2?.fontSize : '13px',
    color: theme.palette.text.primary,
  }),
  FormatButtons: {
    width: 'auto',
    height: '32px',
    marginTop: 8,
  },
  commandButton: {
    whiteSpace: 'nowrap',
    minWidth: 'max-content',
    color: 'white',
    border: '1px solid white',
    borderRadius: '6px',
    fontSize: 14,
    marginTop: 8,
    padding: '1px 10px',
    '&:hover': {
      border: '1px solid white',
    },
  },
}))
const SharedCameraStatusTile = ({ label, icon, value, useSVGIcon = false, isNewUi, firmwareUpdate, requested, sendCommands, commandName, isShared }) => {
  const { t } = useTranslation()
  const classes = useStyles({ isNewUi })
  const camera = useSelectedCamera()
  const history = useHistory()

  return (
    <div className={classes.root} data-testid="tile">
      { useSVGIcon
        ? (
          <div style={{ paddingLeft: '3px' }}>
            <svg width="32" height="32" viewBox="0 0 32 32"> { icon } </svg>
          </div>
          )
        : <div><img src={icon} className={classes.icon} alt="icon" /></div> }
      <div className={classes.value}>
        { value }
      </div>
      <div className={classes.label}>
        { t(label) }
        { isShared && (
          <Grid>
            <Grid container xs={12} justifyContent="center" item>
              <SecondaryButton
                align="right"
                color="primary"
                variant="outlined"
                className={classes.commandButton}
                onClick={() => history.replace('/camera-sharing/guests')}
                value={t('app:camera.go_to_sharing')}
              />

            </Grid>
          </Grid>
        ) }
      </div>
    </div>
  )
}

export default SharedCameraStatusTile
