import { Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Box, Grid, makeStyles } from '@material-ui/core'

import useUser from 'user-module/user/core/useUser'
import CheckoutIcon from 'spypoint/src/assets/icons/CheckoutPageIcon'
import AppBarContainer from 'shared-module/components/app-bar/AppBarContainer'
import CheckoutComponent from './CheckoutComponent'

const useStyles = makeStyles({
  root: {
    marginTop: '150px',
    overflow: 'hidden',
  },
  grid: {
    padding: '0 2rem',
  },
})

const CheckoutPage = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const user = useUser()

  return user.cartEnabled
    ? (
      <Box className={classes.root}>
        <Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={3} className={classes.grid}>
          <AppBarContainer Icon={CheckoutIcon} title={t('menu.checkout_menu')} />
          <CheckoutComponent />
        </Grid>
      </Box>
      )
    : <Redirect to="/" />
}

export default CheckoutPage
