import makeStyles from '@material-ui/core/styles/makeStyles'
import downloadStatus from 'photo-module/download/downloadStatus'
import { useActions } from 'shared-module/actions/ActionsContext'
import FavoriteShadowIcon from 'assets/icons/photo/FavoriteShadowIcon'
import videoState from 'video-module/video-clip/core/videoState'

const useStyles = makeStyles(theme => ({
  statusIcon: {
    '& path': {
      fill: theme.palette.secondary.contrastText,
    },
    position: 'relative',
    height: '20px',
    width: 'auto',
    marginRight: '5px',
  },
  statusNotification: {
    display: 'inline-block',
    height: '8px',
    width: '8px',
    borderRadius: '50%',
    position: 'absolute',
    left: '14px',
    zIndex: 2,
  },
  pending: {
    background: theme.palette.primary.pending,
  },
  error: {
    background: theme.palette.primary.danger,
  },
}))

const PhotoStatusIcons = ({ photo, GalleryIcon }) => {
  const classes = useStyles()
  const Icon = useActions().photoActions.hdIcon()

  const isFavorite = photo.favorite
  const hdPhotoStatus = photo.hdStatus
  const hdVideoStatus = photo.hdVideoStatus
  const status = videoState.hasPreview(photo) ? hdVideoStatus : hdPhotoStatus
  const isAStream = videoState.isAStream(photo)
  return !isAStream && (
    <>
      { GalleryIcon && hdVideoStatus === downloadStatus.requestable && <GalleryIcon className={classes.statusIcon} /> }
      { status === downloadStatus.available && <Icon className={classes.statusIcon} /> }
      { (status === downloadStatus.pending || status === downloadStatus.error) && (
        <>
          <span
            data-testid={status}
            className={`${classes.statusNotification} ${status === downloadStatus.pending ? classes.pending : classes.error}`}
          />
          <Icon className={classes.statusIcon} />
        </>
      ) }
      { isFavorite && <FavoriteShadowIcon className={classes.statusIcon} /> }
    </>
  )
}

export default PhotoStatusIcons
