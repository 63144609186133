import { useTranslation } from 'react-i18next'
import useSelectedPhoto from 'photo-module/photo/core/useSelectedPhoto'
import useVideoActions from 'video-module/video-clip/core/useVideoActions'
import ConfirmationDialog from 'shared-module/components/confirmation/ConfirmationDialog'

const DialogFullHdVideoError = ({ open, onClose }) => {
  const { t } = useTranslation()
  const photo = useSelectedPhoto()
  const actions = useVideoActions()

  const acknowledgeError = () => {
    onClose()
    actions.clearHdVideo(photo.cameraId, photo.id)
  }

  return (
    <ConfirmationDialog
      open={open}
      onClose={onClose}
      title={t('photo_viewer.full_hd_video.error.title')}
      text={t('photo_viewer.full_hd_video.error.text')}
      confirmText={t('button.dismiss')}
      onConfirm={acknowledgeError}
    />
  )
}

export default DialogFullHdVideoError
