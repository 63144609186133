import { makeStyles } from '@material-ui/core/styles'
import { Link, Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  removeButtonStyle: {
    paddingTop: '2px',
    color: theme.palette.text.primary,
    fontSize: '1rem',
    cursor: 'pointer',
  },
  removeButtonWrapper: {
    marginTop: 20,
  },
}))

const RemoveCartItemButton = ({ productId, onDelete }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Grid item className={classes.removeButtonWrapper} xs={6}>
      <Link
        underline="always"
        className={classes.removeButtonStyle}
        onClick={onDelete}
        data-testid={'deleteBtn-' + productId}
        key={productId + '-deleteBtn'}
      >
        { t('app:checkout_process.cart.delete_item') }
      </Link>
    </Grid>
  )
}

export default RemoveCartItemButton
