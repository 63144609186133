import { useTheme } from '@material-ui/core'
import SvgIcon from '@material-ui/core/SvgIcon'

const FreePhotosIcon = (props) => {
  const theme = useTheme()

  return (
    <SvgIcon data-testid="FreePhotosIcon" viewBox="0 0 101 101" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clip-path="url(#clip0_19888_115)">
        <path d="M95.4255 29.4071L96.5093 19.9572L89.7199 19.1368L89.0505 13.8212L64.953 16.315L11.4668 9.98215L10.2874 20.3837H9.52242V22.0899H10.1599L6.62179 22.4509L8.31116 38.3978L3.52991 81.054L7.48242 81.5134L7.09989 92.5712L93.1624 95.6556L93.6086 82.5306L96.4456 82.2353L94.278 62.154L95.4255 29.4071ZM15.2918 21.3353L21.2524 20.679L15.2918 20.3837L16.6624 20.5478L17.013 17.2665L35.3093 19.4321L15.1643 21.4993L15.2918 21.3353ZM87.0424 88.6993L47.613 87.2884L87.2974 83.1868L87.0424 88.6993ZM20.1049 83.1868L12.4549 28.4228L83.3768 19.2681L90.7718 75.5415L20.1049 83.1868Z" fill="#F89021" />
        <path d="M69.798 45.3868L61.0005 55.8212L35.373 44.9931L24.2487 57.6587L26.5437 75.5415L82.6755 69.34L80.0618 51.6212L69.798 45.3868Z" fill="#F89021" />
        <path d="M55.6774 46.9946C57.1729 46.9946 58.6347 46.5377 59.8777 45.6817C61.1207 44.8258 62.0889 43.6093 62.6597 42.1864C63.2306 40.7635 63.3783 39.1982 63.0842 37.6889C62.7901 36.1795 62.0675 34.7939 61.0078 33.7076C59.9481 32.6213 58.5991 31.8833 57.1316 31.587C55.6641 31.2906 54.1441 31.4493 52.7643 32.0429C51.3845 32.6366 50.2069 33.6384 49.3807 34.9216C48.5544 36.2047 48.1167 37.7115 48.1231 39.2509C48.123 40.2706 48.3186 41.2802 48.6987 42.2219C49.0787 43.1636 49.6357 44.0187 50.3376 44.7382C51.0395 45.4577 51.8725 46.0274 52.7889 46.4146C53.7052 46.8019 54.6869 46.999 55.6774 46.9946Z" fill="#F89021" />
      </g>
      <defs>
        <clipPath id="clip0_19888_115">
          <rect width="92.9156" height="85.575" fill="white" transform="translate(3.49805 9.9165)" />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}

export default FreePhotosIcon
