import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useEffect, useState } from 'react'
import MembershipRenewalSection from 'spypoint/src/user-module/club-membership/ui/header/MembershipRenewalSection'
import { useUserClubMembership } from 'spypoint/src/user-module/club-membership/core/useUserClubMembership'
import HeaderPricingSection from 'spypoint/src/user-module/club-membership/ui/header/HeaderPricingSection'
import JoinClubMembershipSection from 'spypoint/src/user-module/club-membership/ui/header/JoinClubMembershipSection'
import eCommerceApi from 'ecommerce-module/api/eCommerceApi.js'
import { useLDFlags } from 'spypoint/src/launchDarkly-module/launchDarklyFlagsContext'

const useStyles = makeStyles((theme) => ({
  header: {
    boxShadow: 'none',
    background: theme.palette.primary.contrastText,
    [theme.breakpoints.down('sm')]: {
      marginTop: '56px',
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: '64px',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '2.5rem',
    paddingBottom: '2.5rem',
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column',
    },
  },
}))

const MembershipPageHeader = () => {
  const classes = useStyles()
  const userMembership = useUserClubMembership()
  const { flags } = useLDFlags()
  const eCommerceEnabled = flags && flags['e-commerce']
  const [product, setProduct] = useState(null)

  useEffect(() => {
    const fetchProduct = async () => {
      if (eCommerceEnabled) {
        const insidersClub = await eCommerceApi.fetchInsidersClubProduct()
        setProduct(insidersClub)
      }
    }
    fetchProduct()
  }, [eCommerceEnabled])
  return (
    <Container maxWidth={false} className={classes.header}>
      <Container maxWidth="lg" disableGutters>
        <Grid container className={classes.container}>
          <HeaderPricingSection product={product} eCommerceEnabled={eCommerceEnabled} />

          <Grid container item sm={5}>
            { userMembership.isMember ? <MembershipRenewalSection /> : <JoinClubMembershipSection /> }
          </Grid>
        </Grid>
      </Container>
    </Container>
  )
}
export default MembershipPageHeader
