import { useTheme } from '@material-ui/core'
import SvgIcon from '@material-ui/core/SvgIcon'

const CameraSharingIcon = (props) => {
  const theme = useTheme()

  return (
    <SvgIcon data-testid="CameraSharingIcon" viewBox="0 0 101 101" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clip-path="url(#clip0_19146_3811)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M36.2645 36.3294C33.2528 36.3294 30.812 38.7084 30.812 41.6422C30.812 44.125 32.5612 46.2102 34.9235 46.7923L38.8484 36.9635C38.0797 36.559 37.1988 36.3294 36.2645 36.3294ZM8.9989 15.7454V86.1266H59.4397V15.7454H8.9989ZM19.9209 80.777C19.9125 80.8002 19.8886 80.8152 19.8634 80.8152H14.4837C14.4669 80.8152 14.4529 80.8029 14.4529 80.7852V21.0882C14.4529 21.0718 14.4669 21.0581 14.4837 21.0581H43.6726C43.7161 21.0581 43.7455 21.1005 43.7301 21.1401L41.5656 26.5608C39.9033 26.006 38.1204 25.7054 36.266 25.7054C27.2307 25.7054 19.9068 32.8396 19.9068 41.6408C19.9068 48.1724 23.9412 53.7871 29.7136 56.2467L19.9209 80.777ZM36.2645 52.2649C30.2411 52.2649 25.358 47.5083 25.358 41.6408C25.358 35.7733 30.2411 31.0181 36.2645 31.0181C42.288 31.0181 47.1697 35.7747 47.1697 41.6408C47.1697 47.5069 42.2866 52.2649 36.2645 52.2649ZM93.1652 23.9045L84.4947 48.279L78.899 39.1225L64.8938 46.3879V32.8902L72.6258 28.881L67.0134 19.7232L93.1652 23.9045ZM72.6255 72.9909L67.013 82.1488L93.1648 77.9675L84.4943 53.5916L78.8987 62.7495L64.8934 55.4841V68.9818L72.6255 72.9909Z" fill="#F89021" />
      </g>
      <defs>
        <clipPath id="clip0_19146_3811">
          <rect width="84.1662" height="70.3812" fill="white" transform="translate(8.9989 15.7454)" />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}

export default CameraSharingIcon
