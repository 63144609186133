// React Core imports
import { Component } from 'react'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { Paper, withStyles } from '@material-ui/core'

const styles = {
  paperWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  paper: {
    padding: '20px',
    margin: '110px 20px',
    flexGrow: '2',
    maxWidth: '500px',
  },
  content: {
    width: '100%',
  },
}

class CameraUnavailableError extends Component {
  render () {
    const { t, classes } = this.props

    return (
      <div>

        <div className={classes.paperWrapper}>
          <Paper className={classes.paper} elevation={1}>
            <div className={classes.content}>
              <p>{ t('errors.page_unavailable_camera') }</p>
            </div>
          </Paper>
        </div>
      </div>
    )
  }
}

export default compose(
  withTranslation(),
  withStyles(styles),
)(CameraUnavailableError)
