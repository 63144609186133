import React, { ReactNode } from 'react'

import Grid from '@mui/material/Grid'
import MuiBox from '@mui/material/Box'
import makeStyles from '@material-ui/core/styles/makeStyles'
export interface ICBenefitsMobileItem {
  icon: ReactNode
  title?: string
  description: string
  showAsterisk?: boolean
}

// Bypasses Typescript error: cannot be used as a JSX component
const Box: any = MuiBox

const useStyles = makeStyles(theme => ({
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'baseline',
    marginLeft: '16px',
  },
  title: {
    textAlign: 'left',
    marginRight: '4px',
    display: 'inline',
    fontSize: '0.875rem',
    fontWeight: 700,
    fontFamily: theme.typography.fontFamily,
    textTransform: 'uppercase',
    color: theme.palette.text.secondary,
  },
  description:
    {
      textAlign: 'left',
      fontWeight: 400,
      fontSize: '0.875rem',
      fontFamily: 'ibm-plex-sans-regular',
      color: theme.palette.text.secondary,
    },
}))

const CBenefitsMobileItem = (props: ICBenefitsMobileItem) => {
  const Icon: any = props.icon
  const classes = useStyles()

  return (
    <Grid item display="flex" spacing={0} padding={0} flexDirection="row" justifyContent="center" alignItems="left" mx="16px">
      <Icon color="primary" />
      <Box className={classes.titleContainer}>
        <Box component="span" className={classes.title}>{ props.title }{ props.showAsterisk && <Box component="span">*</Box> }</Box>
        <Box component="span" className={classes.description}>{ props.description }</Box>
      </Box>
    </Grid>
  )
}

export default CBenefitsMobileItem
