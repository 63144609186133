import SvgIcon from '@material-ui/core/SvgIcon'

const NormalIcon = (props) => (
  <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
    <rect width="24" height="24" rx="4.5" fill="#AB0C1C" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9999 12.8794L16.1205 17L16.9999 16.1206L12.8794 12L16.9999 7.87944L16.1205 7L11.9999 11.1206L7.87938 7L6.99994 7.87944L11.1205 12L6.99994 16.1206L7.87938 17L11.9999 12.8794Z" fill="#F5F4F4" />
  </SvgIcon>
)

export default NormalIcon
