import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const TriangleIcon = (props) => {
  const theme = useTheme()

  return (
    <SvgIcon {...props} data-testid="TriangleIcon">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.08018 11.0293C6.48745 11.7402 7.51226 11.7402 7.92174 11.0293L13.2317 1.78711C13.639 1.07839 13.1299 0.1875 12.3109 0.1875L1.68876 0.1875C0.872012 0.1875 0.360714 1.07617 0.767982 1.78711L6.08018 11.0293Z"
        fill={props.color || theme.palette.secondary.dark}
      />
    </SvgIcon>
  )
}

export default TriangleIcon
