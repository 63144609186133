import { useDispatch } from 'react-redux'
import userClubMembershipState from 'spypoint/src/user-module/club-membership/core/userClubMembershipState'

const useUserClubMembershipActions = () => {
  const dispatch = useDispatch()

  return {
    get: () => dispatch(userClubMembershipState.get()),
  }
}

export default useUserClubMembershipActions
