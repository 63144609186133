import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import CameraModelTile from 'camera-module/new-activation/ui/components/CameraModelTile'
import { FormDataContext } from 'shared-module/new-components/form/FormDataContext'
import CameraModelSelect from 'storybook-component-module/src/wrappers/activatecamera/CameraModelSelect'
import useSortedCameraModels from 'camera-module/model/core/sorted-camera-models/useSortedCameraModels'
import sortedCameraModelState from 'camera-module/model/core/sorted-camera-models/sortedCameraModelState'
import { CELLULAR_MODELS, NON_CELLULAR_MODELS, SHOWCASE_MODELS } from 'camera-module/model/core/sorted-camera-models/sortedCameraModelIndexes'

const useStyles = makeStyles(theme => ({
  box: {
    width: '100%',
    height: '100%',
    padding: '2rem 1rem 3rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      padding: '2rem 1rem 1rem',
    },
  },
  titles: {
    color: theme.palette.text.primary,
  },
  footNotesContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  footNotes: {
    fontSize: '0.75rem',
    textAlign: 'center',
    color: theme.palette.text?.tertiary,
  },
  footNotesAction: {
    marginLeft: '0.25rem',
    textDecorationLine: 'underline',
  },
  inputContainer: {
    maxWidth: '100%',
    [theme.breakpoints.only('sm')]: {
      maxWidth: '100%',
    },
    [theme.breakpoints.only('xs')]: {
      maxWidth: '100%',
    },
  },
}))

const CameraSelectionStep = ({ activatedModel, setActivatedModel, setShowSteps }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const sortedModels = useSortedCameraModels()

  const [models, setModels] = useState()
  const [allCellularModels, setAllCellularModels] = useState()
  const [isCellularCamera, setIsCellularCamera] = useState(true)

  useEffect(() => {
    setActivatedModel && setActivatedModel(undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleStateChange = () => {
    setIsCellularCamera(!isCellularCamera)
    setActivatedModel(undefined)
  }

  const getFilteredModels = cameras => isCellularCamera
    ? Object.values(cameras[SHOWCASE_MODELS])
    : Object.values(cameras[NON_CELLULAR_MODELS])

  useEffect(() => {
    if (sortedCameraModelState.hasModels(sortedModels)) {
      setModels(getFilteredModels(sortedModels))
      setAllCellularModels(Object.values(sortedModels[CELLULAR_MODELS]))
    }

    setShowSteps(isCellularCamera)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedModels, isCellularCamera])

  return (
    <Box className={classes.box}>
      <Typography variant="h1" align="center" className={classes.titles}>{ isCellularCamera
        ? t('app:active_device.steps.step_1.header')
        : t('app:active_device.steps.step_1.non_cellular_header') }
      </Typography>

      <CameraModelTile activatedModel={activatedModel} setActivatedModel={setActivatedModel} models={models} />

      { isCellularCamera && (
        <><Typography variant="h2" className={classes.titles}>{ t('app:active_device.steps.step_1.device_not_listed') }</Typography>
          <FormDataContext>
            <Grid item xs={12} md={12}>
              <CameraModelSelect
                fieldName="cameraModel"
                className={classes.inputContainer}
                models={allCellularModels}
                activatedModel={activatedModel}
                setActivatedModel={setActivatedModel}
              />
            </Grid>
          </FormDataContext>
        </>
      ) }

      <Box className={classes.footNotes}>
        <Typography variant="caption" align="center">{ isCellularCamera
          ? t('app:active_device.steps.step_1.non_cellular_link')
          : t('app:active_device.steps.step_1.link_cellular_page') }
        </Typography>

        <Typography variant="caption" onClick={handleStateChange} className={clsx(classes.footNotes, classes.footNotesAction)}>
          { t('app:active_device.steps.step_1.continue') }
        </Typography>
      </Box>
    </Box>
  )
}

export default CameraSelectionStep
