import Box from '@material-ui/core/Box'
import makeStyles from '@material-ui/core/styles/makeStyles'
import videoState from 'video-module/video-clip/core/videoState'
import { PlayerIcon } from 'assets/icons/SpypointIcons'

const useStyles = makeStyles({
  iconWrapper: {
    position: 'absolute',
    top: '40%',
    right: '40%',
    zIndex: 4,
  },
})

const VideoThumbnailOverlay = ({ photo }) => {
  const classes = useStyles()
  if (videoState.isAVideo(photo) || videoState.hasPreview(photo)) {
    return (
      <Box component="span" className={classes.iconWrapper}>
        <PlayerIcon fontSize="small" />
      </Box>
    )
  } else {
    return null
  }
}

export default VideoThumbnailOverlay
