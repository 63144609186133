import { Link } from '@material-ui/core'
import useLanguage from 'shared-module/components/language/useLanguage'
import { useTranslation } from 'react-i18next'

const ReferralLink = () => {
  const { t } = useTranslation()
  const language = useLanguage()

  const href = language === 'en' ? 'https://www.spypoint.com/en/spypoint-experience/referral-program' : 'https://www.spypoint.com/fr/experience-spypoint/programme-referencement'

  return <Link href={href} target="_blank" rel="noreferrer" style={{ fontSize: '14px', lineHeight: '150%' }}>{ t('add_camera.referral_help') }</Link>
}

export default ReferralLink
