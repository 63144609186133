import structuredClone from '@ungap/structured-clone'
import CameraSettingsFormContent from './CameraSettingsFormContent'
import Form from 'form-module/ui'
import Spinner from 'shared-module/components/Spinner.js'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import { useTranslation } from 'react-i18next'
import { useEffect, useRef, useState } from 'react'
import appConfig from 'shared-module/config/appConfig'
import switches from 'shared-module/config/switches'

const CameraSettingsForm = ({ onSubmit, onReset, isNewUi, dialogActions }) => {
  const { t } = useTranslation()
  const camera = useSelectedCamera()
  const [validationSchema, setValidationSchema] = useState()
  const [settings, setSettings] = useState()
  const [initialial, setInitial] = useState(null)
  const isFirst = useRef(true)
  const cameraSettigsEnabled = appConfig.cameraSettings.switch === switches.on
  useEffect(() => {
    if (camera?.config && isFirst.current) {
      setInitial(structuredClone(camera?.config))
      isFirst.current = false
    }
  }, [camera?.config])
  const submit = (formData) => {
    onSubmit(formData, initialial, setInitial)
  }

  if (!camera) {
    return <Spinner />
  }
  const newData = camera.config

  return (
    <Form
      isNewUi={isNewUi}
      data={newData}
      validationSchema={validationSchema}
      leaveGuard
      leaveGuardTexts={{
        title: t('camera.settings.leaveGuard.title'),
        content: t('camera.settings.leaveGuard.content'),
      }}
      onSubmit={submit}
    >
      <CameraSettingsFormContent
        dialogActions={dialogActions}
        isNewUi={isNewUi}
        settings={settings}
        setSettings={setSettings}
        setValidationSchema={setValidationSchema}
        onReset={onReset}
      />
    </Form>
  )
}

export default CameraSettingsForm
