import { makeStyles } from '@material-ui/core/styles'
import useSelectedPhoto from 'photo-module/photo/core/useSelectedPhoto'
import PreviousButton from 'photo-module/photo/ui/photo-viewer/actions-bar/PreviousButton'
import NextButton from 'photo-module/photo/ui/photo-viewer/actions-bar/NextButton'
import downloadStatus from 'photo-module/download/downloadStatus'
import videoState from 'video-module/video-clip/core/videoState'
import VideoPreviewViewer from '../../../../video-module/preview/VideoPreviewViewer'
import H265VideoPlayerContainer from '../../../../video-module/player/H265VideoPlayerContainer'

const useStyles = makeStyles(() => ({
  photoViewer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  photo: {
    maxWidth: '100%',
    maxHeight: '100vh',
  },
}))

const SpypointPhotoViewerContainer = () => {
  const classes = useStyles()
  const photo = useSelectedPhoto()

  const renderViewer = () => {
    if (videoState.isAVideo(photo)) {
      return (
        <H265VideoPlayerContainer photo={photo} />
      )
    } else if (photo.urls.preview) {
      return <VideoPreviewViewer photo={photo} />
    } else {
      return (
        <img
          className={classes.photo}
          src={photo.hdStatus === downloadStatus.available
            ? photo.urls.hd
            : photo.urls.large}
          alt=""
        />
      )
    }
  }

  return (
    <div className={classes.photoViewer}>
      <PreviousButton photo={photo} />
      { renderViewer() }
      <NextButton photo={photo} />
    </div>
  )
}

export default SpypointPhotoViewerContainer
