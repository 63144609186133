import { makeStyles } from '@material-ui/core/styles'
import { Grid, useTheme } from '@material-ui/core'
import VisaCardImage from 'spypoint/public/images/visa-card.svg'
import MastercardImage from 'spypoint/public/images/mastercard.svg'
import AmexCardImage from 'spypoint/public/images/amex-card.svg'
import { CardElement } from '@stripe/react-stripe-js'
import AppButton from 'shared-module/components/button/AppButton'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  cardImage: {
    width: '30px',
    height: '18.75px',
    margin: '2px',
  },
  cardElement: {
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: '3px',
    padding: '16px 8px',
  },
}))

const PaymentInformationForm = ({ displayCancelButton, updatePaymentInfoState, closeForm }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()

  const options = {
    hidePostalCode: true,
    style: {
      base: {
        iconColor: theme.palette.text.primary,
        color: theme.palette.text.primary,
        '::placeholder': {
          color: theme.palette.text.secondary,
        },
      },
      invalid: {
        iconColor: theme.palette.error.main,
        color: theme.palette.error.main,
      },
    },
  }

  const updateFormComplete = event => updatePaymentInfoState(event.complete, true)

  return (
    <Grid container direction="column" spacing={2} data-testid="cardForm">
      <Grid item container justifyContent="flex-end">
        <img src={VisaCardImage} className={classes.cardImage} alt="visa-card" />
        <img src={MastercardImage} className={classes.cardImage} alt="mastercard" />
        <img src={AmexCardImage} className={classes.cardImage} alt="amex-card" />
      </Grid>
      <Grid item>
        <CardElement className={classes.cardElement} options={options} onChange={updateFormComplete} />
      </Grid>
      { displayCancelButton && (
        <Grid item style={{ alignSelf: 'flex-end', width: '130px' }}>
          <AppButton color="secondary" value={t('app:checkout.billing.cancel')} onClick={closeForm} fullWidth />
        </Grid>
      ) }
    </Grid>
  )
}

export default PaymentInformationForm
