import { Grid } from '@material-ui/core'
import SecondaryButton from 'shared-module/components/button/SecondaryButton'
import { useTranslation } from 'react-i18next'
import CardImage from 'spypoint/src/user-module/account/payment/method/CardImage'

const PaymentMethodDisplay = ({ card, openForm }) => {
  const { t } = useTranslation()

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid xs={2} md={1} item container>
        <CardImage brand={card.brand} />
      </Grid>
      <Grid xs={10} md={7} item container direction="column">
        <Grid item>{ `${t('app:account.payment.method.card.prefix')} *${card.last4}` }</Grid>
        <Grid item>{ t('app:account.payment.method.card.message') }</Grid>
      </Grid>
      <Grid xs={6} md={3} item>
        <SecondaryButton
          color="default"
          value={t('button.update')}
          onClick={openForm}
        />
      </Grid>
    </Grid>
  )
}

export default PaymentMethodDisplay
