import SvgIcon from '@material-ui/core/SvgIcon'

const Plan = (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.15 5.9969L13.7229 1.99377V4.28127C11.2414 4.28127 10.0195 4.28638 9.10658 4.29019H9.10656H9.10655C7.8333 4.29551 7.1608 4.29831 4.50998 4.28128L0.849976 9.28518L3.13748 7.71252H13.694V10L19.15 5.9969ZM6.27708 18.0063L0.850002 14.0031L6.30597 10L6.30596 12.2875L16.8625 12.2875L19.15 10.7149L15.49 15.7188C12.8392 15.7017 12.1667 15.7045 10.8935 15.7099C9.98049 15.7137 8.75864 15.7188 6.27708 15.7188V18.0063Z"
      fill="#F5F4F4"
    />

  </SvgIcon>
)

export default Plan
