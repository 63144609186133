import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const PowerMode = ({ props }) => {
  const theme = useTheme()
  return (
    <SvgIcon width="32" height="32" viewBox="0 0 32 32" {...props} data-testid="PowerMode">
      <path
        d="M28.332 13.0742H27.3477V9.82031C27.3477 9.58333 27.2474 9.39193 27.0469 9.24609C26.8464 9.08203 26.6003 9 26.3086 9H3.09375C2.78385 9 2.51953 9.08203 2.30078 9.24609C2.10026 9.39193 2 9.58333 2 9.82031V21.2227C2 21.4414 2.10026 21.6328 2.30078 21.7969C2.51953 21.9609 2.78385 22.043 3.09375 22.043H26.3086C26.6003 22.043 26.8464 21.9609 27.0469 21.7969C27.2474 21.6328 27.3477 21.4414 27.3477 21.2227V17.9141H28.332C28.6237 17.9141 28.8698 17.8411 29.0703 17.6953C29.2891 17.5312 29.3984 17.3307 29.3984 17.0938V13.9219C29.3984 13.6849 29.2891 13.4844 29.0703 13.3203C28.8698 13.1562 28.6237 13.0742 28.332 13.0742ZM13.2656 18.3242L13.293 15.5352L6.56641 15.9727L15.5625 12.582V15.3984L22.2617 14.9336L13.2656 18.3242Z"
        fill={theme.palette.text.primary}
      />
    </SvgIcon>
  )
}

export default PowerMode
