import { useUserClubMembership } from 'spypoint/src/user-module/club-membership/core/useUserClubMembership'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  footNote: {
    fontSize: '12px',
  },
}))

const FooterInfo = () => {
  const { t } = useTranslation()
  const userClubMembership = useUserClubMembership()
  const classes = useStyles()

  const isClubMember = userClubMembership.isMember

  return (
    <Box m={5}>
      { !isClubMember && (
        <Typography
          component="p"
          align="center"
          className={classes.footNote}
        >{ t('billing.free_plan_offered') }
        </Typography>
      ) }
    </Box>

  )
}

export default FooterInfo
