import generateI18N from 'shared-module/i18n/i18n'
import enApp from 'assets/locales/en/spypoint.en.json'
import frApp from 'assets/locales/fr/spypoint.fr.json'
import deApp from 'assets/locales/de/spypoint.de.json'

const appResources = {
  en: { app: enApp },
  fr: { app: frApp },
  de: { app: deApp },
}

export default generateI18N(['app'], appResources)
