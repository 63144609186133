import IconButton from '@material-ui/core/IconButton'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Tooltip from '@material-ui/core/Tooltip'
import {
  FullHdActive,
  FullHdAvailable,
  FullHdUnavailable,
} from 'assets/icons/SpypointIcons'
import downloadStatus from 'photo-module/download/downloadStatus'
import useSelectedPhoto from 'photo-module/photo/core/useSelectedPhoto'
import { useTranslation } from 'react-i18next'
import useConfirmationDialogActions from 'shared-module/components/confirmation/useConfirmationDialogActions'
import { useUserHdPackage } from 'user-module/hd-package/core/useUserHdPackage'
import DialogBuyHdPackage from './DialogBuyHdPackage'
import DialogFullHdConfirmRequest from './DialogFullHdConfirmRequest'
import DialogHdPhotoError from './DialogHdPhotoError'

const useStyles = makeStyles(theme => ({
  statusNotification: {
    display: 'inline-block',
    height: '12px',
    width: '12px',
    borderRadius: '50%',
    position: 'absolute',
    top: '10px',
    right: '10px',
  },
  pending: {
    background: theme.palette.primary.pending,
  },
  error: {
    background: theme.palette.error.main,
  },
}))

const PhotoHdButton = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const userHdPackage = useUserHdPackage()
  const photo = useSelectedPhoto()
  const photoHdRequestDialogActions = useConfirmationDialogActions()
  const buyHdPackageDialogActions = useConfirmationDialogActions()
  const photoHdErrorDialogActions = useConfirmationDialogActions()
  const shared = JSON.parse(localStorage.getItem('isShared'))

  if (!photo || !userHdPackage) {
    return null
  }

  const handleClick = () => {
    userHdPackage.remaining > 0
      ? photoHdRequestDialogActions.open()
      : buyHdPackageDialogActions.open()
  }

  let hdIcon = <IconButton color="secondary" disabled={shared} onClick={() => handleClick()}><FullHdAvailable /></IconButton>

  if (photo.hdStatus === downloadStatus.available) {
    hdIcon = <IconButton color="secondary"><FullHdActive /></IconButton>
  }

  if (photo.hdStatus === downloadStatus.unavailable) {
    hdIcon = (
      <Tooltip title={t('photo_viewer.hd.unavailable')} enterDelay={300} leaveDelay={300}>
        <span>
          <IconButton color="secondary" disabled>
            <FullHdUnavailable />
          </IconButton>
        </span>
      </Tooltip>
    )
  }

  if (photo.hdStatus === downloadStatus.pending) {
    hdIcon = (
      <Tooltip title={t('photo_viewer.hd.pending')} enterDelay={300} leaveDelay={300}>
        <span>
          <IconButton color="secondary" disabled>
            <span className={`${classes.statusNotification} ${classes.pending}`} />
            <FullHdActive />
          </IconButton>
        </span>
      </Tooltip>
    )
  }

  if (photo.hdStatus === downloadStatus.error) {
    hdIcon = (
      <IconButton disabled={shared} color="secondary" onClick={() => photoHdErrorDialogActions.open()}>
        <span className={`${classes.statusNotification} ${classes.error}`} />
        <FullHdActive />
      </IconButton>
    )
  }

  return (
    <>
      { hdIcon }
      <DialogFullHdConfirmRequest open={photoHdRequestDialogActions.isOpen} onClose={photoHdRequestDialogActions.close} />
      <DialogBuyHdPackage open={buyHdPackageDialogActions.isOpen} onClose={buyHdPackageDialogActions.close} />
      <DialogHdPhotoError open={photoHdErrorDialogActions.isOpen} onClose={photoHdErrorDialogActions.close} />
    </>
  )
}

export default PhotoHdButton
