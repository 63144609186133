import { useTheme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import InformationBarElement from 'photo-module/photos/ui/photos-gallery/information-bar/InformationBarElement'
import { useUserClubMembership } from 'spypoint/src/user-module/club-membership/core/useUserClubMembership'
import ClubMembershipIcon from 'spypoint/src/assets/icons/ClubMembershipIcon'

const MembershipElement = ({ style, className }) => {
  const theme = useTheme()
  const history = useHistory()
  const { t } = useTranslation()
  const userMembership = useUserClubMembership()

  const WhiteCMIcon = () => <ClubMembershipIcon fill={theme.palette.text.primary} />
  const OrangeCMIcon = () => <ClubMembershipIcon color="primary" style={{ display: 'block' }} fill={theme.palette.primary.main} />

  return userMembership.isMember
    ? <InformationBarElement text={t('membership.member')} Icon={WhiteCMIcon} style={style} customHover onClick={() => history.push('/membership')} className={className} />
    : <InformationBarElement text={t('membership.join')} Icon={OrangeCMIcon} color="primary" style={style} onClick={() => history.push('/membership')} customHover className={className} />
}

export default MembershipElement
