import { FormControl, FormHelperText, InputLabel, MenuItem, Select, useTheme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import CameraModeListItem from './CameraModesListItem'

const ScheduleSelector = ({ name, formik, values, startValue, onChangeValue }) => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel>{ t(`app:schedule.${name}`) }</InputLabel>
      <Select
        name={name}
        value={formik.values[name]}
        label={t(`app:schedule.${name}`)}
        onChange={(e) => {
          formik.setFieldValue(name, e.target.value)
          if (onChangeValue) onChangeValue(e.target.value)
          formik.setFieldError(name, null)
        }}
      >
        { name === 'mode'
          ? [
            <MenuItem key={`${name}-${values[0]}`} value={values[0]}>
              <CameraModeListItem text={t(`app:schedule.${values[0]}`)} border={`1px solid ${theme.palette.background.tile}`} color="primary" />
            </MenuItem>,
            <MenuItem key={`${name}-${values[1]}`} value={values[1]}>
              <CameraModeListItem text={t(`app:schedule.${values[1]}`)} fill={theme.palette.background.default} />
            </MenuItem>,
            ]
          : values.map((item, i) => {
            if ((typeof startValue === 'undefined' && values.length !== i + 1) || (i > startValue && i !== 0)) {
              return (
                <MenuItem key={`${name}-${item}`} value={i}>
                  { item }
                </MenuItem>
              )
            }
          }) }
      </Select>
      { formik.errors[name] && <FormHelperText style={{ color: 'red' }}>{ formik.errors[name] }</FormHelperText> }
    </FormControl>
  )
}

export default ScheduleSelector
