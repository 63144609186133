import PaymentInformationDisplay from 'spypoint/src/checkout-module/order/ui/checkout-page/payment/PaymentInformationDisplay'
import PaymentInformationForm from 'spypoint/src/checkout-module/order/ui/checkout-page/payment/PaymentInformationForm'
import { Box, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  paperWrapper: {
    background: theme.palette.primary.contrastText,
    padding: '24px',
  },
  paymentInfoTitle: {
    fontFamily: 'oswald-normal',
    marginBottom: '30px',
    fontSize: '18px',
  },
}))

const PaymentInformation = ({ paymentInfo, paymentInfoState, updatePaymentInfoState }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  if (!paymentInfo) {
    return <CircularProgress data-testid="progressSpinner" />
  }

  return (
    <Box className={classes.paperWrapper}>
      <Box className={classes.paymentInfoTitle}>
        { t('menu.payment') }
      </Box>
      { !paymentInfoState.isOpenForm
        ? (
          <PaymentInformationDisplay
            card={paymentInfo?.card}
            openForm={() => updatePaymentInfoState(false, true)}
          />
          )
        : (
          <PaymentInformationForm
            displayCancelButton={!!paymentInfo?.isAvailable}
            updatePaymentInfoState={updatePaymentInfoState}
            closeForm={() => updatePaymentInfoState(true, false)}
          />
          ) }
    </Box>
  )
}

export default PaymentInformation
