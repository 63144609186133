import { useTheme } from '@material-ui/core'
import useMediaQuery from '@mui/material/useMediaQuery'

import MembershipElement from 'photo-module/photos/ui/photos-gallery/information-bar/elements/MembershipElement'
import PhotoCountElement from 'photo-module/photos/ui/photos-gallery/information-bar/elements/PhotoCountElement'
import HdPhotosCountElement from 'photo-module/photos/ui/photos-gallery/information-bar/elements/HdPhotosCountElement'
import FavoritesCountElement from 'photo-module/photos/ui/photos-gallery/information-bar/elements/FavoritesCountElement'
import CameraNameElement from 'photo-module/photos/ui/photos-gallery/information-bar/elements/CameraNameElement'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import VideosCountElement from 'photo-module/photos/ui/photos-gallery/information-bar/elements/VideosCountElement'
import PlanNameElement from 'photo-module/photos/ui/photos-gallery/information-bar/elements/PlanNameElement'
import CameraUpload from 'photo-module/photos/ui/photos-gallery/upload-photo/CameraUpload'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  cameraName: {
    textOverflow: 'ellipsis',
    maxWidth: '80px',
    fontSize: '0.875rem',
  },
}))
const InformationBarItems = ({ hideCameraName = false, style, className }) => {
  const theme = useTheme()
  const selectedCamera = useSelectedCamera()
  const breakpoint = useMediaQuery(theme.breakpoints.down('xs'))
  const classes = useStyles()
  const isCellularCamera = selectedCamera && selectedCamera.isCellular
  const shared = JSON.parse(localStorage.getItem('isShared'))

  return (
    <>
      <MembershipElement style={style} className={className} />
      { isCellularCamera && (
        <>
          <PlanNameElement className={className} />
          <PhotoCountElement style={style} className={className} />
          <HdPhotosCountElement style={style} className={className} />
          <VideosCountElement style={style} className={className} />
          <FavoritesCountElement style={style} className={className} />
        </>
      ) }
      { !hideCameraName && <CameraNameElement className={classes.cameraName} customHover /> }
      { !breakpoint && selectedCamera && !selectedCamera.isCellular && !shared && <CameraUpload style={style} className={className} /> }
    </>
  )
}

export default InformationBarItems
