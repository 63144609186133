import { useTranslation } from 'react-i18next'

import { mobileBenefits } from 'spypoint/src/user-module/club-membership/core/insidersClubBenefits.selectors'
import CBenefitsMobileItem from 'storybook-component-module/src/components/BenefitsMobileItem/CBenefitsMobileItem'

const MembershipMainBenefitsMobile = () => {
  const { t } = useTranslation()

  return mobileBenefits.map(benefit => (
    <CBenefitsMobileItem
      key={benefit.id}
      icon={benefit.icon}
      description={t(benefit.description)}
      title={t(benefit.title)}
      showAsterisk={benefit.isExclusiveToNa}
    />
  ),
  )
}

export default MembershipMainBenefitsMobile
