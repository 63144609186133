import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@material-ui/core'
import MaskedInput from 'react-text-mask'
import FormTextField from 'shared-module/new-components/form/FormTextField'

const PromoCodeInputMask = ({ inputRef, ...other }) => {
  const _ = /[A-Za-z0-9]/
  return (
    <MaskedInput
      {...other}
      ref={(ref) => { inputRef(ref ? ref.inputElement : null) }}
      mask={[_, _, _, _, '-', _, _, _, _, '-', _, _, _, _]}
    />
  )
}

const PromoCode = ({ valid, getPromoCode }) => {
  const { t } = useTranslation()
  const [promoCode, setPromoCode] = useState('')

  const handleChange = (event) => {
    const code = event.target.value.toUpperCase()
    setPromoCode(code)

    valid(isValid(code))
    getPromoCode(code.replace(/[-_]/g, ''))
  }

  const isValid = (promoCode) => (promoCode === '' || /[0-9A-Z]{4}-[0-9A-Z]{4}-[0-9A-Z]{4}/.test(promoCode))

  return (
    <>
      <Typography variant="h5" color={!isValid(promoCode) ? 'error' : 'textPrimary'} style={{ fontSize: '16px', paddingBottom: '10px' }}>{ t('add_camera.promo_code.label') }</Typography>
      <FormTextField
        id="promocode"
        name="promocode"
        label={t('redeem_promo_code.form.input')}
        placeholder="____-____-____"
        InputLabelProps={{ shrink: true }}
        InputProps={{ inputComponent: PromoCodeInputMask }}
        value={promoCode}
        onChange={handleChange}
        error={!isValid(promoCode)}
        style={{ maxWidth: '195px' }}
      />
    </>
  )
}

export default PromoCode
