import MenuItem from 'shared-module/navigation/menu/MenuItem'
import HelpIcon from 'assets/icons/navigation/HelpIcon'
import apm from 'shared-module/monitoring/apm'
import useLanguage from 'shared-module/components/language/useLanguage'

const SpypointHelpMenuItem = ({ onClick }) => {
  const language = useLanguage()

  const showHelp = () => {
    onClick()
    apm.log('help')
    if (language === 'en' || language === 'fr') {
      window.open(`https://www.spypoint.com/${language}/support`, '_blank')
    } else { window.open('https://www.spypoint.info/support', '_blank') }
  }

  return <MenuItem Icon={HelpIcon} text="menu.help" onClick={showHelp} />
}

export default SpypointHelpMenuItem
