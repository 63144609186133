const PERKS = 'PERKS'
const FAVORITE = 'FAVORITE'
const FREE_PHOTOS = 'FREE_PHOTOS'
const BUCKTRACKER = 'BUCKTRACKER'
const CAMERA_SHARING = 'CAMERA_SHARING'
const PHOTO_HISTORY = 'PHOTO_HISTORY'
const FREE_HD_PHOTOS = 'FREE_HD_PHOTOS'
const FREE_SHIPPING = 'FREE_SHIPPING'
const PREMIUM_SERVICES = 'PREMIUM_SERVICES'
const MONTHLY_GIVEAWAY = 'MONTHLY_GIVEAWAY'
const DISCOUNT = 'DISCOUNT'
const FULL_HD_PHOTO_VIDEO = 'FULL_HD_PHOTO_VIDEO'
const PHOTO_TRANSMISSION = 'PHOTO_TRANSMISSION'
const ACCESSORIES = 'ACCESSORIES'

const iCBenefitTypes = {
  free_photos: FREE_PHOTOS,
  bucktracker: BUCKTRACKER,
  camera_sharing: CAMERA_SHARING,
  photo_history: PHOTO_HISTORY,
  favorite: FAVORITE,
  free_hd_photos: FREE_HD_PHOTOS,
  monthly_giveaway: MONTHLY_GIVEAWAY,
  free_shipping: FREE_SHIPPING,
  premium_services: PREMIUM_SERVICES,
  perks: PERKS,
  full_hd_photo_video: FULL_HD_PHOTO_VIDEO,
  photo_transmission: PHOTO_TRANSMISSION,
  accessories: ACCESSORIES,
  discount: DISCOUNT,
}

export { iCBenefitTypes }
