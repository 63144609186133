import Grid from '@material-ui/core/Grid'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PrimaryButton from 'shared-module/components/button/PrimaryButton'
import SecondaryButton from 'shared-module/components/button/SecondaryButton'
import useCameraSchedule, { SCHEDULE_TYPES } from '../../../../core/useCameraSchedule'
import ScheduleDays from './ScheduleDays'
import ScheduleSelector from './ScheduleSelector'

const ScheduleCreationForm = ({
  selectedPeriod,
  formik,
  startValue,
  setStartValue,
  onDeletePeriod,
}) => {
  const { t } = useTranslation()
  const items = [SCHEDULE_TYPES.instant, SCHEDULE_TYPES.standard]
  const { getHoursForSelect } = useCameraSchedule()
  useEffect(() => {
    if (selectedPeriod) {
      setStartValue(selectedPeriod.startTime.hour)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPeriod])
  const onChangeStartTime = (value) => {
    setStartValue(value)
  }
  useEffect(() => {
    formik.resetForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <form style={{ padding: 15 }} onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>

        <ScheduleDays formik={formik} selectedPeriod={selectedPeriod} />
        <Grid item sm={6} xs={12}>
          <ScheduleSelector
            name="start_time"
            formik={formik}
            values={getHoursForSelect}
            onChangeValue={onChangeStartTime}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <ScheduleSelector
            name="end_time"
            formik={formik}
            values={getHoursForSelect}
            startValue={startValue}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <ScheduleSelector name="mode" formik={formik} values={items} />
        </Grid>
      </Grid>
      <Grid container style={{ paddingTop: 10 }} spacing={2} justifyContent="flex-end" alignItems="flex-end">
        <Grid item>
          { selectedPeriod && (
            <SecondaryButton
              onClick={() => onDeletePeriod(selectedPeriod)}
              disabled={formik.dirty}
              align="right"
              color="primary"
              value={t('app:schedule.delete_button')}
            >
              { t('app:schedule.delete_button') }
            </SecondaryButton>
          ) }
        </Grid>
        <Grid item>
          <PrimaryButton
            type="submit"
            align="right"
            disabled={formik.values.days.length === 0}
            color="primary"
            value={t('app:schedule.apply_button')}
          />
        </Grid>
      </Grid>
    </form>
  )
}

export default ScheduleCreationForm
