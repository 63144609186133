import { useTranslation } from 'react-i18next'
import { Link } from '@material-ui/core'
import useLanguage from 'shared-module/components/language/useLanguage'

const ReferralLink = () => {
  const { t } = useTranslation()
  const language = useLanguage()

  const href = language === 'en' ? 'https://www.spypoint.com/en/spypoint-experience/referral-program' : 'https://www.spypoint.com/fr/experience-spypoint/programme-referencement'

  return <Link href={href} target="_blank" rel="noreferrer">{ t('redeem_promo_code.info.link') }</Link>
}

export default ReferralLink
