import activatedCameraState from 'camera-module/new-activation/core/activatedCamera.state'
import { SET_ACTIVATED_CAMERA } from 'camera-module/new-activation/core/activatedCamera.actions'

const activatedCameraReducer = (state = activatedCameraState.defaultState, action) => {
  switch (action.type) {
    case SET_ACTIVATED_CAMERA:
      return activatedCameraState.set(action.camera)

    default:
      return state
  }
}

export default activatedCameraReducer
