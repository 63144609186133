import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress, Grid } from '@material-ui/core'

import useUser from 'user-module/user/core/useUser'
import useUserActions from 'user-module/user/core/useUserActions'
import CButton from 'storybook-component-module/src/components/buttons/CButton'
import { useCart } from 'spypoint/src/checkout-module/order/core/cart.hooks'
import cartActions from 'spypoint/src/checkout-module/order/core/cart.actions'

const useStyles = makeStyles((theme) => ({
  checkoutButtons: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  cancelButton: {
    width: '100%',
    boxShadow: 'none',
    color: theme.palette.basic?.white,
    background: theme.palette.background?.tile,
    '&:hover': {
      background: theme.palette.background?.tile + 'B3',
    },
  },
  button: {
    width: '100%',
    boxShadow: 'none',
  },
}))

const CheckoutActionButtons = ({ stripe, elements, spinner, purchaseDisabled, buttonDisabled, className, submitPayment = null, setCheckoutIsExited, isActivation, setStep }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const user = useUser()
  const history = useHistory()
  const dispatch = useDispatch()
  const { reload } = useUserActions()
  const { cartItems, deleteFromCart } = useCart()

  const handleCancelClick = async () => {
    setCheckoutIsExited(true)
    await deleteFromCart({ itemId: cartItems?.products[0]?.id })
    reload(user)
    dispatch(cartActions.fetchCart())

    isActivation
      ? setStep(1)
      : history.goBack()
  }

  return (
    <Grid container item spacing={2} className={clsx(classes.checkoutButtons && className)}>
      <Grid item xs={12} sm={6}>
        <CButton variety="primary" className={classes.cancelButton} onClick={handleCancelClick}>
          { t('button.cancel') }
        </CButton>
      </Grid>

      <Grid item xs={12} sm={6} container justifyContent="center">
        { !spinner
          ? (
            <CButton
              id="place_order"
              type="submit"
              color="primary"
              variety="primary"
              className={classes.button}
              disabled={purchaseDisabled || !stripe || !elements || buttonDisabled}
              onClick={submitPayment}
            >
              { t('app:checkout.place_order') }
            </CButton>
            )
          : <CircularProgress id="spinner" /> }
      </Grid>
    </Grid>
  )
}

export default CheckoutActionButtons
