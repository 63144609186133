import { useEffect, useRef, useState } from 'react'
import videojs from 'video.js'
import 'video.js/dist/video-js.min.css'
import videoState from 'video-module/video-clip/core/videoState'
import H265VideoPlayer from 'spypoint/src/video-module/player/H265VideoPlayer'
import Box from '@material-ui/core/Box'
import './H265Player.css'

const H265VideoPlayerContainer = ({ photo }) => {
  const playerRef = useRef(null)
  const videoRef = useRef(null)
  const [notSupported, setNotSupported] = useState(false)

  useEffect(() => {
    let player
    if (playerRef.current) {
      player = playerRef.current
    } else {
      if (!videoRef.current) return
      player = playerRef.current = videojs(videoRef.current, {
        controlBar: { pictureInPictureToggle: false },
        autoplay: false,
        muted: true,
        controls: true,
        errorDisplay: false,
        preload: 'auto',
      })
    }
    player.poster(photo.urls.large)
    player.posterImage.off(['click', 'tap'])
    player.src(videoState.getVideoUrl(photo))
    if (!playerRef.current.canPlayType('video/mp4; codecs="hev1"')) {
      player.bigPlayButton.hide()
      setNotSupported(true)
    }
  }, [photo, videoRef])

  return (
    <Box margin="auto" width={{ xs: '100%', md: '60%' }} zIndex="tooltip">
      <div data-testid="videoplayer" data-vjs-player>
        { notSupported && <H265VideoPlayer photo={photo} videoJS={playerRef.current} /> }
        <video ref={videoRef} className="video-js vjs-16-9 vjs-big-play-centered" style={{ outline: 'none' }} />
      </div>
    </Box>
  )
}

export default H265VideoPlayerContainer
