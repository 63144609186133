import SecondaryButton from 'shared-module/new-components/buttons/SecondaryButton'
import { Trans } from 'react-i18next'
import { Box } from '@material-ui/core'
import usePaymentHistoryActions from 'spypoint/src/user-module/payment-history/core/usePaymentHistoryActions'

const PaymentHistoryLoad = ({ lastLoadedInvoiceId }) => {
  const { getPaymentHistory } = usePaymentHistoryActions()

  const loadMore = () => {
    getPaymentHistory(lastLoadedInvoiceId)
  }

  return (
    <Box sx={{
      marginTop: 16,
      display: 'flex',
      justifyContent: 'space-around',
    }}
    >
      <SecondaryButton onClick={loadMore}>
        <Trans i18nKey="app:account.payment.history.load" />
      </SecondaryButton>
    </Box>
  )
}

export default PaymentHistoryLoad
