import { useUserClubMembership } from 'spypoint/src/user-module/club-membership/core/useUserClubMembership'
import FavoriteButton from 'photo-module/photo/ui/photo-viewer/actions-bar/FavoriteButton'
import useConfirmationDialogActions from 'shared-module/components/confirmation/useConfirmationDialogActions'
import JoinTheClubDialog from 'spypoint/src/user-module/club-membership/ui/join-the-club-dialog/JoinTheClubDialog'
import FavoriteButtonLayout from 'photo-module/photo/ui/photo-viewer/actions-bar/FavoriteButtonLayout'

const MemberFavoriteButton = () => {
  const club = useUserClubMembership()
  const dialogActions = useConfirmationDialogActions()

  return club?.isMember
    ? <FavoriteButton />
    : (
      <>
        <FavoriteButtonLayout onClick={() => dialogActions.open()} />
        <JoinTheClubDialog open={dialogActions.isOpen} onClose={dialogActions.close} />
      </>
      )
}

export default MemberFavoriteButton
