import { useTranslation } from 'react-i18next'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useCart } from 'spypoint/src/checkout-module/order/core/cart.hooks'
import CartDivider from 'spypoint/src/checkout-module/order/ui/cart-lines/CartDivider'
import Amount from 'shared-module/components/amount/Amount'
import useUser from 'user-module/user/core/useUser'

const useStyles = makeStyles((theme) => ({
  savings: {
    fontSize: '1.625rem',
    fontFamily: 'oswald-normal',
    fontWeight: '500',
    textAlign: 'center',
    color: theme.palette.primary.main,
    flex: 'none',
    order: 10,
    flexGrow: 0,
  },
}))
const CartSavingsLine = () => {
  const classes = useStyles()
  const user = useUser()
  const { cartDiscount, isPromoCodeApplied } = useCart()
  const { t } = useTranslation()

  return cartDiscount && cartDiscount > 0
    ? (
      <Grid item>
        <CartDivider />
        <Typography className={classes.savings}>
          <span> { isPromoCodeApplied ? t('app:checkout.cart_savings') : t('app:checkout.insiders_club_savings') } </span>
          { ' ' }
          <Amount value={cartDiscount} currencyCode={user.currencyCode} />
        </Typography>
      </Grid>
      )
    : null
}

export default CartSavingsLine
