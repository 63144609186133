// React Core imports
import { Component } from 'react'
// Material-UI imports
import withStyles from '@material-ui/core/styles/withStyles'

const styles = theme => ({
  root: {
    position: 'relative',
    height: '40px',
    width: '220px',
    margin: '5px',
  },
  title: {
    position: 'absolute',
    width: '100%',
    textAlign: 'center',
    fontFamily: theme.typography.h3.fontFamily,
    fontSize: '24pt',
    textTransform: 'uppercase',
    color: theme.palette.text.primary,
    zIndex: '10',
    fontWeight: '700',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
      paddingLeft: '12px',
    },
  },
})

class PlanBlockTitle extends Component {
  render () {
    const { classes } = this.props

    return (
      <div className={classes.root}>
        <div className={classes.title}>
          { this.props.title }
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(PlanBlockTitle)
