import { Component, createRef } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import DialogActions from '@material-ui/core/DialogActions'
import Spinner from 'shared-module/components/Spinner'
import DialogBox from 'shared-module/components/DialogBox'
import PrimaryButton from 'shared-module/components/button/PrimaryButton'
import { SpypointPhotoHelper } from 'photo-module/photos/ui/photos-gallery/upload-photo/SpypointPhoto.helper'
import messageActions from 'shared-module/message/messageActions'
import apm from 'shared-module/monitoring/apm'
import InformationBarElement from '../information-bar/InformationBarElement'
import UploadIcon from 'assets/icons/photo/UploadIcon'

class CameraUpload extends Component {
  constructor (props) {
    super(props)

    this.handleOpenUploadImage = this.handleOpenUploadImage.bind(this)
    this.handleFileChange = this.handleFileChange.bind(this)

    this.state = {
      isVisibleSpinner: false,
      isUploadDialogOpen: false,
    }

    // File management
    this.fileInput = createRef()

    // Camera data
    this.cameraId = props.cameraId
  }

  handleOpenUploadImage (event) {
    event.preventDefault()

    // Click file input
    this.fileInput.current.click()
  }

  /**
   * Upload photo on file change event
   * @param {Event} event
   */
  handleFileChange (event) {
    event.preventDefault()

    const { selectedCamera } = this.props

    // Reset message
    this.setState({ isVisibleSpinner: true })

    // Upload photos list
    const spypointHeper = new SpypointPhotoHelper(selectedCamera.status.model)
    spypointHeper.uploadPhotos(this.fileInput.current.files, selectedCamera.id)
      .then(() => {
        // Open dialog
        this.setState({ isUploadDialogOpen: true })
        apm.log('upload-photo', [{ cameraModel: selectedCamera.status.model }])
      })
      .catch(error => {
        this.props.dispatch(messageActions.showError(error.message))
        apm.log('upload-photo-error', [{ cameraModel: selectedCamera.status.model, uploadPhotoError: error }])
      })
      .finally(() => {
        // End loading
        this.setState({ isVisibleSpinner: false })
      })
  }

  handleUploadDialogClose = () => {
    this.setState({ isUploadDialogOpen: false })
  }

  renderDialog () {
    return (
      <DialogBox
        title={this.props.t('camera_upload.dialog_title')}
        open={this.state.isUploadDialogOpen}
        onClose={this.handleUploadDialogClose}
      >
        <div>
          <p>{ this.props.t('camera_upload.dialog_confirmation') }</p>
          <p>{ this.props.t('camera_upload.dialog_warning_plan') }</p>
          <DialogActions>
            <PrimaryButton onClick={this.handleUploadDialogClose} value={this.props.t('button.close')} />
          </DialogActions>
        </div>
      </DialogBox>
    )
  }

  render () {
    return (
      <>
        <InformationBarElement
          text={this.props.t('camera_upload.upload_photos')}
          style={this.props.style}
          Icon={UploadIcon}
          customHover
          onClick={this.handleOpenUploadImage}
          className={this.props.className}
        />

        <input
          type="file"
          ref={this.fileInput}
          onChange={this.handleFileChange}
          style={{ display: 'none' }}
          multiple
          accept="image/jpeg"
        />

        { this.state.isVisibleSpinner && <Spinner /> }

        { this.renderDialog() }
      </>
    )
  }
}

const mapStateToProps = state => ({
  selectedCamera: state.selectedCamera.camera,
})

export default compose(
  withTranslation(),
  connect(mapStateToProps),
)(CameraUpload)
