import { useTranslation } from 'react-i18next'
import Typography from '@material-ui/core/Typography'
import FormTextField from 'shared-module/new-components/form/FormTextField'

const Referrer = ({ referrer, setReferrer }) => {
  const { t } = useTranslation()
  return (
    <>
      <Typography variant="h5" color="textPrimary" style={{ fontSize: '16px', paddingBottom: '10px' }}>{ t('add_camera.referrer.label') }</Typography>
      <FormTextField
        id="referrer"
        name="referrer"
        autoComplete="nope"
        label={t('add_camera.referrer.placeholder')}
        value={referrer}
        onChange={(e) => setReferrer(e.target.value)}
      />
    </>
  )
}

export default Referrer
