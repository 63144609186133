import { Box, Grid, Link, Typography } from '@material-ui/core'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import { makeStyles } from '@material-ui/core/styles'
import { Trans, useTranslation } from 'react-i18next'
import Amount from 'shared-module/components/amount/Amount'
import { getUserCurrency } from 'shared-module/common-utils'

const useStyles = makeStyles(() => ({
  link: {
    width: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  bold: {
    fontWeight: 600,
  },
}))

const dateOption = { day: '2-digit', month: 'long', year: 'numeric' }

const getDateString = (time, language) => {
  const date = new Date(time * 1000)
  return date.toLocaleDateString(language, dateOption)
}

const PaymentHistoryItem = ({ payment, index }) => {
  const classes = useStyles()
  const { i18n } = useTranslation()
  const currency = getUserCurrency()

  return (
    <Box
      borderTop={index !== 0 ? 2 : 'none'}
      borderColor="divider"
      paddingY={2}
      paddingX={{
        xs: 0,
        sm: 3,
      }}
    >
      <Grid container spacing={1}>
        <Grid xs={12} item container justifyContent="space-between">
          <Grid item>{ getDateString(payment.created, i18n.language) }</Grid>
          <Grid className={classes.bold} item>
            <Amount value={payment.total / 100} currencyCode={currency} />
          </Grid>
        </Grid>
        <Grid xs={12} item container justifyContent="space-between">
          <Grid item>
            <Box color="text.tertiary">
              <Typography variant="caption">{ payment.invoice_number }</Typography>
            </Box>
          </Grid>
          <Grid item>
            <Link
              href={payment.invoice_url}
              target="_blank"
              className={classes.link}
              starticon={<SaveAltIcon />}
              style={{ cursor: 'pointer' }}
            >
              <SaveAltIcon style={{ fontSize: 18 }} />
              <Box sx={{
                marginLeft: 4,
              }}
              >
                <Trans i18nKey="app:account.payment.history.invoice" />
              </Box>
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default PaymentHistoryItem
