const PhotoHDSteps = [
  'app:purchasing_flow.photo_hd.login',
  'app:purchasing_flow.photo_hd.complete_profile',
  'app:purchasing_flow.photo_hd.complete_checkout',
]

export const InstructionsKeys = {
  login: {
    displayBecomeMemberInstructions: [
      'app:become_club_member.login',
      'app:become_club_member.complete_profile_login',
      'app:become_club_member.complete_checkout',
    ],
    displayTransmissionPlanInstructions: [
      'app:purchasing_flow.transmission_plan.login',
      'app:purchasing_flow.transmission_plan.complete_profile_login',
      'app:purchasing_flow.transmission_plan.select_device',
      'app:purchasing_flow.transmission_plan.complete_checkout',
    ],
    displayPhotoHDInstructions: [...PhotoHDSteps],
    displayCartBecomeMemberInstructions: [
      'app:purchasing_flow.cart.login.become_member',
    ],
    displayCartTransmissionPlanInstructions: [
      'app:purchasing_flow.cart.login.transmission_plan',
    ],
    displayCartPhotoHDInstructions: ['app:purchasing_flow.cart.login.photo_hd'],
    displayCartVideoHDInstructions: ['app:purchasing_flow.cart.login.video_hd'],
    displayActiveAccountInstructions: [
      'app:active_device.create_account',
      'app:active_device.activate_device'],
    displaySharedCamerasInstructions: [
      'app:share_camera.access_camera',
      'app:share_camera.access_camera_step2',
      'app:share_camera.access_camera_step3',
    ],
  },
  register: {
    displayBecomeMemberInstructions: [
      'app:become_club_member.create_account',
      'app:become_club_member.complete_profile',
      'app:become_club_member.complete_checkout',
    ],
    displayTransmissionPlanInstructions: [
      'app:purchasing_flow.transmission_plan.create_account',
      'app:purchasing_flow.transmission_plan.complete_profile',
      'app:purchasing_flow.transmission_plan.select_device',
      'app:purchasing_flow.transmission_plan.complete_checkout',
    ],
    displayPhotoHDInstructions: [...PhotoHDSteps],
    displayCartBecomeMemberInstructions: [
      'app:purchasing_flow.cart.register.become_member',
    ],
    displayCartTransmissionPlanInstructions: [
      'app:purchasing_flow.cart.register.transmission_plan',
    ],
    displayCartPhotoHDInstructions: [
      'app:purchasing_flow.cart.register.photo_hd',
    ],
    displayCartVideoHDInstructions: [
      'app:purchasing_flow.cart.register.video_hd',
    ],
    displayActiveAccountInstructions: [
      'app:active_device.create_account',
      'app:active_device.activate_device'],
    displaySharedCamerasInstructions: [
      'app:share_camera.register',
      'app:share_camera.register_step2',
    ],
  },
}

export const InstructionsTitle = {
  displayBecomeMemberInstructions: 'app:become_club_member.title',
  displayTransmissionPlanInstructions: 'app:purchasing_flow.transmission_plan.title',
  displayPhotoHDInstructions: 'app:purchasing_flow.photo_hd.title',
  displayActiveAccountInstructions: 'app:active_device.title',
  displaySharedCamerasInstructions: 'app:share_camera.title',
}

export const InstructionsCartTitle = {
  login: 'app:purchasing_flow.cart.login.title',
  register: 'app:purchasing_flow.cart.register.title',
}
export const InstructionsProfileTitle = {
  login: 'app:account.profile.pageTitle',
  register: 'app:account.profile.pageTitle',
}

export const InstructionsPaymentsTitle = {
  login: 'app:account.payments',
  register: 'app:account.payments',
}
