export const getStripeElementOptions = (theme, paymentIntentData) => {
  return {
    clientSecret: paymentIntentData?.clientSecret,
    fonts: [
      {
        family: 'ibm-plex-sans-regular',
        src: 'url(https://fonts.gstatic.com/s/ibmplexsans/v19/zYXgKVElMYYaJe8bpLHnCwDKhdHeFaxOedc.woff2) format(\'woff2\')',
      },
    ],
    appearance: {
      theme: 'flat',
      labels: 'floating',
      rules: {
        '.Error': {
          fontSize: '0.75rem',
        },
        '.Label': {
          color: theme.palette.secondary.light,
        },
        '.Input': {
          color: theme.palette.text.primary,
          padding: '0.625rem 0.925rem',
          border: `1px solid ${theme.palette.background?.tile}`,
        },
        '.Input::placeholder': {
          color: theme.palette.secondary.light,
        },
        '.Input:focus': {
          boxShadow: '0 0 0 0 transparent, 0 0 0 2px transparent',
          border: `1px solid ${theme.palette.primary.main}`,
        },
        '.Input--invalid': {
          boxShadow: '0 0 0 0 transparent, 0 0 0 2px transparent',
          border: `1px solid ${theme.palette.error.main}`,
        },
        '.Tab': {
          color: theme.palette.secondary.light,
          border: `1px solid ${theme.palette.background?.tile}`,
        },
        '.Tab:focus': {
          boxShadow: '0 0 0 0 transparent, 0 0 0 2px transparent',
          border: `1px solid ${theme.palette.primary.main}`,
        },
        '.Tab--selected': {
          backgroundColor: 'transparent',
          color: theme.palette.primary.main,
          boxShadow: '0 0 0 0 transparent, 0 0 0 2px transparent',
          border: `1px solid ${theme.palette.primary.main}`,
        },
        '.Tab--selected:hover': {
          color: theme.palette.primary.main,
          border: `1px solid ${theme.palette.primary.main}`,
        },
        '.Tab--selected:focus': {
          boxShadow: '0 0 0 0 transparent, 0 0 0 2px transparent',
          border: `1px solid ${theme.palette.primary.main}`,
        },
        '.Tab:hover': {
          color: theme.palette.text?.tertiary,
          border: `1px solid ${theme.palette.secondary.main}`,
        },
        '.TabIcon': {
          fill: theme.palette.secondary.light,
        },
        '.TabIcon--selected': {
          fill: theme.palette.basic?.white,
        },
        '.Block': {
          backgroundColor: 'transparent',
          border: `1px solid ${theme.palette.background?.tile}`,
        },
        '.RedirectText': {
          color: theme.palette.text?.tertiary,
        },
      },
      variables: {
        borderRadius: '0.25rem',
        colorText: theme.palette.text?.tertiary,
        colorDanger: theme.palette.error.main,
        fontFamily: theme.typography.fontFamily,
        colorPrimary: theme.palette.primary.main,
        colorBackground: theme.palette.background?.contrastText,
      },
    },
  }
}
