import { useTranslation } from 'react-i18next'
import FilterButton from 'photo-module/photos/ui/photos-gallery/filter-bar/FilterButton'
import useFilters from 'photo-module/filters/core/useFilters'
import useFiltersActions from 'photo-module/filters/core/useFiltersActions'
import { useUserClubMembership } from 'spypoint/src/user-module/club-membership/core/useUserClubMembership'
import JoinTheClubDialog from 'spypoint/src/user-module/club-membership/ui/join-the-club-dialog/JoinTheClubDialog'
import useConfirmationDialogActions from 'shared-module/components/confirmation/useConfirmationDialogActions'

const SpeciesFilterButton = ({ species, speciesIcon }) => {
  const { t } = useTranslation()
  const userMembership = useUserClubMembership()
  const filters = useFilters()
  const filtersActions = useFiltersActions()
  const dialogActions = useConfirmationDialogActions()

  const onToggle = () => userMembership.filters.includes(species) ? filtersActions.toggleSpeciesFilter(species) : dialogActions.open()

  return (
    <>
      <FilterButton
        iconImage={<img src={speciesIcon} alt={species} height={20} width={24} />}
        text={t(`bucktracker.species.${species}`)}
        disabled={!userMembership.filters.includes(species)}
        selected={filters.species.includes(species)}
        onToggle={onToggle}
      />
      <JoinTheClubDialog open={dialogActions.isOpen} onClose={dialogActions.close} />
    </>
  )
}

export default SpeciesFilterButton
