import { Grid } from '@material-ui/core'
import ProfileInput from 'spypoint/src/user-module/account/profile/info/ProfileInput'
import ProfileCell, { getInitialCell } from 'spypoint/src/user-module/account/profile/info/ProfileCell'
import ProfileCountry from 'spypoint/src/user-module/account/profile/info/ProfileCountry'
import ProfileTerritory from 'spypoint/src/user-module/account/profile/info/ProfileTerritory'
import Paper from '@material-ui/core/Paper'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  paper: {
    background: theme.palette.primary.contrastText,
    marginTop: 24,
    padding: 32,
  },
}))

export const userInfoInitialValues = (user) => ({
  firstName: user.firstName || '',
  lastName: user.lastName || '',
  company: user.company || '',
  cell: getInitialCell(user),
  address: user.address || '',
  apartment: user.apartment || '',
  city: user.city || '',
  postalCode: user.postalCode || '',
  country: user.country || '',
  territory: user.territory || '',
  email: user.email || '',
})

const regexText = /^[a-zA-Z\-' \u00C0-\u00FF.]{1,30}$/
const regexTextAll = /^[a-zA-Z\-'., 0-9\u00C0-\u00FF]{1,30}$/

export const userInfoValidationSchema = {
  firstName: Yup.string().required('input.first_name_required').trim().matches(regexText, 'input.first_name_invalid'),
  lastName: Yup.string().required('input.last_name_required').trim().matches(regexText, 'input.last_name_invalid'),
  company: Yup.string().trim().matches(regexTextAll, 'input.company_invalid'),
  address: Yup.string().required('input.address_required').trim().matches(regexTextAll, 'input.address_invalid'),
  apartment: Yup.string().trim().matches(regexTextAll, 'input.apartment_invalid'),
  city: Yup.string().required('input.city_required').trim().matches(regexText, 'input.city_invalid'),
  postalCode: Yup.string().required('input.postal_code_required'),
  country: Yup.string().required('input.country_required').trim().min(2, 'input.country_invalid'),
  territory: Yup.string().required('input.territory_required').trim().min(4, 'input.territory_invalid'),
}

const ProfileInfo = ({ form, formik, readOnly }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Paper className={classes.paper} elevation={3} square>
      <Grid container spacing={2}>
        <Grid xs={12} sm={6} item>
          <ProfileInput
            form={form}
            formik={formik}
            name="firstName"
            label={`${t('input.first_name')}*`}
            disabled={readOnly}
          />
        </Grid>
        <Grid xs={12} sm={6} item>
          <ProfileInput
            form={form}
            formik={formik}
            name="lastName"
            label={`${t('input.last_name')}*`}
            disabled={readOnly}
          />
        </Grid>
        <Grid xs={12} sm={6} item>
          <ProfileInput
            form={form}
            formik={formik}
            name="company"
            label={`${t('input.company')}`}
            disabled={readOnly}
          />
        </Grid>
        <Grid xs={12} sm={6} item>
          <ProfileCell
            form={form}
            formik={formik}
            name="cell"
            label={`${t('input.phone')}*`}
            disabled={readOnly}
          />
        </Grid>
        <Grid xs={12} sm={8} item>
          <ProfileInput
            form={form}
            formik={formik}
            name="address"
            label={`${t('input.address')}*`}
            disabled={readOnly}
          />
        </Grid>
        <Grid xs={12} sm={4} item>
          <ProfileInput
            form={form}
            formik={formik}
            name="apartment"
            label={`${t('input.apartment')}`}
            disabled={readOnly}
          />
        </Grid>
        <Grid xs={12} sm={8} item>
          <ProfileInput
            form={form}
            formik={formik}
            name="city"
            label={`${t('input.city')}*`}
            disabled={readOnly}
          />
        </Grid>
        <Grid xs={12} sm={4} item>
          <ProfileInput
            form={form}
            formik={formik}
            name="postalCode"
            label={`${t('input.postal_code')}*`}
            disabled={readOnly}
          />
        </Grid>
        <Grid xs={12} sm={6} item>
          <ProfileCountry
            form={form}
            formik={formik}
            name="country"
            label={`${t('input.country')}*`}
            disabled={readOnly}
          />
        </Grid>
        <Grid xs={12} sm={6} item>
          <ProfileTerritory
            form={form}
            formik={formik}
            name="territory"
            label={`${t('input.territory')}*`}
            disabled={readOnly}
          />
        </Grid>
        <Grid xs={12} item>
          <ProfileInput
            form={form}
            formik={formik}
            name="email"
            label={`${t('input.email')}`}
            disabled
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default ProfileInfo
