import { Grid } from '@material-ui/core'
import { Fragment } from 'react'
import CartDivider from 'spypoint/src/checkout-module/order/ui/cart-lines/CartDivider'
import ItemInCart from '../cart-items/ItemInCart'

const CheckoutContent = ({ products, promocode }) => {
  if (!products) {
    return null
  }

  return products.map((product) => (
    <Fragment key={product.id}>
      <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
        <ItemInCart checkout product={product} promocode={promocode} />
      </Grid>
      <CartDivider />
    </Fragment>
  ))
}

export default CheckoutContent
