import { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import CameraPlan from 'camera-module/new-activation/ui/steps/CameraPlan'
import NewCart from 'spypoint/src/checkout-module/order/ui/cart-page/NewCart'
import CheckoutComponent from 'spypoint/src/checkout-module/order/ui/checkout-page/CheckoutComponent'
import { useCart } from 'spypoint/src/checkout-module/order/core/cart.hooks'

const UpsellingPlansPanel = ({ history, step, setStep }) => {
  const [itemId, setItemId] = useState(null)
  const {
    cartItems,
  } = useCart()
  useEffect(() => {
    setItemId(cartItems?.products?.[0]?.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartItems])
  return (
    <>
      <Container>
        { step === 0 && <CameraPlan setStep={setStep} history={history} /> }
        { step === 1 && <NewCart itemId={itemId} setStep={setStep} /> }
      </Container>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        spacing={3}
      >
        { step === 2 && <CheckoutComponent itemId={itemId} isActivation setStep={setStep} /> }
      </Grid>
    </>
  )
}

export default UpsellingPlansPanel
