const buildCameraPlans = apiCameraPlans => {
  const builtCameraPlans = {}

  builtCameraPlans.id = apiCameraPlans.id
  builtCameraPlans.name = buildName(apiCameraPlans)
  builtCameraPlans.period = buildPlanPeriod(apiCameraPlans)
  builtCameraPlans.isUpgradable = buildIsUpgradable(apiCameraPlans)
  builtCameraPlans.isActive = buildIsActive(apiCameraPlans)
  builtCameraPlans.photoCountPerMonth = buildPhotoCount(apiCameraPlans)
  builtCameraPlans.price = apiCameraPlans.price
  builtCameraPlans.priceWithInsidersClub = buildDiscountPrice(apiCameraPlans)
  builtCameraPlans.stepQuantity = apiCameraPlans.stepQuantity
  builtCameraPlans.inventory = apiCameraPlans.inventory
  builtCameraPlans.primaryCategoryId = apiCameraPlans.primaryCategoryId

  return builtCameraPlans
}

const buildName = apiCameraPlans => apiCameraPlans.c_dataPlanName
const buildIsActive = apiCameraPlans => apiCameraPlans.c_isActive
const buildIsUpgradable = apiCameraPlans => apiCameraPlans.c_isUpgradable
const buildPhotoCount = apiCameraPlans => apiCameraPlans.c_photoCountPerMonth
const buildDiscountPrice = apiCameraPlans => apiCameraPlans.productPromotions.find(promo => promo.promotionId === 'InsidersClubDigitalDiscount').promotionalPrice
const buildPlanPeriod = apiCameraPlans => apiCameraPlans.name.includes('Annual') ? 'annual' : 'month_by_month'

export default buildCameraPlans
