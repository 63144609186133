import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ConfirmationDialog from 'shared-module/components/confirmation/ConfirmationDialog'

const JoinTheClubDialog = ({ open, onClose }) => {
  const history = useHistory()
  const { t } = useTranslation()

  const redirectToMembershipPage = () => history.push('/membership')

  return (
    <ConfirmationDialog
      open={open}
      onClose={onClose}
      title={t('app:club_membership_dialog.title')}
      text={t('app:club_membership_dialog.text')}
      confirmText={t('app:club_membership_dialog.join')}
      onConfirm={redirectToMembershipPage}
      cancelText={t('app:club_membership_dialog.dismiss')}
      onCancel={onClose}
    />
  )
}

export default JoinTheClubDialog
