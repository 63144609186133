import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const VideoTileIcon = (props) => {
  const theme = useTheme()

  return (
    <SvgIcon {...props} width="20" height="20" data-testid="VideoTileIcon">
      <g filter="url(#filter0_ddd_6583_138510)">
        <path
          d="M18.0737 14.4983L14.7321 12.5367V14.4091C14.7321 14.9492 14.4951 15.4671 14.0731 15.8489C13.6512 16.2308 13.0789 16.4453 12.4821 16.4453L2.875 16.4453C2.27826 16.4453 1.70597 16.2308 1.28401 15.8489C0.862053 15.4671 0.625 14.9492 0.625 14.4091V5.59086C0.625 5.05084 0.862053 4.53293 1.28401 4.15107C1.70597 3.76921 2.27826 3.55469 2.875 3.55469L12.4821 3.55469C13.0789 3.55469 13.6512 3.76922 14.0731 4.15107C14.4951 4.53293 14.7321 5.05084 14.7321 5.59087V7.46326L18.0737 5.50174C18.7881 5.13014 19.375 5.43557 19.375 6.18047V13.8161C19.375 14.5644 18.79 14.8699 18.0737 14.4983Z"
          fill={props.disabled ? theme.palette.secondary.light : theme.palette.text.primary}
        />
      </g>
      <defs>
        <filter
          id="filter0_ddd_6583_138510"
          x="-0.375"
          y="2.55469"
          width="20.75"
          height="15.6406"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6583_138510" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_6583_138510" result="effect2_dropShadow_6583_138510" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.75" />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="effect2_dropShadow_6583_138510" result="effect3_dropShadow_6583_138510" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_6583_138510" result="shape" />
        </filter>
      </defs>
    </SvgIcon>
  )
}

export default VideoTileIcon
