import { alpha } from '@material-ui/core'
import useLanguage from 'shared-module/components/language/useLanguage'
import Link from '@material-ui/core/Link'
import Box from '@material-ui/core/Box'
import useTheme from '@material-ui/core/styles/useTheme'
import SpypointLogo from 'spypoint/src/assets/icons/SpypointLogo'

const SpypointPublicPageBanner = () => {
  const theme = useTheme()
  const language = useLanguage()

  return (
    <Link href={`https://www.spypoint.com/${language}`}>
      <Box p={3} bgcolor={alpha(theme.palette.background.default, 0.8)}>
        <SpypointLogo style={{ height: '45px', width: '100%' }} />
      </Box>
    </Link>
  )
}

export default SpypointPublicPageBanner
