import IconButton from '@material-ui/core/IconButton'
import { FullHdUnavailable } from 'assets/icons/SpypointIcons'
import { useTranslation } from 'react-i18next'
import SpypointVideoHdIcon from './SpypointVideoHdIcon'
import DialogFullHdVideoUnavailable from 'video-module/video-clip/ui/full-hd-video-status-button/dialog/DialogFullHdVideoUnavailable'
import useConfirmationDialogActions from 'shared-module/components/confirmation/useConfirmationDialogActions'

const SpypointVideoHdUnavailableButton = () => {
  const { t } = useTranslation()
  const videoUnavailableDialogActions = useConfirmationDialogActions()
  const shared = JSON.parse(localStorage.getItem('isShared'))

  return (
    <>
      <IconButton
        data-testid="VideoUnavailableIcon"
        color="secondary"
        onClick={() => videoUnavailableDialogActions.open()}
        disabled={shared}
      >
        <SpypointVideoHdIcon icon={FullHdUnavailable}>{ t('app:video_viewer.button.unavailable') }</SpypointVideoHdIcon>
      </IconButton>
      <DialogFullHdVideoUnavailable
        open={videoUnavailableDialogActions.isOpen}
        onClose={videoUnavailableDialogActions.close}
      />
    </>
  )
}

export default SpypointVideoHdUnavailableButton
