import React from 'react'
import Box from '@material-ui/core/Box'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CCheckbox from 'storybook-component-module/src/components/buttons/CCheckbox'
import VoskerCheckbox from 'vosker/src/components/VoskerCheckbox'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  label: {
    marginTop: 10,
  },
  unchecked: {
    fill: 'transparent !important',
  },
  box: {
    marginLeft: -10,
    marginTop: 8,
  },
}))

const TermsCheckbox = ({ disabled, isNewUi, checked, setChecked }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const CheckBox = isNewUi ? VoskerCheckbox : CCheckbox
  const disabledColor = isNewUi ? 'textSecondary' : 'secondary'
  const handleChange = (e) => {
    setChecked(e.target.checked)
  }
  // @ts-ignore
  return (
    <Box className={classes.box} display="flex" flexDirection="row" alignItems="flex-start">
      <CheckBox
        disabled={disabled}
        checked={checked}
        onChange={(e) => handleChange(e)}
        uncheckedClassName={classes.unchecked}
      />
      <Typography color={disabled ? disabledColor : 'textPrimary'}>
        <Typography className={classes.label}>
          { t('input.email_list', { company: isNewUi ? 'VOSKER®' : 'SPYPOINT' }) }
        </Typography>
      </Typography>
    </Box>
  )
}

export default TermsCheckbox
