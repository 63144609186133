import apm from 'shared-module/monitoring/apm'
import userApi from 'user-module/user/api/userApi'
import switches from 'shared-module/config/switches'
import appConfig from 'shared-module/config/appConfig'
import cartActions from 'spypoint/src/checkout-module/order/core/cart.actions'

import userHdPackageState from 'user-module/hd-package/core/userHdPackageState'
import { SET_USER, SET_USER_SCOUTING_PACKAGE } from 'user-module/user/core/userActionTypes'

import notificationsActions from 'spypoint/src/notifications-module/core/notifications.actions'
import userClubMembershipState from 'spypoint/src/user-module/club-membership/core/userClubMembershipState'

const set = user => ({ type: SET_USER, user })

const getAndSet = userId => dispatch =>
  userApi.getById(userId)
    .then(user => {
      apm.setUserContext({ id: user.id, email: user.email })
      if (user.cartEnabled) dispatch(cartActions.fetchCart(user))
      if (appConfig.notificationCenter.switch === switches.on) dispatch(notificationsActions.getAllNotifications())
      return Promise.all([
        dispatch(userClubMembershipState.get()),
        dispatch(userHdPackageState.get()),
      ])
        .then(() => dispatch(set(user)))
    })

const update = user => dispatch =>
  userApi.update(user)
    .then(() => dispatch(getAndSet(user.id)))

const updateExtended = user => dispatch =>
  userApi
    .updateExtended(user)
    .then(() => dispatch(getAndSet(user.id)))

const getScoutingPackage = () => dispatch =>
  userApi
    .getScountingPackage()
    .then(scoutingPackage => dispatch({ type: SET_USER_SCOUTING_PACKAGE, scoutingPackage }))

const updateFavoritesCount = () => dispatch => dispatch(userClubMembershipState.get())

const userActions =
  {
    getAndSet: getAndSet,
    update: update,
    updateExtended: updateExtended,
    getScoutingPackage: getScoutingPackage,
    updateFavoritesCount: updateFavoritesCount,
  }
export default userActions
