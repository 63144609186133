import Card from '@material-ui/core/Card'
import { Typography } from '@material-ui/core'
import CardContent from '@material-ui/core/CardContent'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles((theme) => ({
  card: {
    margin: '2rem',
    marginBottom: '3.25rem',
    borderRadius: 0,
    display: 'flex',
    height: '29.25rem',
    flexDirection: 'column',
    alignItems: 'center',
    background: theme.palette.primary.contrastText,
    border: `0.375rem solid ${theme.palette.primary.contrastText}`,
    boxShadow: `0rem 0rem 0.625rem 0rem ${theme.palette.basic?.black}80`,
    [theme.breakpoints.down('xs')]: {
      margin: '1rem 0',
      height: '27rem',
    },
  },
  contentCard: {
    padding: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      height: '100%',
      width: '100%',
    },
  },
  img: {
    [theme.breakpoints.down('xs')]: {
      height: '85%',
      width: '100%',
      paddingTop: '1rem',
      transform: 'scale(1.25)',
    },
  },
  bold: {
    fontWeight: 'bold',
  },
  box: {
    width: '100%',
    height: '100%',
    display: 'flex',
    padding: '2rem',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      padding: '1rem 1.25rem',
    },
  },
}))

const VerifySignal = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const imgSrc = '/images/card/activation/device-signal.png'

  return (
    <Card className={classes.card}>
      <CardContent className={classes.contentCard}>
        <img alt="device-signal-icon" src={imgSrc} className={classes.img} />
      </CardContent>

      <Box className={classes.box}>
        <Typography variant="h1" className={classes.title}>{ t('app:active_device.steps.step_3.header') }</Typography>
        <Typography variant="body1" className={classes.content}>{ t('app:active_device.steps.step_3.body_first') }</Typography>
        <Typography variant="body1" className={classes.content}>
          <span className={classes.bold}>{ t('app:active_device.important') }</span> { t('app:active_device.steps.step_3.body_last') }
        </Typography>
      </Box>
    </Card>
  )
}

export default VerifySignal
