import { Grid } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'
import SecondaryButton from 'shared-module/components/button/SecondaryButton'
import CardImage from 'spypoint/src/user-module/account/payment/method/CardImage'

const PaymentInformationDisplay = ({ card, openForm }) => {
  const { t } = useTranslation()

  return (
    <Grid id="payment-information" container direction="row" justifyContent="space-between" spacing={2}>
      <Grid item>
        <Grid container direction="row" alignItems="center" spacing={2}>
          <Grid item>
            <CardImage brand={card?.brand} />
          </Grid>
          <Grid item>
            <Typography variant="body2">****{ card?.last4 }</Typography>
            <Typography variant="body2">
              { `${t('app:checkout.billing.expire')} ${card?.exp_month}/${card?.exp_year?.toString().slice(-2)}` }
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <SecondaryButton
          fullWidth
          color="default"
          value={t('app:checkout.modify')}
          onClick={openForm}
        />
      </Grid>
    </Grid>
  )
}

export default PaymentInformationDisplay
