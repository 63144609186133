import { useTheme } from '@material-ui/core'
import SvgIcon from '@material-ui/core/SvgIcon'

const DiscountIcon = () => {
  const theme = useTheme()

  return (

    <SvgIcon width="121" height="122" viewBox="0 0 121 122" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.125 53.8643V29.9247L24.2571 20.7171H44.1193L53.2514 29.9247V53.8643L44.1193 63.0718H24.2571L15.125 53.8643ZM74.5976 20.7171H90.1222L46.4024 101.283H30.8778L74.5976 20.7171ZM37.4986 51.5624L40.0099 49.0303V34.7586L37.4986 32.2265H30.8778L28.3665 34.7586V49.0303L30.8778 51.5624H37.4986ZM67.7486 92.0756V68.136L76.8807 58.9284H96.7429L105.875 68.136V92.0756L96.7429 101.283H76.8807L67.7486 92.0756ZM90.1222 89.7737L92.6335 87.2416V72.9699L90.1222 70.4379H83.5014L80.9901 72.9699V87.2416L83.5014 89.7737H90.1222Z"
        fill={theme.palette.primary.main}
      />
    </SvgIcon>

  )
}

export default DiscountIcon
