import useSelectedPhoto from 'photo-module/photo/core/useSelectedPhoto'
import IconButton from '@material-ui/core/IconButton'
import { FavoriteActive, FavoriteInactive } from 'assets/icons/SpypointIcons'

const FavoriteButtonLayout = ({ onClick }) => {
  const photo = useSelectedPhoto()
  const shared = JSON.parse(localStorage.getItem('isShared'))

  return (
    <IconButton disabled={shared} color="secondary" onClick={onClick}>
      { photo.favorite ? <FavoriteActive /> : <FavoriteInactive /> }
    </IconButton>
  )
}

export default FavoriteButtonLayout
