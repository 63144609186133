import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import FullScreenBackground from 'shared-module/new-components/layout/FullScreenBackground'

const PrivatePageLayout = ({ children }) => {
  return (
    <FullScreenBackground image="/images/background/promo-codes.png">
      <Container style={{ maxWidth: 743 }}>
        <Box display="flex" flexDirection="column" height="100vh" mt={16}>
          { children }
        </Box>
      </Container>
    </FullScreenBackground>
  )
}

export default PrivatePageLayout
