import { useTranslation } from 'react-i18next'
import ConfirmationDialog from 'shared-module/components/confirmation/ConfirmationDialog'
import useUser from 'user-module/user/core/useUser'
import useVideoActions from 'video-module/video-clip/core/useVideoActions'
import useSelectedPhoto from 'photo-module/photo/core/useSelectedPhoto'

const DialogDownloadHdVideo = ({
  open,
  onClose,
}) => {
  const user = useUser()
  const photo = useSelectedPhoto()
  const actions = useVideoActions()
  const { t } = useTranslation()

  const onConfirm = () => {
    onClose()
    actions.requestHdVideo(photo.cameraId, photo.id)
  }
  return (
    <ConfirmationDialog
      open={open}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={onConfirm}
      title={t('app:video_viewer.confirmation.header')}
      text={t('app:video_viewer.confirmation.body', { remaining: user?.addOns?.hdVideos })}
      confirmText={t('app:video_viewer.confirmation.confirm')}
    />
  )
}

export default DialogDownloadHdVideo
