import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles, useTheme, ThemeProvider } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  toggleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '30px',
    backgroundColor: theme.palette.background?.viewer,
    overflow: 'hidden',
    marginTop: '2rem',
  },
  toggleOption: {
    flex: 1,
    textAlign: 'center',
    padding: '15px 20px',
    cursor: 'pointer',
    color: theme.palette.text?.tertiary,
    fontSize: theme.typography.button?.fontSize,
    '&.selected': {
      backgroundColor: theme.palette.primary?.main,
      color: theme.palette.basic?.black,
      fontWeight: 500,
      borderRadius: '50px',
    },
  },
}))

const ToggleSwitch = ({ initialSelectedOption, setSelectedOption }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const classes = useStyles()
  const handleOptionChange = (option) => {
    setSelectedOption(option)
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.toggleContainer}>
        <div
          className={`${classes.toggleOption} ${initialSelectedOption === 'month_by_month' ? 'selected' : ''}`}
          onClick={() => handleOptionChange('month_by_month')}
        >
          { t('app:active_device.steps.step_4.switch.monthly') }
        </div>
        <div
          className={`${classes.toggleOption} ${initialSelectedOption === 'annual' ? 'selected' : ''}`}
          onClick={() => handleOptionChange('annual')}
        >
          { t('app:active_device.steps.step_4.switch.yearly') }
        </div>
      </div>
    </ThemeProvider>
  )
}

export default ToggleSwitch
