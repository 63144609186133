// React Core imports
import { useTranslation } from 'react-i18next'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import PlanBlockTitle from 'spypoint/src/checkout-module/transmission-plans/ui/transmission-plans-page/PlanBlockTitle'
import PrimaryButton from 'shared-module/new-components/buttons/PrimaryButton'
import { getUserCurrency } from 'shared-module/common-utils'
import { useUserClubMembership } from 'spypoint/src/user-module/club-membership/core/useUserClubMembership'
import FormatedPrice from './FormatedPrice'
import { PHOTO_TRANSMISSION_PLAN } from 'spypoint/src/checkout-module/order/core/productTypes'
import { useCart } from 'spypoint/src/checkout-module/order/core/cart.hooks'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import useUser from 'user-module/user/core/useUser'
import Amount from 'shared-module/components/amount/Amount'
import appConfig from 'shared-module/config/appConfig'
import switches from 'shared-module/config/switches'
import { useBasket } from 'spypoint/src/ecommerce-module/BasketContext'
import CButton from 'storybook-component-module/src/components/buttons/CButton'

const useStyles = makeStyles(theme => ({
  root: {
    height: '297px',
    width: '235px',
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '7px auto',
    },
    margin: '7px',
    fontSize: '10pt',
    borderRadius: '0',
  },
  activePlan: {
    padding: 0,
    border: '3px solid',
    borderColor: theme.palette.secondary.main,
  },
  inactivePlan: {
    padding: '0',
  },
  section: {
    height: '160px',
    width: '100%',
    position: 'relative',
  },
  row: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  basicPlanBanner: {
    height: '38px',
    background: theme.palette.secondary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: '600',
    fontSize: '14px',
  },
  popularPlanBanner: {
    height: '38px',
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: '600',
    fontSize: '14px',
  },
  basicPlanLayout: {
    marginTop: '0.438rem',
    [theme.breakpoints.down('xs')]: {
      height: '300px',
    },
  },
  planLayout: {
    height: '334px',
  },
  disablePlan: {
    pointerEvents: 'none',
    opacity: '0.4',
  },
  photoCount: {
    textAlign: 'center',
    fontSize: '16px',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'right',
      paddingRight: '12px',
      marginTop: '-31px',
      marginBottom: '8px',
      fontSize: '19px',
    },
  },
  annualButton: {
    width: '150px',
    fontSize: '12pt',
    margin: '20px 0px 10px',
  },
  monthlyButton: {
    width: '150px',
    fontSize: '10pt',
    margin: '20px 0px 10px',
  },
  note: {
    margin: '0px 25px',
    textAlign: 'center',
    fontSize: '8pt',
  },
  infos: {
    margin: '10px 10px 20px 10px',
    lineHeight: '1.5em',
  },
  divider: {
    margin: '20px',
    backgroundColor: theme.palette.background.secondary,
  },
  check: {
    color: theme.palette.primary.main,
    width: '15px',
    marginRight: '10px',
    paddingTop: '10px',
  },
  triangle: {
    width: 0,
    height: 0,
    borderLeft: '116px solid transparent',
    borderRight: '117px solid transparent',
    borderTop: `22px solid ${theme.palette.secondary.main}`,
    [theme.breakpoints.down('xs')]: {
      margin: '0 auto',
      borderLeft: '135px solid transparent',
      borderRight: '135px solid transparent',
      borderTop: `17px solid ${theme.palette.secondary.main}`,
    },
  },
  brightTriangle: {
    borderTopColor: theme.palette.primary.main,
  },
  planPrice: {
    fontSize: '40px',
    textAlign: 'center',
    fontWeight: 'bolder',
  },
  currentPlan: {
    color: theme.palette.primary.main,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: '700',
    position: 'absolute',
    top: '-30px',
    width: '100%',
    padding: '30px',
  },
  mainPrice: {
    textAlign: 'center',
    display: 'inline-block',
    fontSize: '40px',
    margin: '10px 0',
    [theme.breakpoints.down('xs')]: {
      fontSize: '50px',
    },
  },
  secondaryPrice: {
    fontSize: '30px',
    display: 'inline-block',
  },
  secondaryPricePeriod: {
    display: 'inline-block',
    fontSize: '15px',
  },
  secondaryPriceWrapper: {
    color: theme.palette.primary.main,
  },
  insidersClub: {
    fontSize: '.85rem',
    [theme.breakpoints.down('xs')]: {
      display: 'inline-block',
      marginRight: '10px',
    },
  },
  alreadyAMemberPrice: {
    textAlign: 'center',
    marginTop: '30px',
    marginBottom: '40px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '40px',
      marginBottom: '30px',
    },
  },
  buyNowButtonWrapper: {
    position: 'relative',
  },
}))

const PlanBlock = ({ plan, subscription, periodType, onSelectPlan }) => {
  const { t } = useTranslation()

  const classes = useStyles()
  const { addToCart, navigateToCart } = useCart()
  const selectedCamera = useSelectedCamera()
  const user = useUser()
  const userClubMembership = useUserClubMembership()

  const isCurrentPlanFree = plan.id === 'Free'
  const isCurrentPlanBasic = plan.id === 'Basic'
  const userIsNotAMember = !userClubMembership.isMember
  const userIsAlreadyAMember = userClubMembership.isMember
  const isCurrentSubscriptionBasic = subscription.plan.id === 'Basic'
  const isMigratedFromBasicSubscription = isCurrentSubscriptionBasic && isCurrentPlanBasic && userIsAlreadyAMember && !subscription.isFree
  const isUserActivePlanIsTheFreePlan = isMigratedFromBasicSubscription ? true : subscription.isFree

  const eCommerceEnabled = appConfig.eCommerce.switch === switches.on
  const planNameMatchSubscription = subscription.plan.id === plan.name
  const planPeriodMatchSelection = subscription.paymentFrequency === periodType
  const isThisCurrentPlan = eCommerceEnabled
    ? userIsNotAMember ? planNameMatchSubscription && planPeriodMatchSelection : planNameMatchSubscription
    : subscription.plan.id === plan.id && planPeriodMatchSelection

  const ifMonthly = periodType === 'month_by_month'
  const ifAnnually = periodType === 'annual'
  const canNotUpgradeMonthlyPlan = !plan.canUpgradeMonthByMonth
  const canNotUpgradeAnnualPlan = !plan.canUpgradeAnnual
  const buyNowButtonShouldNotBeVisible = (ifMonthly && canNotUpgradeMonthlyPlan) || (ifAnnually && canNotUpgradeAnnualPlan)
  const canShowBuyNowButton = !buyNowButtonShouldNotBeVisible
  const isTrialPeriod = isUserActivePlanIsTheFreePlan && subscription.plan.id === 'Premium'
  const isNotTrialPeriod = !isTrialPeriod
  const ifCurrentPlanIsFree = plan.isFree
  const showBasicBanner = eCommerceEnabled ? plan.name === 'Basic' : plan.showBanner === 'insidersBasic'
  const showMostPopular = eCommerceEnabled ? plan.name === 'Premium' : plan.id === 'Premium'
  const currencyCode = getUserCurrency()

  const getPhotoCountString = () => plan.photoCountPerMonth ? plan.photoCountPerMonth + ' ' + t('billing.photos') : t('billing.unlimited_photos')

  let shouldDisplayAsCurrentPlan = false
  if (isNotTrialPeriod) {
    const shouldDisplayFreePlan = isUserActivePlanIsTheFreePlan && ifCurrentPlanIsFree && userIsNotAMember
    const shouldDisplayBasicPlan = isUserActivePlanIsTheFreePlan && isCurrentPlanBasic && userIsAlreadyAMember
    const shouldDisplayCurrentPlan = isThisCurrentPlan && !ifCurrentPlanIsFree
    if (shouldDisplayFreePlan || shouldDisplayBasicPlan || shouldDisplayCurrentPlan) {
      shouldDisplayAsCurrentPlan = true
    }
  } else {
    shouldDisplayAsCurrentPlan = false
  }

  const renderFreePlanBlock = () => {
    return (
      <div className={classes.section} style={{ position: 'relative' }}>
        <Box mt={5} textAlign="center">
          <Typography className={classes.mainPrice} component="h2" variant="h2">
            <Amount currencyCode={currencyCode} hideZerosDecimals value={0} />
          </Typography>
        </Box>
        { (isUserActivePlanIsTheFreePlan && shouldDisplayAsCurrentPlan) && (<Typography component="h2" variant="h2" className={classes.currentPlan} style={{ top: 'unset', bottom: '0' }}>{ t('billing.current_plan') }</Typography>) }
      </div>
    )
  }

  const renderAddToCartButton = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { addProductToBasket } = useBasket()
    const newCartItem = product => [{
      productId: product?.id,
      quantity: product?.stepQuantity,
      price: product?.price,
      productName: product?.name,
      itemText: product?.primaryCategoryId,
      itemId: selectedCamera.id,
    }]

    const addPhotoTransmissionPlanToBasket = async (product) => {
      try {
        console.log(product)
        await addProductToBasket(newCartItem(product))
        navigateToCart()
      } catch (error) {
        console.error('Failed to add product to basket:', error)
      }
    }
    const addPhotoTransmissionPlanToCart = () => {
      addToCart({
        productId: PHOTO_TRANSMISSION_PLAN,
        cameraId: selectedCamera.id,
        paymentFrequency: periodType,
        plan: plan.id,
      })
      navigateToCart()
    }

    if (eCommerceEnabled) {
      return <PrimaryButton onClick={() => addPhotoTransmissionPlanToBasket(plan)}>{ t('app:checkout_process.add_to_cart') }</PrimaryButton>
    }

    if (user.cartEnabled && !eCommerceEnabled) {
      return <PrimaryButton onClick={addPhotoTransmissionPlanToCart} id={`${plan.id}_add_to_cart`}>{ t('app:checkout_process.add_to_cart') }</PrimaryButton>
    }
    return <PrimaryButton onClick={() => onSelectPlan({ plan, frequency: periodType })} id={`${plan.id}_buy`}>{ t('app:checkout_process.add_to_cart') }</PrimaryButton>
  }

  const renderBuyNowButton = () => {
    if (isThisCurrentPlan) {
      return (<Typography variant="h2" className={classes.currentPlan}>{ t('billing.current_plan') }</Typography>)
    } else if (canShowBuyNowButton) {
      return renderAddToCartButton()
    }

    return null
  }

  const renderButtonsSection = () => {
    const {
      pricePerMonthIfPaidPerMonth,
      pricePerMonthIfPaidPerMonthInsidersClub,
      pricePerMonthIfPaidAnnually,
      pricePerMonthIfPaidAnnuallyInsidersClub,
      price,
      priceWithInsidersClub,
    } = plan

    return (
      <div className={classes.section}>

        { userIsNotAMember && (
          <Box component="div" align="center">
            <Typography className={classes.mainPrice} component="h2" variant="h2">
              { eCommerceEnabled
                ? <FormatedPrice price={plan.period === 'annual' ? (price / 12) : price} />
                : ifMonthly
                  ? <FormatedPrice price={pricePerMonthIfPaidPerMonth} />
                  : <FormatedPrice price={pricePerMonthIfPaidAnnually} /> }
            </Typography>
            <Typography component="h6" variant="h6" fontWeight={700} style={{ display: 'inline-block' }}>/MO</Typography>
          </Box>
        ) }

        { userIsAlreadyAMember && (
          <Box component="div" className={classes.alreadyAMemberPrice}>
            <Typography className={classes.mainPrice} component="h2" variant="h2">
              { eCommerceEnabled
                ? <FormatedPrice price={plan.period === 'annual' ? (priceWithInsidersClub / 12) : priceWithInsidersClub} />
                : ifMonthly
                  ? <FormatedPrice price={pricePerMonthIfPaidPerMonthInsidersClub} />
                  : <FormatedPrice price={pricePerMonthIfPaidAnnuallyInsidersClub} /> }
            </Typography>
            <Typography component="h6" variant="h6" fontWeight={700} style={{ display: 'inline-block' }}>/MO</Typography>
          </Box>
        ) }

        { userIsNotAMember && (
          <Box className={classes.secondaryPriceWrapper} component="div" align="center">
            <Typography component="p" className={classes.insidersClub}>{ t('app:photo_transmition_plan.plans.membership_price_title') }</Typography>
            <Typography className={classes.secondaryPrice} component="h2" variant="h2">
              { eCommerceEnabled
                ? <FormatedPrice price={plan.period === 'annual' ? (priceWithInsidersClub / 12) : priceWithInsidersClub} />
                : ifMonthly
                  ? <FormatedPrice price={pricePerMonthIfPaidPerMonthInsidersClub} />
                  : <FormatedPrice price={pricePerMonthIfPaidAnnuallyInsidersClub} /> }
            </Typography>
            <Typography component="h6" variant="h6" className={classes.secondaryPricePeriod}> / MO</Typography>
          </Box>
        ) }

        <Box align="center" mt="20px" className={classes.buyNowButtonWrapper}>
          { renderBuyNowButton() }
        </Box>
      </div>
    )
  }

  const renderBasicPlanBanner = () => {
    return (
      <Box className={classes.basicPlanBanner} fontWeight={600} clone>
        <Typography variant="body2" align="center">{ t('billing.insiders_club_banner') }</Typography>
      </Box>
    )
  }

  const renderMostPopularBanner = () => {
    return (
      <Box className={classes.popularPlanBanner} clone>
        <Typography variant="body2" align="center">{ t('billing.most_popular_banner') }</Typography>
      </Box>
    )
  }

  const cssClasses = plan.photoCountPerMonth
    ? classes.triangle
    : classes.triangle + ' ' + classes.brightTriangle

  const activePlanBorderStyle = shouldDisplayAsCurrentPlan ? classes.activePlan : classes.inactivePlan
  const disablePlanStyle = userIsAlreadyAMember && isCurrentPlanFree ? classes.disablePlan : ''
  const basicPlanStyle = !showBasicBanner ? classes.basicPlanLayout : classes.planLayout
  const premiumPlanStyle = !showMostPopular ? classes.basicPlanLayout : classes.planLayout

  return (
    <Paper className={`${classes.root}  ${activePlanBorderStyle} ${basicPlanStyle} ${premiumPlanStyle} ${disablePlanStyle}`}>
      { showBasicBanner && renderBasicPlanBanner() }
      { showMostPopular && renderMostPopularBanner() }
      <div className={cssClasses} />
      <PlanBlockTitle title={t('billing.plans.' + plan.name)} />

      <Typography component="h5" variant="h5" className={classes.photoCount}>
        { getPhotoCountString() }
      </Typography>
      { ifCurrentPlanIsFree ? renderFreePlanBlock() : renderButtonsSection() }
    </Paper>
  )
}

export default PlanBlock
