import { useTranslation } from 'react-i18next'
import FilterButton from 'photo-module/photos/ui/photos-gallery/filter-bar/FilterButton'
import useFilters from 'photo-module/filters/core/useFilters'
import { FavoriteActive } from 'assets/icons/SpypointIcons'
import useFiltersActions from 'photo-module/filters/core/useFiltersActions'
import { useUserClubMembership } from 'spypoint/src/user-module/club-membership/core/useUserClubMembership'
import JoinTheClubDialog from 'spypoint/src/user-module/club-membership/ui/join-the-club-dialog/JoinTheClubDialog'
import useConfirmationDialogActions from 'shared-module/components/confirmation/useConfirmationDialogActions'

const FavoritesFilterButton = () => {
  const { t } = useTranslation()
  const userMembership = useUserClubMembership()
  const filters = useFilters()
  const filtersActions = useFiltersActions()
  const dialogActions = useConfirmationDialogActions()

  const onToggle = () => userMembership.isMember ? filtersActions.toggleFavoriteFilter() : dialogActions.open()

  return (
    <>
      <FilterButton
        Icon={FavoriteActive}
        text={t('favorite.filter_btn')}
        disabled={!userMembership.isMember}
        selected={filters.favorite}
        onToggle={onToggle}
      />
      <JoinTheClubDialog open={dialogActions.isOpen} onClose={dialogActions.close} />
    </>
  )
}

export default FavoritesFilterButton
