import { useTranslation } from 'react-i18next'
import { Box } from '@material-ui/core'
import PrimaryButton from 'shared-module/new-components/buttons/PrimaryButton'
import { useForm } from 'shared-module/new-components/form/new/FormContext'
import usePromoCodesActions from 'user-module/promo-code/core/usePromoCodesActions'

const RedeemPromoCodesButton = () => {
  const { t } = useTranslation()
  const { fields, status } = useForm()
  const promoCodesActions = usePromoCodesActions()

  const redeem = () => {
    status.setSubmitting(true)
    const promocode = fields.promocode.value.toUpperCase().replace(/[-_]/g, '')
    promoCodesActions.redeem(promocode)
      .then((redeemed) => status.success({ spydollars: redeemed.spydollars }))
      .catch(() => fields.promocode.setError({ key: 'redeem_promo_code.error' }))
      .finally(() => status.setSubmitting(false))
  }

  return (
    <Box mt={1}>
      <PrimaryButton onClick={redeem} disabled={status.isSubmitting}>{ t('redeem_promo_code.form.button') }</PrimaryButton>
    </Box>
  )
}

export default RedeemPromoCodesButton
