import { createTheme } from '@material-ui/core'
import responsiveFontSizes from '@material-ui/core/styles/responsiveFontSizes'

// Names are the one used in figma
const colors = {
  pureWhite: '#fff',
  pureBlack: '#000000',
  pureGrey: '#F0F1F5',
  backgroundRegular: '#0E0B0B', // background dark
  grey200: '#313030', // background grey, divider
  grey500: '#5c5a5a',
  grey700: '#B1AFAF',
  tileBackground: '#464444',
  photoViewerBackground: '#231F20',

  grey900: '#F5F4F4', // primary text and headers
  formText: '#D3CFCF', // secondary text

  error400: '#F51330', // error
  error600: '#AB0C1C', // secondary error
  badgeError: '#AB0C1C',
  darkThemeRegular: '#191616', // Dark Theme - Background / Regular

  badgeInfo: '#3399ff',
  badgeSuccess: '#2BA167',
  badgeStatus: '#E6EA32',
  badgePending: '#F9B643',

  // primary button
  brandingPrimary400: '#F89021', // enable
  brandingPrimary600: '#DF6E06', // hover
  grey100: '#1F1E1E', // text

  // secondary button
  grey600: '#939090', // enable
  grey550: '#767474',
}

// @see defaults are documented here https://material-ui.com/customization/default-theme/?expand-path=$.typography
const muiTheme = createTheme({
  typography: {
    fontFamily: 'ibm-plex-sans-regular',
    fontFamilyBold: 'ibm-plex-sans-regular',
    fontFamilyTabs: 'oswald-normal',
    fontFamilySvg: 'chakra-petch-bold',
    fontFamilyHind: 'hind-regular',
    h1: {
      fontSize: '1.625rem', // 26px
      fontFamily: 'oswald-normal',
    },
    h2: {
      fontSize: '1.3125rem', // 21px
      fontFamily: 'oswald-normal',
    },
    subtitle1: {
      fontSize: '1.625rem', // 26px
      fontFamily: 'oswald-normal',
    },
    h3: {
      fontSize: '1.1rem', // 16px
      fontFamily: 'oswald-regular',
    },
    h4: {
      fontSize: '0.875rem', // 14px
      fontFamily: 'oswald-regular',
    },
    h5: {
      fontFamily: 'oswald-normal',
    },
    h6: {
      fontFamily: 'oswald-normal',
    },
    menuLink: {
      fontSize: '1.0711rem',
      lineHeight: 1.167,
      fontFamily: 'oswald-regular',
    },
    button: {
      fontSize: '1.0625rem', // 17px
      fontFamily: 'oswald-normal',
    },
    body1: {
      fontSize: '1rem', // 16px
      fontFamily: 'ibm-plex-sans-regular',
    },
  },
  palette: {
    type: 'dark',
    error: {
      main: colors.error400,
      secondary: colors.error600,
    },
    text: {
      primary: colors.grey900,
      secondary: colors.formText,
      tertiary: colors.grey700,
    },
    primary: {
      main: colors.brandingPrimary400,
      contrastText: colors.grey100,
      dark: colors.brandingPrimary600,
      disabled: colors.brandingPrimaryDisabled,
      danger: colors.badgeError,
      info: colors.badgeInfo,
      status: colors.badgeStatus,
      success: colors.badgeSuccess,
      pending: colors.badgePending,
    },
    secondary: {
      main: colors.grey600,
      light: colors.grey550,
      dark: colors.grey900,
    },
    background: {
      pureBlack: colors.pureBlack,
      default: colors.backgroundRegular,
      paper: colors.grey200,
      tile: colors.tileBackground,
      secondary: colors.grey500,
      filter: colors.grey700,
      light: colors.formText,
      contrastText: colors.grey100,
      viewer: colors.photoViewerBackground,
      contrast: colors.grey100,
      paperContrast: colors.darkThemeRegular,
    },
    divider: colors.grey200,
    basic: {
      white: colors.pureWhite,
      black: colors.pureBlack,
      lightGrey: colors.pureGrey,
      grey: colors.grey200,
    },
  },
  overrides: {
    MuiLink: {
      root: {
        '&:hover': {
          color: colors.brandingPrimary600,
        },
      },
    },
    MuiTypography: {
      h1: {
        color: colors.grey900,
      },
    },
    MuiDialog: {
      paper: {
        padding: 24,
      },
    },
  },
})

const theme = responsiveFontSizes(muiTheme)
export default theme
