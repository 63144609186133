import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  textBold: {
    fontFamily: 'ibm-plex-sans-regular',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '21px',
    textAlign: 'left',
    color: '#D3CFCF',
  },
}))
const CameraDisclaimer = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <span className={classes.textBold}> { t('camera_sharing.form_helper') }</span>
  )
}

export default CameraDisclaimer
