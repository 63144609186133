import { useTranslation } from 'react-i18next'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContentText from '@material-ui/core/DialogContentText'
import PrimaryButton from 'shared-module/components/button/PrimaryButton'
import SecondaryButton from 'shared-module/components/button/SecondaryButton'
import VoskerPrimaryButton from 'vosker/src/components/buttons/VoskerPrimaryButton'
import VoskerSecondaryButton from 'vosker/src/components/buttons/VoskerSecondaryButton'
import ValideSolid from 'shared-module/components/confirmation/icons/ValideSolid'

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      padding: '48px !important',
      [theme.breakpoints.down('xs')]: {
        padding: '1rem !important',
      },
    },
    '& .MuiDialogContent-root': {
      padding: '0px 0px  0px 17px',
    },
  },
  dialogActions: {
    paddingTop: '32px',
    [theme.breakpoints.down('xs')]: {
      paddingTop: '14px',
    },
  },
  dialogTitle: {
    padding: '5px 1rem',
  },
  newDialogTitle: {
    padding: '1rem 1.5rem 0rem',
  },
  confirmButton: {
    [theme.breakpoints.up('lg')]: {
      minWidth: '33%',
      flexWrap: 'nowrap',
    },
  },
  contentText: {
    fontSize: '14px',
  },
  confirmationWrap: {
    display: 'flex',
  },
}))

const NewPurchaseConfirmation = ({
  open,
  onClose,
  title,
  text,
  onConfirm,
  confirmText,
  onCancel,
  cancelText,
  isNewUi = false,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const ConfirmButton = isNewUi ? VoskerPrimaryButton : PrimaryButton
  const CancelButton = isNewUi ? VoskerSecondaryButton : SecondaryButton

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth className={classes.dialog}>
      <Box className={classes.confirmationWrap}>
        <ValideSolid />
        <Box className={classes.confirmationTexts}>
          <DialogTitle className={isNewUi ? classes.newDialogTitle : classes.dialogTitle}>{ title }</DialogTitle>
          { text && (
            <DialogContent>
              <DialogContentText className={classes.contentText} i18nkey="multiline">
                { text }
              </DialogContentText>
            </DialogContent>
          ) }
        </Box>
      </Box>
      <DialogActions className={classes.dialogActions}>
        <Grid container display="flex" flexDirection="row" justifyContent="flex-end" spacing={1}>
          { onCancel && (
            <Grid item xs={isNewUi ? 12 : 'auto'} sm="auto" lg={isNewUi ? 4 : 'auto'}>
              <CancelButton onClick={onCancel} value={cancelText || t('button.cancel')}>
                { cancelText || t('button.cancel') }
              </CancelButton>
            </Grid>
          ) }

          { onConfirm && (
            <Grid item xs={isNewUi ? 12 : 'auto'} sm="auto" className={isNewUi ? classes.confirmButton : ''}>
              <ConfirmButton onClick={onConfirm} value={confirmText}>
                { confirmText }
              </ConfirmButton>
            </Grid>
          ) }
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default NewPurchaseConfirmation
