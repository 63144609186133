import { useTheme } from '@material-ui/core'

const VoskerInfoIcon = ({ color }) => {
  const theme = useTheme()
  return (
    <svg width="23" height="23" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill={theme.palette?.status?.warning + ' !important'}>
      <circle cx="10" cy="10.0001" r="9.99964" fill="#F9C20A" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.0651 4.65747H8.92236V6.62845H11.0651V4.65747ZM11.0682 8.00012H8.92824V15.4999H11.0682V8.00012Z" fill={theme.palette.background?.shadow} />
    </svg>
  )
}
export default VoskerInfoIcon
