import { useState, useRef } from 'react'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import InformationBarItems from './InformationBarItems'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import Grow from '@material-ui/core/Grow'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import MenuList from '@material-ui/core/MenuList'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Box from '@material-ui/core/Box'
import GalleryTopBarCameraName from 'photo-module/photos/ui/photos-gallery/information-bar/elements/GalleryTopBarCameraName'
import AppBarTitle from 'shared-module/components/app-bar/AppBarTitle'
import PhotoGalleryIcon from 'assets/icons/navigation/PhotoGalleryIcon'
import { useTranslation } from 'react-i18next'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useTheme } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  appbar: {
    height: '64px',
    paddingLeft: '24px',
    paddingRight: '24px',
  },
  content: {
    fontSize: '0.857rem',
    textTransform: 'uppercase',
  },
}))

const InformationBar = () => {
  const anchorRef = useRef()
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()

  const handleClose = () => {
    setOpen(false)
  }

  const handleToggle = () => {
    setOpen(!open)
  }

  const infoBarItem = {
    cursor: 'pointer',
    display: 'flex',
    borderLeft: `4px solid ${theme.palette.background.default}`,
    borderRight: `4px solid ${theme.palette.background.default}`,
    alignItems: 'center',
  }

  return (
    <Grid container justifyContent="flex-start" className={classes.appbar}>
      <Grid item md={1} sm={2} xs={6} style={{ display: 'flex', alignItems: 'center' }}>
        <AppBarTitle Icon={PhotoGalleryIcon} title={t('menu.photo_gallery')} />
      </Grid>
      <Hidden mdDown>
        <Grid item md={11} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'stretch', gap: '0px', flexWrap: 'wrap' }}>
          <InformationBarItems style={infoBarItem} className={classes.content} />
        </Grid>
      </Hidden>
      <Hidden lgUp>
        <Grid item md={11} sm={10} xs={6} style={{ display: 'flex', alignItems: 'center' }}>
          <Grid container justifyContent="flex-end">
            <GalleryTopBarCameraName />
            <MoreVertIcon
              ref={anchorRef}
              aria-haspopup="true"
              onClick={handleToggle}
              style={{ fill: 'white' }}
              data-testid="information-bar-mobile-icon"
            />

            <Popper
              open={open}
              anchorEl={anchorRef.current}
              placement="bottom-end"
              transition
              disablePortal
              data-testid="information-bar-mobile-content"
            >
              { ({ TransitionProps }) => (
                <Grow {...TransitionProps}>
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <Box boxShadow={5}>
                        <MenuList style={{ padding: 0 }}>
                          <InformationBarItems hideCameraName style={{ borderBottom: `4px solid ${theme.palette.background.default}` }} className={classes.content} />
                        </MenuList>
                      </Box>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              ) }
            </Popper>
          </Grid>
        </Grid>
      </Hidden>
    </Grid>
  )
}

export default InformationBar
