import { useDispatch } from 'react-redux'
import useUser from 'user-module/user/core/useUser'
import promoCodesApi from 'user-module/promo-code/api/promoCodesApi'
import userActions from 'user-module/user/core/userActions'

const promocodeActions = {
  redeem: (user, promocode) => dispatch => promoCodesApi
    .redeem(promocode)
    .then(response => Promise.all([Promise.resolve(response), dispatch(userActions.reload(user.id))])),
}

const usePromoCodesActions = () => {
  const dispatch = useDispatch()
  const user = useUser()

  const redeem = (promocode) => dispatch(promocodeActions.redeem(user, promocode))
    .then(([response]) => Promise.resolve(response))

  return { redeem: redeem }
}

export default usePromoCodesActions
