import { TextField } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const ProfileInput = ({ form, formik, name, label, disabled }) => {
  const { t } = useTranslation()

  return (
    <TextField
      id={name}
      name={name}
      label={label}
      variant="outlined"
      {...formik.getFieldProps(name)}
      error={!!formik.errors[name]}
      helperText={!!formik.errors[name] && t(formik.errors[name])}
      fullWidth
      inputProps={{
        form: form,
      }}
      disabled={disabled}
      data-testid={name}
    />
  )
}

export default ProfileInput
