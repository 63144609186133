/* eslint-disable no-constant-condition */
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import BatteryIcon from 'camera-module/cameras/ui/cameras-page/icons/BatteryIcon'
import CellularSignalIcon from 'camera-module/cameras/ui/cameras-page/icons/CellularSignalIcon'
import MemoryIcon from 'camera-module/cameras/ui/cameras-page/icons/MemoryIcon'
import cameraActions from 'camera-module/camera/core/cameraActions'
import useCameraModelsAndVariants from '../../../model/core/useCameraModelsAndVariants'
import CameraNotificationMessage from './camera-notification-message/CameraNotificationMessage'
import CameraExpirationNotice from './camera-expiration-notice/CameraExpirationNotice'
import { useCriticalNotification } from 'notifications-module/core/notifications.hooks'
import ManagePlanButton from 'camera-module/cameras/ui/cameras-page/ManagePlanButton'
import CameraUpdateMessage from 'camera-module/cameras/ui/cameras-page/camera-updating-message/CameraUpdateMessage'

import V100 from 'camera-module/cameras/ui/cameras-page/icons/camera_model/V100.png'
import V150 from 'camera-module/cameras/ui/cameras-page/icons/camera_model/V150.png'
import V200 from 'camera-module/cameras/ui/cameras-page/icons/camera_model/V200.png'
import V300 from 'camera-module/cameras/ui/cameras-page/icons/camera_model/V300.png'
import V300Ultimate from 'camera-module/cameras/ui/cameras-page/icons/camera_model/V300-ultimate.png'
import VKX from 'camera-module/cameras/ui/cameras-page/icons/camera_model/VKX.png'
import VoskerNoImgIcon from 'camera-module/cameras/ui/cameras-page/icons/VoskerNoImgIcon'
import GroupIcon from 'assets/icons/navigation/GroupIcon'
import ShareIcon from 'assets/icons/navigation/ShareIcon'
import NormalIcon from 'assets/icons/navigation/NormalIcon'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import DeleteCameraDialog from '../../../../../spypoint/src/cameras-module/ui/cameras-page/DeleteCameraDialog'
import useConfirmationDialogActions from 'shared-module/components/confirmation/useConfirmationDialogActions'
import cameraSharingApi from 'camera-module/camera/api/cameraSharingApi'
import cameraSharingActions from 'camera-module/camera/core/CameraSharingActions'
import messageActions from 'shared-module/message/messageActions'
import GuestsList from 'camera-module/camera/ui/camera-sharing-page/GuestsList'
import ConfirmationDialog from 'shared-module/components/confirmation/ConfirmationDialog'

const useStyles = makeStyles((theme) => ({
  tile: {
    minWidth: 256,
    maxWidth: '15.666667%',
    margin: '0.75rem 0.5rem !important',
    [theme.breakpoints.only('md')]: {
      minWidth: 224,
    },
    [theme.breakpoints.only('sm')]: {
      minWidth: 218,
    },
    flexDirection: 'column',
  },
  innerTile: {
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'column',
    background: 'none',
  },
  cameraNameItem: ({ isSpypoint }) => ({
    borderRadius: '0.375rem 0.375rem 0 0',
    background: isSpypoint ? theme.palette.primary.main : theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
  }),
  Icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '30%',
    paddingRight: '0.344rem',
  },
  GroupIcon: {
    marginLeft: '8px',
  },
  NoramlIcon: {
    marginRight: '8px',
  },
  menuItemStyle: {
    fontFamily: 'ibm-plex-sans-regular',
    fontSize: '14px',
    fontWeight: 'bold',
  },
  Icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '30%',
    paddingRight: '0.344rem',
  },
  GroupIcon: {
    marginLeft: '8px',
  },
  ownerName: {
    overflow: 'hidden',
    whiteSpace: 'noWrap',
    textOverflow: 'ellipsis',
    padding: '0.344rem 0 0.344rem 0',
    fontWeight: 'bold',
    fontSize: theme.typography.label?.fontSize,
    fontFamily: theme.typography.label?.fontFamily,
  },

  cameraName: ({ isSpypoint }) => ({
    overflow: 'hidden',
    whiteSpace: 'noWrap',
    textOverflow: 'ellipsis',
    padding: isSpypoint ? '0.344rem 0 0.344rem 1rem' : '0.344rem 2.5rem 0.344rem 1rem',
    fontWeight: 'bold',
    fontSize: theme.typography.label?.fontSize,
    fontFamily: theme.typography.label?.fontFamily,
  }),
  frame: ({ isGuest }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '0 0 0.375rem 0.375rem',
    background: theme.palette.background.paper,
    paddingBottom: isGuest ? 20 : 0,
  }),
  photoWrapper: {
    width: 130,
    height: 200,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1.5rem 0',
  },
  photoWrapperNoImg: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 200,
    height: 200,
    margin: '0.1rem 0',
    borderRadius: '0.375rem',
    background: 'transparent',
  },
  status: ({ isSpypoint }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    padding: isSpypoint ? '0.5rem 0 1.5rem 0' : '0.5rem 0',
  }),
  cameraImage: {
    width: 'inherit',
  },
  noImgIcon: {
    width: '3.75rem',
    height: '3.75rem',
  },
}))

const CameraTile = ({ camera, isNewUi, isSpypoint, isShared, ownedSharedCameras, isGuest }) => {
  const classes = useStyles({ isSpypoint, isGuest })
  const history = useHistory()
  const dispatch = useDispatch()
  const [menuAnchorElement, setMenuAnchorElement] = useState(null)
  const { t } = useTranslation()

  const cameraModel = useCameraModelsAndVariants(camera.status?.model)
  const deleteDialogActions = useConfirmationDialogActions()
  const imageByModel = { V100: V100, V150: V150, V200: V200, V300: V300, 'V300-ULTIMATE': V300Ultimate, VKX: VKX }
  const criticalNotification = useCriticalNotification(camera.id)
  const Shared = (cameraId) => {
    const sharedCam = []
    return sharedCam.concat.apply([], ownedSharedCameras?.map(x => x?.sharedCameras)).map(x => x.cameraId).includes(cameraId)
  }

  const openDeleteDialog = () => {
    setMenuAnchorElement(null)
    deleteDialogActions.open()
  }

  const openMenu = (e) => {
    e.stopPropagation()
    setMenuAnchorElement(e.currentTarget)
  }
  const stopSharingeDialogActions = useConfirmationDialogActions()
  const handleCameraClick = () => {
    if (isShared) {
      localStorage.setItem('isShared', true)
      dispatch(cameraActions.set({ ...camera, id: camera.cameraId, isShared: true }))
      history.push('/camera/' + camera.cameraId)
    } else {
      localStorage.setItem('isShared', false)
      dispatch(cameraActions.set(camera))
      history.push('/camera/' + camera.id)
    }
  }
  const revokeAccess = () => {
    cameraSharingApi.revokeAccess(camera.cameraId)
      .then(() => {
        dispatch(cameraSharingActions.fetchAllSharedCameras(true))
        dispatch(messageActions.showSuccess('Camera revoked successfully'))
      })
      .catch(() => dispatch(messageActions.showError()))
  }
  const removeAccess = (cameraId) => {
    cameraSharingApi.deleteAccess({ cameraIds: [cameraId] })
      .then(() => {
        dispatch(cameraSharingActions.fetchOwnedSharedCameras())
        stopSharingeDialogActions.close()
      })
      .catch(() => dispatch(messageActions.showError()))
  }
  const renderCameraTileMessage = () => {
    return (
      <>
        { criticalNotification && <CameraNotificationMessage notification={criticalNotification} /> }

        <CameraExpirationNotice camera={camera} />

        { camera.status?.updateInProgress && <CameraUpdateMessage /> }
        { isGuest && <GuestsList camera={camera} /> }
      </>
    )
  }

  return (
    <Box component={Grid} container item xs sm={3} md={1} lg={2} xl={1} className={classes.tile}>
      <Grid container id={camera.id} onClick={isGuest ? null : handleCameraClick} className={classes.innerTile}>
        <Grid container className={classes.cameraNameItem}>
          <Grid container direction="row" xs={8} sm={8} md={8} lg={8} xl={8} item>
            <Grid xs={!isShared && isGuest ? 12 : 7} sm={!isShared && isGuest ? 12 : 7} md={!isShared && isGuest ? 12 : 7} lg={!isShared && isGuest ? 12 : 7} xl={!isShared && !isGuest ? 12 : 7}>
              <Typography title={!isShared ? camera.config.name : camera?.name} className={classes.cameraName}>
                { !isShared ? camera.config.name : camera?.name }
              </Typography>
            </Grid>
            { isShared && !isGuest && (
              <Grid xs={5} sm={5} md={5} lg={5} xl={5}>
                <Typography title={camera?.OwnerName} className={classes.ownerName}>
                  { `(${camera?.OwnerName})` }
                </Typography>
              </Grid>
            ) }
          </Grid>
          { !isNewUi && (
            <Grid className={classes.Icon} item>
              { Shared(camera.id) && <ShareIcon /> }
              <GroupIcon onClick={openMenu} className={classes.GroupIcon} />
            </Grid>
          ) }
        </Grid>

        <Grid container item className={classes.frame}>
          { cameraModel && !!imageByModel[cameraModel.name]
            ? (
              <Grid item className={classes.photoWrapper}>
                <img
                  src={imageByModel[cameraModel.name]}
                  alt={camera.status?.model}
                  className={classes.cameraImage}
                />
              </Grid>
              )
            : (
                isSpypoint
                  ? (
                    <div className={classes.photoWrapper}>
                      <img src={cameraModel?.icon} className={classes.cameraImage} alt={camera.status?.model} />
                    </div>
                    )
                  : (
                    <Grid item className={classes.photoWrapperNoImg}>
                      <VoskerNoImgIcon className={classes.noImgIcon} />
                    </Grid>
                    )
              ) }

          { !isGuest && (
            <Grid container item className={classes.status}>
              <Grid item data-testid={'battery-status-' + camera.id}>
                <BatteryIcon
                  battery={
                isSpypoint
                  ? (camera.status?.model === 'FLEX' && camera.status.batteryType === '12V'
                      ? camera.status.solarBattery
                      : camera.status?.mainBattery)
                  : (camera.status?.model === 'FLEX' && camera.status.batteryType === '12V'
                      ? camera.status.solarBattery
                      : camera.status?.mainBattery)
              }
                />
              </Grid>

              <Grid item data-testid={'cellular-signal-status-' + camera.id}>
                <CellularSignalIcon signal={camera.status?.signal} />
              </Grid>

              <Grid item data-testid={'memory-status-' + camera.id}>
                <MemoryIcon memory={camera.status?.memory} />
              </Grid>
            </Grid>
          ) }

          { !isShared && (
            <Grid container item>
              <ManagePlanButton isNewUi={isNewUi} camera={camera} isSpypoint={isSpypoint} />
            </Grid>
          ) }
        </Grid>

      </Grid>

      { renderCameraTileMessage() }
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id="camera-menu"
        anchorEl={menuAnchorElement}
        open={Boolean(menuAnchorElement)}
        onClose={() => setMenuAnchorElement(false)}
      >
        <MenuItem style={{ backgroundColor: 'rgb(49, 48, 48)' }} onClick={isGuest ? stopSharingeDialogActions.open : isShared ? revokeAccess : openDeleteDialog}>{ isSpypoint && <NormalIcon className={classes.NoramlIcon} /> }<span className={classes.menuItemStyle}>{ isGuest ? t('camera.menu.stop') : isShared ? t('camera.menu.revoke') : t('camera.menu.delete') }</span></MenuItem>
      </Menu>
      <DeleteCameraDialog camera={camera} open={deleteDialogActions.isOpen} onClose={deleteDialogActions.close} />
      <ConfirmationDialog
        isNewUi={false}
        open={stopSharingeDialogActions.isOpen}
        title={t('camera_sharing.stop_sharing.title')}
        text={t('camera_sharing.stop_sharing.message')}
        onConfirm={() => {
          removeAccess(camera?.cameraId)
          setMenuAnchorElement(false)
        }}
        onCancel={stopSharingeDialogActions.close}
        confirmText={t('camera_sharing.stop_sharing.cta_button_confirm')}
        cancelText={t('camera_sharing.stop_sharing.cta_button_cancel')}
      />
    </Box>
  )
}
export default CameraTile
