import { useTranslation } from 'react-i18next'
import Amount from 'shared-module/components/amount/Amount'
import LineTitle from './LineTitle'

const CartQuantityLine = ({ product, currency }) => {
  const { t } = useTranslation()

  return (
    <>
      <LineTitle>{ t('app:checkout_process.hd_photos.quantity') }</LineTitle>
      <LineTitle align="right">
        { product.quantity }
        { ' @ ' }
        <Amount
          value={product.basePrice}
          currencyCode={currency}
        />
        { ' ' }
        { t('app:checkout_process.hd_photos.each') }
      </LineTitle>
    </>
  )
}

export default CartQuantityLine
