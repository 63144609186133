import SpypointCameraStatusTile from '../SpypointCameraStatusTile'

const SpypointMemoryTile = ({ value, disable }) => (
  <SpypointCameraStatusTile
    label="app:camera.sd_card"
    icon="/images/status/sdcard.svg"
    value={`${value.percentage}%`}
    disable={disable}
  />
)

export default SpypointMemoryTile
