import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const NotificationsFilledIconActive = ({ ...props }) => {
  const theme = useTheme()

  return (
    <SvgIcon data-testid="NotificationActiveBell" width="16" height="16" {...props}>
      <path
        d="M24.2002 8.98047C24.2002 11.2597 22.3525 13.1074 20.0732 13.1074C17.794 13.1074 15.9463 11.2597 15.9463 8.98047C15.9463 6.70122 17.794 4.85352 20.0732 4.85352C22.3525 4.85352 24.2002 6.70122 24.2002 8.98047Z"
        fill={theme.palette.error.main}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5385 14.4683C16.7444 14.1993 14.5598 11.8449 14.5598 8.98029C14.5598 7.80004 14.9306 6.7064 15.5621 5.80954C15.2253 5.66736 14.8733 5.55109 14.5087 5.46309V4.75893C14.5087 3.95417 13.8046 3.25 12.9998 3.25C12.1951 3.25 11.4909 3.95417 11.4909 4.75893V5.46309C8.57362 6.16726 6.46112 8.68214 6.46112 11.8006V17.3333L4.44922 19.3452V20.3512H21.5504V19.3452L19.5385 17.3333V14.4683ZM13.4022 23.369H12.9998C11.8933 23.369 10.9879 22.4637 10.9879 21.3571H15.0117C15.0117 21.6589 14.9111 21.9607 14.8105 22.1619C14.6093 22.7655 14.1064 23.2684 13.4022 23.369Z"
        fill={theme.palette.text.primary}
      />
    </SvgIcon>
  )
}

export default NotificationsFilledIconActive
