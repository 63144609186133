import { useState } from 'react'
import { CircularProgress, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import AppButton from 'shared-module/components/button/AppButton'
import PrimaryButton from 'shared-module/components/button/PrimaryButton'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { useDispatch } from 'react-redux'
import dialogActions from 'shared-module/dialog/dialogActions'
import cartApi from 'spypoint/src/checkout-module/order/core/cart.api'
import CheckoutDialog from 'spypoint/src/checkout-module/order/ui/checkout-page/CheckoutDialog'
import cartActions from 'spypoint/src/checkout-module/order/core/cart.actions'
import userClubMembershipState from 'spypoint/src/user-module/club-membership/core/userClubMembershipState'
import userHdPackageState from 'user-module/hd-package/core/userHdPackageState'
import userPaymentMethodsState from 'spypoint/src/user-module/payment-methods/core/userPaymentMethods.state'
import useOrderNav from 'checkout-module/checkout/core/useOrderNav'
import { useCart } from '../../core/cart.hooks'
import TagManager from 'react-gtm-module'
import { clearEcommerce, prepareGTMData } from '../../core/purchaseDataBuilder'
import appConfig from 'shared-module/config/appConfig'
import switches from 'shared-module/config/switches'
import useUserActions from 'user-module/user/core/useUserActions'

const useStyles = makeStyles((theme) => ({
  continueButton: {
    height: '100%',
    backgroundColor: theme.palette.background.tile,
    marginRight: 15,
    '&:hover': {
      background: theme.palette.background.paper,
    },
  },
  checkoutButtons: {
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      padding: '0px 15px',
    },
    marginBottom: 10,
  },
}))

const CheckoutButtons = ({ user, disabledPlaceOrder, createPaymentInfo, creditCartNotRequired, isActivation = false, setStep }) => {
  const history = useHistory()
  const classes = useStyles()
  const { t } = useTranslation()
  const sharedCameraEnabled = appConfig.sharedCamera?.switch === switches.on
  const { handleBuyMore } = useOrderNav('device_selection', sharedCameraEnabled ? '/' : '/camera/plan')
  const [showDialog, setShowDialog] = useState(false)
  const [dialogData, setDialogData] = useState(null)
  const [paymentError, setPaymentError] = useState(false)
  const [spinner, setSpinner] = useState(false)
  const stripe = useStripe()
  const elements = useElements()
  const dispatch = useDispatch()
  const location = useLocation()
  const { cartItems, deleteFromCart } = useCart()
  const { reload } = useUserActions()
  let customSuccessMsg = false

  const goToCart = () => history.push({
    pathname: '/cart',
    search: location.search,
    state: { fromCheckout: '/checkout' },
  })

  const goToMain = () => {
    deleteFromCart({ itemId: cartItems?.products[0]?.id })
    setStep(0)
  }

  const submitForms = async () => {
    if (!stripe || !elements) {
      return
    }
    setSpinner(true)
    if (createPaymentInfo) {
      const cardElement = elements.getElement(CardElement)
      const { error, token } = await stripe.createToken(cardElement)
      if (error) {
        // in case of credit card not required the error state of stripe is normal
        if (creditCartNotRequired) {
          customSuccessMsg = true
          processPlaceOrder(null)
          return
        }
        dispatch(dialogActions.showError(error.message))
        setSpinner(false)
        return
      }
      processPlaceOrder(token.id)
    } else processPlaceOrder(null)
  }

  const processPlaceOrder = customerToken => {
    cartApi
      .placeOrder(customerToken)
      .then(handleSuccess)
      .catch(handleError)
  }

  const handleSuccess = (productItems) => {
    const successItems = productItems.filter(item => item.status.toUpperCase() === 'SUCCESS')
    if (successItems.length !== 0 && appConfig.gtm.switch === switches.on) {
      dispatchGTMPurchaseEvent(successItems)
    }

    const failedItems = productItems.filter(item => item.status.toUpperCase() !== 'SUCCESS')
    if (failedItems.length === 0) {
      creditCartNotRequired && customSuccessMsg
        ? openDialog({
          title: t('app:checkout.dialog.title'),
          text: t('app:checkout.dialog.text_no_credit_card'),
        })
        : openDialog({
          title: t('app:checkout.dialog.title'),
          text: t('app:checkout.dialog.text'),
        })
    } else {
      setPaymentError(true)
      openDialog({
        title: t('app:checkout.dialog.error.title'),
        text: t('app:checkout.dialog.error.message'),
      })
    }
  }

  const dispatchGTMPurchaseEvent = (successItems) => {
    const gtmData = prepareGTMData(successItems, cartItems, user)
    if (!gtmData) return
    TagManager.dataLayer({
      dataLayerName: 'purchasedProduct',
      dataLayer: clearEcommerce,
    })
    TagManager.dataLayer({
      dataLayerName: 'purchasedProduct',
      dataLayer: gtmData,
    })
  }

  const handleError = (error) => {
    setPaymentError(true)
    openDialog({
      title: t('app:checkout.dialog.error.title'),
      text: t('app:checkout.dialog.error.text'),
      footer: t('app:checkout.dialog.error.footer'),
      errorMessages: [error?.response?.data?.message],
    })
  }

  const openDialog = (data) => {
    setShowDialog(true)
    setDialogData(data)
    setSpinner(false)
  }

  const reloadPayment = () => {
    reload(user)
    dispatch(cartActions.fetchCart())
    dispatch(userClubMembershipState.get())
    dispatch(userHdPackageState.get())
    dispatch(userPaymentMethodsState.clear())
  }

  const closeDialog = (isError) => {
    if (isActivation && paymentError) {
      reloadPayment()
      setShowDialog(false)
      setPaymentError(false)
      return
    }
    if (!isError) {
      reload(user)
      reloadPayment()
      handleBuyMore()
      if (isActivation) {
        history.push({
          pathname: '/confirm-activation',
        })
      }
    } else {
      goToCart()
    }
  }

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        spacing={2}
        className={classes.checkoutButtons}
      >
        <Grid item xs={12} sm={isActivation ? 4 : 6}>
          { isActivation
            ? (
              <AppButton
                fullWidth
                color="secondary"
                className={classes.continueButton}
                value={t('button.cancel')}
                onClick={goToMain}
              />
              )
            : (
              <AppButton
                fullWidth
                color="secondary"
                className={classes.continueButton}
                value={t('app:checkout.modify_cart')}
                onClick={goToCart}
              />
              ) }
        </Grid>
        <Grid item xs={12} sm={isActivation ? 8 : 6} container justifyContent="center">
          { !spinner
            ? (
              <PrimaryButton
                id="place_order"
                fullWidth
                value={t('app:checkout.place_order')}
                disabled={disabledPlaceOrder}
                onClick={submitForms}
              />
              )
            : <CircularProgress /> }
        </Grid>
      </Grid>
      { showDialog && <CheckoutDialog showDialog={showDialog} dialogData={dialogData} closeDialog={closeDialog} /> }
    </>
  )
}
export default CheckoutButtons
