import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import FirstNameField from 'storybook-component-module/src/wrappers/fields/FirstNameField'
import clsx from 'clsx'
import LastNameField from 'storybook-component-module/src/wrappers/fields/LastNameField'
import { VoskerInputStyles } from 'vosker/src/components/buttons/VoskerInputField'
import TermsCheckbox from 'storybook-component-module/src/wrappers/fields/TermsCheckbox'
import { useTranslation } from 'react-i18next'
import { FormDataContext } from 'shared-module/new-components/form/FormDataContext'
import ConsentFormButton from 'user-module/user/ui/register/components/ConsentFormButton'
import EmailListCheckbox from 'storybook-component-module/src/wrappers/fields/EmailListCheckbox'
import VoskerSecondaryButton from 'vosker/src/components/buttons/VoskerSecondaryButton'
import CButton from 'storybook-component-module/src/components/buttons/CButton'
import Grid from '@material-ui/core/Grid'
import useUserActions from 'user-module/user/core/useUserActions'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material'
import useUser from 'user-module/user/core/useUser'

const useStyles = makeStyles(theme => ({
  dialog: {
    width: '500px',
    padding: '32px',
    margin: 'auto',
    borderRadius: ({ isNewUi }) => isNewUi ? '8px' : 0,
  },
  label: {
    marginTop: 10,
  },
  link: {
    color: `${theme.palette.status?.info}`,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  input: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.palette.background?.paper,
      paddingRight: 0,
    },
    '& .MuiOutlinedInput-root.Mui-error': {
      marginBottom: '0.25rem',
    },
  },
  titleStyle: {
    textAlign: ({ isNewUi }) => isNewUi ? 'left' : 'center',
    fontFamily: ({ isNewUi }) => isNewUi ? 'exo2-semibold' : 'Oswald',
    padding: '0px',
    fontSize: ({ isNewUi }) => isNewUi ? '17px' : '28px',
    fontWeight: ({ isNewUi }) => isNewUi ? 600 : 500,
    textTransform: ({ isNewUi }) => isNewUi ? undefined : 'uppercase',
  },
  textContent: {
    textAlign: ({ isNewUi }) => isNewUi ? 'left' : 'center',
    fontSize: ({ isNewUi }) => isNewUi ? '13px' : '15px',
    fontStyle: 'normal',
    fontWeight: ({ isNewUi }) => isNewUi ? 500 : 400,
    marginBottom: ({ isNewUi }) => isNewUi ? '16px' : '24px',
    color: theme.palette.text.primary,
  },
  content: {
    overflowY: 'hidden !important',
  },
}))

const ConsentFormDialog = ({ onClose, isNewUi = false }) => {
  const theme = useTheme()
  const classes = useStyles({ isNewUi })
  const { t } = useTranslation()
  const importedClasses = VoskerInputStyles()
  const [checked, setChecked] = useState(false)
  const [checkedEmail, setCheckedEmail] = useState(false)
  const userActions = useUserActions()
  const user = useUser()
  const breakpoint = useMediaQuery(theme.breakpoints.only('xs'))
  const LogoutButton = isNewUi ? VoskerSecondaryButton : CButton

  return (
    <Dialog
      open
      onClose={onClose}
      disableBackdropClick
      PaperProps={{
        className: classes.dialog,
      }}
    >
      <DialogTitle className={classes.titleStyle}> { t('consentForm.title') }</DialogTitle>
      <div style={{ alignSelf: isNewUi ? 'left' : 'center', textAlign: isNewUi ? 'left' : 'center' }}>
        <div className={classes.textContent} style={{ marginTop: '24px' }}>
          { t('consentForm.subtitle', { company: isNewUi ? 'VOSKER®' : 'SPYPOINT' }) }
        </div>
        <div className={classes.textContent}>
          { t('consentForm.content') }
        </div>
      </div>
      <FormDataContext>
        <FirstNameField
          fieldName="firstName"
          className={isNewUi ? clsx(importedClasses.input, classes.input) : ''}
          validations
          isNewUi={isNewUi}
          defaultValue={user?.firstName}
        />
        <LastNameField
          fieldName="lastName"
          className={isNewUi ? clsx(importedClasses.input, classes.input) : ''}
          validations
          isNewUi={isNewUi}
          defaultValue={user?.lastName}
        />
        <TermsCheckbox
          checked={checked}
          setChecked={setChecked}
          fieldName="consent"
          validations
          isNewUi={isNewUi}
        />
        { breakpoint
          ? (
            <Grid container justifyContent="flex-end">
              <Grid xs={12} item style={{ marginBottom: isNewUi ? '8px' : '24px' }}>
                <LogoutButton color="primary" variety="secondary" onClick={() => userActions.logout()} style={{ width: '100%' }}>
                  { t('menu.logout') }
                </LogoutButton>
              </Grid>
              <Grid xs={12} item>
                <ConsentFormButton isNewUi={isNewUi} checked={checked} checkedEmail={checkedEmail} onClose={onClose} />
              </Grid>
            </Grid>
            )
          : (
            <Grid container justifyContent="flex-end">
              <Grid item style={{ marginRight: isNewUi ? '8px' : '24px' }}>
                <LogoutButton color="primary" variety="secondary" onClick={() => userActions.logout()} style={{ width: '100%' }}>
                  { t('menu.logout') }
                </LogoutButton>
              </Grid>
              <Grid item>
                <ConsentFormButton isNewUi={isNewUi} checked={checked} onClose={onClose} />
              </Grid>
            </Grid>
            ) }
      </FormDataContext>
    </Dialog>
  )
}

export default ConsentFormDialog
