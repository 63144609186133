import { useTranslation } from 'react-i18next'

import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Grid, Typography } from '@material-ui/core'

import useUserActions from 'user-module/user/core/useUserActions'
import CButton from 'storybook-component-module/src/components/buttons/CButton'
import PasswordsField from 'storybook-component-module/src/wrappers/fields/PasswordsField'
import { useFormik } from 'formik'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginTop: 24,
  },
  title: {
    fontWeight: 600,
    marginBottom: 16,
  },
  paper: {
    background: theme.palette.primary.contrastText,
    padding: 32,
  },
}))

const ProfilePassword = ({
  readOnly,
  inherentClasses,
  elevation = 3,
  variation = 'primary',
  padding,
  validationSchema,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const InitialValues = {
    current: '',
    new: '',
  }

  const { updatePassword } = useUserActions()

  const onSubmit = () =>
    updatePassword(formik.values.current, formik.values.new).then(
      (value) => value.messageType === 'SUCCESS' && formik.resetForm(),
    )
  const formik = useFormik({
    initialValues: InitialValues,
    validationSchema: validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: onSubmit,
  })
  return (
    <Box className={classes.wrapper}>
      <Box className={classes.title}>
        <Typography variant="h3">{ t('input.password') }</Typography>
      </Box>
      <Paper className={inherentClasses ? inherentClasses.overridePaper : classes.paper} square elevation={elevation}>
        <form id="password-form" onSubmit={formik.handleSubmit}>
          <Grid container spacing={2} style={{ padding: padding || 0 }}>
            <Grid xs={12} item>
              <PasswordsField
                name="current"
                label={t('input.password_current')}
                margin="none"
                disabled={readOnly}
                formik={formik}
                touched={formik.touched.current}
              />
            </Grid>
            <Grid xs={12} item>
              <PasswordsField
                name="new"
                label={t('change_password.form.password')}
                margin="none"
                disabled={readOnly}
                formik={formik}
                touched={formik.touched.new}
              />
            </Grid>
            <Grid container xs={12} justifyContent="flex-end" item>
              <CButton variety={variation} color="primary" type="submit">
                { t('button.update') }
              </CButton>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Box>
  )
}

export default ProfilePassword
