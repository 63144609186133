import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  shortDays: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  dayPicker: {
    cursor: 'pointer',
    fontSize: theme.typography.h3,
    color: theme.palette.primary.main,
    height: '40px',
    width: '40px',
    borderRadius: '20px',
    border: `1px solid ${theme.palette.primary.main}`,
    marginRight: '2px',
    '&:hover': {
      color: theme.palette.text.secondary,
    },
  },
  selectedDayPicker: {
    backgroundColor: theme.palette.primary.main,
    cursor: 'pointer',
    fontSize: theme.typography.h3,
    color: theme.palette.background.default,
    height: '40px',
    width: '40px',
    borderRadius: '20px',
    marginRight: '2px',
    border: `1px solid ${theme.palette.primary.main}`,
    '&:hover': {
      color: theme.palette.text.secondary,
    },
  },
}))
const days = [0, 1, 2, 3, 4, 5, 6]
const ScheduleDays = ({
  formik,
  selectedPeriod,
}) => {
  const { t } = useTranslation()
  const [isEveryDay, setEveryDay] = useState(false)
  const classes = useStyles()

  return (
    <>
      <Grid
        container
        wrap="nowrap"
        item
        direction="row"
        justifyContent="space-between"
        xs={12}
      >
        { days.map((day, index) => (
          <div
            key={`days-${day}`}
            onClick={() => {
              if (!selectedPeriod) {
                formik.setFieldValue('days',
                  [...new Set([...formik.values.days, index])])
                if (formik.values.days.includes(index)) {
                  const weekDays = [...formik.values.days]
                  const i = formik.values.days.indexOf(index)
                  if (i > -1) {
                    weekDays.splice(i, 1)
                    formik.setFieldValue('days', weekDays)
                    setEveryDay(weekDays.length === 7)
                  }
                } else {
                  setEveryDay(formik.values.days.length === 6)
                }
              }
            }}
            className={formik.values.days.includes(index)
              ? classes.selectedDayPicker
              : classes.dayPicker}
          >
            <div className={classes.shortDays}>
              { t(`app:schedule.short_days.${day}`) }
            </div>
          </div>
        )) }
      </Grid>
      <Grid item xs={12}>
        { t('app:schedule.every_day') }
        <Switch
          checked={isEveryDay}
          disabled={!!selectedPeriod}
          color="primary"
          onChange={(e) => {
            setEveryDay(e.target.checked)
            formik.setFieldValue('days', e.target.checked ? days : [0])
          }}
        />
      </Grid>
    </>
  )
}

export default ScheduleDays
