import { useDispatch, useSelector } from 'react-redux'

import sortedCameraModelState from 'camera-module/model/core/sorted-camera-models/sortedCameraModelState'
import sortedCameraModelActions from 'camera-module/model/core/sorted-camera-models/sortedCameraModelActions'
import useUser from 'user-module/user/core/useUser'

const useSortedCameraModels = () => {
  const user = useUser()
  const dispatch = useDispatch()
  const state = useSelector(state => state.sortedModels)

  if (!sortedCameraModelState.isReady(state)) {
    dispatch(sortedCameraModelActions.getModels(user))
  }

  return state.sortedModels
}

export default useSortedCameraModels
