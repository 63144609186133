export const getDateString = (textDate, language) => {
  const date = new Date(textDate)
  const dateOption = { day: '2-digit', month: 'long', year: 'numeric' }
  return date.toLocaleDateString(language, dateOption)
}

export const translatePrices = (camera, userClubMembership) => {
  const isPaymentAnnual = camera.subscriptions[0].paymentFrequency === 'annual'
  const isClubMember = userClubMembership.isMember

  if (isPaymentAnnual && isClubMember) return camera?.subscriptions?.[0]?.plan?.pricePerYearInsidersClub
  else if (isPaymentAnnual && !isClubMember) return camera?.subscriptions?.[0]?.plan?.pricePerYear
  else if (!isPaymentAnnual && isClubMember) return camera?.subscriptions?.[0]?.plan?.pricePerMonthIfPaidPerMonthInsidersClub
  else if (!isPaymentAnnual && !isClubMember) return camera.subscriptions?.[0]?.plan?.pricePerMonthIfPaidPerMonth
}

export const translateDateAndPrice = (camera, userClubMembership, subscription, i18n, showMembershipPrice) => {
  const showPlanPrice = (!userClubMembership.isMember && camera && camera.subscription.plan.name !== 'Free') ||
    (userClubMembership.isMember && camera && camera.subscription.plan.name !== 'Basic')

  return {
    date: userClubMembership.isMember
      ? getDateString(subscription[0]?.endDateBillingCycle, i18n.language)
      : getDateString(camera.subscriptions?.[0]?.endDateBillingCycle, i18n.language),
    price: showPlanPrice
      ? '$' + translatePrices(camera, userClubMembership)
      : showMembershipPrice ? '$' + subscription?.[0]?.plan?.price : '',
  }
}

export const translatePriceState = (camera, userClubMembership) => {
  const plan = camera.subscriptions?.[0].plan.name
  const isClubMember = userClubMembership.isMember
  const clubAutoRenew = userClubMembership.isAutoRenew
  const isFree = camera.subscriptions?.[0]?.isFree
  const isAutoRenew = camera.subscriptions?.[0]?.isAutoRenew

  if (isFree && !isClubMember) return 'renewal_without_price'
  else if (plan === 'Basic' && isClubMember && clubAutoRenew) return 'renewal_without_price'
  else if (plan === 'Basic' && isClubMember && !clubAutoRenew) return 'expiring'

  else if (!isFree && isAutoRenew) return 'renewal'
  else if (!isFree && !isAutoRenew) return 'expiring'
  else if (plan !== 'Basic' && !isFree && isClubMember) return 'renewal'

  // NOT ENTIRELY TESTED: Technically only supports Premium Plan case as of May 6, 2022
  else if (plan === 'Basic' && isFree && !isClubMember) return 'expiring'
  else if ((plan === 'Standard' || plan === 'Premium') && isFree) return 'expiring'
}
