import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const PhotoTileIcon = (props) => {
  const theme = useTheme()

  return (
    <SvgIcon {...props} width="20" height="20" data-testid="PhotoTileIcon">
      <path
        d="M18.25 5.70312V15.5469C18.25 16.4526 17.5151 17.1875 16.6094 17.1875H2.39062C1.48486 17.1875 0.75 16.4526 0.75 15.5469V5.70312C0.75 4.79736 1.48486 4.0625 2.39062 4.0625H5.39844L5.81885 2.93799C6.05811 2.29883 6.66992 1.875 7.35352 1.875H11.6431C12.3267 1.875 12.9385 2.29883 13.1777 2.93799L13.6016 4.0625H16.6094C17.5151 4.0625 18.25 4.79736 18.25 5.70312ZM13.6016 10.625C13.6016 8.3623 11.7627 6.52344 9.5 6.52344C7.2373 6.52344 5.39844 8.3623 5.39844 10.625C5.39844 12.8877 7.2373 14.7266 9.5 14.7266C11.7627 14.7266 13.6016 12.8877 13.6016 10.625ZM12.5078 10.625C12.5078 12.2827 11.1577 13.6328 9.5 13.6328C7.84229 13.6328 6.49219 12.2827 6.49219 10.625C6.49219 8.96728 7.84229 7.61719 9.5 7.61719C11.1577 7.61719 12.5078 8.96728 12.5078 10.625ZM8.40625 10.625C8.40625 10.0234 8.89844 9.53125 9.5 9.53125C9.80078 9.53125 10.0469 9.28516 10.0469 8.98437C10.0469 8.68359 9.80078 8.4375 9.5 8.4375C8.29346 8.4375 7.3125 9.41846 7.3125 10.625C7.3125 10.9258 7.55859 11.1719 7.85938 11.1719C8.16016 11.1719 8.40625 10.9258 8.40625 10.625Z"
        fill={props.disabled ? theme.palette.secondary.light : theme.palette.text.primary}
      />
    </SvgIcon>
  )
}

export default PhotoTileIcon
