import { Box } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import CButton from 'storybook-component-module/src/components/buttons/CButton'

const ForwardActionButton = ({ steps, activeStep, handleNext }) => {
  const onClick = () =>
    steps[activeStep].forwardAction && steps[activeStep].forwardAction() !== null
      ? steps[activeStep].forwardAction()
      : handleNext()

  return (
    <Box component={Grid} container display="flex" width="100%" justifyContent="flex-end">
      <Grid item xs={12} sm="auto">
        <CButton
          color="primary"
          style={{ width: '100%' }}
          disabled={!steps[activeStep].disabled}
          variety={steps[activeStep].forwardButtonVariety}
          onClick={onClick}
        >
          { steps[activeStep].forwardButtonText }
        </CButton>
      </Grid>
    </Box>
  )
}

export default ForwardActionButton
