export const getCameraSharingTabs = () => [
  {
    id: 'guests',
    path: '/camera-sharing/guests',
    label: 'camera_sharing.guests',
  },
  {
    id: 'cameras',
    path: '/camera-sharing/cameras',
    label: 'camera_sharing.shared_camera',
  },
]
