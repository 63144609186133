import { useMediaQuery, useTheme } from '@material-ui/core'

import MembershipFooterDesktop from 'spypoint/src/user-module/club-membership/ui/footer/MembershipFooterDesktop'
import MembershipFooterMobile from 'spypoint/src/user-module/club-membership/ui/footer/MembershipFooterMobile'

const MembershipPageFooter = () => {
  const theme = useTheme()
  const xsBreakpoints = useMediaQuery(theme.breakpoints.only('xs'))

  return xsBreakpoints
    ? <MembershipFooterMobile />
    : <MembershipFooterDesktop />
}
export default MembershipPageFooter
