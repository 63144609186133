import axios from 'axios'
import { siteId } from '../config/default'

const restapi = axios.create()

restapi.interceptors.request.use((config) => {
  config.baseURL = window.location.origin + '/sfcc'

  const token = localStorage.getItem(`access_token_${siteId}`)

  if (token) {
    config.headers.Authorization = `Bearer ${token}`
    config.withCredentials = false
  }

  return config
})

export default restapi
