import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const SaveIcon = (props) => {
  const theme = useTheme()

  return (
    <SvgIcon {...props} style={{ width: 18, height: 18, marginBottom: 0 }} viewBox="0 0 16 16" fill="none" data-testid="SaveIcon">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.41 3.55065L12.4493 0.589973V0.589973C12.1316 0.272249 11.7007 0.0937535 11.2514 0.09375H1.78795C0.852256 0.09375 0.09375 0.852256 0.09375 1.78795V14.2121C0.09375 15.1477 0.852256 15.9062 1.78795 15.9062H14.2121C15.1477 15.9062 15.9062 15.1477 15.9062 14.2121V4.74863V4.74863C15.9062 4.2993 15.7278 3.86838 15.41 3.55066L15.41 3.55065ZM9.69417 1.78791V4.61157H5.17632V1.78791H9.69417ZM14.0002 14.212H1.9996H1.9996C1.88264 14.212 1.78783 14.1172 1.78783 14.0002V1.99969C1.78783 1.88273 1.88264 1.78791 1.9996 1.78791H3.48202V5.45867C3.48202 5.92652 3.86128 6.30577 4.32912 6.30577H10.5412C11.009 6.30577 11.3883 5.92652 11.3883 5.45867V1.92493L14.1499 4.68657C14.1896 4.7263 14.2119 4.78017 14.2119 4.83633V14.0002C14.2119 14.1172 14.1171 14.212 14.0002 14.212ZM8.00002 7.15288C6.28737 7.15288 4.894 8.54625 4.894 10.2589C4.894 11.9716 6.28737 13.3649 8.00002 13.3649C9.71268 13.3649 11.106 11.9716 11.106 10.2589C11.106 8.54625 9.71268 7.15288 8.00002 7.15288ZM7.9999 11.6708C7.22142 11.6708 6.58807 11.0374 6.58807 10.259C6.58807 9.48047 7.22142 8.84712 7.9999 8.84712C8.77839 8.84712 9.41173 9.48047 9.41173 10.259C9.41173 11.0374 8.77839 11.6708 7.9999 11.6708Z"
        fill={theme.palette.text.primary}
      />
    </SvgIcon>
  )
}

export default SaveIcon
