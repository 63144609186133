import LogoutIcon from 'assets/icons/navigation/LogoutIcon'
import MenuItem from 'shared-module/navigation/menu/MenuItem'
import useUserActions from 'user-module/user/core/useUserActions'

const LogoutMenuItem = ({ onClick }) => {
  const userActions = useUserActions()

  const logout = () => {
    onClick()
    userActions.logout()
    localStorage.setItem('popup2', false)
    localStorage.setItem('popup3', false)
    localStorage.setItem('optInB', false)
    localStorage.setItem('firstTime', false)
    localStorage.removeItem('initialTime')
    localStorage.setItem('popup4', false)
    localStorage.setItem('optInLastB', false)
    localStorage.setItem('secondTime', false)
    localStorage.removeItem('initialTimeLast')
    localStorage.setItem('popup5', false)
    localStorage.setItem('optInSubscribe', false)
  }

  return (
    <MenuItem Icon={LogoutIcon} text="menu.logout" onClick={logout} />
  )
}

export default LogoutMenuItem
