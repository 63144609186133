import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import InsidersClubImage from 'spypoint/public/images/insiders-club.svg'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import PrimaryButton from 'shared-module/new-components/buttons/PrimaryButton'
import webViewNavigate from 'checkout-module/checkout/ui/shared/web-view-bridge/webViewNavigate'

const useStyles = makeStyles(theme => ({
  banner: {
    marginTop: 5,
    outline: `1px solid ${theme.palette.primary.main}`,
  },
  image: {
    height: '80%',
    width: '80%',
    padding: 5,
  },
  twentyPercentOff: {
    color: theme.palette.primary.main,
  },
  line: {
    display: 'inline-block',
  },
}))

const InsidersClubBanner = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles()

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      className={classes.banner}
    >
      <Grid container justifyContent="center" item xs={3}>
        <img
          className={classes.image}
          src={InsidersClubImage}
          alt="insiders-club"
          data-testid="insiders-club-image"
        />
      </Grid>
      <Grid item container justifyContent="center" xs={5}>
        <Typography variant="body2">
          <Trans i18nKey="app:checkout_process.club_membership.description">
            <span className={classes.line}>
              Join now to benefit from
              <strong className={classes.twentyPercentOff}>
                20% off
              </strong>
            </span>
            <strong className={classes.twentyPercentOff}>
              Photo Transmission Plans
            </strong>
            and
            <strong className={classes.twentyPercentOff}>
              FULL-HD Photo packages
            </strong>
          </Trans>
        </Typography>
      </Grid>
      <Grid container justifyContent="center" item xs={4}>
        <Box m={3}>
          <PrimaryButton
            onClick={() => { webViewNavigate(history, 'membership') }}
            variant="outlined"
            fullWidth
            data-testid="join-the-club-button"
          >
            { t('app:checkout_process.club_membership.button') }
          </PrimaryButton>
        </Box>
      </Grid>
    </Grid>
  )
}
export default InsidersClubBanner
