import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import HelpRoundedIcon from '@material-ui/icons/HelpRounded'
import { Typography } from '@material-ui/core'
import CameraIcon from 'assets/icons/navigation/CameraIcon'
import CAppBar from 'storybook-component-module/src/components/containers/CAppBar'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import SpypointAddCameraCard from './SpypointAddCameraCard'
import Paper from '@material-ui/core/Paper'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useReloadCameras } from 'camera-module/cameras/core/useCameras'
import Box from '@material-ui/core/Box'
import CButton from 'storybook-component-module/src/components/buttons/CButton'
import CMobileSwiper from 'storybook-component-module/src/components/carousels/CMobileSwiper'
import ConfirmationDialog from 'shared-module/components/confirmation/ConfirmationDialog'
import useLanguage from 'shared-module/components/language/useLanguage'
import useSharedCameras from 'camera-module/camera/core/useSharedCameras'
import consentActions from 'consent-module/core/consentActions'
import useConsent from 'consent-module/core/useConsent'
import TerritoryConsentConfirmation from '../../TerritoryConsentConfirmation'

import { cards } from './Cards'
import useUser from 'user-module/user/core/useUser'
import ConsentFormDialog from '../../ConsentFormDialog'
import TerritoryConsentDialog from '../../TerritoryConsentDialog'
import MarketingNewsLetterjournayBdialog from '../../MarketingNewsLetterjournayBdialog'
import MarketingNewsletterJourneyAConfirmation from '../../MarketingNewsletterJourneyAConfirmation'
import { useLDFlags } from 'spypoint/src/launchDarkly-module/launchDarklyFlagsContext'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '178px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '150px',
    },
  },
  appbar: {
    background: theme.palette.background.default,
  },
  paper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paperContrast,
    paddingTop: '46px',
    paddingBottom: '46px',
    paddingLeft: '62px',
    columnGap: '40px',
    paddingRight: '62px',
    borderRadius: '0px',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '42px 20px',
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      paddingLeft: '4px',
      columnGap: '12px',
      paddingRight: '4px',
    },
  },
  cardMobile: {
    maxWidth: '100% !important',
    margin: '0 auto',
  },
  title: {
    textAlign: 'center',
    fontSize: '26px',
    marginBottom: '70px',
    fontFamily: 'Oswald-normal',
    fontWeight: 500,
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      margin: '0px 24px',
    },
  },
  buttonContainer: {
    marginTop: '30px',
    marginBottom: '40px',
    cardMobile: {
      maxWidth: '100% !important',
    },
  },
  buttonMobileContainer: {
    paddingTop: '20px',
    cardMobile: {
      maxWidth: '100% !important',
    },
  },
  buttonBoxMobileContainer: {
    marginTop: '20px',
    marginBottom: '30px',
  },
  buttonFullWidth: {
    width: '100% !important',
  },
  dotStyle: {
    width: ' 10px',
    height: '10px',
    border: 'solid 3px #2c2c2c',
    backgroundColor: 'transparent',
    borderRadius: '100%',
    margin: '0px 5px',
  },
  overflowHidden: {
    overflow: 'hidden',
    paddingBottom: '10px',
    '& .swiper-slide-prev  div > div > div > p, ': {
      paddingRight: '20px',
    },
    '& .swiper-slide-next  div > div > div > p, ': {
      paddingLeft: '20px',
    },
    '& .swiper-slide-prev > div > div': {
      height: '490px',
    },
    '& .swiper-slide-next > div > div': {
      height: '490px',
    },
  },
  swiperContainerStyle: {
    height: '100% !important',
    margin: '1rem 0rem !important',
    paddingBottom: '2rem !important',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
  },
  helpIcon: {
    color: theme.palette.basic.white,
    width: '1.875rem',
    height: '1.875rem',
  },
  link: {
    color: theme.palette.primary.main,
    paddingLeft: 5,
  },
  list: {
    color: theme.palette.primary.main,
    paddingLeft: 25,
  },
}))

const SpypointAddCameraPage = ({ setOpen, setIsOpenNewsLetterConfirmationDialog, isOpenNewsLetterConfirmationDialog, handleOpen, setIsOpenNewsLetterDialog, isOpenNewsLetterDialog, isOpenNewsLetterBDialog, setIsOpenNewsLetterBDialog, setOpenB, handleOpenDialogB, optInB }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const isSmallDevice = useMediaQuery((theme) => theme.breakpoints.down('xs'))
  const cameras = useReloadCameras()
  const firstActivation = cameras?.length === 0
  const [lastSlide, setLastSlide] = useState(false)
  const [swiperInstance, setSwiperInstance] = useState()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [actionsExecuted, setActionsExecuted] = useState(false)
  const [journeyB, setJourneyB] = useState(true)
  const showPopup2 = JSON.parse(localStorage.getItem('popup2'))

  const sharedCameras = useSharedCameras()
  const territory = useConsent()
  const dispatch = useDispatch()
  const user = useUser()
  const { consent } = user
  const [isOpenConsentDialog, setIsOpenConsentDialog] = useState(false)
  const [isOpenTerritoryDialog, setIsOpenTerritoryDialog] = useState(true)
  const [isOpenTerritoryConfirmationDialog, setIsOpenTerritoryConfirmationDialog] = useState(false)
  const language = useLanguage()
  const isFrench = language === 'fr'
  const isRegister = JSON.parse(localStorage.getItem('isRegister'))
  const { flags } = useLDFlags()
  const marketingConsentEnabled = flags && flags['marketing-consent']

  const goToNextSlide = () => swiperInstance.slideNext()
  const goToEndSlide = () => swiperInstance.slideTo(cards.length - 1)
  const reachEnd = () => setLastSlide(true)

  const goToCameraActivation = () => { history.push('/camera-activation') }
  const addCamera = history.location.state?.data

  useEffect(() => {
    dispatch(consentActions.getTerritoryRequired(user?.id))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (!consent) setIsOpenConsentDialog(true)
  }, [consent])

  const renderTileContent = (props) => {
    const card = isSmallDevice ? props.slide : props
    return (
      <Grid item md={4} xs={12} key={card.label}>
        <SpypointAddCameraCard
          image={card.image}
          label={t(card.label)}
          title={t(card.title)}
          content={t(card.subtitle)}
          cardMobile={classes.cardMobile}
          subContent={t(card.complementText)}
          infoIcon={card.infoIcon}
        />
      </Grid>
    )
  }
  const handleHelpIconClick = () => {
    setDialogOpen(true)
  }

  const handleDialogClose = () => {
    setDialogOpen(false)
  }
  return (
    <div className={classes.root}>
      <CAppBar Icon={CameraIcon} title={t('menu.add_camera')}>
        <HelpRoundedIcon Icon={HelpRoundedIcon} className={classes.helpIcon} onClick={handleHelpIconClick} />
      </CAppBar>
      <ConfirmationDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        title={t('app:active_device.tooltip_header')}
        text={(
          <div>
            { t('app:active_device.tooltip_body') }
            <ul className={classes.list}>
              <li>
                <a
                  href={
                    isFrench
                      ? 'https://www.spypoint.com/fr/support'
                      : 'https://www.spypoint.com/en/support'
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.link}
                >
                  { t('app:active_device.support_center') }
                </a>
              </li>
              <li>
                <a
                  href={
                    isFrench
                      ? 'https://www.spypoint.com/fr/support#chat'
                      : 'https://www.spypoint.com/en/support#chat'
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.link}
                >
                  { t('app:active_device.chat_url') }
                </a>
              </li>
            </ul>
          </div>
        )}
        confirmText={t('app:active_device.first_step.tooltip.confirm_button')}
        onConfirm={handleDialogClose}
      />
      <Container maxWidth="lg" p={8}>
        <Paper className={[classes.paper, isSmallDevice ? classes.overflowHidden : '']}>
          <Grid container justifyContent="center" data-testid="add-camera-title">
            <Grid item md={12}>
              <Typography className={classes.title}>{ t('app:active_device.follow_steps') }</Typography>
            </Grid>
          </Grid>
          { isSmallDevice
            ? (
              <CMobileSwiper
                breakpoints={{
                  320: { slidesPerView: 1.3, spaceBetween: 8 },
                  375: { slidesPerView: 1.25, spaceBetween: 10 },
                  425: { slidesPerView: 1.3, spaceBetween: 10 },
                  480: { slidesPerView: 1.5, spaceBetween: 10 },
                }}
                centeredSlides
                slideToClickedSlide
                slides={cards}
                className={classes.swiperContainerStyle}
                onReachEnd={reachEnd}
                setSwiperInstance={setSwiperInstance}
              >
                { renderTileContent }
              </CMobileSwiper>
              )
            : (
              <Grid container spacing={3} className={classes.container}>
                { cards.map(card => (
                  <Grid item sm={4} md={4} key={card.label}>
                    <SpypointAddCameraCard
                      image={card.image}
                      label={t(card.label)}
                      title={t(card.title)}
                      content={t(card.subtitle)}
                      subContent={t(card.complementText)}
                      infoIcon={card.infoIcon}
                    />
                  </Grid>
                )) }
              </Grid>
              ) }
        </Paper>
        { !isSmallDevice
          ? (
            <Box display="flex" justifyContent="flex-end">
              <Grid item sm="auto" xs={12} className={classes.buttonContainer} data-testid="add-camera-button">
                <CButton variety="primary" color="primary" onClick={goToCameraActivation}>
                  { t('app:active_device.start') }
                </CButton>
              </Grid>
            </Box>
            )
          : lastSlide
            ? (
              <Box className={classes.buttonBoxMobileContainer}>
                <Grid item sm="auto" xs={12} className={classes.buttonMobileContainer} data-testid="add-camera-button">
                  <CButton
                    variety="primary"
                    color="primary"
                    onClick={goToCameraActivation}
                    className={classes.buttonFullWidth}
                  >
                    { t('app:active_device.mobile_start') }
                  </CButton>
                </Grid>
              </Box>
              )
            : (
              <Box className={classes.buttonBoxMobileContainer}>
                <Grid item sm="auto" xs={12} className={classes.buttonMobileContainer} data-testid="add-camera-button">
                  <CButton variety="primary" onClick={goToNextSlide} color="primary" className={classes.buttonFullWidth}>
                    { t('app:active_device.next') }
                  </CButton>
                </Grid>

                { !firstActivation && (
                  <Grid item xs={12} className={classes.buttonMobileContainer} data-testid="add-camera-button">
                    <CButton
                      variety="secondary"
                      color="primary"
                      onClick={goToEndSlide}
                      className={classes.buttonFullWidth}
                    >
                      { t('app:active_device.skip') }
                    </CButton>
                  </Grid>
                ) }
              </Box>
              ) }
      </Container>
      { isOpenConsentDialog && <ConsentFormDialog onClose={() => setIsOpenConsentDialog(false)} /> }
      { marketingConsentEnabled && territory?.isTerritoryRequired && isOpenTerritoryDialog && !isRegister && !isOpenConsentDialog && <TerritoryConsentDialog setIsOpenTerritoryConfirmationDialog={setIsOpenTerritoryConfirmationDialog} onClose={() => setIsOpenTerritoryDialog(false)} /> }
      { isOpenTerritoryConfirmationDialog && <TerritoryConsentConfirmation setIsOpenTerritoryConfirmationDialog={setIsOpenTerritoryConfirmationDialog} isOpenTerritoryConfirmationDialog={isOpenTerritoryConfirmationDialog} onClose={() => setIsOpenTerritoryConfirmationDialog(false)} /> }
      { marketingConsentEnabled && !showPopup2 && journeyB && territory?.requiresConsent && isOpenNewsLetterBDialog && !territory?.isTerritoryRequired && !isRegister && !isOpenConsentDialog && <MarketingNewsLetterjournayBdialog optInB={optInB} handleOpen={handleOpen} handleOpenDialogB={handleOpenDialogB} setIsOpenNewsLetterConfirmationDialog={setIsOpenNewsLetterConfirmationDialog} setOpen={setOpen} onClose={() => setIsOpenNewsLetterBDialog(false)} /> }
      { isOpenNewsLetterConfirmationDialog && <MarketingNewsletterJourneyAConfirmation setIsOpenNewsLetterConfirmationDialog={setIsOpenNewsLetterConfirmationDialog} onClose={() => setIsOpenNewsLetterConfirmationDialog(false)} /> }

    </div>
  )
}

export default SpypointAddCameraPage
