import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

const AppBarTitle = ({ Icon, title }) => (
  <Box>
    <Grid container spacing={2} alignItems="center" wrap="nowrap">
      <Grid item style={{ paddingBottom: '2px' }}>
        <Box color="secondary.dark" ml={0.5}>
          <Icon />
        </Box>
      </Grid>
      <Grid item>
        <Typography variant="h2" color="textPrimary" noWrap>{ title }</Typography>
      </Grid>
    </Grid>
  </Box>
)

export default AppBarTitle
