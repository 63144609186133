import { useUserClubMembership } from 'spypoint/src/user-module/club-membership/core/useUserClubMembership'
import { formatDate } from 'shared-module/common-utils'
import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import CTypography from 'storybook-component-module/src/components/typographies/CTypography'
import Amount from 'shared-module/components/amount/Amount'
import { useReloadClubMembershipPlan } from 'spypoint/src/user-module/club-membership-plan/core/useClubMembershipPlan'
import useUser from 'user-module/user/core/useUser'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  text: {
    [theme.breakpoints.only('sm')]: {
      fontSize: '0.75rem',
    },
  },
}))

const BillingCycleEndDate = () => {
  const { i18n, t } = useTranslation()
  const membership = useUserClubMembership()
  const classes = useStyles()
  const membershipPlan = useReloadClubMembershipPlan()
  const user = useUser()

  const endDate = formatDate(membership.billingCycleEndDate.toString(), i18n)

  return (
    <Grid container item sm={12} md={10} spacing={1} className={classes.container}>
      { membership.isAutoRenew
        ? (
          <Grid item>
            <CTypography variant="body2" className={classes.text}>
              { t('app:club_membership.billing_cycle_end_date.renewal1') }
              <strong>{ endDate }</strong>
              { t('app:club_membership.billing_cycle_end_date.renewal2') }
              <Amount value={membershipPlan?.price} currencyCode={user.currencyCode} align="center" />
            </CTypography>
          </Grid>
          )
        : (
          <>
            <Grid item>
              <CTypography variant="body2" className={classes.text}>
                { t('app:club_membership.billing_cycle_end_date.text') }
                <strong>{ endDate }</strong>
              </CTypography>
            </Grid>
            <Grid item>
              <CTypography variant="body2" className={classes.text}>{ t('app:club_membership.billing_cycle_end_date.note') }</CTypography>
            </Grid>
          </>
          ) }
    </Grid>
  )
}

export default BillingCycleEndDate
