import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { Box, Container } from '@material-ui/core'
import CTabs from 'storybook-component-module/src/components/tabs/CTabs'

import CameraListPanel from 'cameras-module/ui/cameras-page/CameraListPanel'
import SharedCameraListPanel from 'cameras-module/ui/cameras-page/SharedCameraListPanel'

import { getSharedCameraTabs, getCurrentTabPath } from './SharedCameraHelpers'
import Divider from '@material-ui/core/Divider'
import { useEffect } from 'react'
import cameraSharingActions from 'camera-module/camera/core/CameraSharingActions'
import useQueryParams from 'shared-module/url/useQueryParams'
import { useDispatch } from 'react-redux'

const SharedCameraPage = () => {
  const { t } = useTranslation()
  const { pathname } = useLocation()

  const tabs = getSharedCameraTabs()
  const tabIndex = getCurrentTabPath(tabs, pathname)
  const { v, accept } = useQueryParams()
  const dispatch = useDispatch()

  useEffect(() => {
    if (accept === true) {
      dispatch(cameraSharingActions.acceptInvitation(v))
    } else if (accept === false) {
      dispatch(cameraSharingActions.rejectInvitation(v))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) // Empty array ensures this runs only once after first render

  const routeSelectedTable = () => {
    if (pathname === tabs[0].path) { return <CameraListPanel /> }
    if (pathname === tabs[1].path && !tabs[1].disabled) { return <SharedCameraListPanel /> }
  }

  return (
    <Box sx={{ marginTop: { xs: '103px', sm: '128px' } }} data-testid="sharedCamera">

      <Container maxWidth={false} style={{ paddingTop: 32 }}>
        <CTabs tabs={tabs.filter(({ disabled }) => !disabled)} tabIndex={tabIndex} />
        <Divider variant="fullWidth" style={{ marginBottom: 50 }} />

        { routeSelectedTable() }
      </Container>
    </Box>
  )
}

export default SharedCameraPage
