import { useState } from 'react'
import Navbar from 'shared-module/navigation/Navbar'
import Menu from 'spypoint/src/shared-module/navigation/menu/Menu'
import SpypointLogo from 'spypoint/src/assets/icons/SpypointLogo'
import NotificationsFilledIcon from 'assets/icons/NotificationsFilledIcon'
import NotificationsFilledIconActive from 'assets/icons/NotificationsFilledIconActive'
import SpypointCartButton from 'spypoint/src/checkout-module/order/ui/cart-button/SpypointCartButton'
import { useUserClubMembership } from 'spypoint/src/user-module/club-membership/core/useUserClubMembership'

const SpypointAppBar = () => {
  const [isMenuVisible, setIsMenuVisible] = useState(false)
  const handleToggleMenu = () => setIsMenuVisible((visible) => !visible)
  const userClubMembership = useUserClubMembership()
  return (
    <div>
      <Navbar
        onClick={handleToggleMenu}
        Logo={SpypointLogo}
        CartButton={SpypointCartButton}
        showSpyPointJoinInsidersClubBtn={!userClubMembership?.isMember}
        notificationIcon={NotificationsFilledIcon}
        notificationIconActive={NotificationsFilledIconActive}
      />
      <Menu open={isMenuVisible} onClose={handleToggleMenu} />
    </div>
  )
}

export default SpypointAppBar
