import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Title from 'shared-module/new-components/layout/Title'
import RedeemPromoCodesSection from 'user-module/promo-code/ui/redeem-promo-codes/components/RedeemPromoCodesSection'
import ReferralLink from 'user-module/promo-code/ui/redeem-promo-codes/components/ReferralLink'

const RedeemPromoCodesInfo = () => {
  const { t } = useTranslation()

  return (
    <RedeemPromoCodesSection>
      <Title h="h2">{ t('redeem_promo_code.info.title') }</Title>
      <Typography variant="body2" color="textSecondary">{ t('redeem_promo_code.info.line1') }</Typography>
      <Typography variant="body2" color="textSecondary">{ t('redeem_promo_code.info.line2') }</Typography>
      <Box pt={3}>
        <ReferralLink />
      </Box>
    </RedeemPromoCodesSection>
  )
}

export default RedeemPromoCodesInfo
