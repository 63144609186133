import SvgIcon from '@material-ui/core/SvgIcon'

const PhotoGalleryIcon = (props) => (
  <SvgIcon width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" data-testid="PhotoGalleryIcon" {...props}>
    <g clipPath="url(#clip0_18771_10472)">
      <path
        d="M26.11 7.82248L26.4075 5.30249L24.5438 5.08374L24.36 3.66623L17.745 4.33124L3.0625 2.64249L2.73874 5.41623H2.52875V5.87123H2.70374L1.73249 5.96748L2.19624 10.22L0.883743 21.595L1.96875 21.7175L1.86374 24.6662L25.4887 25.4887L25.6112 21.9887L26.39 21.91L25.795 16.555L26.11 7.82248ZM4.1125 5.66999L5.74874 5.49498L4.1125 5.41623L4.48874 5.45999L4.58499 4.58499L9.60749 5.16248L4.0775 5.71373L4.1125 5.66999ZM23.8087 23.6337L12.985 23.2575L23.8787 22.1637L23.8087 23.6337ZM5.43374 22.1637L3.33375 7.55998L22.8025 5.11874L24.8325 20.125L5.43374 22.1637Z"
        fill="#F5F4F4"
      />
      <path
        d="M19.075 12.0838L16.66 14.8663L9.62499 11.9788L6.57124 15.3563L7.20124 20.125L22.61 18.4713L21.8925 13.7463L19.075 12.0838Z"
        fill="#F5F4F4"
      />
      <path
        d="M15.1987 12.5125C15.6093 12.5125 16.0106 12.3906 16.3518 12.1624C16.693 11.9341 16.9588 11.6097 17.1155 11.2303C17.2722 10.8509 17.3127 10.4334 17.232 10.0309C17.1513 9.62844 16.9529 9.25894 16.662 8.96928C16.3711 8.67961 16.0008 8.4828 15.5979 8.40378C15.1951 8.32475 14.7779 8.36707 14.3991 8.52537C14.0203 8.68367 13.697 8.95083 13.4702 9.293C13.2434 9.63518 13.1233 10.037 13.125 10.4475C13.125 10.7194 13.1787 10.9886 13.283 11.2398C13.3873 11.4909 13.5402 11.7189 13.7329 11.9108C13.9256 12.1026 14.1543 12.2546 14.4058 12.3578C14.6574 12.4611 14.9268 12.5136 15.1987 12.5125Z"
        fill="#F5F4F4"
      />
    </g>
    <defs>
      <clipPath id="clip0_18771_10472">
        <rect width="25.5063" height="22.82" fill="white" transform="translate(0.875 2.625)" />
      </clipPath>
    </defs>
  </SvgIcon>
)

export default PhotoGalleryIcon
