import SecondaryButton from 'shared-module/new-components/buttons/SecondaryButton'
import { Trans } from 'react-i18next'

const PaymentMethodAdd = ({ openForm }) => {
  return (
    <SecondaryButton onClick={openForm}>
      <Trans i18nKey="menu.add" />
    </SecondaryButton>
  )
}

export default PaymentMethodAdd
