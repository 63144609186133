import { useTranslation } from 'react-i18next'
import ConfirmationDialog from 'shared-module/components/confirmation/ConfirmationDialog'
import { useHistory } from 'react-router-dom'

const SpypointDialogBuyHDVideoRedirection = ({ open, onClose }) => {
  const { t } = useTranslation()
  const history = useHistory()

  const confirmBuy = () => {
    onClose()
    history.push('/cart?video_hd=true')
  }

  return (
    <ConfirmationDialog
      open={open}
      onClose={onClose}
      title={t('app:video_viewer.video_count_error.header')}
      text={t('app:video_viewer.video_count_error.body')}
      confirmText={t('app:video_viewer.video_count_error.button')}
      onConfirm={confirmBuy}
      onCancel={onClose}
    />
  )
}

export default SpypointDialogBuyHDVideoRedirection
