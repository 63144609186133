import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Form from 'form-module/ui/Form'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import useUser from 'user-module/user/core/useUser'
import { useCart } from 'spypoint/src/checkout-module/order/core/cart.hooks'
import BillingAddressDisplay from 'checkout-module/billing/BillingAddressDisplay'
import BillingAddressForm from 'checkout-module/billing/BillingAddressForm'
import dialogActions from 'shared-module/dialog/dialogActions'
import useUserActions from 'user-module/user/core/useUserActions'
import billingAddressHelpers from 'checkout-module/billing/BillingAddressHelpers'
import { validateCell } from 'spypoint/src/user-module/account/profile/info/ProfileCell'

const useStyles = makeStyles(theme => ({
  paperWrapper: {
    padding: 30,
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.primary.contrastText,
    marginBottom: 15,
  },
  title: {
    fontFamily: 'oswald-normal',
    fontSize: '18px',
    marginBottom: 24,
  },
}))

const BillingAddress = () => {
  const cart = useCart()
  const user = useUser()
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const userActions = useUserActions()

  const [isModifying, setIsModifying] = useState(false)
  const handleModificationChange = () => setIsModifying(!isModifying)
  const [validationOnChange, setValidationOnChange] = useState(user.isAddressComplete)

  const onSubmit = data => {
    const cell = data.cell
    const userId = user.id
    const updatedUser = { ...data, id: userId, cell }

    userActions.update(updatedUser).then(() => {
      setIsModifying(false)
      cart.fetchCheckout()
    }).catch(error => {
      dispatch(dialogActions.showError(error.response.data.message))
    })
  }

  return (
    <div className={classes.paperWrapper}>
      <div className={classes.title}>
        { t('menu.billing') }
      </div>
      <Grid container alignItems="flex-end" justifyContent="flex-end" spacing={2}>
        { user.isAddressComplete && !isModifying
          ? <BillingAddressDisplay modifyBillingAddress={handleModificationChange} />
          : (
            <Form
              id="billing-address-form"
              onSubmit={onSubmit}
              data={billingAddressHelpers.initialValues(user)}
              validate={validateCell}
              validateOnBlur={user.isAddressComplete}
              validateOnChange={validationOnChange}
              validationSchema={billingAddressHelpers.validationSchema()}
            >
              <BillingAddressForm
                user={user}
                onSubmit={onSubmit}
                onCancel={handleModificationChange}
                setValidationOnChange={setValidationOnChange}
              />
            </Form>
            ) }
      </Grid>
    </div>
  )
}

export default BillingAddress
