import CardContent from '@material-ui/core/CardContent'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Box from '@material-ui/core/Box'
import useLanguage from 'shared-module/components/language/useLanguage'
import ConfirmationDialog from 'shared-module/components/confirmation/ConfirmationDialog'
import SpypointInfoIcon from 'assets/icons/SpypointInfoIcon'
import useConfirmationDialogActions from 'shared-module/components/confirmation/useConfirmationDialogActions'
import { Trans, useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    borderRadius: '0px',
    flexDirection: 'column',
    alignItems: 'center',
    height: '530px',
    border: '6px solid #1f1e1e',
    background: theme.palette.primary.contrastText,
    boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.50)',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
    [theme.breakpoints.down('xs')]: {
      margin: '0',
    },
  },
  stepLabel: {
    color: theme.palette.primary.main,
    fontSize: '21px',
    fontFamily: 'oswald-normal',
    fontWeight: 500,
    [theme.breakpoints.only('sm')]: {
      fontSize: '18px',
    },
  },
  content: {
    color: theme.palette.text.primary,
    fontSize: '21px',
    fontFamily: 'oswald-normal',
    fontWeight: 500,
    [theme.breakpoints.only('sm')]: {
      fontSize: '18px',
    },
  },
  paragraph: {
    fontSize: '16px',
    fontFamily: 'ibm-plex-sans-regular',
    [theme.breakpoints.only('sm')]: {
      fontSize: '14px',
    },
  },
  subParagraph: {
    marginTop: '16px',
    fontSize: '16px',
    fontFamily: 'ibm-plex-sans-regular',
    [theme.breakpoints.only('sm')]: {
      fontSize: '14px',
    },
  },
  infoIcon: {
    position: 'relative',
    top: '1px',
  },
  contentCard: {
    width: '100%',
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  box: {
    margin: '16px 24px auto',
    [theme.breakpoints.only('xs')]: {
      margin: '16px 18px 16px 24px',
    },
  },
}))

const SpypointAddCameraCard = ({ image, label, title, content, subContent, infoIcon = false }) => {
  const classes = useStyles()
  const language = useLanguage()
  const isFrench = language === 'fr'
  const dialogActions = useConfirmationDialogActions()
  const { t } = useTranslation()
  return (
    <>
      <Card className={classes.card} style={{ height: isFrench ? '560px' : undefined }}>
        <CardContent className={classes.contentCard}>
          { image }
        </CardContent>
        <Box component="div" align="left" mt="16px" ml="24px" mr="24px" width="100%" pl="24px" pr="24px">
          <Typography className={classes.content}><span className={classes.stepLabel}>{ label }</span> { title }</Typography>
        </Box>
        <Box component="div" align="left" className={classes.box}>
          <Typography className={classes.paragraph}>
            { content }
          </Typography>
          <Typography className={classes.subParagraph}>
            { subContent }
            { infoIcon && (
              <Box component="span" onClick={dialogActions.open}>
                <SpypointInfoIcon
                  style={{
                    position: 'relative',
                    top: '1px',
                    width: '12px',
                    height: '12px',
                  }}
                />
              </Box>
            ) }
          </Typography>
        </Box>
      </Card>
      <ConfirmationDialog
        open={dialogActions.isOpen}
        onClose={dialogActions.close}
        title={t('app:active_device.first_step.tooltip.header')}
        text={<Trans i18nKey="app:active_device.first_step.tooltip.body" />}
        confirmText={t('app:active_device.first_step.tooltip.confirm_button')}
        onConfirm={dialogActions.close}
      />
    </>
  )
}
export default SpypointAddCameraCard
