export const DEFINE_STARTCODE = new Uint8Array([0, 0, 0, 1])
export const DEFINE_KEY_FRAME = 0x15
export const DEFINE_P_FRAME = 0x09
export const DEFINE_OTHERS_FRAME = 0x99
export const DEFINE_HEV_PS = [32, 33, 34, 39]

const naluHeader = { vps: null, sps: null, pps: null, sei: null }

export const setStartCode = (dataStream, replace = false) => {
  if (replace) {
    const returnStream = dataStream
    returnStream[0] = DEFINE_STARTCODE[0]
    returnStream[1] = DEFINE_STARTCODE[1]
    returnStream[2] = DEFINE_STARTCODE[2]
    returnStream[3] = DEFINE_STARTCODE[3]
    return returnStream
  } else {
    const returnStream = new Uint8Array(DEFINE_STARTCODE.length + dataStream.length)
    returnStream.set(DEFINE_STARTCODE, 0)
    returnStream.set(dataStream, DEFINE_STARTCODE.length)
    return returnStream
  }
}

export const GET_NALU_TYPE = (charByte) => {
  const type = (charByte & 0x7E) >> 1
  if (type >= 1 && type <= 9) {
    return DEFINE_P_FRAME
  } else if (type >= 16 && type <= 21) {
    return DEFINE_KEY_FRAME
  } else {
    const typeInfo = DEFINE_HEV_PS.indexOf(type)
    if (typeInfo >= 0) {
      return DEFINE_HEV_PS[typeInfo]
    } else {
      return DEFINE_OTHERS_FRAME
    }
  }
}

export const setHeaders = (data, codecContext, isHev1 = false) => {
  if (naluHeader.vps === null || naluHeader.sps === null || naluHeader.pps === null || naluHeader.sei === null) {
    const naluArr = codecContext.nalu_arrays
    naluHeader.vps = setStartCode(naluArr[0][0].data, false)
    naluHeader.sps = setStartCode(naluArr[1][0].data, false)
    naluHeader.pps = setStartCode(naluArr[2][0].data, false)
    if (isHev1) naluHeader.pps = setStartCode(naluArr[2][0].data.slice(0, -1), false)
    if (naluArr.length > 3) {
      naluHeader.sei = setStartCode(naluArr[3][0].data, false)
    } else {
      naluHeader.sei = new Uint8Array()
    }
  }
  if (data[4] === naluHeader.vps[4]) {
    data = data.subarray(naluHeader.vps.length +
      naluHeader.sps.length +
      naluHeader.pps.length +
      naluHeader.sei.length)
  }
  const frame = new Uint8Array(
    naluHeader.vps.length +
    naluHeader.sps.length +
    naluHeader.pps.length +
    naluHeader.sei.length +
    data.length,
  )
  frame.set(naluHeader.vps, 0)

  frame.set(naluHeader.sps,
    naluHeader.vps.length)

  frame.set(naluHeader.pps,
    naluHeader.vps.length +
    naluHeader.sps.length)

  frame.set(naluHeader.sei,
    naluHeader.vps.length +
    naluHeader.sps.length +
    naluHeader.pps.length)

  frame.set(setStartCode(data, true),
    naluHeader.vps.length +
    naluHeader.sps.length +
    naluHeader.pps.length +
    naluHeader.sei.length)
  return frame
}
export const processFrame = (sample) => {
  let frame
  const frameType = GET_NALU_TYPE(sample.data[4])
  const isKeyFrame = sample.is_sync || frameType === DEFINE_KEY_FRAME
  const codecContext = sample.description.hvcC
  const isHev1 = sample.description.type === 'hev1'
  if (isKeyFrame) {
    frame = setHeaders(sample.data, codecContext, isHev1)
  } else {
    frame = setStartCode(sample.data, true)
  }
  return frame
}

export const copyArray = (arr) => {
  const copiedArray = new ArrayBuffer(arr.byteLength)
  new Uint8Array(copiedArray).set(new Uint8Array(arr))
  return copiedArray
}

export const findFirstKey = (keyFrames, frames, time) => {
  const first = frames.findIndex((frame) => {
    return frame[1] >= time
  })
  let index = keyFrames.findIndex((keyFrame) => {
    return keyFrame >= first
  })
  if (index === -1) index = keyFrames.length
  return keyFrames[index - 1]
}
