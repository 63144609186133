import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useUserClubMembership } from 'spypoint/src/user-module/club-membership/core/useUserClubMembership'
import useUserClubMembershipActions from 'spypoint/src/user-module/club-membership/core/useUserClubMembershipActions'
import { enableAutoRenew, disableAutoRenew } from 'checkout-module/checkout/api/storagePlanApi'
import useConfirmationDialogActions from 'shared-module/components/confirmation/useConfirmationDialogActions'
import ConfirmationDialog from 'shared-module/components/confirmation/ConfirmationDialog'
import Box from '@material-ui/core/Box'
import CToggleSwitch from 'storybook-component-module/src/components/switch/CToggleSwitch'
import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Spinner from 'shared-module/components/Spinner'

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: '-0.25rem',
  },
  text: {
    [theme.breakpoints.only('sm')]: {
      fontSize: '0.875rem',
    },
  },
}))

const AutoRenewal = ({ titleClass, isAvailablePaymentMethod }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const userMembership = useUserClubMembership()
  const userMembershipActions = useUserClubMembershipActions()
  const [autoRenewalDisabled, setAutoRenewalDisabled] = useState(false)
  const [autoRenewalDisabledLoading, setAutoRenewalDisabledLoading] = useState(false)
  const dialogActions = useConfirmationDialogActions()

  const onSwitchAutoRenew = (autoRenewOn) => {
    if (autoRenewOn) {
      onEnableAutoRenew()
    } else {
      dialogActions.open()
    }
  }
  const onEnableAutoRenew = () => {
    setAutoRenewalDisabledLoading(true)
    enableAutoRenew(userMembership.stripeSubscriptionId)
      .then(() => {
        userMembershipActions.get()
      })
      .finally(() => {
        setAutoRenewalDisabledLoading(false)
        setAutoRenewalDisabled(false)
      })
  }

  const onDisableAutoRenew = () => {
    setAutoRenewalDisabledLoading(true)
    dialogActions.close()
    disableAutoRenew(userMembership.stripeSubscriptionId)
      .then(() => {
        userMembershipActions.get()
      })
      .finally(() => {
        setAutoRenewalDisabled(false)
        setAutoRenewalDisabledLoading(false)
      })
  }

  return (
    <Grid item className={classes.container}>
      <CToggleSwitch
        title={t('app:club_membership.auto_renew.title')}
        disabled={autoRenewalDisabled || !isAvailablePaymentMethod}
        checked={userMembership.isAutoRenew}
        color={userMembership.isAutoRenew ? 'primary' : 'default'}
        titleClassName={titleClass}
        onChange={() => {
          onSwitchAutoRenew(!userMembership.isAutoRenew)
        }}
      />
      { autoRenewalDisabledLoading && <Spinner /> }
      <ConfirmationDialog
        open={dialogActions.isOpen}
        onClose={dialogActions.close}
        title={t('app:club_membership.auto_renew.modal_title')}
        text={<Box mb={1.5}>{ t('app:club_membership.auto_renew.dialog_content') }</Box>}
        onConfirm={onDisableAutoRenew}
        confirmText={t('button.disable')}
        onCancel={dialogActions.close}
      />
    </Grid>
  )
}

export default AutoRenewal
