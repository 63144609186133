import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline-block',
  },
  button: {
    display: 'flex',
    margin: '5px',
    borderRadius: '5px',
    padding: '6px',
    fontFamily: theme.typography.h3.fontFamily,
    color: theme.palette.background.pureBlack,
    userSelect: 'none',
    cursor: 'pointer',
    background: theme.palette.background.light,
    '&:hover': {
      background: theme.palette.text.primary,
    },
    [theme.breakpoints.down('sm')]: {
      whiteSpace: 'nowrap',
    },
  },
  selected: {
    background: theme.palette.primary.main,
    color: theme.palette.background.pureBlack,
    '&:hover': {
      background: theme.palette.primary.dark,
    },
  },
  disabled: {
    opacity: '0.4',
    '&:hover': {
      background: theme.palette.background.filter,
    },
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    margin: '0px 3px',
    height: '20px',
  },
  text: {
    margin: '0px 3px',
    textTransform: 'uppercase',
  },
  svg: {
    pointerEvents: 'none',
  },
}))

const ToggleButton = ({ Icon, iconImage, text, onClick, selected, disabled }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div
        className={`${classes.button} ${selected ? classes.selected : null} ${disabled ? classes.disabled : null}`}
        onClick={onClick}
      >
        <div className={classes.icon}>
          { Icon && <Icon width="20px" className={classes.svg} /> }
          { iconImage && iconImage }
        </div>
        <div className={classes.text}>{ text }</div>
      </div>
    </div>
  )
}

export default ToggleButton
