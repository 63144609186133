import { useTranslation } from 'react-i18next'
import FilterButton from 'photo-module/photos/ui/photos-gallery/filter-bar/FilterButton'
import useFilters from 'photo-module/filters/core/useFilters'
import { FullHdActive } from 'assets/icons/SpypointIcons'
import useFiltersActions from 'photo-module/filters/core/useFiltersActions'

const HdFilterButton = () => {
  const { t } = useTranslation()
  const filters = useFilters()
  const filtersActions = useFiltersActions()

  return (
    <FilterButton
      Icon={FullHdActive}
      text={t('hd.filter_btn')}
      selected={filters.hd}
      onToggle={() => filtersActions.toggleHdFilter()}
    />
  )
}

export default HdFilterButton
