import { useTranslation } from 'react-i18next'
import { MobileAppSplashScreenStyles } from 'shared-module/download-app/MobileAppSplashScreen.style'
import Head from 'shared-module/new-components/head/Head'
import Meta from 'shared-module/new-components/head/Meta'

const MobileAppSplashScreen = () => {
  const classes = MobileAppSplashScreenStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.root}>
      <Head title={t('app:head.title')}>
        <Meta name="robots" content="noindex" />
      </Head>
      <header>
        <img src="/images/spypoint.svg" alt="spypointLogo" />
      </header>
      <main>
        <p>{ t('download_app.title') }</p>
        <p>{ t('download_app.subtitle') }</p>
        <p>{ t('download_app.content') }</p>
        <p>{ t('download_app.content_next') }</p>
      </main>
      <footer>
        <span>
          <a href="https://apps.apple.com/app/spypoint-link/id1074343060">
            <img src="/images/app-from-store/spypoint-appstore-badge.png" alt={t('download_app.alt_text_appstore')} />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.spypoint.spypointApp">
            <img src="/images/app-from-store/spypoint-google-play-badge.png" alt={t('download_app.alt_text_google_play')} />
          </a>
        </span>
        <span>
          <img src="/images/app-from-store/get-spypoint-from-store-background.png" alt="Spypoint mobile app" />
        </span>
      </footer>
    </div>
  )
}

export default MobileAppSplashScreen
