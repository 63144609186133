import SvgIcon from '@material-ui/core/SvgIcon'

const ClubMembershipIcon = ({ fill, ...props }) => (
  <SvgIcon width="23" height="22" viewBox="0 0 23 22" fill="none" data-testid="ClubMembershipIcon" {...props}>
    <g clipPath="url(#clip0_7720_78)">
      <path fillRule="evenodd" clipRule="evenodd" d="M11.1562 1.37728C16.5477 1.29436 19.75 3.45376 19.75 3.45376L19.6373 10.0258C19.6373 10.0258 19.7078 14.7241 17.356 16.6957C15.0043 18.6673 11.1598 20.6249 11.1598 20.6249H11.1527C11.1527 20.6249 7.30825 18.6673 4.9565 16.6957C2.60475 14.7241 2.67516 10.0258 2.67516 10.0258L2.5625 3.45376C2.5625 3.45376 5.76482 1.29436 11.1562 1.37728ZM11.0037 18.7324H11.1848C18.018 15.2941 18.0296 13.6745 18.0738 7.47351C18.0793 6.69446 18.0854 5.8431 18.1056 4.90674C14.5664 2.52599 7.62313 2.52543 4.08339 4.90674C4.10737 5.90221 4.1137 6.80141 4.11946 7.61979C4.16176 13.6317 4.17337 15.2815 10.9256 18.6927L11.0037 18.7324ZM10.2576 16.2959C9.03901 15.6373 6.86244 14.461 6.30195 13.1157C5.65672 11.6596 5.66855 9.67465 5.67897 7.92541C5.68318 7.21984 5.68715 6.55262 5.64769 5.97392C6.95185 5.19842 9.12052 4.71978 10.7541 4.75096L10.7556 4.76005C10.89 4.75292 11.0247 4.75292 11.1591 4.76005L11.1606 4.75096C12.7981 4.72076 14.9585 5.19772 16.267 5.97392C16.2285 6.54425 16.2323 7.20623 16.2363 7.90813C16.2463 9.664 16.2577 11.6697 15.6126 13.1157C15.0513 14.46 12.8842 15.6317 11.6641 16.2914C11.3526 16.4598 11.1028 16.5949 10.9573 16.6909C10.8134 16.5962 10.5662 16.4626 10.2576 16.2959Z" fill={fill} />
    </g>
    <defs>
      <clipPath id="clip0_7720_78">
        <rect width="17.1875" height="19.25" fill="white" transform="translate(2.5625 1.375)" />
      </clipPath>
    </defs>
  </SvgIcon>

)

export default ClubMembershipIcon
