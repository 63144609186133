import webViewDevice from 'checkout-module/checkout/ui/shared/web-view-bridge/webViewDevice'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import cartActions, { CLEAR_ERROR } from 'spypoint/src/checkout-module/order/core/cart.actions'
import {
  getCartDiscountSelector,
  getCartItemCountSelector,
  getCartItemsSelector,
  getCartQuantitySelector,
  getCartTotalBeforeTax,
  getCartTotalSelector,
  getCartTotalTaxesSelector, getCreditCardNotRequired,
  getErrorSelector,
  getMessageSelector,
} from 'spypoint/src/checkout-module/order/core/cart.selectors'
import {
  INSIDERS_CLUB_MEMBERSHIP,
  PHOTO_TRANSMISSION_PLAN,
  PHOTOHD, VIDEOHD,
} from './productTypes'

const CART_PATH = '/cart'

export const useCart = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { isNativeWebview } = webViewDevice()

  const fetchCart = () => dispatch(cartActions.fetchCart())
  const fetchCheckout = () => dispatch(cartActions.fetchCheckout())
  const deleteFromCart = ({ itemId }) => dispatch(
    cartActions.deleteFromCart({ itemId }))
  const addToCart = ({ productId, cameraId, paymentFrequency, plan }) => dispatch(
    cartActions.addToCart({ productId, cameraId, paymentFrequency, plan }))
  const updateQuantity = ({ itemId, quantity }) => dispatch(
    cartActions.updateQuantity({ itemId, quantity }))
  const updatePaymentFrequency = ({ itemId, paymentFrequency }) => dispatch(
    cartActions.updatePaymentFrequency({ itemId, paymentFrequency }))
  const applyPromoCode = (promoCode) => { dispatch(cartActions.applyPromoCode(promoCode)) }
  const clearError = () => { dispatch({ type: CLEAR_ERROR }) }

  const cartItems = useSelector(getCartItemsSelector)
  const cartItemsCount = useSelector(getCartItemCountSelector)
  const cartQuantity = useSelector(getCartQuantitySelector)
  const cartTotal = useSelector(getCartTotalSelector)
  const cartDiscount = useSelector(getCartDiscountSelector)
  const cartTotalBeforeTaxes = useSelector(getCartTotalBeforeTax)
  const cartTotalTaxes = useSelector(getCartTotalTaxesSelector)
  const cartError = useSelector(getErrorSelector)
  const cartMessage = useSelector(getMessageSelector)
  const creditCartNotRequired = useSelector(getCreditCardNotRequired)

  const isInsidersClubInCart = cartItems.products.some(
    product => product.productId === INSIDERS_CLUB_MEMBERSHIP)
  const hdPhotosItemInCart = cartItems.products.find(
    product => product.productId === PHOTOHD)
  const hdVideosItemInCart = cartItems.products.find(
    product => product.productId === VIDEOHD)
  const isPromoCodeApplied = cartItems.products.length > 0 &&
    cartItems.products.some(product => product.promocode)
  const allItemsArePTP = cartItems.products.length > 0 && cartItems.products.every(
    product => product.productId === PHOTO_TRANSMISSION_PLAN)

  const navigateToCart = () => {
    if (history.location.pathname !== CART_PATH) history.push(CART_PATH)
  }

  const isUnavailableItemInCart = isNativeWebview && !allItemsArePTP

  return {
    cartItems,
    cartItemsCount,
    cartTotal,
    cartDiscount,
    navigateToCart,
    deleteFromCart,
    addToCart,
    fetchCart,
    updateQuantity,
    updatePaymentFrequency,
    isInsidersClubInCart,
    fetchCheckout,
    cartTotalBeforeTaxes,
    cartTotalTaxes,
    cartQuantity,
    hdPhotosItemInCart,
    allItemsArePTP,
    isUnavailableItemInCart,
    applyPromoCode,
    clearError,
    isPromoCodeApplied,
    cartError,
    cartMessage,
    creditCartNotRequired,
    hdVideosItemInCart,
  }
}
