import makeStyles from '@material-ui/core/styles/makeStyles'
import Grid from '@material-ui/core/Grid'
import ClearFiltersIcon from 'assets/icons/photo/ClearFiltersIcon'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'
import useFilterCollection from 'photo-module/filters-bar/core/filters.hooks'

const useStyles = makeStyles(theme => ({
  clearIcon: {
    verticalAlign: 'middle',
    display: 'inline-flex',
    marginBottom: -12,
    marginRight: -10,
  },
  selectedItem: {
    border: `solid 1px ${theme.palette.background.paper}`,
    borderRadius: '4px',
    marginRight: 10,
    padding: '2px 10px 2px 10px',
  },
}))

const SelectedFiltersList = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    selectedFilters,
    deleteSelectedFilter,
  } = useFilterCollection()
  const renderFilters = selectedFilters && selectedFilters.map((value) => {
    return (
      <Grid item className={classes.selectedItem} key={value} onClick={() => deleteSelectedFilter(value)}>
        <ClearFiltersIcon className={classes.clearIcon} />
        <Typography
          component="span"
          variant="body2"
          color="secondary"
        >
          { t(`app:filters.tags.${value}`) }
        </Typography>
      </Grid>
    )
  })
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      style={{ margin: '10px 20px 10px' }}
    >
      { renderFilters }
    </Grid>
  )
}

export default SelectedFiltersList
