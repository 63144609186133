import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import Chip from '@material-ui/core/Chip'
import { makeStyles } from '@material-ui/core/styles'

import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import InformationBarElement from 'photo-module/photos/ui/photos-gallery/information-bar/InformationBarElement'
import Plan from 'assets/icons/navigation/Plan'

const useStyles = makeStyles(theme => ({
  item: {
    [theme.breakpoints.down('md')]: {
      borderBottom: `4px solid ${theme.palette.background.default}`,
      alignItems: 'left',
    },
    alignItems: 'center',
    display: 'flex',
    '&:hover': {
      background: theme.palette.background.tile,
      cursor: 'pointer',
      textDecoration: 'none!important',
    },
  },
  chip: {
    cursor: 'pointer',
    fontWeight: 'bold',
    fontSize: '0.875rem',
    margin: '8px',
  },
}))
export const RenderNameElement = ({ isCameraUpgradable, isPlanFreeTrial, camera, t, className }) => (
  <InformationBarElement
    Icon={Plan}
    wrapperStyle={isCameraUpgradable ? { margin: '4px' } : undefined}
    text={isPlanFreeTrial
      ? t('app:photo_gallery_page.plans.trial')
      : t(`app:photo_gallery_page.plans.${camera.subscription.plan.name.toLowerCase()}`)}
    className={className}
  />
)
const PlanNameElement = ({ className }) => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()
  const camera = useSelectedCamera()

  const isPlanFreeTrial = camera && camera.subscription.plan.name === 'Premium' && camera.subscription.isFree
  const isCameraUpgradeable = camera.subscription.plan.id !== 'Premium' || isPlanFreeTrial
  const onClick = () => history.push(`/camera/${camera.id}/plan`)

  return camera && (
    <Grid item className={classes.item} onClick={onClick}>
      <Grid container alignItems="center" wrap="nowrap">

        <Grid item>
          <RenderNameElement
            isCameraUpgradable={isCameraUpgradeable}
            camera={camera}
            isPlanFreeTrial={isPlanFreeTrial}
            t={t}
            className={className}
          />
        </Grid>

        { isCameraUpgradeable && (
          <Grid item>
            <Chip
              size="small"
              color="primary"
              onClick={onClick}
              className={classes.chip}
              label={t('app:photo_gallery_page.chip.upgrade')}
            />
          </Grid>
        ) }
      </Grid>
    </Grid>
  )
}

export default PlanNameElement
