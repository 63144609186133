import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import BatteryHelperImage from 'spypoint/public/images/battery-helper.png'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '260px',
    height: '382px',
    justifyContent: 'center',
    backgroundColor: '#FFFFFF',
  },
  title: {
    height: '32px',
    flexShrink: 0,
    width: '100%',
    textAlign: 'center',
    backgroundColor: theme.palette.primary.pending,

  },
  text: {
    fontFamily: 'oswald-normal',
    fontSize: '21px',
    color: theme.palette.primary.contrastText,
  },
  imageHelper: {
    textAlign: 'center',
    marginTop: '6px',
  },
  image: {
    width: '165px',
    height: 'auto',
  },
  descriptorContainer: {
    padding: '4px 16px',
    textAlign: 'center',
  },
  descriptor: {
    fontFamily: 'oswald-normal',
    fontSize: '16px',
    color: theme.palette.primary.contrastText,
  },
  descriptionsContainer: {
    padding: '8px 16px',
    textAlign: 'center',
  },
  descriptions: {
    fontWeight: 400,
    fontSize: '0.813rem',
    fontFamily: 'ibm-plex-sans-regular',
    color: theme.palette.primary.contrastText,
    paddingBottom: '8px',
  },
}))

const BatteryHelper = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Box className={classes.container}>
      <Box component="div" className={classes.title}>
        <Typography className={classes.text}> { t('add_camera.battery_helper.header') }</Typography>
      </Box>

      <Box component="div" className={classes.imageHelper}>
        <img src={BatteryHelperImage} alt="battery-helper" className={classes.image} />
      </Box>

      <Box component="div" className={classes.descriptorContainer}>
        <Typography className={classes.descriptor}>
          { t('add_camera.battery_helper.descriptor_title') }
        </Typography>
      </Box>

      <Box component="div" className={classes.descriptorContainer}>
        <Typography className={classes.descriptions}>
          { t('add_camera.battery_helper.descriptor_body') }
        </Typography>

        <Typography className={classes.descriptions}>
          { t('add_camera.battery_helper.descriptions') }
        </Typography>
      </Box>
    </Box>
  )
}

export default BatteryHelper
