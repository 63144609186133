import { useHistory } from 'react-router-dom'
import useQueryParams from 'shared-module/url/useQueryParams'
import { INSIDERS_CLUB_MEMBERSHIP, PHOTOHD, VIDEOHD } from 'spypoint/src/checkout-module/order/core/productTypes'
import { useCart } from 'spypoint/src/checkout-module/order/core/cart.hooks'

const useDirectPurchaseLogic = () => {
  const queryParams = useQueryParams()
  const history = useHistory()
  const { addToCart, cartItems, hdPhotosItemInCart, hdVideosItemInCart, updateQuantity } = useCart()

  const directPurchase = () => {
    if (queryParams.become_member === true) {
      addToCart({ productId: INSIDERS_CLUB_MEMBERSHIP, paymentFrequency: 'annual' })
    } else if (queryParams.photo_hd === true) {
      if (!cartItems.isFetched) return
      hdPhotosItemInCart
        ? updateQuantity({ itemId: hdPhotosItemInCart.id, quantity: hdPhotosItemInCart.quantity + 1 })
        : addToCart({ productId: PHOTOHD })
    } else if (queryParams.video_hd === true) {
      if (!cartItems.isFetched) return
      hdVideosItemInCart
        ? updateQuantity({ itemId: hdVideosItemInCart.id, quantity: hdVideosItemInCart.quantity + 1 })
        : addToCart({ productId: VIDEOHD })
    } else if (queryParams.transmission_plan) {
      return history.push('/camera/plan?transmission_plan=true')
    }
  }

  return { directPurchase, isCartItemsFetched: cartItems.isFetched }
}

export default useDirectPurchaseLogic
