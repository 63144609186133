import { useTranslation } from 'react-i18next'
import ConfirmationDialog from 'shared-module/components/confirmation/ConfirmationDialog'
import { useCart } from 'spypoint/src/checkout-module/order/core/cart.hooks'

const WarningDialog = ({ itemId, isOpen, onClose }) => {
  const { t } = useTranslation()
  const { deleteFromCart } = useCart()

  return (
    <ConfirmationDialog
      open={isOpen}
      onClose={onClose}
      title={t('app:checkout_process.insiders_club_subscription.warning_dialog.warning_title')}
      text={t('app:checkout_process.insiders_club_subscription.warning_dialog.warning_text')}
      confirmText={t('app:checkout_process.insiders_club_subscription.warning_dialog.confirm_text').toLocaleUpperCase()}
      cancelText={t('app:checkout_process.insiders_club_subscription.warning_dialog.cancel_text').toLocaleUpperCase()}
      onConfirm={() => deleteFromCart({ itemId })}
      onCancel={onClose}
    />
  )
}

export default WarningDialog
