import { useEffect, useRef, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@mui/material/Box'
import { useTheme } from '@material-ui/core'

const formatTime = (time) => {
  const hour = time.hour || 0
  const minute = time.minute || 0

  return `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`
}
const useStyles = makeStyles((theme) => ({
  styleIcon: {
    color: theme.palette.basic?.grey,
    '& ::-webkit-calendar-picker-indicator': {
      cursor: 'pointer',
      filter: theme.palette.type === 'dark' ? 'invert(0.8)' : 'invert(0.3)',
      fontSize: '20px',
    },
    width: '186px !important',
    height: '50px !important',
    paddingTop: '5px',
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.border?.secondary,
      borderWidth: '2px',
    },
    '& .MuiOutlinedInput-input': {
      padding: '15.5px 14px',
      fontWeight: '600',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.border?.divider,
      borderWidth: '2px',
    },
    '& .MuiSvgIcon-root': {
      fill: `${theme.palette.text?.secondary} !important`,
    },
  },
}))

const TimeField = ({ id, name, time, onChange, dataTestId }) => {
  const classes = useStyles()
  const theme = useTheme()
  const transitTimeRef = useRef()
  const [value, setValue] = useState(formatTime(time))
  const step = time.minute === undefined ? 3600 : 60

  useEffect(() => {
    setValue(formatTime(time))
  }, [time])

  const timeChanged = (event) => {
    const time = event.target.value
    setValue(time)
    onChange(time, name)
  }
  return (
    <Box ref={transitTimeRef} theme={theme}>
      <TextField
        type="time"
        id={id}
        name={name}
        value={value}
        onChange={timeChanged}
        className={classes.styleIcon}
        InputProps={{
          step: step,
        }}
        fullWidth
        variant="outlined"
      />
    </Box>
  )
}

export default TimeField
