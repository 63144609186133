
export const getSharedCameraTabs = () => [
  {
    id: '',
    path: '/',
    label: 'app:sharedCamera.myCameras.title',
    disabled: false,
  },
  {
    id: 'shared-cameras',
    path: '/shared-cameras',
    label: 'app:sharedCamera.shared.title',
    disabled: false,
  },
]

export const getCurrentTabPath = (tabs, pathname) => (
  tabs.filter(({ disabled }) => !disabled).map(tab => tab.path).includes(pathname) ? pathname : tabs[0].path
)
