import React from 'react'
import Box from '@mui/material/Box'
import { makeStyles } from '@material-ui/core/styles'
import SyncPendingIcon from 'assets/icons/SyncPendingIcon'
const useStyles = makeStyles((theme) => ({
  alert: {
    display: 'flex',
    alignItems: 'center',
    background: '#F9B6431A',
    marginTop: '45px',
    border: '1px solid #F9B643',
    padding: '12px 0px',
    borderRadius: '5px',
  },
  content: {
    fontFamily: 'ibm-plex-sans-regular',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '21px',
    textAlign: 'left',
  },
  subContent: {
    fontFamily: 'ibm-plex-sans-regular',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    textAlign: 'left',
  },
}))
const WarningBox = ({ Icon = SyncPendingIcon, text, subText, style }) => {
  const classes = useStyles()
  return (
    <Box className={classes.alert} style={style}>
      <Box>
        <Icon style={{ height: '14px', marginLeft: '8px', position: 'relative', top: '2px' }} />
        <Box display="inline-flex">
          <span className={classes.content}>
            <span>{ text } </span> <br />
            <span className={classes.subContent}>{ subText }</span>
          </span>
        </Box>
      </Box>
    </Box>
  )
}

export default WarningBox
